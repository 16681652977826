import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import FormControl from "@material-ui/core/FormControl";
import axios from "axios";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import apiUrl from "../api";
import CardText from "components/Card/CardText.js";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import CustomInput from "components/CustomInput/CustomInput.js";
import Progress from "../Components/Progress";
import CustomSweet from "../Components/CustomSweet";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { makeApiCall } from "utils/commonCheckFunctions";

const useStyles = makeStyles(cssstyles);
const userType = localStorage.getItem("user_type");
const SUPERADMINUSER = "superadmin";

const base_url = apiUrl;
const sourceCurrencies = [
  { name: "USD", value: "usd" },
  { name: "EUR", value: "eur" },
  { name: "GBP", value: "gbp" },
  { name: "CAD", value: "cad" },
  { name: "NGN", value: "ngn" },
];
const destinationCurrencies = [
  { name: "NGN", value: "ngn" },
  { name: "GHS", value: "ghs" },
  { name: "KES", value: "kes" },
  { name: "INR", value: "inr" },
  { name: "PHP", value: "php" },
  { name: "XOF", value: "xof" },
  { name: "ZAR", value: "zar" },
  { name: "USD", value: "usd" },
  { name: "AUD", value: "aud" },
  { name: "VND", value: "vnd" },
  { name: "IDR", value: "idr" },
  { name: "SGD", value: "sgd" },
  { name: "CNY", value: "cny" },
  { name: "UGX", value: "ugx" },
  { name: "RWF", value: "rwf" },
  { name: "XAF", value: "xaf" },
  { name: "CAD", value: "cad" },
  { name: "BRL", value: "brl" },
  { name: "EUR", value: "eur" },
  { name: "USDT", value: "usdt" },
  { name: "USDC", value: "usdc" }
];

const getBAUserList = (classes, baUsers, handleChange, selectedBA) => {
  if (userType === SUPERADMINUSER) {
    return (
      <GridItem xs={12} sm={12} md={3}>
        <Card>
          <CardBody>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel className={classes.selectLabel}>{"BA *"}</InputLabel>

              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={selectedBA}
                onChange={handleChange}
                inputProps={{
                  name: "selectedBA",
                }}
              >
                {baUsers.map((row) => (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={row.id}
                    key={row.id}
                  >
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CardBody>
        </Card>
      </GridItem>
    );
  } else {
    return null;
  }
};

const CurrencyConversionView = (props = {}) => {
  const { currency, value, id, handleSubmit, label: buttonLabel } = props;
  const [conversionValue, setConverisonValue] = useState(value);
  const classes = useStyles();
  useEffect(() => {
    setConverisonValue(value);
  }, [value]);

  const handleCurrencyValueChange = (event) => {
    const { value } = event.target;
    setConverisonValue(value);
  };

  const getConverionValueElement = () => {
    return (
      <CustomInput
        labelText="Value"
        inputProps={{
          type: "number",
          value: conversionValue,
          onChange: handleCurrencyValueChange,
          inputProps: {
            min: "0",
          },
        }}
      />
    );
  };
  const handleClick = (event) => {
    handleSubmit(currency, conversionValue, id);
  };
  const getActionElement = () => {
    return (
      <Button
        variant="contained"
        size="sm"
        onClick={handleClick}
        disabled={conversionValue === "" || conversionValue < 0}
      >
        {buttonLabel}
      </Button>
    );
  };
  return (
    <TableRow>
      <TableCell className={classes.tableCell}>{currency}</TableCell>
      <TableCell className={classes.tableCell}>
        {getConverionValueElement()}
      </TableCell>
      <TableCell className={classes.tableCell}>{getActionElement()}</TableCell>
    </TableRow>
  );
};

const initialAlertState = {
  alert: false,
  success: false,
  error: false,
  title: "",
  message: "",
};
const tableColumns = ["Source Currency", "Value", "Action"];
const CurrencyConversion = () => {
  const [loading, setLoading] = useState(false);
  const [activeCurrency, setActiveCurrency] = useState("ngn");
  const [ba, setBa] = useState("");
  const [baUsers, setBaUsers] = useState([]);
  const [conversion, setConversion] = useState([]);
  const [alertObject, setAlertObject] = useState(initialAlertState);
  const classes = useStyles();

  useEffect(() => {
    const getAllBaUsers = async () => {
      const data = await makeApiCall({
        url: "/users.json",
        method: "get",
        params: { role: "ba" },
      });
      const { error = false } = data;
      if (!error) {
        setBaUsers(data);
      } else {
        const { message } = data;
        console.log(message);
      }
    };
    getAllBaUsers();
  }, []);

  useEffect(() => {
    if (activeCurrency !== "" && ba !== "" && userType === SUPERADMINUSER) {
      fetchConversionRate();
    } else if (userType !== SUPERADMINUSER) {
      fetchConversionRate();
    }
  }, [activeCurrency, ba]);

  const fetchConversionRate = () => {
    setLoading(true);
    const params = userType === SUPERADMINUSER ? { ba_id: ba } : {};
    axios({
      method: "get",
      url: base_url + "/currency_conversions.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        destination_currency: activeCurrency,
        ...params,
      },
    })
      .then((result) => {
        if (result.data) {
          setConversion(result.data.currency_conversions);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleCurrencyChange = (event) => {
    const { value } = event.target;
    setActiveCurrency(value);
  };

  const handleBAChange = (event) => {
    const { value } = event.target;
    setBa(value);
  };

  const getUpdatedList = (method, data) => {
    if (method === "put") {
      const updatedData = conversion.map((element) => {
        if (element.id === data.id) {
          element = data;
        }
        return element;
      });
      return updatedData;
    } else {
      return [...conversion, data.c];
    }
  };

  const hideAlert = () => {
    setAlertObject(initialAlertState);
  };

  const UpdateOrCreateConversionRate = (sourceCurrency, value, id) => {
    const destinationCurrency = activeCurrency;
    const method = id ? "put" : "post";
    const message = id ? "updated" : "created";
    const url = id
      ? base_url + "/currency_conversions/" + id + ".json"
      : base_url + "/currency_conversions.json";
    const params =
      userType === SUPERADMINUSER ? { "currency_conversion[ba_id]": ba } : {};
    setLoading(true);
    axios({
      method: method,
      url: url,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        "currency_conversion[source_currency]": sourceCurrency.toLowerCase(),
        "currency_conversion[destination_currency]": destinationCurrency,
        "currency_conversion[value]": value,
        ...params,
      },
    })
      .then((result) => {
        const alertObject = {
          alert: true,
          success: true,
          error: false,
          message: `Currency Conversion successfully ${message} !!`,
          title: "Currency Conversion!",
        };
        const updatedList = getUpdatedList(method, result.data);
        setLoading(false);
        setConversion(updatedList);
        setAlertObject(alertObject);
      })
      .catch((error) => {
        const alertObject = {
          alert: true,
          success: false,
          error: true,
          message: `Something Went Wrong :(`,
          title: "Currency Conversion!",
        };
        setLoading(false);
        setAlertObject(alertObject);
      });
  };

  const getDetinationCurrencyList = () => {
    return destinationCurrencies.map((data) => {
      return (
        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value={data.value}
          key={data.value}
        >
          {data.name}
        </MenuItem>
      );
    });
  };

  const getTableData = () => {
    const elements = sourceCurrencies.map((row) => {
      const data = conversion.filter(
        (element) =>
          element.source_currency.toString().toLowerCase() === row.value
      );
      // console.log(data);
      const { value, label, id } =
        data.length > 0
          ? { value: Number(data[0].value), label: "update", id: data[0].id }
          : { value: 0, label: "submit", id: undefined };
      return (
        <CurrencyConversionView
          key={row.name}
          currency={row.name}
          value={value}
          label={label}
          id={id}
          handleSubmit={UpdateOrCreateConversionRate}
        ></CurrencyConversionView>
      );
    });
    return elements;
  };

  return (
    <>
      <CustomSweet
        alert={alertObject.alert}
        message={alertObject.message}
        title={alertObject.title}
        hide_alert={hideAlert}
        success={alertObject.success}
        error={alertObject.error}
      />
      <div className={loading ? classes.main_screen : ""}>
        <div align="center">{loading === true && <Progress />}</div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={3}>
            <Card>
              <CardBody>
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    {"Destination Currency *"}
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={activeCurrency}
                    onChange={handleCurrencyChange}
                    inputProps={{
                      name: "simpleSelect",
                    }}
                  >
                    {getDetinationCurrencyList()}
                  </Select>
                </FormControl>
              </CardBody>
            </Card>
          </GridItem>
          {getBAUserList(classes, baUsers, handleBAChange, ba)}
        </GridContainer>
        <GridContainer>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="rose" text>
                  <CardText color="rose">
                    <h4 className={classes.cardTitle}>
                      {activeCurrency.toString().toUpperCase() +
                        " Currency Conversion"}
                    </h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <Table>
                    <TableHead>
                      <TableRow
                        className={
                          classes.tableRow + " " + classes.tableRowHead
                        }
                      >
                        {tableColumns.map((data) => {
                          return (
                            <TableCell
                              key={data}
                              className={
                                classes.tableHeadCell + " " + classes.tableCell
                              }
                            >
                              {data}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {ba === "" && userType === SUPERADMINUSER ? (
                        <TableRow>
                          <TableCell className={classes.tableCell} style={{textAlign:"center"}} colSpan={3}>
                            Please select BA !!!
                          </TableCell>
                        </TableRow>
                      ) : (
                        getTableData()
                      )}
                    </TableBody>
                  </Table>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridContainer>
      </div>
    </>
  );
};

export default CurrencyConversion;
