export const countryCodes = {
  GH: "233", //Ghana
  GW: "245", //Guinea Bissau
  BJ: "229", //Benin
  TG: "228", //Togo
  NE: "227", //Niger
  ML: "223", //Mali
  BF: "226", //Burkina Faso
  SN: "221", //Senegal
  CI: "225", //Ivory Coast
  SL: "232", //Sierra Leone
};
