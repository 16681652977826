import React, { Component } from "react";
import { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CardHeader from "components/Card/CardHeader.js";
import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import CardText from "components/Card/CardText.js";
import axios from "axios";
import apiUrl from "../api";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Progress from "../Components/Progress";
import CustomSweet from "../Components/CustomSweet";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

const base_url = apiUrl;
const userType = localStorage.getItem("user_type");
const SUPERADMINUSER = "superadmin";

class PaymentGatewaySetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settingId:"",
      name: "",
      paymentGatewayEndpoint: "",
      balanceEndpoint: "",
      clientId: "",
      terminalId: "",
      gatewayPin: "",
      apiKey: "",
      secretKey: "",
      senderName: "",
      validateAccountEndpoint: "",
      postEndpoint: "",
      verifyEndpoint: "",
      statusEndpoint: "",
      prefix: "",
      entityCode: "",
      clientName: "",
      originatorName: "",
      vendorId: "",
      sourceAccount: "",
      channelCode: "",
      principal: "",
      credentials: "",
      hashKey: "",
      phoneNumber: "",
      accountNumber: "",
      contactType: "",
      paymentMode: "",
      purpose: "",
      narration: "",
      loginId: "",
      loginEndpoint: "",
      createBenEndpoint: "",
      billerCatalogEndpoint: "",
      ioCatalogEndpoint: "",
      payBillerEndpoint: "",
      skuCatalogEndpoint: "",
      amountDueEndpoint: "",
      xApiKey: "",
      token: "",
      cashierId: "",
      host: "",
      partnerId: "",
      rateEndpoint: "",
      agentId: "",
      xApiSecret: "",
      baId: "",
      userId: "",

      validName: true,
      validBa: true,
      validPaymentGatewayEndpoint: true,
      baUserList: [],
      paymentGatewayList: [],
      selectedCurrencies: new Set(),
      loading: false,
      success: false,
      error: false,
      alert: false,
      message: "",
      title: "",
      update: false,
    };
  }

  componentDidMount() {
    this.getPaymentGateways();
    this.getBaUsers();
  }

  componentDidUpdate(prevProps, prevState) {
    const { name: prevName,baId:prevBaId } = prevState;
    const {
      name,
      validName,
      validBa,
      validPaymentGatewayEndpoint,
      baId,
      paymentGatewayEndpoint,
    } = this.state;
    if (name !== prevName && userType !== SUPERADMINUSER) {
      this.getPaymentGatewayDetails();
    }else if((name!== prevName || prevBaId !== baId)&& userType === SUPERADMINUSER){
      this.getPaymentGatewayDetails();
    }
    if (!validName && name !== "") {
      this.setState({ validName: true });
    }
    if (!validBa && baId !== "") {
      this.setState({ validBa: true });
    }
    if (!validPaymentGatewayEndpoint && paymentGatewayEndpoint !== "") {
      this.setState({ validPaymentGatewayEndpoint: true });
    }
  }

  getPaymentGatewayDetails = () => {
    const { name,baId } = this.state;
    const isSuperAdmin = userType === SUPERADMINUSER;
    if(isSuperAdmin && (baId ===""||name ==="")){
      return;
    }
    const params = isSuperAdmin?{ba_id:baId}:{};
    this.setState({ loading: true });
    axios({
      method: "get",
      url: base_url + "/show_payment_gateway_settings.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        payment_gateway: name,
        ...params,
      },
    })
      .then((result) => {
        //access the results here....
        const [setting] = result.data.payment_gateway_settings;
        const updatedSetting = this.fillPaymentGatewayDetails(setting);
        if (updatedSetting) {
          this.setState({
            ...updatedSetting,
            update: true,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
          });
          this.clearFields();
        }
      })
      .catch((error) => {
        console.log("Error", error);
        this.setState({
          update: false,
        });
      });
  };

  fillPaymentGatewayDetails = (setting = null) => {
    let updatedSetting = null;
    if (setting) {
      updatedSetting = {
        settingId:setting.id?setting.id:"",
        accountNumber: setting.account_number ? setting.account_number : "",
        agentId: setting.agent_id ? setting.agent_id : "",
        amountDueEndpoint: setting.amount_due_endpoint
          ? setting.amount_due_endpoint
          : "",
        apiKey: setting.api_key ? setting.api_key : "",
        baId: setting.ba_id ? setting.ba_id : "",
        balanceEndpoint: setting.balance_endpoint
          ? setting.balance_endpoint
          : "",
        billerCatalogEndpoint: setting.biller_catalog_endpoint
          ? setting.biller_catalog_endpoint
          : "",
        cashierId: setting.cashier_id ? setting.cashier_id : "",
        channelCode: setting.channel_code ? setting.channel_code : "",
        clientId: setting.client_id ? setting.client_id : "",
        clientName: setting.client_name ? setting.client_name : "",
        contactType: setting.contact_type ? setting.contact_type : "",
        createBenEndpoint: setting.create_ben_endpoint
          ? setting.create_ben_endpoint
          : "",
        credentials: setting.credentials ? setting.credentials : "",
        entityCode: setting.entity_code ? setting.entity_code : "",
        gatewayPin: setting.gateway_pin ? setting.gateway_pin : "",
        hashKey: setting.hash_key ? setting.hash_key : "",
        host: setting.host ? setting.host : "",
        ioCatalogEndpoint: setting.ioCatalogEndpoint
          ? setting.ioCatalogEndpoint
          : "",
        loginEndpoint: setting.login_endpoint ? setting.login_endpoint : "",
        loginId: setting.login_id ? setting.login_id : "",
        name: setting.name ? setting.name : "",
        narration: setting.narration ? setting.narration : "",
        originatorName: setting.originator_name ? setting.originator_name : "",
        partnerId: setting.partner_id ? setting.partner_id : "",
        payBillerEndpoint: setting.pay_biller_endpoint
          ? setting.pay_biller_endpoint
          : "",
        paymentGatewayEndpoint: setting.payment_gateway_endpoint
          ? setting.payment_gateway_endpoint
          : "",
        paymentMode: setting.payment_mode ? setting.payment_mode : "",
        phoneNumber: setting.phone_number ? setting.phone_number : "",
        postEndpoint: setting.post_endpoint ? setting.post_endpoint : "",
        prefix: setting.prefix ? setting.prefix : "",
        principal: setting.principal ? setting.principal : "",
        purpose: setting.purpose ? setting.purpose : "",
        rateEndpoint: setting.rate_endpoint ? setting.rate_endpoint : "",
        secretKey: setting.secret_key ? setting.secret_key : "",
        senderName: setting.sender_name ? setting.sender_name : "",
        skuCatalogEndpoint: setting.sku_catalog_endpoint
          ? setting.sku_catalog_endpoint
          : "",
        sourceAccount: setting.source_account ? setting.source_account : "",
        statusEndpoint: setting.status_endpoint ? setting.status_endpoint : "",
        terminalId: setting.terminal_id ? setting.terminal_id : "",
        token: setting.token ? setting.token : "",
        userId: setting.userId ? setting.userId : "",
        validateAccountEndpoint: setting.validate_account_endpoint
          ? setting.validate_account_endpoint
          : "",
        vendorId: setting.vendor_id ? setting.vendor_id : "",
        verifyEndpoint: setting.verify_endpoint ? setting.verify_endpoint : "",
        xApiKey: setting.x_api_key ? setting.x_api_key : "",
        xApiSecret: setting.x_api_secret ? setting.x_api_secret : "",
      };
    }
    return updatedSetting;
  };

  clearFields=()=>{
    this.setState({
      paymentGatewayEndpoint: "",
      balanceEndpoint: "",
      clientId: "",
      terminalId: "",
      gatewayPin: "",
      apiKey: "",
      secretKey: "",
      senderName: "",
      validateAccountEndpoint: "",
      postEndpoint: "",
      verifyEndpoint: "",
      statusEndpoint: "",
      prefix: "",
      entityCode: "",
      clientName: "",
      originatorName: "",
      vendorId: "",
      sourceAccount: "",
      channelCode: "",
      principal: "",
      credentials: "",
      hashKey: "",
      phoneNumber: "",
      accountNumber: "",
      contactType: "",
      paymentMode: "",
      purpose: "",
      narration: "",
      loginId: "",
      loginEndpoint: "",
      createBenEndpoint: "",
      billerCatalogEndpoint: "",
      ioCatalogEndpoint: "",
      payBillerEndpoint: "",
      skuCatalogEndpoint: "",
      amountDueEndpoint: "",
      xApiKey: "",
      token: "",
      cashierId: "",
      host: "",
      partnerId: "",
      rateEndpoint: "",
      agentId: "",
      xApiSecret: "",
      // baId: "",
      userId: "",
      update:false,
    })
  }
  getBaUsers = () => {
    axios({
      method: "get",
      url: base_url + "/users.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: { role: "ba" },
    })
      .then((result) => {
        //access the results here....
        this.setState({
          baUserList: result.data,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  getPaymentGateways = () => {
    axios({
      method: "get",
      url: base_url + "/payment_gateways.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {},
    })
      .then((result) => {
        //access the results here....
        this.setState({
          paymentGatewayList: result.data.payment_gateways,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
    });
  };

  handleCurrencyChange = (event) => {
    const { value } = event.target;
    const { selectedCurrencies } = this.state;
    if (selectedCurrencies.has(value)) {
      selectedCurrencies.delete(value);
    } else {
      selectedCurrencies.add(value);
    }
    this.setState({
      selectedCurrencies,
    });
  };

  handleUpdate = () => {
    const valid = this.validateForm();
    if (valid) {
      const {
        name,
        paymentGatewayEndpoint,
        balanceEndpoint,
        clientId,
        terminalId,
        gatewayPin,
        apiKey,
        secretKey,
        senderName,
        validateAccountEndpoint,
        postEndpoint,
        verifyEndpoint,
        statusEndpoint,
        prefix,
        entityCode,
        clientName,
        originatorName,
        vendorId,
        sourceAccount,
        channelCode,
        principal,
        credentials,
        hashKey,
        phoneNumber,
        accountNumber,
        contactType,
        paymentMode,
        purpose,
        narration,
        loginId,
        loginEndpoint,
        createBenEndpoint,
        billerCatalogEndpoint,
        ioCatalogEndpoint,
        payBillerEndpoint,
        skuCatalogEndpoint,
        amountDueEndpoint,
        xApiKey,
        token,
        cashierId,
        host,
        partnerId,
        rateEndpoint,
        agentId,
        xApiSecret,
        baId,
        userId,
        update,
        settingId,
      } = this.state;
      this.setState({ loading: true });
      const params = {
        "payment_gateway_setting[name]": name,
        "payment_gateway_setting[payment_gateway_endpoint]": paymentGatewayEndpoint,
        "payment_gateway_setting[balance_endpoint]": balanceEndpoint,
        "payment_gateway_setting[client_id]": clientId,
        "payment_gateway_setting[terminal_id]": terminalId,
        "payment_gateway_setting[gateway_pin]": gatewayPin,
        "payment_gateway_setting[api_key]": apiKey,
        "payment_gateway_setting[secret_key]": secretKey,
        "payment_gateway_setting[sender_name]": senderName,
        "payment_gateway_setting[validate_account_endpoint]": validateAccountEndpoint,
        "payment_gateway_setting[post_endpoint]": postEndpoint,
        "payment_gateway_setting[verify_endpoint]": verifyEndpoint,
        "payment_gateway_setting[status_endpoint]": statusEndpoint,
        "payment_gateway_setting[prefix]": prefix,
        "payment_gateway_setting[entity_code]": entityCode,
        "payment_gateway_setting[client_name]": clientName,
        "payment_gateway_setting[originator_name]": originatorName,
        "payment_gateway_setting[vendor_id]": vendorId,
        "payment_gateway_setting[source_account]": sourceAccount,
        "payment_gateway_setting[channel_code]": channelCode,
        "payment_gateway_setting[principal]": principal,
        "payment_gateway_setting[credentials]": credentials,
        "payment_gateway_setting[hash_key]": hashKey,
        "payment_gateway_setting[phone_number]": phoneNumber,
        "payment_gateway_setting[account_number]": accountNumber,
        "payment_gateway_setting[contact_type]": contactType,
        "payment_gateway_setting[payment_mode]": paymentMode,
        "payment_gateway_setting[purpose]": purpose,
        "payment_gateway_setting[narration]": narration,
        "payment_gateway_setting[login_id]": loginId,
        "payment_gateway_setting[login_endpoint]": loginEndpoint,
        "payment_gateway_setting[create_ben_endpoint]": createBenEndpoint,
        "payment_gateway_setting[biller_catalog_endpoint]": billerCatalogEndpoint,
        "payment_gateway_setting[io_catalog_endpoint]": ioCatalogEndpoint,
        "payment_gateway_setting[pay_biller_endpoint]": payBillerEndpoint,
        "payment_gateway_setting[sku_catalog_endpoint]": skuCatalogEndpoint,
        "payment_gateway_setting[amount_due_endpoint]": amountDueEndpoint,
        "payment_gateway_setting[x_api_key]": xApiKey,
        "payment_gateway_setting[token]": token,
        "payment_gateway_setting[cashier_id]": cashierId,
        "payment_gateway_setting[host]": host,
        "payment_gateway_setting[partner_id]": partnerId,
        "payment_gateway_setting[rate_endpoint]": rateEndpoint,
        "payment_gateway_setting[agent_id]": agentId,
        "payment_gateway_setting[x_api_secret]": xApiSecret,
        "payment_gateway_setting[ba_id]": baId,
        "payment_gateway_setting[user_id]": userId,
      };
      const message = update?"Updated":"Created";
      const method = update?"put":"post";
      const title =`${update?"Update ":"Create "} Payment Gateway Setting !!!`;
      const url = update?`/payment_gateway_settings/${settingId}.json`:`/payment_gateway_settings.json`
      axios({
        method: method,
        url: base_url + url,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        },
        params: params,
      })
        .then((result) => {
          //access the results here....
          this.setState({
            loading: false,
            alert: true,
            message: `Settings ${message} Successfully!!`,
            title,
            success: true,
            error: false,
          });
        })
        .catch((error) => {
          console.log("Error", error);
          this.setState({
            loading: false,
            alert: true,
            message: "Something went wrong !!",
            title,
            error: true,
            success: false,
          });
        });
    }
  };

  validateForm = () => {
    const { name, baId, paymentGatewayEndpoint } = this.state;

    if (name === "") {
      alert("Select Paymen Gateway !!");
      this.setState({
        validName: false,
      });
      return false;
    }

    if (baId === "") {
      alert("Select BA !!");
      this.setState({
        validBa: false,
      });
      return false;
    }

    if (paymentGatewayEndpoint === "") {
      alert("Enter Payment Gateway EndPoint !!");
      this.setState({
        validPaymentGatewayEndpoint: false,
      });
      return false;
    }

    return true;
  };

  hideAlert = () => {
    this.setState({
      alert: false,
      message: "",
      success: false,
      error: false,
    });
  };

  getPaymentGatewayList = () => {
    const { classes } = this.props;
    const { paymentGatewayList } = this.state;
    return paymentGatewayList.map((data) => {
      return (
        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value={data.name}
          key={data.id}
        >
          {data.name}
        </MenuItem>
      );
    });
  };

  getBaUserList = () => {
    const { classes } = this.props;
    const { baUserList } = this.state;
    return baUserList.map((data) => {
      return (
        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value={data.id}
          key={data.id}
        >
          {data.name}
        </MenuItem>
      );
    });
  };

  render() {
    const {
      name,
      message,
      title,
      alert,
      error,
      success,
      paymentGatewayEndpoint,
      balanceEndpoint,
      clientId,
      terminalId,

      gatewayPin,
      apiKey,
      secretKey,
      senderName,
      validateAccountEndpoint,
      postEndpoint,
      verifyEndpoint,
      statusEndpoint,
      prefix,
      entityCode,
      clientName,
      originatorName,
      vendorId,
      sourceAccount,
      channelCode,
      principal,
      credentials,
      hashKey,
      accountNumber,
      phoneNumber,
      contactType,
      paymentMode,
      purpose,
      narration,
      loginId,
      loginEndpoint,
      createBenEndpoint,
      billerCatalogEndpoint,
      ioCatalogEndpoint,
      payBillerEndpoint,
      skuCatalogEndpoint,
      amountDueEndpoint,
      xApiKey,
      token,
      cashierId,
      host,
      partnerId,
      rateEndpoint,
      agentId,
      xApiSecret,
      baId,
      userId,
      validName,
      validBa,
      validPaymentGatewayEndpoint,
      update
    } = this.state;
    const { classes } = this.props;
    return (
      <Fragment>
        <CustomSweet
          alert={alert}
          message={message}
          title={title}
          hide_alert={this.hideAlert}
          danger={error}
          success={success}
        />
        <div className={this.state.loading === true ? classes.main_screen : ""}>
          <div align="center">
            {this.state.loading === true && <Progress />}
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" text>
                  <CardText color="rose">
                    <h4>{update?"Update ":"Create "}Payment Gateway Setting</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth error={!validName}>
                        <InputLabel
                          htmlFor="name"
                          className={classes.selectLabel}
                        >
                          {"Payment Gateway *"}
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={name}
                          onChange={this.handleChange}
                          inputProps={{
                            name: "name",
                          }}
                        >
                          {this.getPaymentGatewayList()}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth error={!validBa}>
                        <InputLabel
                          htmlFor="baId"
                          className={classes.selectLabel}
                        >
                          {"BA *"}
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={baId}
                          onChange={this.handleChange}
                          inputProps={{
                            name: "baId",
                          }}
                        >
                          {this.getBaUserList()}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            // placeholder: "Payment Gateway Endpoint",
                            value: paymentGatewayEndpoint,
                            onChange: this.handleChange,
                            name: "paymentGatewayEndpoint",
                            autoComplete: "off",
                          }}
                          labelText="Payment Gateway Endpoint"
                          id="Payment Gateway Endpoint"
                          error= {!validPaymentGatewayEndpoint}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: balanceEndpoint,
                            onChange: this.handleChange,
                            name: "balanceEndpoint",
                            autoComplete: "off",
                          }}
                          labelText="Balance Endpoint"
                          id="Balance Endpoint"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: clientId,
                            onChange: this.handleChange,
                            name: "clientId",
                            autoComplete: "off",
                          }}
                          labelText= "Client ID "
                          id="Client ID"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: terminalId,
                            onChange: this.handleChange,
                            name: "terminalId",
                            autoComplete: "off",
                          }}
                          labelText= "Termianl ID"
                          id="Termianl ID" 

                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: gatewayPin,
                            onChange: this.handleChange,
                            name: "gatewayPin",
                            autoComplete: "off",
                          }}
                          labelText="Gateway Pin"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: apiKey,
                            onChange: this.handleChange,
                            name: "apiKey",
                            autoComplete: "off",
                          }}
                          labelText="Api key"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: secretKey,
                            onChange: this.handleChange,
                            name: "secretKey",
                            autoComplete: "off",
                          }}
                          labelText="Secret Key"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: senderName,
                            onChange: this.handleChange,
                            name: "senderName",
                            autoComplete: "off",
                          }}
                          labelText="Sender Name Pin"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: validateAccountEndpoint,
                            onChange: this.handleChange,
                            name: "validateAccountEndpoint",
                            autoComplete: "off",
                          }}
                          labelText="Validate Account Endpoint"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: postEndpoint,
                            onChange: this.handleChange,
                            name: "postEndpoint",
                            autoComplete: "off",
                          }}
                          labelText="Post Endpoint"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: verifyEndpoint,
                            onChange: this.handleChange,
                            name: "verifyEndpoint",
                            autoComplete: "off",
                          }}
                          labelText="Verify Endpoint"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: statusEndpoint,
                            onChange: this.handleChange,
                            name: "statusEndpoint",
                            autoComplete: "off",
                          }}
                          labelText="Status Endpoint"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: prefix,
                            onChange: this.handleChange,
                            name: "prefix",
                            autoComplete: "off",
                          }}
                          labelText="Prefix"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: entityCode,
                            onChange: this.handleChange,
                            name: "entityCode",
                            autoComplete: "off",
                          }}
                          labelText="Entity Code"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: clientName,
                            onChange: this.handleChange,
                            name: "clientName",
                            autoComplete: "off",
                          }}
                          labelText="Client Name"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: originatorName,
                            onChange: this.handleChange,
                            name: "originatorName",
                            autoComplete: "off",
                          }}
                          labelText="Originator Name"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: vendorId,
                            onChange: this.handleChange,
                            name: "vendorId",
                            autoComplete: "off",
                          }}
                          labelText="Vendor Id"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: sourceAccount,
                            onChange: this.handleChange,
                            name: "sourceAccount",
                            autoComplete: "off",
                          }}
                          labelText="Source Account"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: channelCode,
                            onChange: this.handleChange,
                            name: "channelCode",
                            autoComplete: "off",
                          }}
                          labelText="Channel Code"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: principal,
                            onChange: this.handleChange,
                            name: "principal",
                            autoComplete: "off",
                          }}
                          labelText="Principal"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: credentials,
                            onChange: this.handleChange,
                            name: "credentials",
                            autoComplete: "off",
                          }}
                          labelText="credentials"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: hashKey,
                            onChange: this.handleChange,
                            name: "hashKey",
                            autoComplete: "off",
                          }}
                          labelText="Hash Key"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: accountNumber,
                            onChange: this.handleChange,
                            name: "accountNumber",
                            autoComplete: "off",
                          }}
                          labelText="Account Number"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: phoneNumber,
                            onChange: this.handleChange,
                            name: "phoneNumber",
                            autoComplete: "off",
                          }}
                          labelText="Phone Number"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: contactType,
                            onChange: this.handleChange,
                            name: "contactType",
                            autoComplete: "off",
                          }}
                          labelText="Contact Type"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: paymentMode,
                            onChange: this.handleChange,
                            name: "paymentMode",
                            autoComplete: "off",
                          }}
                          labelText="Payment Mode"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: purpose,
                            onChange: this.handleChange,
                            name: "purpose",
                            autoComplete: "off",
                          }}
                          labelText="Purpose"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: narration,
                            onChange: this.handleChange,
                            name: "narration",
                            autoComplete: "off",
                          }}
                          labelText="Narration"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: loginId,
                            onChange: this.handleChange,
                            name: "loginId",
                            autoComplete: "off",
                          }}
                          labelText="Login Id"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: loginEndpoint,
                            onChange: this.handleChange,
                            name: "loginEndpoint",
                            autoComplete: "off",
                          }}
                          labelText="Login Endpoint"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: createBenEndpoint,
                            onChange: this.handleChange,
                            name: "createBenEndpoint",
                            autoComplete: "off",
                          }}
                          labelText="Create Ben Endpoint"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: billerCatalogEndpoint,
                            onChange: this.handleChange,
                            name: "billerCatalogEndpoint",
                            autoComplete: "off",
                          }}
                          labelText="Biller Catalog Endpoint"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: ioCatalogEndpoint,
                            onChange: this.handleChange,
                            name: "ioCatalogEndpoint",
                            autoComplete: "off",
                          }}
                          labelText="IO Catalog Endpoint"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: payBillerEndpoint,
                            onChange: this.handleChange,
                            name: "payBillerEndpoint",
                            autoComplete: "off",
                          }}
                          labelText="Pay Biller Endpoint"

                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: skuCatalogEndpoint,
                            onChange: this.handleChange,
                            name: "skuCatalogEndpoint",
                            autoComplete: "off",
                          }}
                          labelText="Sku Catalog Endpoint"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: amountDueEndpoint,
                            onChange: this.handleChange,
                            name: "amountDueEndpoint",
                            autoComplete: "off",
                          }}
                          labelText="Amount Due Endpoint"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            placeholder: "",
                            value: xApiKey,
                            onChange: this.handleChange,
                            name: "xApiKey",
                            autoComplete: "off",
                          }}
                          labelText="X Api Key"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: token,
                            onChange: this.handleChange,
                            name: "token",
                            autoComplete: "off",
                          }}
                          labelText="Token"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: cashierId,
                            onChange: this.handleChange,
                            name: "cashierId",
                            autoComplete: "off",
                          }}
                          labelText="Cashier Id"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: host,
                            onChange: this.handleChange,
                            name: "host",
                            autoComplete: "off",
                          }}
                          labelText="Host"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: partnerId,
                            onChange: this.handleChange,
                            name: "partnerId",
                            autoComplete: "off",
                          }}
                          labelText="Partner Id"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: rateEndpoint,
                            onChange: this.handleChange,
                            name: "rateEndpoint",
                            autoComplete: "off",
                          }}
                          labelText="Rate Endpoint"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: agentId,
                            onChange: this.handleChange,
                            name: "agentId",
                            autoComplete: "off",
                          }}
                          labelText="Agent Id"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: xApiSecret,
                            onChange: this.handleChange,
                            name: "xApiSecret",
                            autoComplete: "off",
                          }}
                          labelText="x Api Secret"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: userId,
                            onChange: this.handleChange,
                            name: "userId",
                            autoComplete: "off",
                          }}
                          labelText="User Id"
                        />
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="center" style={{ marginTop: "40px" }}>
                    <GridItem xs={12} md={3} sm={12}>
                      <FormControl fullWidth>
                        <Button color="rose" onClick={this.handleUpdate}>
                        {update?"Update ":"Create "} Gateway Setting
                        </Button>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </Fragment>
    );
  }
}

export default withStyles(cssstyles)(PaymentGatewaySetting);
