import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import FormControl from "@material-ui/core/FormControl";
import axios from "axios";
import Table from "components/Table/Table.js";
import apiUrl from "../api";
import CardText from "components/Card/CardText.js";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import CustomInput from "components/CustomInput/CustomInput.js";
import Progress from "../Components/Progress";
import CustomSweet from "../Components/CustomSweet";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { makeApiCall } from "utils/commonCheckFunctions";

const base_url = apiUrl;
const userType = localStorage.getItem("user_type");
const SUPERADMINUSER = "superadmin";
const CurrencyDetails = React.forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const onChangeInput = (event) => {
    setValue(event.target.value);
  };

  return (
    <CustomInput
      id="value"
      labelText="Value"
      inputProps={{
        type: "number",
        value: value,
        onChange: onChangeInput,
        inputRef: ref,
      }}
    />
  );
});

const ActionButton = (props) => {
  const label = props.label;

  const handleClick = () => {
    const {
      id,
      sourceCurrency,
      destinationCurrency,
      element: { value },
      handleClick: handleButtonClick,
    } = props;
    //  console.log(value,sourceCurrency,destinationCurrency,id);
    handleButtonClick(value, sourceCurrency, destinationCurrency, id);
  };
  return (
    <Button variant="contained" size="sm" onClick={handleClick}>
      {label}
    </Button>
  );
};

class SourceCurrencyConversion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      conversions: [],
      source_currencies: [
        { name: "USD", value: "usd", ref: React.createRef() },
        { name: "EUR", value: "eur", ref: React.createRef() },
        { name: "GBP", value: "gbp", ref: React.createRef() },
        { name: "CAD", value: "cad", ref: React.createRef() },
        { name: "NGN", value: "ngn", ref: React.createRef() },
      ],
      destinationCurrencies: [
        { name: "USD", value: "usd" },
        { name: "EUR", value: "eur" },
        { name: "GBP", value: "gbp" },
        { name: "CAD", value: "cad" },
        { name: "BRL", value: "brl" },
      ],
      activeCurrency: "usd",
      selectedBA: "",
      baUsers: [],
    };
  }
  componentDidMount() {
    if (userType !== SUPERADMINUSER) {
      this.getConversionData(this.state.activeCurrency);
    } else {
      this.getAllBaUsers();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (userType === SUPERADMINUSER) {
      const { selectedBA: prevBA } = prevState;
      const { activeCurrency, selectedBA } = this.state;
      if (selectedBA !== prevBA) {
        this.getConversionData(activeCurrency);
      }
    }
  }

  handleCurrencyChange = (event) => {
    event.preventDefault();
    const { activeCurrency } = this.state;
    const value = event.target.value;
    if (value !== activeCurrency) {
      this.setState({ activeCurrency: value });
      this.getConversionData(value);
    }
  };

  handeleBaChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  getBAUserList = () => {
    const { classes } = this.props;
    if (userType === SUPERADMINUSER) {
      return (
        <GridItem xs={12} sm={12} md={3}>
          <Card>
            <CardBody>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel className={classes.selectLabel}>
                  {"BA *"}
                </InputLabel>

                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={this.state.selectedBA}
                  onChange={this.handeleBaChange}
                  inputProps={{
                    name: "selectedBA",
                  }}
                >
                  {this.state.baUsers.map((row) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={row.id}
                      key={row.id}
                    >
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CardBody>
          </Card>
        </GridItem>
      );
    } else {
      return null;
    }
  };

  getAllBaUsers = async () => {
    const data = await makeApiCall({
      url: "/users.json",
      method: "get",
      params: { role: "ba" },
    });
    const { error = false } = data;
    if (!error) {
      this.setState({
        baUsers: data,
      });
    } else {
      const { message } = data;
      console.log(message);
    }
  };

  getConversionData = (currency) => {
    this.setState({ loading: true });
    const params =
      userType === SUPERADMINUSER ? { ba_id: this.state.selectedBA } : {};
    axios({
      method: "get",
      url: base_url + "/source_currency_conversions.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        source_currency_output: currency,
        ...params,
      },
    })
      .then((result) => {
        if (result.data) {
          this.setState({
            conversions: result.data.source_currency_conversions,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          show_error: true,
          error_message: error.response.data
            ? error.response.data.message
            : error.message,
        });
      });
  };

  updateDataOrCreateData = (method, data) => {
    const { conversions } = this.state;
    if (method === "put") {
      const updatedData = conversions.map((element) => {
        if (element.id === data.id) {
          element = data;
        }
        return element;
      });
      return updatedData;
    } else {
      return [...conversions, data.c];
    }
  };

  handleButtonClick = (value, sourceCurrency, destinationCurrency, id) => {
    const method = id ? "put" : "post";
    const message = id ? "updated" : "created";
    const url = id
      ? base_url + "/source_currency_conversions/" + id + ".json"
      : base_url + "/source_currency_conversions.json";
    this.setState({ loading: true });
    const params =
      userType === SUPERADMINUSER
        ? { "source_currency_conversion[ba_id]": this.state.selectedBA }
        : {};
    axios({
      method: method,
      url: url,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        "source_currency_conversion[source_currency_input]": sourceCurrency,
        "source_currency_conversion[source_currency_output]": destinationCurrency,
        "source_currency_conversion[value]": value,
        ...params,
      },
    })
      .then((result) => {
        this.setState({
          loading: false,
          alert: true,
          alert_msg: `Currency Conversion successfully ${message}.!`,
          alert_title: "Currency Conversion!",
          danger: false,
          warning: false,
          success: true,
          conversions: this.updateDataOrCreateData(method, result.data),
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
          alert: true,
          alert_msg: "Something Went Wrong !!",
          alert_title: "Error!",
          danger: true,
          warning: false,
          success: false,
        });
      });
  };
  getDetinationCurrencyList = () => {
    const { classes } = this.props;
    return this.state.destinationCurrencies.map((data) => {
      return (
        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value={data.value}
          key={data.value}
        >
          {data.name}
        </MenuItem>
      );
    });
  };

  getData = () => {
    if (userType === SUPERADMINUSER && this.state.selectedBA === "") {
      return [];
    }
    return this.state.source_currencies.map((row) => {
      const { conversions, activeCurrency } = this.state;
      const data = conversions.filter(
        (element) => element.source_currency_input === row.value
      );
      const { value, label, id } =
        data.length > 0
          ? { value: Number(data[0].value), label: "update", id: data[0].id }
          : { value: 0, label: "submit", id: undefined };
      return [
        row.name,
        <CurrencyDetails ref={row.ref} value={value} />,
        <ActionButton
          label={label}
          element={row.ref.current}
          sourceCurrency={row.value}
          destinationCurrency={activeCurrency}
          id={id}
          handleClick={this.handleButtonClick}
        />,
      ];
    });
  };
  hideAlert = () => {
    this.setState({
      alert: false,
      alert_msg: "",
      alert_title: "",
      alert_type: "",
    });
  };
  render() {
    const { classes } = this.props;
    var settingData = this.getData();
    // console.log(this.state);

    return (
      <div>
        <CustomSweet
          alert={this.state.alert}
          message={this.state.alert_msg}
          title={this.state.alert_title}
          hide_alert={this.hideAlert}
          danger={this.state.danger}
          warning={this.state.warning}
          success={this.state.success}
          times={this.state.times}
        />
        <div className={this.state.loading === true ? classes.main_screen : ""}>
          <div align="center">
            {this.state.loading === true && <Progress />}
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody>
                  <FormControl fullWidth>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      {"Destination Currency *"}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={this.state.activeCurrency}
                      onChange={this.handleCurrencyChange}
                      inputProps={{
                        name: "activeCurrency",
                      }}
                    >
                      {this.getDetinationCurrencyList()}
                    </Select>
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>

            {this.getBAUserList()}
          </GridContainer>
          <GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <Card>
                  <CardHeader color="rose" text>
                    <CardText color="rose">
                      <h4 className={classes.cardTitle}>
                        {this.state.activeCurrency.toString().toUpperCase() +
                          " Currency Conversion"}
                      </h4>
                    </CardText>
                  </CardHeader>
                  <CardBody>
                    <Table
                      tableHeaderColor="primary"
                      tableHead={["Source Currency", "Value", "Action"]}
                      tableData={settingData}
                      id="conversionSettings"
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </GridContainer>
        </div>
      </div>
    );
  }
}
export default withStyles(cssstyles)(SourceCurrencyConversion);
