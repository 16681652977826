import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import axios from "axios";
// import { Redirect } from "react-router-dom";
import apiUrl from "../api";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);
const base_url = apiUrl;
export default function LoginPage() {
  const [cardAnimaton, setCardAnimation] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [form, setState] = React.useState({
    isLoggedIn: false,
    user_type: "",
    ba_id:"",
    session_token: "",
    show_error: false,
    error_message: "",
  });
  // setTimeout(function() {
  //   setCardAnimation("");
  // }, 700);
  const classes = useStyles();
  const onSubmit = (e) => {
    e.preventDefault();
    // get our form data out of state
    // const { username, password } = this.state;
    const base_url = apiUrl;
    // console.log(apiUrl, process.env.NODE_ENV);
    axios
      .post(
        base_url,
        { username: username, password: password },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((result) => {
        //access the results here....
        localStorage.setItem("user_type", result.data.user_role);
        localStorage.setItem("session_token", result.data.jwt);
        localStorage.setItem("ba_id", result.data.ba_id);
        
        getWalletInfo(result.data.user_role, result.data.jwt);
        setState({
          ...form,
          isLoggedIn: true,
          user_type: result.data.user_role,
          session_token: result.data.jwt,
          ba_id: result.data.ba_id,
          show_error: false,
        });
        if (result.data.user_role !== "agent" && result.data.user_role !== "txnagent" && result.data.user_role !== "wiretxnagent" && result.data.user_role !== "cryptoagent")
          window.location.href = "/admin/transactions";
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          setState({
            ...form,
            show_error: true,
            error_message: error.response.data.message,
          });
        }
        console.log("Login Error", error);
      });
  };
  const getWalletInfo = (user_type, session_token) => {
    if (user_type === "agent"|| user_type==="txnagent" || user_type === "wiretxnagent" || user_type === "cryptoagent") {
      axios({
        method: "get",
        url: base_url + "/users/get_user_info.json",
        headers: {
          Authorization: `Bearer ${session_token}`,
        },
        params: {},
      })
        .then((result) => {
          // console.log(result);

          localStorage.setItem(
            "walletStatus",
            JSON.stringify(result.data.client)
          );
          window.location.href = "/admin/transactions";
        })
        .catch((error) => {
          this.setState({
            show_error: true,
            error_message: error.response.data
              ? error.response.data.message
              : error.message,
          });
        });
    }
  };
  const onVerifySubmit = (e) => {
    e.preventDefault();
    window.location.href = "/auth/verify_bank_account";
  };
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h2 className={classes.cardTitle}>Log in</h2>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Username"
                  id="username"
                  value={username}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => setUsername(event.target.value),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Face className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomInput
                  labelText="Password"
                  id="password"
                  value={password}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => setPassword(event.target.value),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                  }}
                />
                {form.show_error && (
                  <span style={{ fontSize: 12, color: "#E2392C" }}>
                    {form.error_message}
                  </span>
                )}
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <div>
                  <GridContainer justify="center">
                    <GridItem>
                      <Button
                        color="rose"
                        size="sm"
                        onClick={(event) => onSubmit(event)}
                      >
                        Sign In
                      </Button>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer justify="center">
                    <GridItem>
                      <Button
                        color="rose"
                        onClick={(event) => onVerifySubmit(event)}
                      >
                        Verify Account
                      </Button>
                    </GridItem>
                  </GridContainer>
                </div>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>{" "}
    </div>
  );
}
