import React from "react";
// react component for creating dynamic tables
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
// @material-ui/icons
import CustomInput from "components/CustomInput/CustomInput.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import CustomSweet from "../Components/CustomSweet";
import Progress from "../Components/Progress";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import apiUrl from "../api";
import { makeApiCall } from "utils/commonCheckFunctions";

const base_url = apiUrl;
const userType = localStorage.getItem("user_type");
const SUPERADMINUSER = "superadmin";

class CreatePayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: 0,
      categories: [],
      billers: [],
      payment_items: [],
      category_id: "",
      biller_id: "",
      client_id: "",
      client_all: [],
      selectedBA: "",
      baUsers: [],
      customer_id: "",
      client_payment_id: "",
      email: "",
      mobile: "",
      payment_code: "",
      loading: false,
      amount: "",
      valid_category_id: "",
      valid_biller_id: "",
      valid_payment_code: "",
      valid_amount: "",
      valid_customer_id: "",
      valid_client_payment_id: "",
      valid_client_id: "",
      valid_email: "",
    };
  }
  componentDidMount() {
    this.getCategories();
    if (userType !== SUPERADMINUSER) {
      this.getAllClients();
    } else {
      this.getAllBaUsers();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { selectedBA: prevSelectedBA } = prevState;
    const { selectedBA } = this.state;
    if (prevSelectedBA !== selectedBA && userType === SUPERADMINUSER) {
      this.getAllClients();
    }
  }

  getAllBaUsers = async () => {
    const data = await makeApiCall({
      url: "/users.json",
      method: "get",
      params: { role: "ba" },
    });
    const { error = false } = data;
    if (!error) {
      this.setState({
        baUsers: data,
      });
    } else {
      const { message } = data;
      console.log(message);
    }
  };

  handleCategoryChange = (event) => {
    this.setState({ category_id: event.target.value, valid_category_id: "" });
    if (event.target.value !== "" && event.target.value !== "select") {
      this.getBillers(event.target.value);
    }
  };
  handleBillerChange = (event) => {
    this.setState({ biller_id: event.target.value, valid_biller_id: "" });
    if (event.target.value !== "" && event.target.value !== "select") {
      this.getPaymentItems(event.target.value);
    }
  };
  handleItemChange = (event) => {
    this.setState({ payment_code: event.target.value, valid_payment_code: "" });
  };
  handleClientChange = (event) => {
    this.setState({ client_id: event.target.value });
  };
  handleCustomerIDChange = (event) => {
    this.setState({ customer_id: event.target.value, valid_customer_id: "" });
  };
  handleClientPaymentIDChange = (event) => {
    this.setState({
      client_payment_id: event.target.value,
      valid_client_payment_id: "",
    });
  };
  handleAmountChange = (event) => {
    this.setState({ amount: event.target.value, valid_amount: "" });
  };
  handleEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };
  handleMobileChange = (event) => {
    this.setState({ mobile: event.target.value });
  };
  handlepay = (event) => {
    event.preventDefault();
    if (this.validateForm()) {
      this.setState({ loading: true });
      this.create_signature();
    }
  };
  create_signature() {
    const {
      payment_code,
      amount,
      customer_id,
      client_payment_id,
      client_id,
      email,
      mobile,
    } = this.state;
    var param_list =
      "amount=" +
      amount +
      "&client_id=" +
      client_id +
      "&client_pmt_id=" +
      client_payment_id +
      "&currency=ngn&customer_id=" +
      customer_id +
      "&payment_code=" +
      payment_code;
    if (email !== "")
      param_list += "&customer_email=" + email.replace(/ {1,}/g, "+");
    if (mobile !== "")
      param_list += "&customer_mobile=" + mobile.replace(/ {1,}/g, "+");
    // const base_url = window.location.origin;
    this.setState({ loading: true });
    axios({
      method: "post",
      url: base_url + "/payments/create_signature.json?" + param_list,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {},
    })
      .then((result) => {
        //access the results here....
        // console.log(result);
        this.setState({ loading: false });
        this.doTransaction(result.data);
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log("Error", error);
        // swal("Error!", "Error. Something went wrong. Please retry.", "error");
      });
  }
  doTransaction(data) {
    const {
      payment_code,
      amount,
      customer_id,
      client_payment_id,
      // client_id,
      email,
      mobile,
    } = this.state;
    var param_list =
      "amount=" +
      amount +
      "&api_token=" +
      data["api_token"] +
      "&client_pmt_id=" +
      client_payment_id +
      "&currency=ngn&customer_id=" +
      customer_id +
      "&payment_code=" +
      payment_code +
      "&tonce=" +
      data["tonce"] +
      "&signature=" +
      data["signature"];

    if (email !== "")
      param_list += "&customer_email=" + email.replace(/ {1,}/g, "+");
    if (mobile !== "")
      param_list += "&customer_mobile_no=" + mobile.replace(/ {1,}/g, "+");
    // const base_url = window.location.origin;
    this.setState({ loading: true });
    var confirmation = window.confirm(
      "Are you sure you want to create payment?"
    );
    if (confirmation) {
      axios({
        method: "post",
        url: base_url + "/api/v1/payments?" + param_list,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        },
        params: {},
      })
        .then((result) => {
          //access the results here....
          //   console.log(result);
          this.setState(
            {
              loading: false,
              alert: true,
              alert_msg: "Payment successfully initiated.!",
              alert_title: "Payment!",
              danger: false,
              warning: false,
              success: true,
              times: "0",
            },
            () => this.cleanInputs()
          );
          // window.location.reload();
        })
        .catch((error) => {
          // console.log("Error", error.response.data);
          this.setState({
            loading: false,
            alert: true,
            alert_msg: error.response.data.error.message,
            alert_title: "Error!",
            danger: true,
            warning: false,
            success: false,
          });
        });
    }
  }
  cleanInputs() {
    this.setState({
      category_id: "",
      biller_id: "",
      payment_code: "",
      amount: "",
      customer_id: "",
      client_payment_id: "",
      client_id: "",
      selectedBA:"",
      email: "",
      mobile: "",
    });
  }
  validateForm() {
    const {
      category_id,
      biller_id,
      payment_code,
      amount,
      customer_id,
      client_payment_id,
      client_id,
      selectedBA,
    } = this.state;

    if (category_id === "" || category_id === "select") {
      alert("Please select Category");
      this.setState({ valid_category_id: "false" });
      return false;
    }
    if (biller_id === "" || biller_id === "select") {
      this.setState({ valid_biller_id: "false" });
      alert("Please select Biller");
      return false;
    }
    if (payment_code === "" || payment_code === "select") {
      this.setState({ valid_payment_code: "false" });
      alert("Please select Payment Item");
      return false;
    }
    if (selectedBA === "" && userType === SUPERADMINUSER) {
      alert("Please select BA !!!");
      return false;
    }
    if (client_id === "" || client_id === "select") {
      this.setState({ valid_client_id: "false" });
      alert("Please select Client");
      return false;
    }
    if (customer_id === "") {
      this.setState({ valid_customer_id: "false" });
      alert("Please enter Customer ID");
      return false;
    }
    if (client_payment_id === "") {
      this.setState({ valid_client_payment_id: "false" });
      alert("Please enter Client Payment ID");
      return false;
    }
    if (amount === "") {
      this.setState({ valid_amount: "false" });
      alert("Please enter Amount");
      return false;
    } else {
      if (amount < 0) {
        this.setState({ valid_amount: "false" });
        alert("Please enter Positive Amount");
        return false;
      }
    }

    return true;
  }
  getAllClients() {
    // const base_url = window.location.origin;
    const params =
      userType === SUPERADMINUSER ? { ba_id: this.state.selectedBA } : {};
    axios({
      method: "get",
      url: base_url + "/clients.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: { ...params },
    })
      .then((result) => {
        //access the results here....
        this.setState({
          client_all: result.data.clients,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }
  getCategories() {
    // var base_url = window.location.origin;
    axios({
      method: "get",
      url: base_url + "/payments/getCategories.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {},
    })
      .then((result) => {
        //access the results here....
        this.setState({
          categories: result.data,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }
  getBillers(category_id) {
    // var base_url = window.location.origin;
    axios({
      method: "get",
      url: base_url + "/payments/getBillers.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        category_id: category_id,
      },
    })
      .then((result) => {
        //access the results here....
        this.setState({
          billers: result.data,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }
  getPaymentItems(biller_id) {
    // var base_url = window.location.origin;
    axios({
      method: "get",
      url: base_url + "/payments/getPaymentItems.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        biller_id: biller_id,
      },
    })
      .then((result) => {
        //access the results here....
        this.setState({
          payment_items: result.data,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }
  hideAlert = (times) => {
    this.setState(
      {
        alert: false,
        alert_msg: "",
        alert_title: "",
        alert_type: "",
      },
      () => {
        if (times === "1") {
          // const base_url = window.location.origin;
        }
      }
    );
  };

  handleBaChange=(event)=>{
   this.setState({
     selectedBA:event.target.value,
     client_id:"",
   })
  }

  getBAUserList() {
    const { classes } = this.props;
    if (userType === SUPERADMINUSER) {
      return (
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel className={classes.selectLabel}>{"BA *"}</InputLabel>

          <Select
            MenuProps={{
              className: classes.selectMenu,
            }}
            classes={{
              select: classes.select,
            }}
            value={this.state.selectedBA}
            onChange={this.handleBaChange}
          >
            {this.state.baUsers.map((row) => (
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value={row.id}
                key={row.id}
              >
                {row.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else {
      return null;
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <CustomSweet
          alert={this.state.alert}
          message={this.state.alert_msg}
          title={this.state.alert_title}
          hide_alert={this.hideAlert}
          danger={this.state.danger}
          warning={this.state.warning}
          success={this.state.success}
          times={this.state.times}
        />
        <div className={this.state.loading === true ? classes.main_screen : ""}>
          <div align="center">
            {this.state.loading === true && <Progress />}
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" text>
                  <CardText color="rose">
                    <h4 className={classes.cardTitle}>Create Payment</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <form>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={3}></GridItem>
                      <GridItem xs={12} md={6} sm={10}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                            success={this.state.valid_category_id === ""}
                            error={this.state.valid_category_id === "false"}
                          >
                            {"Categories *"}
                          </InputLabel>

                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.category_id}
                            onChange={this.handleCategoryChange}
                            inputProps={{
                              name: "simpleSelect",
                              id: "category-select",
                            }}
                          >
                            {this.state.categories.map((row) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value={row.code}
                                key={row.code}
                              >
                                {row.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={3}></GridItem>
                      <GridItem xs={12} md={6} sm={10}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                            success={this.state.valid_biller_id === ""}
                            error={this.state.valid_biller_id === "false"}
                          >
                            {"Billers *"}
                          </InputLabel>

                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.biller_id}
                            onChange={this.handleBillerChange}
                            inputProps={{
                              name: "simpleSelect",
                              id: "biller-select",
                            }}
                          >
                            {this.state.billers.map((row) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value={row.code}
                                key={row.code}
                              >
                                {row.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={3}></GridItem>
                      <GridItem xs={12} md={6} sm={10}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                            success={this.state.valid_payment_code === ""}
                            error={this.state.valid_payment_code === "false"}
                          >
                            {"Payment Items *"}
                          </InputLabel>

                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.payment_code}
                            onChange={this.handleItemChange}
                            inputProps={{
                              name: "simpleSelect",
                              id: "payments-select",
                            }}
                          >
                            {this.state.payment_items.map((row) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value={row.payment_code}
                                key={row.payment_code}
                              >
                                {row.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={3}></GridItem>
                      <GridItem
                        xs={12}
                        md={6}
                        sm={10}
                        style={{ height: "50px" }}
                      >
                        {this.getBAUserList()}
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={3}></GridItem>
                      <GridItem
                        xs={12}
                        md={6}
                        sm={10}
                        style={{ height: "50px" }}
                      >
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                            success={this.state.valid_client_id === ""}
                            error={this.state.valid_client_id === "false"}
                          >
                            {"Clients *"}
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.client_id}
                            onChange={this.handleClientChange}
                            inputProps={{
                              name: "simpleSelect",
                              id: "client-select",
                            }}
                          >
                            {this.state.client_all.map((row) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value={row.id}
                                key={row.id}
                              >
                                {row.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={3}></GridItem>
                      <GridItem
                        xs={12}
                        md={6}
                        sm={10}
                        style={{ height: "50px" }}
                      >
                        <CustomInput
                          labelText="Customer ID *"
                          success={this.state.valid_customer_id === ""}
                          error={this.state.valid_customer_id === "false"}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: this.state.customer_id,
                            onChange: this.handleCustomerIDChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={3}></GridItem>
                      <GridItem
                        xs={12}
                        md={6}
                        sm={10}
                        style={{ height: "50px" }}
                      >
                        <CustomInput
                          labelText="Client Payment ID *"
                          success={this.state.valid_client_payment_id === ""}
                          error={this.state.valid_client_payment_id === "false"}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: this.state.client_payment_id,
                            onChange: this.handleClientPaymentIDChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={3}></GridItem>
                      <GridItem
                        xs={12}
                        md={6}
                        sm={10}
                        style={{ height: "50px" }}
                      >
                        <CustomInput
                          labelText="Amount *"
                          success={this.state.valid_amount === ""}
                          error={this.state.valid_amount === "false"}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: this.state.amount,
                            onChange: this.handleAmountChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={3}></GridItem>
                      <GridItem
                        xs={12}
                        md={6}
                        sm={10}
                        style={{ height: "50px" }}
                      >
                        <CustomInput
                          labelText="Customer Email"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: this.state.email,
                            onChange: this.handleEmailChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={3}></GridItem>
                      <GridItem
                        xs={12}
                        md={6}
                        sm={10}
                        style={{ height: "50px" }}
                      >
                        <CustomInput
                          labelText="Customer Mobile Number"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: this.state.mobile,
                            onChange: this.handleMobileChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                    <br />

                    <GridContainer justify="center">
                      <GridItem
                        xs={12}
                        md={3}
                        sm={3}
                        style={{ height: "50px" }}
                      >
                        <FormControl fullWidth>
                          <Button color="rose" onClick={this.handlepay}>
                            Proceed To Pay
                          </Button>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}
export default withStyles(cssstyles)(CreatePayment);
