import React from "react";
// react component for creating dynamic tables
import { withStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import FormControl from "@material-ui/core/FormControl";
import CardText from "components/Card/CardText.js";
import axios from "axios";
import apiUrl from "../api";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";
import CustomSweet from "../Components/CustomSweet";
import Progress from "../Components/Progress";
import ProviderSettingDetails from "../ProviderSetting/providerSettingTable";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { makeApiCall } from "utils/commonCheckFunctions";

const styling = (theme) => {
  return { ...styles(theme), ...cssstyles };
};

const base_url = apiUrl;
const userType = localStorage.getItem("user_type");
const SUPERADMINUSER = "superadmin";
// const tableRef = React.useRef(null);
const tableColumns = [
  "Provider Name",
  "Payment Gateway",
  "Minimum Amount",
  "Maximum Amount",
  "Fee",
  "Action",
];

const excludeCurrencyList = ["xof"];
const currencyList = [
  { name: "NGN", value: "ngn" },
  { name: "GHS", value: "ghs" },
  { name: "KES", value: "kes" },
  { name: "XOF", value: "xof" },
  { name: "UGX", value: "ugx" },
  { name: "RWF", value: "rwf" },
  { name: "XAF", value: "xaf" },

];
class BankSetting extends React.Component {
  state = {
    loading: false,
    providers: [],
    providerSettings: [],
    gatewaysdata: [],
    settingdata: [],
    filterCurrency: "ngn",
    payment_gateway_all: [],
    propsTobeSent: [],
    selectedBA:"",
    baUsers:[],
    currencyWiseProviderSetting: {
      xof: [],
    },
  };

  componentDidMount() {
    if(userType !== SUPERADMINUSER){
      this.getProviderSettings();
      this.getPaymentGateways();
      this.getProviderSettingsCurrencyWise();
    }else{
      this.getAllBaUsers();
      this.getPaymentGateways();

    }

    
  }

  componentDidUpdate(prevProps, prevState) {
    const { filterCurrency, selectedBA} = this.state;
    if (filterCurrency !== prevState.filterCurrency && !excludeCurrencyList.includes(filterCurrency) && userType !== SUPERADMINUSER) {
      this.getPaymentGateways();
      this.getProviderSettingData();
    }else if(userType === SUPERADMINUSER){
      if(filterCurrency !== prevState.filterCurrency && !excludeCurrencyList.includes(filterCurrency) && selectedBA!==""){
        this.getProviderSettingData();
        this.getPaymentGateways();

      }
      else if(selectedBA !== prevState.selectedBA){
        this.getProviderSettings();
        this.getProviderSettingsCurrencyWise();
      }
    }

  }

  getAllBaUsers = async () => {
    const data = await makeApiCall({
      url: "/users.json",
      method: "get",
      params: { role: "ba" },
    });
    const { error = false } = data;
    
    if (!error) {
      this.setState({
        baUsers:data,
      })
    } else {
      const { message } = data;
      console.log(message);
    }
  };

  handleBaChange = (event) => {
    const { value } = event.target;
    this.setState({
      selectedBA: value,
    });
  };

  getBAUserList() {
    const { classes } = this.props;
    if (userType === SUPERADMINUSER) {
      return (
        <Card>
        <CardBody>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel className={classes.selectLabel}>{"BA *"}</InputLabel>

          <Select
            MenuProps={{
              className: classes.selectMenu,
            }}
            classes={{
              select: classes.select,
            }}
            value={this.state.selectedBA}
            onChange={this.handleBaChange}
          >
            {this.state.baUsers.map((row) => (
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value={row.id}
                key={row.id}
              >
                {row.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        </CardBody>
      </Card>
      );
    } else {
      return null;
    }
  }



  getProviderSettingsCurrencyWise = () => {
    const params = userType === SUPERADMINUSER ? {ba_id : this.state.selectedBA}:{};
    axios({
      method: "get",
      url: base_url + "/show_country_wise_settings.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        currency: "xof",
        ...params,
      },
    })
      .then((result) => {
        // console.log(result.data);
        const { provider_settings: settings } = result.data;
        this.setState((prevState) => ({
          currencyWiseProviderSetting: {
            ...prevState.currencyWiseProviderSetting,
            xof: settings,
          },
        }));
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  handleProviderSettingButtonClick = (
    providerCode,
    providerId,
    minimumAmount,
    maximumAmount,
    fee,
    paymentGateway,
    currency,
    countryCode,
    ...rest
  ) => {
    const [id] = rest;
    const isFormValid = this.validateForm(
      paymentGateway,
      minimumAmount,
      maximumAmount,
      fee
    );
    if (isFormValid) {
      this.updateOrCreateBankSetting(
        providerId,
        providerCode,
        minimumAmount,
        maximumAmount,
        fee,
        paymentGateway,
        currency,
        countryCode,
        id
      );
    }
  };

  updateOrCreateBankSetting = (
    providerId,
    providerCode,
    minimumAmount,
    maximumAmount,
    fee,
    paymentGateway,
    currency,
    countryCode,
    id
  ) => {
    const url = id ? "/provider_settings/" + id : "/provider_settings";
    const method = id ? "put" : "post";
    const message = id
      ? "Provider Setting successfully updated."
      : "Provider Setting successfully created.";
    this.setState({ loading: true });
    const params = userType === SUPERADMINUSER ?{ba_id:this.state.selectedBA}:{};
    const param = {
      "provider_setting[provider_id]": providerId,
      "provider_setting[provider_code]": providerCode,
      "provider_setting[currency]": currency,
      "provider_setting[minimum_amount]": minimumAmount,
      "provider_setting[maximum_amount]": maximumAmount,
      "provider_setting[fee]": fee,
      "provider_setting[payment_gateway_id]": paymentGateway,
      ...params,
    };
    if (countryCode) {
      param["provider_setting[country_code_id]"] = countryCode;
    }
    axios({
      method: method,
      url: base_url + url + ".json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: param,
    })
      .then((result) => {
        //access the results here....
        const { provider_setting: providerSetting } = result.data;
        if (excludeCurrencyList.includes(currency)) {
          const { currencyWiseProviderSetting } = this.state;
          const dataToBeUpdate = currencyWiseProviderSetting[currency];
          const {
            id,
            maximum_amount,
            minimum_amount,
            payment_gateway_id,
            fee,
          } = providerSetting;
          let ObjectToBeUpdated = dataToBeUpdate
            .filter((elm) => elm.country_code_id === countryCode)[0]
            .providerList.filter((elm) => elm.provider_id === providerId)[0];
          ObjectToBeUpdated["id"] = id ? id : null;
          ObjectToBeUpdated["maximum_amount"] = maximum_amount
            ? maximum_amount
            : 0;
          ObjectToBeUpdated["minimum_amount"] = minimum_amount
            ? minimum_amount
            : 0;
          ObjectToBeUpdated["payment_gateway_id"] = payment_gateway_id
            ? payment_gateway_id
            : null;
          ObjectToBeUpdated["fee"] = fee ? fee : 0;
          this.setState((prevState) => ({
            loading: false,
            alert: true,
            alert_msg: message,
            alert_title: "Provider Setting!",
            danger: false,
            warning: false,
            success: true,
            currencyWiseProviderSetting: {
              ...prevState.currencyWiseProviderSetting,
              [currency]: dataToBeUpdate,
            },
          }));
        } else {
          let updatedProviderSettingData = [];
          if (providerSetting) {
            updatedProviderSettingData = this.updateProviderSettingData(
              providerSetting
            );
          }
          this.setState(
            {
              loading: false,
              alert: true,
              alert_msg: message,
              alert_title: "Provider Setting!",
              danger: false,
              warning: false,
              success: true,
              providerSettings: updatedProviderSettingData,
            },
            this.getProviderSettingData
          );
        }
      })
      .catch((error) => {
        console.log("Error", error);
        this.setState({
          loading: false,
          alert: true,
          alert_msg: "Something went Wrong !!",
          alert_title: "Error!",
          danger: true,
          warning: false,
          success: false,
        });
      });
  };

  updateProviderSettingData = (data = {}) => {
    const { providerSettings } = this.state;

    let updatedSettingData = [];
    if (data.id) {
      const elementFound = providerSettings.some((element) => {
        return element.id === data.id;
      });
      if (elementFound) {
        updatedSettingData = providerSettings.map((element) => {
          if (element.id === data.id) {
            element = data;
          }

          return element;
        });
      } else {
        updatedSettingData = [...providerSettings, data];
      }
      return updatedSettingData;
    }
    return providerSettings;
  };

  validateForm = (payment_gate, min_amount, max_amount, fee) => {
    // console.log(payment_gate, min_amount, max_amount, fee);
    if (payment_gate === "" || payment_gate === "select") {
      alert("Please select Payment Gateway!!");
      return false;
    }
    if (min_amount === "") {
      alert("Please enter Minimum Amount!!");
      return false;
    }
    if (max_amount === "") {
      alert("Please enter Maximum Amount!!");
      return false;
    }
    if (fee === "") {
      alert("Please enter Fee!!");
      return false;
    }
    if (parseInt(min_amount) > parseInt(max_amount)) {
      alert(
        "Please enter Minimum Amount less then Maximum Amount",
        min_amount,
        max_amount
      );
      return false;
    }
    return true;
  };

  getData = () => {
    const { payment_gateway_all } = this.state;
    // console.log(this.state.propsTobeSent);
    return this.state.propsTobeSent.map((row) => {
      return (
        <ProviderSettingDetails
          key={row.providerId}
          {...row}
          allPaymentGateway={payment_gateway_all}
          handleClick={this.handleProviderSettingButtonClick}
        />
      );
    });
  };

  getProviderSettings = () => {
    const params = userType ===SUPERADMINUSER?{ba_id:this.state.selectedBA}:{};
    axios({
      method: "get",
      url: base_url + "/provider_settings.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {...params},
    })
      .then((result) => {
        //access the results here....
        this.setState(
          {
            providers: result.data.providers,
            gatewaysdata: result.data.payment_gateways,
            providerSettings: result.data.provider_settings,
          },
          () => {
            this.getProviderSettingData();
          }
        );
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  handleCurrencyChange = (event) => {
    const { value } = event.target;

    this.setState({
      filterCurrency: value,
    });
    // this.setEnableButton(currency);
  };

  getProviderSettingData = () => {
    const { providerSettings, providers, filterCurrency } = this.state;
    let settings = [];

    const provider = providers
      .filter((data) => {
        return data.currency === filterCurrency;
      })
      .map((data) => {
        const { id: providerId, ...rest } = data;
        return { providerId, ...rest };
      });

    const providerSetting = providerSettings.filter((data) => {
      return data.currency === filterCurrency;
    });

    for (let value of provider) {
      let matchFound = false;
      for (let data of providerSetting) {
        if (data.provider_id === value.providerId) {
          matchFound = true;
          settings.push({ ...value, ...data });
        }
      }
      if (!matchFound) {
        settings.push(value);
      }
    }
    //  console.log(settings);
    this.setState({
      propsTobeSent: settings,
    });
  };

  getPaymentGateways = () => {
    const { filterCurrency: currency } = this.state;
    axios({
      method: "get",
      url: base_url + "/show_provider_payment_gateways.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        currency: currency,
      },
    })
      .then((result) => {
        this.setState({
          payment_gateway_all: result.data.provider_payment_gateways,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  hideAlert = (times) => {
    this.setState({
      alert: false,
      alert_msg: "",
      alert_title: "",
      alert_type: "",
    });
  };

  getCurrencylist = () => {
    const { classes } = this.props;
    return currencyList.map((data) => {
      return (
        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value={data.value}
          key={data.value}
        >
          {data.name}
        </MenuItem>
      );
    });
  };

  getDataForDifferentCountry() {
    const { filterCurrency, currencyWiseProviderSetting } = this.state;
    const { classes } = this.props;
    const countryList = currencyWiseProviderSetting[filterCurrency];
    return countryList.map((data) => {
      return (
        <Card key={data.name}>
          <CardHeader color="rose" text>
            <CardText color="rose">
              <h4 className={classes.cardTitle}>{data.name.toUpperCase()}</h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <Table>
              <TableHead>
                <TableRow
                  className={classes.tableRow + " " + classes.tableRowHead}
                >
                  {tableColumns.map((data) => {
                    return (
                      <TableCell
                        key={data}
                        className={
                          classes.tableHeadCell + " " + classes.tableCell
                        }
                      >
                        {data}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.providerList.map((row) => {
                  return (
                    <ProviderSettingDetails
                      key={row.provider_id}
                      name={row.name}
                      providerId={row.provider_id}
                      code={row.code}
                      payment_gateway_id={row.payment_gateway_id}
                      minimum_amount={row.minimum_amount}
                      maximum_amount={row.maximum_amount}
                      fee={row.fee}
                      id={row.id}
                      allPaymentGateway={row.paymentGatewayList}
                      handleClick={this.handleProviderSettingButtonClick}
                      currency={filterCurrency}
                      countryCode={data.country_code_id}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </CardBody>
        </Card>
      );
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <CustomSweet
          alert={this.state.alert}
          message={this.state.alert_msg}
          title={this.state.alert_title}
          hide_alert={this.hideAlert}
          danger={this.state.danger}
          warning={this.state.warning}
          success={this.state.success}
          times={this.state.times}
        />
        <div className={this.state.loading === true ? classes.main_screen : ""}>
          <div align="center">
            {this.state.loading === true && <Progress />}
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <Card style={{ minHeight: "70px" }}>
                <CardBody style={{ textAlign: "center" }}>
                  <FormControl fullWidth>
                    <h6>Filter banks by :</h6>
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody>
                  <FormControl fullWidth>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      {"Currency *"}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={this.state.filterCurrency}
                      onChange={this.handleCurrencyChange}
                      inputProps={{
                        name: "simpleSelect",
                      }}
                    >
                      {this.getCurrencylist()}
                    </Select>
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              {this,this.getBAUserList()}
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <Card>
                  <CardHeader color="rose" text>
                    <CardText color="rose">
                      <h4 className={classes.cardTitle}>
                        {this.state.filterCurrency.toString().toUpperCase() +
                          " Provider Settings"}
                      </h4>
                    </CardText>
                  </CardHeader>
                  <CardBody>
                    {!excludeCurrencyList.includes(
                      this.state.filterCurrency
                    ) ? (
                      <Table>
                        <TableHead>
                          <TableRow
                            className={
                              classes.tableRow + " " + classes.tableRowHead
                            }
                          >
                            {tableColumns.map((data) => {
                              return (
                                <TableCell
                                  key={data}
                                  className={
                                    classes.tableHeadCell +
                                    " " +
                                    classes.tableCell
                                  }
                                >
                                  {data}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>{this.getData()}</TableBody>
                      </Table>
                    ) : (
                      this.getDataForDifferentCountry()
                    )}
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </GridContainer>
        </div>
      </div>
    );
  }
}
export default withStyles(styling)(BankSetting);
