import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Progress from "../Components/Progress";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import apiUrl from "../api";
import { Box } from "@material-ui/core";
import ReactTable from "react-table";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CustomSweet from "../Components/CustomSweet";

const base_url = apiUrl;

const style = {
  boxStyle: {
    padding: "40px",
    textAlign: "center",
  },
};

class ExchangeRate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sourceCurrencyList: [],
      sourceCurrency: "",
      allCurrencies: [],
      loading: false,
      exchangeRate: [], //this is for exchange rate data
      exchangeRateCommission: [], // this is for holding commision value
      excahngeRateValues: {},
      totalApiCall: 0,
    };
    this.reactTableColumns = [
      {
        Header: "ID",
        accessor: "id",
      },
      // {
      //   accessor: "name",
      //   Header: "Name",
      // },
      {
        Header: "Code",
        accessor: "code",
      },
      {
        Header: "Exchange Rate",
        accessor: "rate",
      },
      {
        Header: "Commission Percentage",
        accessor: "commission",
      },
      {
        Header: "Commission Rate",
        accessor: "commissionRate",
      },
      // {
      //   Header: "System Rate",
      //   accessor: "systemRate",
      // },
      {
        Header: "Updated At Date",
        accessor: "date",
      },
      {
        Header: "Updated At Time",
        accessor: "time",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ];
  }

  componentDidMount() {
    this.getAllCurrencies();
  }

  handleSourceCurrencyChange = (event) => {
    const currentSelectedCurrency = this.state.sourceCurrency;
    const inputCurrency = event.target.value;

    if (currentSelectedCurrency !== inputCurrency) {
      this.setState({
        sourceCurrency: event.target.value,
        loading: true,
      });
    }
  };

  handleCommisionChange = (event) => {
    const { name, value } = event.target;
    const { excahngeRateValues } = this.state;
    const existingValue = excahngeRateValues[name];
    const data = existingValue
      ? { [name]: { ...existingValue, value: value } }
      : { [name]: { value: value } };
    this.setState({
      excahngeRateValues: { ...this.state.excahngeRateValues, ...data },
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { sourceCurrency, totalApiCall } = this.state;
    const {
      sourceCurrency: prevSourceCurrency,
      totalApiCall: prevTotalApiCall,
    } = prevState;
    if (prevSourceCurrency !== sourceCurrency) {
      this.getExchangeRate(this.state.sourceCurrency);
      this.getExchangeRateCommission(this.state.sourceCurrency);
    }
    if (prevTotalApiCall !== totalApiCall) {
      const loading = totalApiCall > 0;
      this.setState({
        loading,
      });
    }
  }

  getExchangeRateCommission = (currency = "usd") => {
    this.setState((state) => ({
      totalApiCall: state.totalApiCall + 1,
    }));
    axios({
      method: "get",
      url:
        base_url + `/exchange_commission_rates.json?currency_input=${currency}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
    })
      .then((result) => {
        //access the results here....
        const exchangeRateCommission = result.data.exchange_commission_rates;
        const excahngeRateValues = this.getExchangeRateValueObject(
          exchangeRateCommission
        );

        this.setState((state) => ({
          exchangeRateCommission,
          excahngeRateValues,
          loading: false,
          totalApiCall: state.totalApiCall - 1 < 0 ? 0 : state.totalApiCall - 1,
        }));
      })
      .catch((error) => {
        console.log(error);
        this.setState((state) => ({
          totalApiCall: state.totalApiCall - 1 < 0 ? 0 : state.totalApiCall - 1,
        }));
      });
  };

  getExchangeRateValueObject = (exchangeRateCommission = []) => {
    const excahngeRateValues = {};
    //  console.log(exchangeRateCommission);
    for (let element of exchangeRateCommission) {
      excahngeRateValues[element.currency_output] = {
        value: Number(element.commission_percentage),
        id: element.id,
        commissionRate: element.commission_rate,
        updatedAt: element.updated_at,
        systemRate: element.exchange_rate,
      };
    }
    return excahngeRateValues;
  };

  getExchangeRate = (currency = "usd") => {
    this.setState((state) => ({
      totalApiCall: state.totalApiCall + 1,
    }));
    axios({
      method: "get",
      url: base_url + `/get_currency_exchange_rates.json?currency=${currency}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
    })
      .then((result) => {
        //access the results here....
        const exchangeRate = result.data.currency_info;
        this.setState((state) => ({
          exchangeRate,
          // tableRows: this.getRowData(exchangeRate),
          totalApiCall: state.totalApiCall - 1 < 0 ? 0 : state.totalApiCall - 1,
        }));
      })
      .catch((error) => {
        console.log(error);
        this.setState((state) => ({
          totalApiCall: state.totalApiCall - 1 < 0 ? 0 : state.totalApiCall - 1,
        }));
      });
  };

  getInputElement = (name = "", value) => {
    return (
      <CustomInput
        labelText="Value"
        inputProps={{
          type: "number",
          value: value,
          onChange: this.handleCommisionChange,
          name: name,
        }}
      />
    );
  };

  handleUpdateOrSubmitCommission = (
    id,
    destinationCurrency,
    exchangeRate,
    value
  ) => {
    const { sourceCurrency } = this.state;
    const method = id ? "put" : "post";
    const message = id ? "updated" : "created";
    const url = id
      ? base_url + "/exchange_commission_rates/" + id + ".json"
      : base_url + "/exchange_commission_rates.json";
    this.setState({ loading: true });
    axios({
      method: method,
      url: url,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        "exchange_commission_rate[currency_input]": sourceCurrency,
        "exchange_commission_rate[currency_output]": destinationCurrency,
        "exchange_commission_rate[commission_percentage]": value,
        "exchange_commission_rate[exchange_rate]": exchangeRate,
      },
    })
      .then((result) => {
        // console.log(result.data);
        const { data } = result;
        const param = data.exchange_commission
          ? data.exchange_commission
          : data;
        this.setState({
          loading: false,
          alert: true,
          alert_msg: `Currency Conversion successfully ${message}.!`,
          alert_title: "Currency Conversion!",
          danger: false,
          warning: false,
          success: true,
          excahngeRateValues: this.getUpdatedExchangeComissionValues(param),
          // conversions: this.updateDataOrCreateData(method, result.data),
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
          alert: true,
          alert_msg: "Something Went Wrong !!",
          alert_title: "Error!",
          danger: true,
          warning: false,
          success: false,
        });
      });
  };

  getActionButton = (id, destinationCurrency, exchangeRate) => {
    const { excahngeRateValues } = this.state;
    const { value } = excahngeRateValues[destinationCurrency]
      ? excahngeRateValues[destinationCurrency]
      : { value: "" };
    const disabled = value === undefined || value === "";

    return (
      <Button
        variant="contained"
        size="sm"
        disabled={disabled}
        onClick={() => {
          this.handleUpdateOrSubmitCommission(
            id,
            destinationCurrency,
            exchangeRate,
            value
          );
        }}
      >
        {id ? "update" : "submit"}
      </Button>
    );
  };

  getUpdatedExchangeComissionValues = (data) => {
    const { excahngeRateValues } = this.state;
    const updatedExchageRateValues = { ...excahngeRateValues };
    updatedExchageRateValues[data.currency_output] = {
      value: data.commission_percentage,
      id: data.id,
      commissionRate: data.commission_rate,
      updatedAt: data.updated_at,
    };
    return updatedExchageRateValues;
  };

  getRowData = (rows = []) => {
    const { excahngeRateValues } = this.state;
    return rows.map((row, index) => {
      const name = row.source.toLowerCase();
      const data = excahngeRateValues[name];
      const { val, id, commissionRate, timeStamp, systemRate } = data
        ? {
            val: data.value,
            id: data.id,
            commissionRate: data.commissionRate,
            timeStamp: data.updatedAt,
            systemRate: data.systemRate,
          }
        : { val: "" };
      const { updateDate, updateTime } = this.getDateAndTime(timeStamp);
      return {
        id: index + 1,
        // name: row.name,
        code: row.source,
        rate: row.rate.toFixed(6),
        commission: this.getInputElement(name, val),
        commissionRate: commissionRate ? commissionRate : "NA",
        systemRate: systemRate ? systemRate : "NA",
        date: updateDate,
        time: updateTime,
        action: this.getActionButton(id, name, row.rate),
      };
    });
  };

  getDateAndTime = (date = "NA") => {
    let updatedDate = new Date(date);

    if (updatedDate.toDateString() !== "Invalid Date") {
      let updateDate =
        updatedDate.getFullYear() +
        "-" +
        (updatedDate.getMonth() + 1) +
        "-" +
        updatedDate.getDate();
      let updateTime =
        updatedDate.getHours() +
        ":" +
        updatedDate.getMinutes() +
        ":" +
        updatedDate.getSeconds();

      return { updateDate, updateTime };
    }
    return { updateDate: "NA", updateTime: "NA" };
  };

  getAllCurrencies = () => {
    axios({
      method: "get",
      url: base_url + "/currencies.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
    })
      .then((result) => {
        //access the results here....
        const currencies = result.data.currencies;
        const sourceCurrencyList = this.filterCurrencySourceDestination(
          currencies
        );
        this.setState({
          allCurrencies: currencies,
          sourceCurrencyList,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  filterCurrencySourceDestination = (currencies = []) => {
    return currencies.filter((data) => {
      return data.attributes.type !== "destination";
    });
  };

  hideAlert = () => {
    this.setState({
      alert: false,
      alert_msg: "",
      alert_title: "",
      alert_type: "",
    });
  };

  render() {
    const { classes } = this.props;
    const { exchangeRate } = this.state;
    const tableRows = this.getRowData(exchangeRate);

    return (
      <>
        <CustomSweet
          alert={this.state.alert}
          message={this.state.alert_msg}
          title={this.state.alert_title}
          hide_alert={this.hideAlert}
          danger={this.state.danger}
          warning={this.state.warning}
          success={this.state.success}
          times={this.state.times}
        />
        <div className={this.state.loading === true ? classes.main_screen : ""}>
          <div align="center">
            {this.state.loading === true && <Progress />}
          </div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" text>
                  <CardText color="rose">
                    <h4 className={classes.cardTitle}>Exchange Rate</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <GridContainer justify="center">
                    <GridItem xs={12} md={3} sm={10}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          {"Source Currency *"}
                        </InputLabel>

                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.sourceCurrency}
                          onChange={this.handleSourceCurrencyChange}
                          inputProps={{
                            name: "simpleSelect",
                          }}
                        >
                          {this.state.sourceCurrencyList.map((row) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={row.attributes.name}
                              key={row.attributes.id}
                            >
                              {row.attributes.name.toUpperCase()}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
              <Card>
                <CardHeader color="rose" text>
                  <CardText color="rose">
                    <h4 className={classes.cardTitle}>Exchange Rate Table</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  {tableRows.length > 0 ? (
                    <ReactTable
                      data={tableRows}
                      columns={this.reactTableColumns}
                      defaultPageSize={10}
                      showPaginationTop
                      showPaginationBottom={false}
                      className="-striped -highlight"
                    ></ReactTable>
                  ) : (
                    <Box component="div" className={classes.boxStyle}>
                      Please Select Currency !!!
                    </Box>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </>
    );
  }
}

export default withStyles({ ...cssstyles, ...style })(ExchangeRate);
