import React from "react";
// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

class CustomSweet extends React.Component {
  constructor(props) {
    super(props);
    this.state = { alert: false };
  }
  hideAlert = () => {
    if (this.props.times === "1") {
      this.props.hide_alert(this.props.times);
    } else {
      this.props.hide_alert();
    }
  };
  showAlert = () => {
    this.setState({ alert: true });
  };
  render() {
    const { classes } = this.props;
    // console.log(this.props);
    return (
      <div>
        {this.props.alert ? (
          <SweetAlert
            danger={this.props.danger}
            warning={this.props.warning}
            success={this.props.success}
            style={{ display: "block", marginTop: "-100px" }}
            title={this.props.title}
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={classes.button + " " + classes.success}
            cancelBtnText={this.props.cancel}
            {...this.props.showCancel}
          >
            {this.props.message}
          </SweetAlert>
        ) : null}
      </div>
    );
  }
}
export default withStyles(styles)(CustomSweet);
