import React, { useEffect, useState } from "react";
import * as yup from "yup";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import CustomSweet from "../Components/CustomSweet";
import Progress from "../Components/Progress";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
// import SweetAlert from "react-bootstrap-sweetalert";
import alertstyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import {
  makeStyles,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import {
  makeApiCall,
  sortObjectAlphabatically,
} from "../../utils/commonCheckFunctions";
// import { countryCodes } from "utils/commonConstant";
const customStyles = {
  marginCustomTop: {
    marginTop: "20px",
  },
};
const useStyle = makeStyles({ ...cssstyles, ...alertstyles, ...customStyles });

const userType = localStorage.getItem("user_type");
const SUPERADMINUSER = "superadmin";

const initialState = {
  sourceCurrency: "",
  sourceAmount: "",
  destinationAmount: "",
  destinationCurrency: "",
  clientTxnId: "",
  accountType: "Etransfer",
  name: "",
  recipientEmail: "",
  recipientPhoneNumber: "",
  recipientCountry: "",
  client: "",
  conversionRate: "",
  selectedBA: "",
  loading: false,
  errors: {},
  dialogOpen: false,

  baUsers: [],
  allCountries: [],
  allClients: [],
  recipientCountryList: [],
};
const initialAlertState = {
  alert: false,
  success: false,
  error: false,
  warning: false,
  message: "",
  title: "",
};

const sourceCurrencyList = [
  { name: "USD", value: "usd" },
  // { name: "GBP", value: "gbp" },
  { name: "CAD", value: "cad" },
  // { name: "EUR", value: "eur" },
];

const destinationCurrencyList = [{ name: "CAD", value: "cad" }];

const DialogComponent = ({
  isOpen = false,
  confirmFunction,
  closeFunction,
}) => {
  return (
    <Dialog open={isOpen} onClose={closeFunction}>
      <DialogTitle>Do you want to Create Transaction?</DialogTitle>
      <DialogActions>
        <Button size="sm" onClick={closeFunction}>
          Cancel
        </Button>
        <Button size="sm" onClick={confirmFunction} color="success">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const getBAUserList = (classes, baUsers, handleChange, errors, selectedBA) => {
  if (userType === SUPERADMINUSER) {
    return (
      <FormControl
        fullWidth
        className={classes.selectFormControl}
        error={errors.selectedBA !== undefined}
      >
        <InputLabel className={classes.selectLabel}>{"BA *"}</InputLabel>

        <Select
          MenuProps={{
            className: classes.selectMenu,
          }}
          classes={{
            select: classes.select,
          }}
          value={selectedBA}
          onChange={handleChange}
          inputProps={{
            name: "selectedBA",
          }}
        >
          {baUsers.map((row) => (
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={row.id}
              key={row.id}
            >
              {row.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  } else {
    return null;
  }
};

const ViewComponent = ({
  sourceCurrency,
  sourceAmount,
  destinationCurrency,
  clientTxnId,
  name,
  // destinationAmount,
  client,
  recipientEmail,
  recipientPhoneNumber,
  errors,
  handleChange,
  // handleSourceAmountChange,
  // handleDestinationAmountChange,
  selectedBA,
  baUsers = [],
  allClients,
  handleCancel,
  handleSubmit,
  recipientCountryList,
  recipientCountry,
}) => {
  const classes = useStyle();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" text>
            <CardText color="rose">
              <h4>Create E-Transfer Transaction</h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <form>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10}>
                  {getBAUserList(
                    classes,
                    baUsers,
                    handleChange,
                    errors,
                    selectedBA
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    error={errors.client !== undefined}
                  >
                    <InputLabel className={classes.selectLabel}>
                      {"Client*"}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={client}
                      onChange={handleChange}
                      inputProps={{
                        name: "client",
                      }}
                    >
                      {allClients.map((row) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={row.id}
                          key={row.id}
                        >
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>

              <GridContainer
                justify="center"
                className={classes.marginCustomTop}
              >
                <GridItem xs={12} md={3} sm={10}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    error={errors.sourceCurrency !== undefined}
                  >
                    <InputLabel className={classes.selectLabel}>
                      {"Source Currency*"}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={sourceCurrency}
                      onChange={handleChange}
                      inputProps={{
                        name: "sourceCurrency",
                      }}
                    >
                      {sourceCurrencyList.map((row) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={row.value}
                          key={row.value}
                        >
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} md={3} sm={10}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    error={errors.destinationCurrency !== undefined}
                  >
                    <InputLabel className={classes.selectLabel}>
                      {"Destination Currency*"}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={destinationCurrency}
                      onChange={handleChange}
                      inputProps={{
                        name: "destinationCurrency",
                      }}
                    >
                      {destinationCurrencyList.map((row) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={row.value}
                          key={row.value}
                        >
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    error={errors.destinationCurrency !== undefined}
                  >
                    <InputLabel className={classes.selectLabel}>
                      {"Recipient Country*"}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={recipientCountry}
                      onChange={handleChange}
                      inputProps={{
                        name: "recipientCountry",
                      }}
                    >
                      {recipientCountryList.map((row) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={row.attributes.code}
                          key={row.attributes.code}
                        >
                          {row.attributes.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Source Amount *"
                    error={errors.sourceAmount !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.sourceAmount !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: sourceAmount,
                      autoComplete: "off",
                      type: "number",

                      name: "sourceAmount",
                    }}
                  />
                </GridItem>
                {/* <GridItem xs={12} md={3} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Destination Amount"
                    formControlProps={{
                      fullWidth: true,
                      // disabled: true,
                    }}
                    inputProps={{
                      onChange: handleDestinationAmountChange,
                      value: destinationAmount,
                      autoComplete: "new-password",
                      name: "destinationAmount",
                      // readOnly: true,
                      type: "number",
                    }}
                  />
                </GridItem> */}
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Client Transaction Id*"
                    error={errors.clientTxnId !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.clientTxnId !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: clientTxnId,
                      autoComplete: "new-password",
                      name: "clientTxnId",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Name *"
                    error={errors.name !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.name !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: name,
                      autoComplete: "off",
                      name: "name",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Recipient Email*"
                    error={errors.recipientEmail !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.recipientEmail !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: recipientEmail,
                      autoComplete: "new-password",
                      name: "recipientEmail",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Recipient Phone Number*"
                    error={errors.recipientPhoneNumber !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.recipientPhoneNumber !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: recipientPhoneNumber,
                      autoComplete: "new-password",
                      name: "recipientPhoneNumber",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer
                justify="center"
                className={classes.marginCustomTop}
              >
                <GridItem xs={12} sm={12} md={3} style={{ height: "50px" }}>
                  <FormControl fullWidth>
                    <Button onClick={handleCancel}>Cancel</Button>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} md={3} sm={12}>
                  <FormControl fullWidth>
                    <Button color="rose" onClick={handleSubmit}>
                      Proceed To Pay
                    </Button>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

const schema = yup.object({
  ...(userType === SUPERADMINUSER
    ? { selectedBA: yup.string().required("Please Select BA !!!") }
    : {}),
  client: yup.string().required("Please Select Client !!!"),
  sourceCurrency: yup.string().required("Please Select Source Currency!!!"),
  destinationCurrency: yup
    .string()
    .required("Please Select  Destination Currency !!!"),
  sourceAmount: yup
    .number()
    .min(0, "Please Enter Valid Source Amount !!! ")
    .required("Please Enter Source Amount !!!"),
  clientTxnId: yup.string().required("Please Enter Client Transaction Id !!!"),
  name: yup.string().required("Please enter name !!!"),
  recipientEmail: yup
    .string()
    .email("Please enter valid Email !!!")
    .required("Please Enter Email !!!"),
  recipientPhoneNumber: yup
    .number()
    .required("Please Enter Recipient Phone Number !!!"),
  recipientCountry: yup.string().required("Please Select Recipient Country!!!"),
});

const getParams = (state = {}) => {
  const {
    client,
    clientTxnId,
    sourceCurrency,
    destinationCurrency,
    sourceAmount,
    accountType,
    name,
    recipientEmail,
    recipientPhoneNumber,
    recipientCountry,
  } = state;
  return sortObjectAlphabatically({
    client_id: client,
    client_txn_id: clientTxnId.trim(),
    source_amount: sourceAmount,
    source_currency: sourceCurrency,
    destination_currency: destinationCurrency,
    account_type: accountType,
    name: name.trim(),
    recipient_email: recipientEmail.trim(),
    recipient_phone_number: recipientPhoneNumber.trim(),
    recipient_country: recipientCountry,
  });
};

const EtransferCreateTransaction = () => {
  const [viewState, setViewState] = useState(initialState);
  const [alertState, setAlertState] = useState(initialAlertState);
  const classes = useStyle();

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "selectedBA") {
      setViewState((prevState) => ({
        ...prevState,
        [name]: value,
        client: "",
      }));
    } else {
      setViewState((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  useEffect(() => {
    if (userType !== SUPERADMINUSER) {
      getClients();
    } else {
      getAllBaUsers();
    }
    getCountries();
  }, []);

  useEffect(() => {
    if (viewState.selectedBA) {
      getClients();
    }
  }, [viewState.selectedBA]);

  useEffect(() => {
    if (viewState.destinationCurrency !== "") {
      const list = viewState.allCountries.filter((el) => {
        const { attributes } = el;
        return (
          viewState.destinationCurrency === attributes.currency.toLowerCase()
        );
      });
      if (list.length > 0) {
        setViewState((prevState) => ({
          ...prevState,
          recipientCountryList: list,
        }));
      }
    }
  }, [viewState.destinationCurrency]);

  // const handleSourceAmountChange = (event) => {
  //   const { name, value } = event.target;
  //   const { conversionRate } = viewState;
  //   if (conversionRate) {
  //     const val = conversionRate - 0;
  //     const src = value ? value - 0 : value;
  //     const dest = value ? src * val : "";
  //     setViewState((prevState) => ({
  //       ...prevState,
  //       [name]: src,
  //       destinationAmount: dest ? dest.toFixed(5) : "",
  //     }));
  //   } else {
  //     setViewState((prevState) => ({
  //       ...prevState,
  //       [name]: value,
  //       destinationAmount: "",
  //     }));
  //   }
  // };

  // const handleDestinationAmountChange = (event) => {
  //   const { value } = event.target;
  //   const { conversionRate } = viewState;
  //   if (conversionRate) {
  //     const val = conversionRate - 0;
  //     const dest = value ? value - 0 : "";
  //     const amount = value && val !== 0 ? dest / val : "";
  //     setViewState((prevState) => ({
  //       ...prevState,
  //       destinationAmount: dest,
  //       sourceAmount: amount ? amount.toFixed(5) : "",
  //     }));
  //   } else {
  //     setViewState((prevState) => ({
  //       ...prevState,
  //       destinationAmount: value,
  //       sourceAmount: "",
  //     }));
  //   }
  // };

  const getAllBaUsers = async () => {
    const data = await makeApiCall({
      url: "/users.json",
      method: "get",
      params: { role: "ba" },
    });
    const { error = false } = data;
    if (!error) {
      setViewState((prevState) => ({
        ...prevState,
        baUsers: data,
      }));
    } else {
      const { message } = data;
      console.log(message);
    }
  };

  const getClients = async () => {
    const params =
      userType === SUPERADMINUSER ? { ba_id: viewState.selectedBA } : {};
    const data = await makeApiCall({
      url: "/clients.json",
      method: "get",
      params: params,
    });
    const { error = false, message } = data;
    if (!error) {
      const { clients } = data;
      const clientList = Array.isArray(clients) ? clients : [clients];
      // this.getClientData(result.data.clients);
      // const clientData = getUpdatedClientlist(clientList);
      setViewState((prevState) => ({ ...prevState, allClients: clientList }));
    } else {
      console.log(message);
    }
  };

  const getCountries = async () => {
    const data = await makeApiCall({
      url: "/country_codes.json",
      method: "get",
    });
    const { error = false, message } = data;
    if (!error) {
      const { countries } = data;
      //   const countryList = countries.filter((element) => {
      //     const { attributes } = element;
      //     return ["GHS", "XOF"].includes(attributes.currency);
      //   });
      // const clientList = Array.isArray(clients) ? clients : [clients];
      // // this.getClientData(result.data.clients);
      // const clientData = getUpdatedClientlist(clientList);
      setViewState((prevState) => ({
        ...prevState,
        allCountries: countries,
      }));
      // console.log(data);
    } else {
      console.log(message);
    }
  };

  const hideAlert = () => {
    setAlertState(initialAlertState);
  };

  const handleCleanform = () => {
    setViewState((prevState) => ({
      ...initialState,
      baUsers: prevState.baUsers,
      allClients: userType === SUPERADMINUSER ? [] : prevState.allClients,
      allCountries: prevState.allCountries,
    }));
  };

  const handleDialogConfirmation = () => {
    createSignature();
  };

  const handleDialogClose = () => {
    setViewState((prevState) => ({
      ...prevState,
      dialogOpen: false,
    }));
  };

  const handleSubmit = () => {
    schema
      .validate(viewState, {
        abortEarly: false,
      })
      .then(() => {
        setViewState((prevState) => ({
          ...prevState,
          dialogOpen: true,
        }));
      })
      .catch((errors) => {
        let allErrors = {};
        errors.inner.map((error) => {
          let [a] = error.path.split(".");
          allErrors[a] = error.message;
          return error;
        });
        setViewState((prevState) => ({
          ...prevState,
          errors: allErrors,
        }));
      });
  };

  const createSignature = async () => {
    setViewState({ ...viewState, loading: true, dialogOpen: false });
    let params = getParams(viewState);
    const data = await makeApiCall({
      url: "/transactions/create_etransfer_signature",
      method: "post",
      params,
    });
    const { error = false, message } = data;
    if (error) {
      console.log(message);
      setViewState({ ...viewState, loading: false });
      setAlertState({
        alert: true,
        success: false,
        error: true,
        warning: false,
        message: "Something went wrong!!!",
        title: "Error",
      });
    } else {
      const { api_token, tonce, signature } = data;
      params = {
        ...params,
        api_token: api_token,
        tonce: tonce,
        signature: signature,
      };
      delete params.client_id; //as client_id is not required for transaction
      params = sortObjectAlphabatically(params);
      createTransaction(params);
    }
  };

  const createTransaction = async (params = {}) => {
    const data = await makeApiCall({
      url: "/api/v1/transactions_etransfer",
      method: "post",
      params,
    });
    const { error, message } = data;
    if (error) {
      console.log(message);
      let val = "Something Went wrong !!!";
      if (message.response.data.error) {
        const {
          response: { data },
        } = message;
        const {
          error: { message: errorMessage },
        } = data;
        val = errorMessage;
      }
      setViewState({ ...viewState, loading: false });
      setAlertState({
        alert: true,
        success: false,
        error: true,
        warning: false,
        message: val,
        title: "Error",
      });
    } else {
      // console.log(data);
      let successMessage = "Transaction Created Successfully!!!";
      setAlertState({
        alert: true,
        success: true,
        error: false,
        warning: false,
        message: successMessage,
        title: "Success",
      });
      handleCleanform();
    }
  };

  return (
    <>
      <CustomSweet
        alert={alertState.alert}
        message={alertState.message}
        title={alertState.title}
        hide_alert={hideAlert}
        danger={alertState.error}
        success={alertState.success}
      />
      <DialogComponent
        isOpen={viewState.dialogOpen}
        confirmFunction={handleDialogConfirmation}
        closeFunction={handleDialogClose}
      />
      <div className={viewState.loading ? classes.main_screen : ""}>
        <div>{viewState.loading && <Progress />} </div>
        <ViewComponent
          {...viewState}
          handleCancel={handleCleanform}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          // handleSourceAmountChange={handleSourceAmountChange}
          // handleDestinationAmountChange={handleDestinationAmountChange}
        />
      </div>
    </>
  );
};

export default EtransferCreateTransaction;
