import React, { Component } from "react";
import { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CardHeader from "components/Card/CardHeader.js";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// import InputLabel from "@material-ui/core/InputLabel";
import CardText from "components/Card/CardText.js";
import axios from "axios";
import apiUrl from "../api";
import Check from "@material-ui/icons/Check";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Progress from "../Components/Progress";
import CustomSweet from "../Components/CustomSweet";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeApiCall } from "utils/commonCheckFunctions";

const userType = localStorage.getItem("user_type");
const SUPERADMINUSER = "superadmin";

const base_url = apiUrl;
const currencyList = [
  { name: "NGN", value: "ngn" },
  { name: "GHS", value: "ghs" },
  { name: "KES", value: "kes" },
  { name: "ZAR", value: "zar" },
  { name: "XOF", value: "xof" },
  { name: "PHP", value: "php" },
  { name: "INR", value: "inr" },
  { name: "IDR", value: "idr" },
  { name: "CNY", value: "cny" },
  { name: "SGD", value: "sgd" },
  { name: "EUR", value: "eur" },
  { name: "GBP", value: "gbp" },
  { name: "CAD", value: "cad" },
  { name: "USD", value: "usd" },
  { name: "BRL", value: "brl" },


];

class AddPaymentGateway extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      validName:true,
      selectedCurrencies: new Set(),
      selectedBA:"",
      baUsers:[],
      loading: false,
      success: false,
      error: false,
      alert: false,
      message: "",
      title: " Create Payment Gateway !!!",
    };
  }

  componentDidUpdate() {
    const{validName,name} =this.state;
    if(!validName && name !==""){
      this.setState({
        validName:true,
      })
    }
  }

  componentDidMount(){
    if(userType === SUPERADMINUSER){
      this.getAllBaUsers();
    }
  }

  handleNameChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleCurrencyChange = (event) => {
    const { value } = event.target;
    const { selectedCurrencies } = this.state;
    if (selectedCurrencies.has(value)) {
      selectedCurrencies.delete(value);
    } else {
      selectedCurrencies.add(value);
    }
    this.setState({
      selectedCurrencies,
    });
  };

  getCurrencyList = () => {
    const { selectedCurrencies } = this.state;
    const { classes } = this.props;
    let elements = currencyList.map((element) => {
      return (
        <FormControlLabel
          control={
            <Checkbox
              key={element.name}
              label={element.name}
              value={element.value}
              checked={selectedCurrencies.has(element.value)}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot,
              }}
              onChange={this.handleCurrencyChange}
            />
          }
          label={element.name}
          key={element.name}
        />
      );
    });
    return elements;
  };

  handleCreate = () => {
    const { name, selectedCurrencies,selectedBA } = this.state;
    // console.log(name, selectedCurrencies);
    if (this.validateForm()) {
      const params = userType === SUPERADMINUSER ? {ba_id:selectedBA}:{};
      this.setState({ loading: true });
      axios({
        method: "post",
        url: base_url + "/payment_gateways.json",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        },
        params: {
          currency_attr: Array.from(selectedCurrencies),
          name: name,
          ...params,
        },
      })
        .then((result) => {
          //access the results here....
          console.log(result);
          this.setState({
            loading: false,
            alert: true,
            message: "Payment Gateway created successfully !!",
            error: false,
            success: true,
            name:"",
            selectedBA:"",
            selectedCurrencies:new Set(),
          });
        })
        .catch((error) => {
          console.log("Error", error.response);
          this.setState({
            loading: false,
            alert: true,
            message: "Something Went Wrong !!",
            error: true,
            success: false,
          });
        });
    }
  };

  validateForm = () => {
    const { name, selectedCurrencies,selectedBA } = this.state;

    if (name === "") {
      alert("Name is Required !!!");
      this.setState({
        validName:false
      })
      return false;
    }
    if (selectedCurrencies.size === 0) {
      alert("Select Currency !!!");
      return false;
    }
    if(selectedBA === "" && userType === SUPERADMINUSER){
      alert("Select BA !!!");
      return false;
    }
    return true;
  };

  hideAlert = () => {
    this.setState({
      alert: false,
      message: "",
      success: false,
      error: false,
    });
  };

  getAllBaUsers = async () => {
    const data = await makeApiCall({
      url: "/users.json",
      method: "get",
      params: { role: "ba" },
    });
    const { error = false } = data;
    if (!error) {
      this.setState({
        baUsers: data,
      });
    } else {
      const { message } = data;
      console.log(message);
    }
  };

  getBAUserList = () => {
    const { classes } = this.props;
    if (userType === SUPERADMINUSER) {
      return (
        <GridItem xs={12} md={6} sm={10} style={{ marginTop: "10px" }}>
              <FormControl
                fullWidth
                className={classes.selectFormControl}
              >
                <InputLabel className={classes.selectLabel}>
                  {"BA *"}
                </InputLabel>

                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={this.state.selectedBA}
                  onChange={this.handleNameChange}
                  inputProps={{
                    name: "selectedBA",
                  }}
                >
                  {this.state.baUsers.map((row) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={row.id}
                      key={row.id}
                    >
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
        </GridItem>
      );
    } else {
      return null;
    }
  };

  render() {
    const { name, message, title, alert, error, success ,validName} = this.state;
    const { classes } = this.props;
    return (
      <Fragment>
        <CustomSweet
          alert={alert}
          message={message}
          title={title}
          hide_alert={this.hideAlert}
          danger={error}
          success={success}
        />
        <div className={this.state.loading === true ? classes.main_screen : ""}>
          <div align="center">
            {this.state.loading === true && <Progress />}
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" text>
                  <CardText color="rose">
                    <h4>Add Payment Gateway</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <GridContainer justify="center">
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            placeholder: "Name *",
                            value: name,
                            onChange: this.handleNameChange,
                            name: "name",
                            autoComplete: "off",
                            error:!validName
                          }}
                        />
                      </FormControl>
                    </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                    {this.getBAUserList()}
                  </GridContainer>
                  <GridContainer justify="center" style={{ marginTop: "40px" }}>
                    <GridItem xs={12} md={6} sm={10}>
                      Currencies *
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="center" style={{ marginTop: "10px" }}>
                    <GridItem xs={12} md={6} sm={10}>
                      {this.getCurrencyList()}
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="center" style={{ marginTop: "40px" }}>
                    <GridItem xs={12} md={3} sm={12}>
                      <FormControl fullWidth>
                        <Button color="rose" onClick={this.handleCreate}>
                         Add Payment Gateway
                        </Button>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </Fragment>
    );
  }
}

export default withStyles(cssstyles)(AddPaymentGateway);
