import React from "react";
// react component for creating dynamic tables
import axios from "axios";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardText from "components/Card/CardText.js";
import CardHeader from "components/Card/CardHeader.js";
import Assignment from "@material-ui/icons/Assignment";
import Table from "components/Table/Table.js";
import apiUrl from "../api";
import { withStyles } from "@material-ui/core/styles";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const base_url = apiUrl;
class ShowUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_user_id: "",
      user: "",
      loading: false,
    };
  }
  componentDidMount() {
    const path = window.location.href;
    const show_user_id = path.substring(path.lastIndexOf("/") + 1);
    this.setState({ show_user_id: show_user_id });
    this.getData(show_user_id);
  }
  getData = (show_user_id) => {
    this.setState({ loading: true });
    // const base_url = window.location.origin;
    axios({
      method: "get",
      url: base_url + "/users/" + show_user_id + ".json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {},
    })
      .then((result) => {
        // console.log(result);
        if (result.data) {
          this.setState({
            user: result.data,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          show_error: true,
          error_message: error.response.data
            ? error.response.data.message
            : error.message,
        });
        this.setState({ loading: false });
        // swal1("Search!", error.response.data.error, "error");
      });
  };
  get getUser() {
    if (this.state.user !== "") {
      return [
        [
          this.state.user.id,
          this.state.user.username,
          this.state.user.name,
          this.state.user.email,
          this.state.user.address,
          this.state.user.pincode,
          this.state.user.contact_no,
          this.state.user.client_name,
          this.state.user.code,
          this.state.user.created_at,
          this.state.user.created_time,
        ],
      ];
    } else {
      return [];
    }
  }
  render() {
    const { classes } = this.props;
    const UserData = this.getUser;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" text>
                  <CardText color="rose">
                    <h4 className={classes.cardTitle}>Complete User Information</h4>
                  </CardText>
                </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="primary"
                  tableHead={[
                    "ID",
                    "User Name",
                    "Name",
                    "Email ID",
                    "Address",
                    "Pincode",
                    "Contact Number",
                    "Client Name",
                    "Code",
                    "Created At",
                    "Created Time",
                  ]}
                  tableData={UserData}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
export default withStyles(cssstyles)(ShowUser);
