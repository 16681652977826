import React from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import FormLabel from "@material-ui/core/FormLabel";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardText from "components/Card/CardText.js";
// import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import apiUrl from "../api";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import CustomSweet from "../Components/CustomSweet";
import Progress from "../Components/Progress";

const base_url = apiUrl;

class VerifyAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      new_password: "",
      country_code: "",
      valid_confirm_password: "",
      valid_new_password: "",
      loading: false,
      banks: [],
      provider: "",
      valid_deposit_number: "",
      valid_provider: "",
      deposit_number: "",
      account_holder_name: "",
      verified_name: "",
    };
  }
  componentDidMount() {}
  getBanks = () => {
    axios({
      method: "get",
      url: base_url + "/bank_list.json",

      params: { currency: this.state.country_code },
    })
      .then((result) => {
        // console.log(result);
        this.setState({ banks: result.data.banks });
      })
      .catch((error) => {
        this.setState({
          show_error: true,
          error_message: error.response.data
            ? error.response.data.message
            : error.message,
        });
      });
  };
  handleCountryChange = (event) => {
    this.setState({ country_code: event.target.value, valid_country: "" }, () =>
      this.getBanks()
    );
  };
  handleProviderChange = (event) => {
    this.setState(
      { provider: event.target.value, valid_provider: "" },
      () => {}
    );
  };
  handleDepositChange = (event) => {
    this.setState({
      deposit_number: event.target.value,
      valid_deposit_number: "",
    });
  };
  hideAlert = (times) => {
    this.setState({
      alert: false,
      alert_msg: "",
      alert_title: "",
      alert_type: "",
    });
  };
  handleCancel = (event) => {
    event.preventDefault();
    this.setState({
      deposit_number: "",
      valid_deposit_number: "",
      provider: "",
      valid_provider: "",
      country_code: "",
      valid_country: "",
      verified_name: "",
    });
  };
  handleVerify = (event) => {
    event.preventDefault();
    // this.setState({ loading: true });
    axios({
      method: "get",
      url: base_url + "/verify_account.json",
      params: {
        currency: this.state.country_code,
        deposit_number: this.state.deposit_number,
        account_code: this.state.provider,
      },
    })
      .then((result) => {
        console.log(result);
        this.setState({ verified_name: result.data.name });
      })
      .catch((error) => {
        this.setState({
          show_error: true,
          error_message: error.response.data
            ? error.response.data.message
            : error.message,
        });
      });
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <CustomSweet
          alert={this.state.alert}
          message={this.state.alert_msg}
          title={this.state.alert_title}
          hide_alert={this.hideAlert}
          danger={this.state.danger}
          warning={this.state.warning}
          success={this.state.success}
          times={this.state.times}
        />
        <div className={this.state.loading === true ? classes.main_screen : ""}>
          <div align="center">
            {this.state.loading === true && <Progress />}
          </div>
          <GridContainer justify="center">
            <GridItem xs={12} md={12} lg={12}>
              <Card>
                <CardHeader color="rose" text>
                  <CardText color="rose">
                    <h4>Verify Account</h4>
                  </CardText>
                </CardHeader>
                <br />
                <br />
                <CardBody>
                  {" "}
                  <form>
                    {" "}
                    <GridContainer justify="center">
                      <GridItem xs={12} md={6} sm={10}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                            success={this.state.valid_txn_type === ""}
                            error={this.state.valid_txn_type === "false"}
                          >
                            {"Please Select Country *"}
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.country_code}
                            onChange={this.handleCountryChange}
                            inputProps={{
                              name: "simpleSelect",
                              id: "country-select",
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value="ngn"
                            >
                              Nigeria
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value="ghs"
                            >
                              Ghana
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      <GridItem xs={12} md={6} sm={10}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                          style={{ height: "40px" }}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                            success={this.state.valid_provider === ""}
                            error={this.state.valid_provider === "false"}
                          >
                            {"Please Select Bank *"}
                          </InputLabel>

                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.provider}
                            onChange={this.handleProviderChange}
                            inputProps={{
                              name: "simpleSelect",
                              id: "simple-select",
                            }}
                          >
                            {this.state.banks &&
                              this.state.banks.map((row) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={row.id}
                                  value={row.code}
                                >
                                  {row.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      <GridItem
                        xs={12}
                        md={6}
                        sm={10}
                        style={{ height: "50px" }}
                      >
                        <FormControl fullWidth>
                          <CustomInput
                            labelText="Enter Account Number *"
                            success={this.state.valid_deposit_number === ""}
                            error={this.state.valid_deposit_number === "false"}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              onChange: this.handleDepositChange,
                              value: this.state.deposit_number,
                            }}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      <GridItem
                        xs={12}
                        md={6}
                        sm={10}
                        style={{ height: "50px" }}
                      >
                        <CustomInput
                          disabled
                          labelText="Account Holder Name"
                          id="email_adress2"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "email",
                            disabled: true,
                            value: this.state.verified_name,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                    <br />
                    <GridContainer justify="center">
                      <GridItem
                        xs={12}
                        sm={12}
                        md={3}
                        style={{ height: "50px" }}
                      >
                        <FormControl fullWidth>
                          <Button onClick={this.handleCancel}>Cancel</Button>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} md={3} sm={12}>
                        <FormControl fullWidth>
                          <Button color="rose" onClick={this.handleVerify}>
                            Submit
                          </Button>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(VerifyAccount);
