import React from "react";
import { withStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import FormControl from "@material-ui/core/FormControl";
import axios from "axios";
import Table from "components/Table/Table.js";
import apiUrl from "../api";
import CardText from "components/Card/CardText.js";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import CustomInput from "components/CustomInput/CustomInput.js";
import Progress from "../Components/Progress";
import CustomSweet from "../Components/CustomSweet";

const base_url = apiUrl;

class Setting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      settingsData: [],
      set_value_index: -1,
      value: "",
      setting_value: [],
    };
  }
  componentDidMount() {
    this.getSettingsData();
  }
  getSettingsData() {
    this.setState({ loading: true });
    // const base_url = window.location.origin;
    axios({
      method: "get",
      url: base_url + "/settings.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {},
    })
      .then((result) => {
        if (result.data) {
          this.setState({
            settingsData: result.data.settings,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          show_error: true,
          error_message: error.response.data
            ? error.response.data.message
            : error.message,
        });
        this.setState({ loading: false });
        // swal1("Search!", error.response.data.error, "error");
      });
  }
  getValue = (row, index) => {
    const { classes } = this.props;
    return (
      <div>
        <FormControl className={classes.contr} align="center">
          <CustomInput
            id="value"
            labelText="Value"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "number",
              value:
                this.state.set_value_index === index
                  ? this.state.value
                  : row.value !== ""
                  ? row.value
                  : "0.0",
              onChange: (e) => this.handleValueChange(e, index),
            }}
          />
        </FormControl>
      </div>
    );
  };
  handleValueChange = (event, index) => {
    console.log(index);
    this.setState({ value: event.target.value, set_value_index: index });
  };
  addButton = (row, index) => {
    // const { classes } = this.props;
    return (
      <div>
        <FormControl>
          <Button
            variant="contained"
            size="sm"
            onClick={(e) => this.handleSave(e, index, row, this)}
          >
            Update
          </Button>
        </FormControl>
      </div>
    );
  };
  getData = () => {
    return this.state.settingsData.map((row, index) => [
      row.var.replace(/_/g, " "),
      this.getValue(row, index),
      this.addButton(row, index),
    ]);
  };
  handleSave = (event, index, row) => {
    event.preventDefault();
    var rows = document.querySelectorAll("table tbody tr");
    var value = rows[index].cells[1].querySelector("input").value;
    var name = rows[index].cells[1].textContent.replace(/ /g, "_");
    var confirmation = window.confirm(
      "Are you sure you want to update setting?"
    );
    this.setState({
      loading: true,
    });
    if (confirmation) {
      axios({
        method: "put",
        url: base_url + "/settings/update_setting/" + row.id + ".json",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        },
        params: {
          "setting[value]": value,
          "setting[var]": name,
        },
      })
        .then((result) => {
          this.setState({
            loading: false,
            set_value_index: -1,
            value: "",
          });
          this.getSettingsData();
          this.setState({
            loading: false,
            alert: true,
            alert_msg: "Settings updated successfully!",
            alert_title: "Settings!",
            danger: false,
            warning: false,
            success: true,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("Error", error.response);
          this.setState({
            loading: false,
            alert: true,
            alert_msg: error.response,
            alert_title: "Error!",
            danger: true,
            warning: false,
            success: false,
          });
        });
    }
  };
  hideAlert = (times) => {
    this.setState({
      alert: false,
      alert_msg: "",
      alert_title: "",
      alert_type: "",
    });
  };
  render() {
    const { classes } = this.props;
    var settingData = this.getData();

    return (
      <div>
        <CustomSweet
          alert={this.state.alert}
          message={this.state.alert_msg}
          title={this.state.alert_title}
          hide_alert={this.hideAlert}
          danger={this.state.danger}
          warning={this.state.warning}
          success={this.state.success}
          times={this.state.times}
        />
        <div className={this.state.loading === true ? classes.main_screen : ""}>
          <div align="center">
            {this.state.loading === true && <Progress />}
          </div>

          <GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <Card>
                  <CardHeader color="rose" text>
                    <CardText color="rose">
                      <h4 className={classes.cardTitle}>{" Settings "}</h4>
                    </CardText>
                  </CardHeader>
                  <CardBody>
                    <Table
                      tableHeaderColor="primary"
                      tableHead={["Description", "Value", "Action"]}
                      tableData={settingData}
                      id="conversionSettings"
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </GridContainer>
        </div>
      </div>
    );
  }
}
export default withStyles(cssstyles)(Setting);
