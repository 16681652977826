import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import moment from "moment";
import apiUrl from "../api";
import CardText from "components/Card/CardText.js";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import CustomInput from "components/CustomInput/CustomInput.js";
import EditIcon from "@material-ui/icons/Edit";
import UpdateIcon from "@material-ui/icons/Update";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteIcon from "@material-ui/icons/Delete";
import Progress from "../Components/Progress";
import CustomSweet from "../Components/CustomSweet";
const base_url = apiUrl;

let d = new Date();
d.setDate(d.getDate() - 1);
d.setHours(0);
d.setMinutes(0);
d.setSeconds(1);
let prev_date = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
//   " "
// let prev_time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();

let tempDate = new Date();
tempDate.setHours(23);
tempDate.setMinutes(59);
tempDate.setSeconds(59);
let date =
  tempDate.getFullYear() +
  "-" +
  (tempDate.getMonth() + 1) +
  "-" +
  tempDate.getDate();
//   " ";
// let time =
//   tempDate.getHours() +
//   ":" +
//   tempDate.getMinutes() +
//   ":" +
//   tempDate.getSeconds();

class BlockListRules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: 0,
      selectedDate: prev_date,
      selectedendDate: date,
      loading: false,
      blockListData: [],
      first_name: "",
      last_name: "",
      status: "",
      update_mode: false,
      update_index: -1,
      set_receiver_first_name_index: -1,
      receiver_first_name: "",
      set_receiver_last_name_index: -1,
      receiver_last_name: "",
      sender_first_name: "",
      set_sender_first_name_index: -1,
      sender_last_name: "",
      set_sender_last_name_index: -1,
      block_status_id: "",
      block_status_id_index: -1,
      comment: "",
      comment_index: -1,
      receiver_account_number: "",
      set_receiver_account_number_index: -1,
    };
  }
  onSearch = (event) => {
    event.preventDefault();
    this.getBlockRules();
  };
  getBlockRules() {
    // const base_url = window.location.origin;
    // var url;
    // if (
    //   this.state.first_name === "" &&
    //   this.state.first_name === "" &&
    //   this.state.selectedDate === "" &&
    //   this.state.selectedendDate === ""
    // ) {
    //   url = base_url + "/blocklist_rules.json?";
    //   this.fetchAllRules(url);
    //   return;
    // }
    const startDate = moment(this.state.selectedDate).format("YYYY-MM-DD");
    const endDate = moment(this.state.selectedendDate).format("YYYY-MM-DD");

    let postDict = {
      start_date: startDate,
      end_date: endDate,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      urid: this.state.status,
    };
    if (startDate === "" || startDate === "Invalid date") {
      delete postDict.start_date;
    }
    if (endDate === "" || endDate === "Invalid date") {
      delete postDict.end_date;
    }
    if (this.state.first_name === "") {
      delete postDict.first_name;
    }

    if (this.state.last_name === "") {
      delete postDict.last_name;
    }
    if (this.state.status === "" || this.state.status === "all") {
      delete postDict.urid;
    }
    console.log(postDict);
    this.setState({ loading: true });
    if (this.handleValidations()) {
      axios({
        method: "get",
        url: base_url + "/get_blocklist_rules_by_parameters.json?",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        },
        params: postDict,
      })
        .then((result) => {
          // console.log(result);
          if (result.data) {
            this.setState({
              blockListData: result.data.rules != null ? result.data.rules : [],
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            show_error: true,
            error_message: error.response.data
              ? error.response.data.message
              : error.message,
          });
          this.setState({ loading: false });
          // swal1("Search!", error.response.data.error, "error");
          this.setState({
            loading: false,
            alert: true,
            alert_msg: error,
            alert_title: "error!",
            danger: false,
            warning: false,
            success: false,
          });
        });
    }
  }
  fetchAllRules(url) {
    this.setState({ loading: true });
    axios({
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {},
    })
      .then((result) => {
        // console.log(result);
        if (result.data) {
          this.setState({
            blockListData: result.data.rules != null ? result.data.rules : [],
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          show_error: true,
          error_message: error.response.data
            ? error.response.data.message
            : error.message,
        });
        this.setState({ loading: false });
        // swal1("Search!", error.response.data.error, "error");
      });
  }
  handleValidations() {
    if (this.state.selectedDate !== "" && this.state.selectedendDate !== "") {
      if (this.state.first_name === "" && this.state.last_name === "") {
        // alert(
        //   "You need to add either of first or last name with other parameters."
        // );
        // this.setState({ loading: false });
        // return false;
      }
    }
    return true;
  }
  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };
  handleEndDateChange = (date) => {
    this.setState({ selectedendDate: date });
  };
  handleLastNameChange = (event) => {
    this.setState({ last_name: event.target.value });
  };
  handleFirstNameChange = (event) => {
    this.setState({ first_name: event.target.value });
  };
  handleStatusChange = (event) => {
    this.setState({ status: event.target.value });
  };
  handleCancel = (event) => {
    this.setState({
      update_mode: false,
      update_index: -1,
      receiver_first_name: "",
      set_receiver_first_name_index: -1,
      set_receiver_last_name_index: -1,
      receiver_last_name: "",
      sender_first_name: "",
      set_sender_first_name_index: -1,
      sender_last_name: "",
      set_sender_last_name_index: -1,
      block_status_id: "",
      block_status_id_index: -1,
      comment: "",
      set_comment_index: -1,
      receiver_account_number: "",
      set_receiver_account_number_index: -1,
    });
  };
  get_status(urid, index, id) {
    const { classes } = this.props;
    if (this.state.update_mode && this.state.update_index === id) {
      return (
        <div>
          <FormControl className={classes.formControl} align="center">
            <InputLabel
              htmlFor="outlined-age-native-simple"
              color="secondary"
            ></InputLabel>
            <Select
              native
              id="pg-select"
              labelWidth={100}
              value={
                this.state.block_status_id_index === index
                  ? this.state.block_status_id
                  : urid !== ""
                  ? urid
                  : ""
              }
              onChange={(e) => this.handleBlockListChange(e, index)}
            >
              <option value="select">Please select one</option>
              <option key={"4000"} value={"4000"}>
                {"4000 - Sender is blocked"}
              </option>
              <option key={"4010"} value={"4010"}>
                {"4010 - Receiver is blocked"}
              </option>
              <option key={"4020"} value={"4020"}>
                {"4020 - Both are blocked"}
              </option>
            </Select>
          </FormControl>
        </div>
      );
    } else {
      switch (urid) {
        case 4000:
          return "4000 - Sender is blocked";
        case 4010:
          return "4010 - Receiver is blocked";
        case 4020:
          return "4020 - Both are blocked";
        default:
          return "";
      }
    }
  }
  addUpdate(
    id,
    index,
    internal_txn_id,
    receiver_first_name,
    receiver_last_name,
    receiver_account_number,
    sender_first_name,
    sender_last_name,
    urid,
    comment
  ) {
    if (this.state.update_mode && this.state.update_index === id) {
      return (
        <div>
          <Button
            justIcon
            round
            simple
            color="success"
            value={id}
            onClick={(e) =>
              this.handleSave(
                e,
                id,
                internal_txn_id,
                receiver_first_name,
                receiver_last_name,
                receiver_account_number,
                sender_first_name,
                sender_last_name,
                urid,
                comment
              )
            }
          >
            <UpdateIcon />
          </Button>{" "}
          <Button
            justIcon
            round
            simple
            color="warning"
            value={id}
            onClick={(e) => this.handleCancel(e, id)}
          >
            <CancelIcon />
          </Button>{" "}
          <Button
            justIcon
            round
            simple
            color="danger"
            value={id}
            onClick={(e) => this.handleDelete(e, id)}
          >
            <DeleteIcon />
          </Button>{" "}
        </div>
      );
    } else {
      return (
        <div>
          <Button
            justIcon
            round
            simple
            color="warning"
            value={id}
            onClick={(e) => this.handleUpdate(e, id)}
          >
            <EditIcon />
          </Button>{" "}
          <Button
            justIcon
            round
            simple
            color="danger"
            value={id}
            onClick={(e) => this.handleDelete(e, id)}
          >
            <DeleteIcon />
          </Button>{" "}
        </div>
      );
    }
  }
  handleSave = (
    event,
    id,
    internal_txn_id,
    receiver_first_name,
    receiver_last_name,
    receiver_account_number,
    sender_first_name,
    sender_last_name,
    urid,
    comment
  ) => {
    event.preventDefault();
    var confirmation = window.confirm(
      "Are you sure you want to update setting?"
    );
    this.setState({
      loading: true,
    });
    if (confirmation) {
      axios({
        method: "put",
        url: base_url + "/update_blocklist_rule/" + id + ".json",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        },
        params: {
          id: id,
          internal_txn_id: internal_txn_id,
          receiver_first_name:
            this.state.receiver_first_name !== ""
              ? this.state.receiver_first_name
              : receiver_first_name,
          receiver_last_name:
            this.state.receiver_last_name !== ""
              ? this.state.receiver_last_name
              : receiver_last_name,
          receiver_account_number:
            this.state.receiver_account_number !== ""
              ? this.state.receiver_account_number
              : receiver_account_number,
          sender_first_name:
            this.state.sender_first_name !== ""
              ? this.state.sender_first_name
              : sender_first_name,
          sender_last_name:
            this.state.sender_last_name !== ""
              ? this.state.sender_last_name
              : sender_last_name,
          urid:
            this.state.block_status_id !== ""
              ? this.state.block_status_id
              : urid,
          comment: this.state.comment !== "" ? this.state.comment : comment,
        },
      })
        .then((result) => {
          //access the results here....
          console.log(result);
          this.setState({
            loading: false,
          });
          if (result.data === true) {
            this.setState({
              loading: false,
              alert: true,
              alert_msg: "Block Rule has been successfully updated.!",
              alert_title: "Blocklist Rule!",
              danger: false,
              warning: false,
              success: true,
              times: "0",
              update_mode: false,
              update_index: -1,
              receiver_first_name: "",
              set_receiver_first_name_index: -1,
              set_receiver_last_name_index: -1,
              receiver_last_name: "",
              sender_first_name: "",
              set_sender_first_name_index: -1,
              sender_last_name: "",
              set_sender_last_name_index: -1,
              block_status_id: "",
              block_status_id_index: -1,
              comment: "",
              set_comment_index: -1,
              receiver_account_number: "",
              set_receiver_account_number_index: -1,
            });
            this.getBlockRules();
          } else if (Array.isArray(result.data)) {
            this.setState({
              loading: false,
              alert: true,
              alert_msg: "Could not update details. " + result.data.data[1],
              alert_title: "Error!",
              danger: true,
              warning: false,
              success: false,
            });
          } else {
            this.setState({
              loading: false,
              alert: true,
              alert_msg: "Could not update details.",
              alert_title: "Error!",
              danger: true,
              warning: false,
              success: false,
            });
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("Error", error.response);
          this.setState({
            loading: false,
            alert: true,
            alert_msg: error.response,
            alert_title: "Error!",
            danger: true,
            warning: false,
            success: false,
          });
        });
    }
  };
  addDelete = (id) => {
    return (
      <button
        variant="contained"
        color="secondary"
        class={"btn-danger btn-s"}
        value={id}
        onClick={(e) => this.handleDelete(e, id)}
      >
        Delete
      </button>
    );
  };
  handleUpdate = (event, id) => {
    event.preventDefault();
    this.setState({ update_mode: true, update_index: id });
  };
  handleDelete = (event, id) => {
    event.preventDefault();
    var confirmation = window.confirm(
      "Are you sure you want to delete setting?"
    );

    if (confirmation) {
      this.setState({
        loading: true,
      });
      axios({
        method: "delete",
        url: base_url + "/delete_blocklist_rule/" + id + ".json",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("session_token")}`,
          "Content-Type": "application/json",
        },
        params: {},
      })
        .then((result) => {
          //access the results here....
          console.log(result);
          this.setState({
            blockListData: this.state.blockListData.filter(function(block) {
              return block.id !== id;
            }),
          });
          this.setState({
            loading: false,
            alert: true,
            alert_msg: "Block rule deleted successfully.!",
            alert_title: "Block rule Delete!",
            danger: false,
            warning: false,
            success: true,
            open: false,
          });
        })
        .catch((error) => {
          console.log("Error", error.response);
          this.setState({
            loading: false,
            alert: true,
            alert_msg: error,
            alert_title: "error!",
            danger: false,
            warning: false,
            success: true,
          });
        });
    }
  };
  handleReceiverFirstNameChange = (event, index) => {
    this.setState({
      receiver_first_name: event.target.value,
      set_receiver_first_name_index: index,
    });
  };
  handleReceiverLastNameChange = (event, index) => {
    this.setState({
      receiver_last_name: event.target.value,
      set_receiver_last_name_index: index,
    });
  };
  handleSenderFirstNameChange = (event, index) => {
    this.setState({
      sender_first_name: event.target.value,
      set_sender_first_name_index: index,
    });
  };
  handleSenderLastNameChange = (event, index) => {
    this.setState({
      sender_last_name: event.target.value,
      set_sender_last_name_index: index,
    });
  };
  handleCommentChange = (event, index) => {
    this.setState({
      comment: event.target.value,
      set_comment_index: index,
    });
  };
  handleBlockListChange = (event, index) => {
    this.setState({
      block_status_id: event.target.value,
      block_status_id_index: index,
    });
  };
  handleReceiverAccountChange = (event, index) => {
    this.setState({
      receiver_account_number: event.target.value,
      set_receiver_account_number_index: index,
    });
  };
  getReceiverFirstName(receiver_first_name, index, id) {
    const { classes } = this.props;
    if (this.state.update_mode && this.state.update_index === id) {
      return (
        <div>
          <FormControl className={classes.formControl} align="center">
            <CustomInput
              id="receiver_first_name"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value:
                  this.state.set_receiver_first_name_index === index
                    ? this.state.receiver_first_name
                    : receiver_first_name !== ""
                    ? receiver_first_name
                    : "",
                onChange: (e) => this.handleReceiverFirstNameChange(e, index),
              }}
            />
          </FormControl>
        </div>
      );
    } else {
      return receiver_first_name;
    }
  }
  getReceiverLastName(receiver_last_name, index, id) {
    const { classes } = this.props;
    if (this.state.update_mode && this.state.update_index === id) {
      return (
        <div>
          <FormControl className={classes.formControl} align="center">
            <CustomInput
              id="receiver_last_name"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value:
                  this.state.set_receiver_last_name_index === index
                    ? this.state.receiver_last_name
                    : receiver_last_name !== ""
                    ? receiver_last_name
                    : "",
                onChange: (e) => this.handleReceiverLastNameChange(e, index),
              }}
            />
          </FormControl>
        </div>
      );
    } else {
      return receiver_last_name;
    }
  }
  getsenderFirstName(sender_first_name, index, id) {
    const { classes } = this.props;
    if (this.state.update_mode && this.state.update_index === id) {
      return (
        <div>
          <FormControl className={classes.formControl} align="center">
            <CustomInput
              id="sender_first_name"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value:
                  this.state.set_sender_first_name_index === index
                    ? this.state.sender_first_name
                    : sender_first_name !== ""
                    ? sender_first_name
                    : "",
                onChange: (e) => this.handleSenderFirstNameChange(e, index),
              }}
            />
          </FormControl>
        </div>
      );
    } else {
      return sender_first_name;
    }
  }

  getsenderLastName(sender_last_name, index, id) {
    const { classes } = this.props;
    if (this.state.update_mode && this.state.update_index === id) {
      return (
        <div>
          <FormControl className={classes.formControl} align="center">
            <CustomInput
              id="sender_last_name"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value:
                  this.state.set_sender_last_name_index === index
                    ? this.state.sender_last_name
                    : sender_last_name !== ""
                    ? sender_last_name
                    : "",
                onChange: (e) => this.handleSenderLastNameChange(e, index),
              }}
            />
          </FormControl>
        </div>
      );
    } else {
      return sender_last_name;
    }
  }
  getComment(comment, index, id) {
    const { classes } = this.props;
    if (this.state.update_mode && this.state.update_index === id) {
      return (
        <div>
          <FormControl className={classes.formControl} align="center">
            <CustomInput
              id="comment"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value:
                  this.state.set_comment_index === index
                    ? this.state.comment
                    : comment !== ""
                    ? comment
                    : "",
                onChange: (e) => this.handleCommentChange(e, index),
              }}
            />
          </FormControl>
        </div>
      );
    } else {
      return comment;
    }
  }
  getReceiverAccount(receiver_account_number, index, id) {
    const { classes } = this.props;
    if (this.state.update_mode && this.state.update_index === id) {
      return (
        <div>
          <FormControl className={classes.formControl} align="center">
            <CustomInput
              id="receiver_account_number"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value:
                  this.state.set_receiver_account_number_index === index
                    ? this.state.receiver_account_number
                    : receiver_account_number !== ""
                    ? receiver_account_number
                    : "",
                onChange: (e) => this.handleReceiverAccountChange(e, index),
              }}
            />
          </FormControl>
        </div>
      );
    } else {
      return receiver_account_number;
    }
  }

  get getData() {
    return this.state.blockListData.map((row, index) => ({
      id: row.id,
      internal_txn_id: row.internal_txn_id,
      receiver_first_name: this.getReceiverFirstName(
        row.receiver_first_name,
        index,
        row.id
      ),
      receiver_last_name: this.getReceiverLastName(
        row.receiver_last_name,
        index,
        row.id
      ),
      receiver_account_number: this.getReceiverAccount(
        row.receiver_account_number,
        index,
        row.id
      ),
      sender_first_name: this.getsenderFirstName(
        row.sender_first_name,
        index,
        row.id
      ),
      sender_last_name: this.getsenderLastName(
        row.sender_last_name,
        index,
        row.id
      ),
      status: this.get_status(row.urid, index, row.id),
      comment: this.getComment(row.comment, index, row.id),
      created_at: row.created_at.substr(0, 10),
      created_at_time: row.created_at.substr(10),
      updated_at: row.updated_at.substr(0, 10),
      updated_at_time: row.updated_at.substr(10),
      action: this.addUpdate(
        row.id,
        index,
        row.internal_txn_id,
        row.receiver_first_name,
        row.receiver_last_name,
        row.receiver_account_number,
        row.sender_first_name,
        row.sender_last_name,
        row.urid,
        row.comment
      ),
      // this.addDelete(row.id),
    }));
  }
  filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
      : true;
  };
  hideAlert = (times) => {
    this.setState({
      alert: false,
      alert_msg: "",
      alert_title: "",
      alert_type: "",
    });
  };
  render() {
    const { classes } = this.props;
    const blockRulesData = this.getData;
    return (
      <div>
        <CustomSweet
          alert={this.state.alert}
          message={this.state.alert_msg}
          title={this.state.alert_title}
          hide_alert={this.hideAlert}
          danger={this.state.danger}
          warning={this.state.warning}
          success={this.state.success}
          times={this.state.times}
        />
        <div className={this.state.loading === true ? classes.main_screen : ""}>
          <div align="center">
            {this.state.loading === true && <Progress />}
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={2}>
              <Card>
                <CardBody style={{ padding: "20px 20px" }}>
                  <InputLabel className={classes.label}>Start Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      inputProps={{ placeholder: "Start Date" }}
                      id="start-date-picker-dialog"
                      label="Start Date"
                      dateFormat={"YYYY-MM-DD"}
                      value={this.state.selectedDate}
                      onChange={this.handleDateChange}
                    />
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={2}>
              <Card>
                <CardBody style={{ padding: "20px 20px" }}>
                  <InputLabel className={classes.label}>End Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      inputProps={{ placeholder: "End Date" }}
                      id="end-date-picker-dialog"
                      label="End Date"
                      dateFormat={"YYYY-MM-DD"}
                      value={this.state.selectedendDate}
                      onChange={this.handleEndDateChange}
                    />
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <Card>
                <CardBody style={{ padding: "10px 20px" }}>
                  <FormControl fullWidth>
                    <CustomInput
                      id="first_name"
                      labelText="First Name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: this.state.first_name,
                        onChange: this.handleFirstNameChange,
                      }}
                    />
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <Card>
                <CardBody style={{ padding: "10px 20px" }}>
                  <FormControl fullWidth>
                    <CustomInput
                      id="lastName"
                      labelText="Last Name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: this.state.last_name,
                        onChange: this.handleLastNameChange,
                      }}
                    />
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <Card>
                <CardBody style={{ padding: "24px 20px" }}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Select Status
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={this.state.status}
                      onChange={this.handleStatusChange}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="all"
                        key="all"
                      >
                        Please select one
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="4000"
                        key="4000"
                      >
                        {"4000 - Sender is blocked"}
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="4010"
                        key="4010"
                      >
                        {"4010 - Receiver is blocked"}
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="4020"
                        key="4020"
                      >
                        {"4020 - Both are blocked"}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <Card>
                <CardBody style={{ padding: "23px 20px" }}>
                  <FormControl fullWidth>
                    <Button color="rose" onClick={this.onSearch}>
                      Search
                    </Button>
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="rose" text>
                  <CardText color="rose">
                    <h4 className={classes.cardTitle}>BlockList Rules</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={blockRulesData}
                    filterable
                    columns={[
                      {
                        Header: "ID",
                        accessor: "id",
                      },

                      {
                        Header: "Internal Transaction ID",
                        accessor: "internal_txn_id",
                      },
                      {
                        accessor: "receiver_first_name",
                        Header: "Receiver First Name",
                        filterMethod: this.filterMethod,
                      },
                      {
                        accessor: "receiver_last_name",
                        Header: "Receiver Last Name",
                        filterMethod: this.filterMethod,
                      },

                      {
                        accessor: "receiver_account_number",
                        Header: "Receiver Account Number",
                      },
                      {
                        accessor: "sender_first_name",
                        Header: "Sender First Name",
                        filterMethod: this.filterMethod,
                      },
                      {
                        accessor: "sender_last_name",
                        Header: "Sender Last Name",
                        filterMethod: this.filterMethod,
                      },
                      {
                        accessor: "status",
                        Header: "Status",
                        filterMethod: this.filterMethod,
                      },
                      {
                        accessor: "comment",
                        Header: "Comments",
                        filterMethod: this.filterMethod,
                      },
                      {
                        Header: "Created Date",
                        accessor: "created_at",
                      },
                      {
                        Header: "Created Time",
                        accessor: "created_at_time",
                      },
                      {
                        Header: "Updated Date",
                        accessor: "updated_at",
                      },
                      {
                        Header: "Updated Time",
                        accessor: "updated_at_time",
                      },
                      {
                        Header: "Action",
                        accessor: "action",
                      },
                    ]}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                    // defaultFilterMethod={this.filterMethod}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}
export default withStyles(cssstyles)(BlockListRules);
