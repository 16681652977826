import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CardHeader from "components/Card/CardHeader.js";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";
import CardText from "components/Card/CardText.js";
import axios from "axios";
import apiUrl from "../api";
import Close from "@material-ui/icons/Close";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import CustomSweet from "../Components/CustomSweet";
import Progress from "../Components/Progress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const base_url = apiUrl;
class SubClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: 0,
      loading: false,
      sub_clients: [],
      open: false,
      currencies: [],
      check_daily: false,
      check_wallet: false,
      check_fee: false,
      name: "",
      limit: "",
    };
  }
  componentDidMount() {
    const path = window.location.href;
    const client_id = path.substring(path.lastIndexOf("/") + 1);
    if (localStorage.getItem("client_name")) {
      this.setState(
        {
          client_id: client_id,
          client_name: localStorage.getItem("client_name"),
        },
        () => {}
      );
    }
    this.getSubClients(client_id);
  }
  getSubClients(client_id) {
    // const base_url = window.location.origin;
    this.setState({ loading: true });
    axios({
      method: "get",
      url: base_url + "/clients/" + client_id + "/sub_clients.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {},
    })
      .then((result) => {
        //access the results here....
        console.log(result.data.sub_clients);
        this.setState({
          sub_clients: result.data.sub_clients,
          loading: false,
        });
      })
      .catch((error) => {
        console.log("Error", error);
        this.setState({ loading: false });
      });
  }
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleNameChange = (event) => {
    this.setState({ name: event.target.value });
  };
  handleLimitChange = (event) => {
    this.setState({ limit: event.target.value });
  };
  handleCreateClient = (event) => {
    event.preventDefault();
    // const base_url = window.location.origin;
    this.setState({ loading: true });
    if (this.validateForm()) {
      axios({
        method: "post",
        url:
          base_url + "/clients/" + this.state.client_id + "/sub_clients.json",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        },
        params: {
          "client[name]": this.state.name,
          "client[transaction_limit]": this.state.limit,
        },
      })
        .then((result) => {
          //access the results here....
          console.log(result);
          this.getSubClients(this.state.client_id);
          this.setState({
            loading: false,
            open: false,
          });
          this.cleanInputs();
          this.setState({
            loading: true,
            alert: true,
            alert_msg: "Sub client created successfully.!",
            alert_title: "Sub client!",
            danger: false,
            warning: false,
            success: true,
          });
        })
        .catch((error) => {
          console.log("Error", error);
          this.setState({ loading: false });
        });
    }
  };
  showDate = (date) => {
    const firstdate = new Date(date.toString());
    return <span>{moment(firstdate).format("YYYY-MM-DD HH:mm:ss")}</span>;
  };
  cleanInputs() {
    this.setState({ name: "", limit: "" });
  }
  validateForm() {
    const { name, limit } = this.state;
    if (name === "") {
      this.setState({ valid_name: "false" });
      alert("Please enter Sub Client Name!!");

      return false;
    }
    if (limit === "" || limit <= 0) {
      this.setState({ valid_limit: "false" });
      alert("Please enter Limit!!");
      return false;
    }
    return true;
  }
  addDelete = (row) => {
    return (
      <div>
        <Button
          justIcon
          round
          simple
          color="danger"
          className="like"
          value={row.id}
          onClick={(e) => this.handleDelete(e, row.id)}
        >
          <Close />
        </Button>{" "}
      </div>
    );
  };
  get getData() {
    var x = this.state.sub_clients;
    return x.map((row, index) => ({
      id: row.id,
      name: row.name,
      transaction_limit: row.transaction_limit,
      api_token: row.client_api_tokens[0].api_token,
      secret_key: row.client_api_tokens[0].secret_key,
      expiration_date: this.showDate(row.client_api_tokens[0].expiration_date),
      action: this.addDelete(row),
    }));
  }
  handleDelete = (event, id) => {
    event.preventDefault();
    this.setState({
      loading: true,
      alert: true,
      alert_msg: "Are you sure you want to delete sub client?",
      alert_title: "Delete Sub Client!",
      danger: false,
      warning: true,
      success: false,
      param: id,
      times: "1",
    });
  };
  hideAlert = (times) => {
    this.setState(
      {
        alert: false,
        alert_msg: "",
        alert_title: "",
        alert_type: "",
      },
      () => {
        if (times === "1") {
          // const base_url = window.location.origin;
          axios({
            method: "delete",
            url: base_url + "/clients/" + this.state.param + ".json",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("session_token")}`,
            },
            params: {},
          })
            .then((result) => {
              const sub_clients = this.state.sub_clients.filter(
                (client) => client.id !== this.state.param
              );

              this.setState(
                {
                  loading: false,
                  alert: true,
                  alert_msg: "Sub Client deleted successfully.!",
                  alert_title: "Sub Client Delete!",
                  danger: false,
                  warning: false,
                  success: true,
                  times: "0",
                  sub_clients: sub_clients,
                }
                // () => this.cleanInputs()
              );
            })
            .catch((error) => {
              console.log(error);
              this.setState({
                loading: false,
                alert: true,
                alert_msg: error.response,
                alert_title: "Error!",
                danger: true,
                warning: false,
                success: false,
              });
            });
        }
      }
    );
  };
  filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
      : true;
  };
  render() {
    const { classes } = this.props;
    const settingData = this.getData;
    return (
      <div>
        <CustomSweet
          alert={this.state.alert}
          message={this.state.alert_msg}
          title={this.state.alert_title}
          hide_alert={this.hideAlert}
          danger={this.state.danger}
          warning={this.state.warning}
          success={this.state.success}
          times={this.state.times}
        />
        <div className={this.state.loading === true ? classes.main_screen : ""}>
          <div align="center">
            {this.state.loading === true && <Progress />}
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <Button onClick={this.handleOpen}>Create Sub CLient</Button>
                    <Dialog
                      open={this.state.open}
                      onClose={this.handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Create SubClient"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          <div>
                            <div>
                              <CustomInput
                                labelText="Sub Client Name *"
                                id="subclientName"
                                success={this.state.valid_name === ""}
                                error={this.state.valid_name === "false"}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  value: this.state.name,
                                  onChange: this.handleNameChange,
                                }}
                              />
                            </div>
                            <div>
                              <CustomInput
                                labelText="Transaction Limit *"
                                id="limit"
                                success={this.state.valid_limit === ""}
                                error={this.state.valid_limit === "false"}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  type: "number",
                                  value: this.state.limit,
                                  onChange: this.handleLimitChange,
                                }}
                              />
                            </div>
                          </div>
                        </DialogContentText>
                      </DialogContent>

                      <DialogActions>
                        <Button onClick={this.handleClose}>Cancel</Button>
                        <Button
                          onClick={(e) => this.handleCreateClient(e)}
                          color="rose"
                          autoFocus
                        >
                          Submit
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="rose" text>
                  <CardText color="rose">
                    <h4 className={classes.cardTitle}>
                      {localStorage.getItem("client_name") + " Sub Clients"}
                    </h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={settingData}
                    filterable
                    columns={[
                      {
                        Header: "ID",
                        accessor: "id",
                      },
                      {
                        accessor: "name",
                        Header: "Name",
                        filterMethod: this.filterMethod,
                      },
                      {
                        accessor: "transaction_limit",
                        Header: "Transaction Limit",
                        filterMethod: this.filterMethod,
                      },
                      {
                        Header: "API Token",
                        accessor: "api_token",
                        filterMethod: this.filterMethod,
                      },
                      {
                        Header: "Secret Key",
                        accessor: "secret_key",
                        filterMethod: this.filterMethod,
                      },
                      {
                        Header: "Expiration Date",
                        accessor: "expiration_date",
                      },
                      {
                        accessor: "action",
                        Header: "Action",
                      },
                    ]}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                    // defaultFilterMethod={this.filterMethod}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}
export default withStyles(cssstyles)(SubClient);
