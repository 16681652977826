import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
// @material-ui/icons
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Radio from "@material-ui/core/Radio";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CardHeader from "components/Card/CardHeader.js";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CardText from "components/Card/CardText.js";
import axios from "axios";
// import Check from "@material-ui/icons/Check";
import apiUrl from "../api";
import Datetime from "react-datetime";
import moment from "moment";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import CustomSweet from "../Components/CustomSweet";
import Progress from "../Components/Progress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
const base_url = apiUrl;

const wallet_currency = ['usd', 'usdc', 'usdt'];

let d = new Date();
d.setDate(d.getDate() - 1);
d.setHours(0);
d.setMinutes(0);
d.setSeconds(1);
let prev_date = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
//   " "
// let prev_time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();

let tempDate = new Date();
tempDate.setHours(23);
tempDate.setMinutes(59);
tempDate.setSeconds(59);
let date =
  tempDate.getFullYear() +
  "-" +
  (tempDate.getMonth() + 1) +
  "-" +
  tempDate.getDate();
class ShowClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      count: 1,
      user_id: 0,
      loading: false,
      versions: [],
      open: false,
      currencies: [],
      client_id: "",
      name: "",
      token: "",
      secret_key: "",
      wallet_currencies: [],
      checked_currency: [],
      fees: [],
      disable: true,
      serverSideFilterList: [],
      filters: [[], [], [], [], [], [], []],
      wallets: [],
      per_page: 100,
      wallet_balance: 0,
      set_wallet_id: 0,
      set_fee_id: -1,
      fee: 0,
      currency_name: "",
      feeable: false,
      txn_limit_enabled: false,
      is_wallet_enabled: false,
      set_limit_id: -1,
      limit: 0,
      default_limits: [],
      principle_within: false,
      set_currency_id: -1,
      checkedValues: [],
      show_table: false,
      selectedDate: prev_date,
      selectedendDate: date,
      status_value: "all",
      rows_count: 0,
      paymentProcess: "",
      // agent_user: "",
    };
    this.ReactTableRef = React.createRef();
  }
  componentDidMount() {
    const path = window.location.href;
    const client_id = path.substring(path.lastIndexOf("/") + 1);
    // if (localStorage.getItem("user_id")) {
    this.setState({
      // user_id: localStorage.getItem("user_id"),
      client_id: client_id,
    });
    // }
    this.getCurrencies();
    this.getWalletableCurrencies(client_id);
    // this.getVersions(client_id, this.state.page);
    this.getClientData(client_id);
    this.getFees(client_id);
    this.getWallets(client_id);
    this.getLimits(client_id);
  }
  onSearch = (event) => {
    event.preventDefault();
    const startDate = moment(this.state.selectedDate).format("YYYY-MM-DD");
    const endDate = moment(this.state.selectedendDate).format("YYYY-MM-DD");
    const reason = this.state.status_value;
    this.getVersions(
      this.state.client_id,
      this.state.page,
      startDate,
      endDate,
      reason
    );
  };
  getVersions = (client_id, page, startDate, endDate, reason) => {
    // const base_url = window.location.origin;
    this.setState({ loading: true, versions: [] });
    axios({
      method: "get",
      url: base_url + "/versions.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        client_id: client_id,
        page: page + 1,
        sort_order: "desc",
        sort_field: "id",
        per_page: this.state.per_page,
        start_date: startDate,
        end_date: endDate,
        reason: reason,
      },
    })
      .then((result) => {
        //access the results here....

        this.setState({
          versions: result.data.versions,
          count: result.data.count,
          page: page,
          loading: false,
          filters: [[], [], [], [], [], [], []],
          pages: result.data.pages,
        });
        let total_count = this.ReactTableRef.current.state.sortedData.length;
        this.setState({ rows_count: total_count });
      })
      .catch((error) => {
        console.log("Error", error);
        this.setState({ loading: false });
      });
  };
  getVersionsWithFilters = (
    client_id,
    filters,
    page,
    sort_order,
    sort_field,
    per_page
  ) => {
    const startDate = moment(this.state.selectedDate).format("YYYY-MM-DD");
    const endDate = moment(this.state.selectedendDate).format("YYYY-MM-DD");
    const reason = this.state.status_value;

    this.setState({ loading: true });
    // console.log("AA", this.state.filters, filters, client_id);
    let postDict = {
      client_id: client_id,
      page: page,
      filters: filters,
      sort_order: sort_order,
      sort_field: sort_field,
      per_page: per_page,
      start_date: startDate,
      end_date: endDate,
      reason: reason,
    };
    if (filters.length < 1) {
      delete postDict.filters;
    }
    axios({
      method: "get",
      url: base_url + "/versions.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: postDict,
    })
      .then((result) => {
        //access the results here....
        console.log(result, "print");
        this.setState({
          versions: result.data.versions,
          count: result.data.count,
          loading: false,
          pages: result.data.pages,
          per_page: per_page,
          rows_count: result.data.versions.length,
        });
      })
      .catch((error) => {
        console.log("Error", error);
        this.setState({ loading: false, versions: [] });
        // swal1("Search!", "Record Not Found", "error");
      });
  };
  getCurrencies = () => {
    // const base_url = window.location.origin;
    axios({
      method: "get",
      url: base_url + "/currencies.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {},
    })
      .then((result) => {
        //access the results here....
        this.setState({
          currencies: this.getAllCurrencies(result.data.currencies),
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  getAllCurrencies = (currencies = []) => {
    return currencies.filter((data) => {
      return data.attributes.type === "destination";
    });
  };
  getFees = (client_id) => {
    // const base_url = window.location.origin;
    axios({
      method: "get",
      url: base_url + "/show_fees.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: { client_id: client_id },
    })
      .then((result) => {
        //access the results here....
        this.setState({
          fees: result.data.fees,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  getWallets = (client_id) => {
    // const base_url = window.location.origin;
    axios({
      method: "get",
      url: base_url + "/show_client_wallets.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: { client_id: client_id },
    })
      .then((result) => {
        //access the results here....
        // console.log(result.data.wallets);
        this.setState({
          wallets: result.data.wallets,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  getLimits = (client_id) => {
    // const base_url = window.location.origin;
    axios({
      method: "get",
      url: base_url + "/show_transaction_limit.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: { client_id: client_id },
    })
      .then((result) => {
        //access the results here....
        // console.log(result.data.client_txn_limits);
        this.setState({
          default_limits: result.data.client_txn_limits,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  getWalletableCurrencies = (client_id) => {
    // const base_url = window.location.origin;
    axios({
      method: "get",
      url: base_url + "/show_walletable_currencies.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {client_id: client_id},
    })
      .then((result) => {
        //access the results here....
        var results = result.data;
        // Results.currencies.map(currency =>(
        //   currency.attributes.status = false
        // ))
        results.currencies.forEach(function(currency) {
          currency.attributes.active = true;
          currency.attributes.status = true;
        });
        // console.log(results);
        this.setState({
          wallet_currencies: results.currencies,
        });
        // console.log(result.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  getClientData = (client_id) => {
    // const base_url = window.location.origin;
    this.setState({ loading: true });
    const me = this;
    // console.log(this.state);
    axios({
      method: "get",
      url: base_url + "/clients/" + client_id + ".json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        client_id: client_id,
      },
    })
      .then((result) => {
        //access the results here....
        this.setState({
          name: result.data.client.name,
          token: result.data.client.api_token,
          secret_key: result.data.client.secret_key,
          loading: false,
          checked_currency: result.data.currencies,
          feeable: result.data.client.feeable,
          is_wallet_enabled: result.data.client.is_wallet_enabled,
          txn_limit_enabled: result.data.client.txn_limit_enabled,
          principle_within: result.data.client.fee_within_principle_amount,
          checkedValues: result.data.currencies.map((currency) => {
            return currency.currency_id;
          }),
          paymentProcess: result.data.client.manual_release
            ? "manual" : result.data.client.partial_release ? "partial" : "automatic",
        });
      })
      .catch((error) => {
        console.log("Error", error);

        this.setState({ loading: false });
      });
  };
  handleNameChange = (event) => {
    this.setState({ name: event.target.value });
  };
  setCheckbox = (currency_id) => {
    var bool = false;
    var result = this.state.checked_currency.find((obj) => {
      return obj.currency_id === currency_id;
    });
    if (typeof result != "undefined") {
      bool = true;
    }
    return bool;
  };
  showFee = (currency_id, type) => {
    var value = "0.0";
    var result;
    var id;
    // console.log(currency_id, "result");
    if (this.state.fees) {
      result = this.state.fees.find((obj) => {
        return obj.currency_id === currency_id;
      });
      if (typeof result != "undefined") {
        value = result.value;
        id = result.id;
        // console.log(value, "result");
      }
    }
    switch (type) {
      case "id":
        return id;
      default:
        return value;
    }
  };
  showLimit = (currency_id, type) => {
    var value = "0.0";
    var result;
    var id;
    var limit_value = "0.0";
    var bal_value = "0.0";
    var lock_value = "0.0";
    if (this.state.default_limits) {
      result = this.state.default_limits.find((obj) => {
        return obj.currency_id === currency_id;
      });
      if (typeof result != "undefined") {
        value = result.default_value;
        limit_value = result.limit_value;
        bal_value = result.balance;
        lock_value = result.locked;
        id = result.id;
      }
    }
    switch (type) {
      case "id":
        return id;
      case "limit":
        return limit_value;
      case "balance":
        return bal_value;
      case "locked":
        return lock_value;
      default:
        return value;
    }
  };
  showWallet = (currency_id, type) => {
    var amt_value = "0.0";
    var bal_value = "0.0";
    var lock_value = "0.0";
    var result, id;
    // console.log(this.state.wallets);
    if (this.state.wallets) {
      result = this.state.wallets.find((obj) => {
        return obj.currency_id === currency_id;
      });
      if (typeof result != "undefined") {
        id = result.id;
        amt_value = result.amount;
        bal_value = result.balance;
        lock_value = result.locked;
      } else {
        return "0.0";
      }
      //   console.log(result, id, amt_value, bal_value, lock_value);
    }
    switch (type) {
      case "id":
        return id;
      case "amount":
        return amt_value;
      case "balance":
        return bal_value;
      case "locked":
        return lock_value;
      default:
        return "";
    }
  };
  handleEdit = (currency_id) => {
    var { wallet_currencies } = this.state;
    for (var i = 0; i < wallet_currencies.length; i++) {
      if (wallet_currencies[i].attributes.id === currency_id) {
        wallet_currencies[i].attributes.active = false;
      }
    }
    // console.log(wallet_currencies, currency_id, "wallet_currencies");

    this.setState({ wallet_currencies });
  };
  handleLimitEdit = (e, currency_id) => {
    e.preventDefault();
    console.log("hello");
    var { wallet_currencies } = this.state;
    for (var i = 0; i < wallet_currencies.length; i++) {
      if (wallet_currencies[i].attributes.id === currency_id) {
        wallet_currencies[i].attributes.status = false;
      }
    }
    this.setState({ wallet_currencies });
  };
  handleSave = (e, currency_id, fee_id) => {
    e.preventDefault();
    var value = document.getElementById("fee" + fee_id).value;

    console.log(fee_id, value);

    if (value >= 0.0) {
      var confirmation = window.confirm("Are you sure you want to update fee?");
      if (confirmation) {
        this.setState({ loading: true });
        axios({
          method: "put",
          url: base_url + "/fees/" + fee_id,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("session_token")}`,
          },
          params: { client: this.state.client_id, value: value },
        })
          .then((result) => {
            //access the results here....
            // console.log(result);
            this.setState({ loading: false });
            this.setFeeCancel(currency_id);
            // alert('Transaction successfully initiated.');

            this.setState({
              loading: false,
              alert: true,
              alert_msg: "Fee successfully updated.",
              alert_title: "Fee Updated!",
              danger: false,
              warning: false,
              success: true,
            });
          })
          .catch((error) => {
            this.setState({ loading: false });
            console.log("Error", error.response);
            this.setState({
              loading: false,
              alert: true,
              alert_msg: error.response,
              alert_title: "Error",
              danger: true,
              warning: false,
              success: false,
            });
          });
      } else {
      }
    } else {
      alert("Please enter amount greater than 0.0");
    }
  };
  handleLimitSave = (e, currency, limit_id, currency_id) => {
    e.preventDefault();
    var value = document.getElementById("limit" + limit_id).value;
    // const base_url = window.location.origin;

    if (value > 0.0) {
      var confirmation = window.confirm(
        "Are you sure you want to update limit?"
      );
      if (confirmation) {
        this.setState({ loading: true });
        axios({
          method: "put",
          url: base_url + "/manage_txn_limit.json",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("session_token")}`,
          },
          params: {
            client: this.state.client_id,
            value: value,
            currency: currency,
          },
        })
          .then((result) => {
            //access the results here....
            // console.log(result);
            var { wallet_currencies } = this.state;
            for (var i = 0; i < wallet_currencies.length; i++) {
              if (wallet_currencies[i].attributes.id === currency_id) {
                wallet_currencies[i].attributes.status = true;
              }
            }
            this.setState({ wallet_currencies });
            this.setState({
              loading: false,
              alert: true,
              alert_msg: "WalletLimit successfully updated.",
              alert_title: "Wallet Limit Updated!",
              danger: false,
              warning: false,
              success: true,
            });
          })
          .catch((error) => {
            this.setState({ loading: false });
            console.log("Error", error.response);
            this.setState({
              loading: false,
              alert: true,
              alert_msg: error.response,
              alert_title: "Error",
              danger: true,
              warning: false,
              success: false,
            });
          });
      }
    } else {
      alert("Please enter amount greater than 0.0");
    }
  };
  handleCancel = (currency_id) => {
    this.setFeeCancel(currency_id);
  };
  setFeeCancel = (currency_id) => {
    var { wallet_currencies } = this.state;
    for (var i = 0; i < wallet_currencies.length; i++) {
      if (wallet_currencies[i].attributes.id === currency_id) {
        wallet_currencies[i].attributes.active = true;
      }
    }
    // console.log(wallet_currencies, currency_id, "wallet_currencies");
    this.setState({ wallet_currencies: wallet_currencies, loading: false });
  };
  handleLimitCancel = (currency_id) => {
    this.setCancel(currency_id);
  };
  setCancel(currency_id) {
    var { wallet_currencies } = this.state;
    for (var i = 0; i < wallet_currencies.length; i++) {
      if (wallet_currencies[i].attributes.id === currency_id) {
        wallet_currencies[i].attributes.status = true;
      }
    }
    this.setState({ wallet_currencies: wallet_currencies, loading: false });
  }
  handleFeeChange = (event, index) => {
    // alert(index);
    // console.log("index", index);
    this.setState({ fee: event.target.value, set_fee_id: index });
  };
  handleBalanceChange = (event, index) => {
    console.log(event, index);
    this.setState({ wallet_balance: event.target.value });
  };
  handleLimitChange = (event, index) => {
    this.setState({ limit: event.target.value, set_limit_id: index });
  };
  handleFilterSubmit = (filterList) => () => {
    this.setState({
      loading: true,
      filters: filterList,
      serverSideFilterList: filterList,
    });
    this.getVersionsWithFilters(this.state.client_id, filterList);
  };
  handleTimelog = (id, currency) => {
    // event.preventDefault();
    localStorage.setItem("client_id", this.state.client_id);
    localStorage.setItem("currency", currency);
    // console.log(localStorage.getItem("wallet_id"));
    // this.setState({ isTimelog: true });
    window.open("/react/wallet_timelog", "_blank");
  };
  handleClientTimelog = (e, id, currency) => {
    e.preventDefault();
    localStorage.setItem("client_id", this.state.client_id);
    localStorage.setItem("currency", currency);
    window.open("/admin/client_timelog/" + this.state.client_id, "_blank");
  };
  showSubClient = (event) => {
    event.preventDefault();
    localStorage.setItem("client_id", this.state.client_id);
    localStorage.setItem("client_name", this.state.name);
    // window.open("/react/subclient", "_blank");
    window.open("/admin/subclient/" + this.state.client_id, "_blank");
  };
  handleAddBalance = (event) => {
    event.preventDefault();
    // const base_url = window.location.origin;
    this.setState({ loading: true });
    const me = this;
    axios({
      method: "put",
      url: base_url + "/manage_wallet.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        client: this.state.client_id,
        currency: this.state.currency_name,
        value: this.state.wallet_balance,
      },
    })
      .then((result) => {
        //access the results here....
        console.log(me.state.set_wallet_id);
        // this.state.wallets.forEach(function(wallet) {
        //   if (wallet.currency_id == me.state.set_wallet_id) {
        //     wallet.balance = me.state.wallet_balance;
        //     wallet.amount = me.state.wallet_balance;
        //   }
        // });
        this.state.wallets.forEach(function(wallet) {
          if (wallet.currency_id === me.state.set_wallet_id) {
            var balance = wallet.balance;
            wallet.balance =
              parseInt(balance) + parseInt(me.state.wallet_balance);
            wallet.amount =
              parseInt(balance) + parseInt(me.state.wallet_balance);
          }
        });
        this.setState({ wallets: this.state.wallets });
        this.setState({ loading: false, open: false });
        this.cleanInputs();

        alert("Balance added successfully!");
      })
      .catch((error) => {
        // console.log("Error", error.response.data.error.message);
        this.setState({ loading: false });
        alert(error.response.data.error.message);
      });
  };
  cleanInputs() {
    this.setState({ wallet_balance: 0 });
  }
  handleOpen = (currency_name, currency_id) => {
    this.setState({
      open: true,
      currency_name: currency_name,
      set_wallet_id: currency_id,
    });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    // const base_url = window.location.origin;
    var check_currency = "";
    for (var i = 0; i < this.state.currencies.length; i++) {
      if (
        document.getElementById(
          "currency" + this.state.currencies[i].attributes.id
        ).checked
      ) {
        // console.log("currency", this.state.currencies[1]);
        check_currency += this.state.currencies[i].attributes.id + ",";
      }
    }
    // console.log(check_currency, "help");
    var confirmation = window.confirm("Are you sure you want to update client");
    if (confirmation) {
      this.setState({ loading: true });
      axios({
        method: "put",
        url: base_url + "/clients/" + this.state.client_id + ".json",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        },
        params: {
          // id: this.state.client_id,
          currency: check_currency,
          "client[name]": this.state.name,
          "client[fee_within_principle_amount]": this.state.principle_within,
          "client[manual_release]": this.state.paymentProcess === "manual",
          "client[partial_release]": this.state.paymentProcess === "partial",
        },
      })
        .then((result) => {
          this.setState({
            loading: false,
            alert: true,
            alert_msg: "Client updated successfully.!",
            alert_title: "Client Update!",
            danger: false,
            warning: false,
            success: true,
            open: false,
          });
          this.setState({ loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("Error", error.response);
          this.setState({
            loading: false,
            alert: true,
            alert_msg: error,
            alert_title: "Client Update!",
            danger: true,
            warning: false,
            success: false,
          });
        });
    }
  };
  handleoptionsameClick = (event) => {
    console.log(event.target.value);
    this.setState({ principle_within: event.target.value });
  };
  handleCheckChange = (event) => {
    const value = Number(event.target.value);
    this.setState((state) => ({
      checkedValues: state.checkedValues.includes(value)
        ? state.checkedValues.filter((c) => c !== value)
        : [...state.checkedValues, value],
    }));
  };
  handlePaymentProcessChange = (event) => {
    const { value } = event.target;
    this.setState({ paymentProcess: value });
  };
  hideAlert = (times) => {
    this.setState(
      {
        alert: false,
        alert_msg: "",
        alert_title: "",
        alert_type: "",
      },
      () => {
        if (times === "1") {
          // const base_url = window.location.origin;
          axios({
            method: "delete",
            url: base_url + "/clients/" + this.state.param + ".json",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("session_token")}`,
            },
            params: {},
          })
            .then((result) => {
              const clients = this.state.clients.filter(
                (client) => client.id !== this.state.param
              );

              this.setState(
                {
                  loading: false,
                  alert: true,
                  alert_msg: "Client deleted successfully.!",
                  alert_title: "Client Delete!",
                  danger: false,
                  warning: false,
                  success: true,
                  times: "0",
                  clients: clients,
                }
                // () => this.cleanInputs()
              );
            })
            .catch((error) => {
              console.log(error);
              this.setState({
                loading: false,
                alert: true,
                alert_msg: error.response,
                alert_title: "Error!",
                danger: true,
                warning: false,
                success: false,
              });
            });
        }
      }
    );
  };
  handleStatusChange = (event) => {
    this.setState({ status_value: event.target.value });
  };
  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };
  handleEndDateChange = (date) => {
    this.setState({ selectedendDate: date });
  };
  get getData() {
    return this.state.versions.map((row, index) => ({
      id: row.id,
      txn_id: row.txn_id,
      currency: row.currency,
      balance: row.balance,
      amount: row.amount,
      locked: row.locked,
      reason: row.reason,
      created_at: row.created_at,
      username: row.username,
    }));
  }
  handleShowTable = (event) => {
    event.preventDefault();
    this.setState({ show_table: true });
    // this.getVersions(this.state.client_id, this.state.page);
  };
  handleExportExcel = (event) => {
    event.preventDefault();
    // const base_url = window.location.origin;
    const startDate = moment(this.state.selectedDate).format("YYYY-MM-DD");
    const endDate = moment(this.state.selectedendDate).format("YYYY-MM-DD");
    this.setState({ loading: true });
    axios({
      method: "get",
      url: base_url + "/versions.xls",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        "Content-type": "application/vnd.ms-excel",
      },
      responseType: "arraybuffer",
      params: {
        start_date: startDate,
        end_date: endDate,
        client_id: this.state.client_id,
        page: this.state.page + 1,
        reason: this.state.status_value,
        sort_order: "desc",
        sort_field: "id",
        per_page: this.state.per_page,
      },
    }).then((result) => {
      this.setState({ loading: false });
      if (result.data) {
        // debugger;
        // console.log(result.data);
        const url = window.URL.createObjectURL(
          new Blob([result.data], { type: "application/vnd.ms-excel" })
        );
        const link = document.createElement("a");
        const fileName =
          "WalletVersions_startDate=" +
          startDate +
          "&endDate=" +
          endDate +
          "&reason=" +
          this.state.reason +
          ".xls";
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        console.log(result.data);
      }
    });
  };
  testService = (page, pageSize, sorted, filtered, state) => {
    // console.log(sorted, state);
    // this.setState({ rows_count: state.sortedData.length });
    this.setState({ filters: filtered }, () =>
      this.getVersionsWithFilters(
        this.state.client_id,
        filtered,
        page + 1,
        "desc",
        "id",
        pageSize
      )
    );

    if (sorted.length > 0) {
      this.setState(
        {
          page: page,
        },
        () =>
          this.getVersionsWithFilters(
            this.state.client_id,
            filtered,
            page,
            sorted[0]["desc"] ? "desc" : "asc",
            sorted[0]["id"],
            pageSize
          )
      );
    }

    // }
  };
  render() {
    const { classes } = this.props;
    const versionsData = this.getData;

    return (
      <div>
        <CustomSweet
          alert={this.state.alert}
          message={this.state.alert_msg}
          title={this.state.alert_title}
          hide_alert={this.hideAlert}
          danger={this.state.danger}
          warning={this.state.warning}
          success={this.state.success}
          times={this.state.times}
        />
        <div className={this.state.loading === true ? classes.main_screen : ""}>
          <div align="center">
            {this.state.loading === true && <Progress />}
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" text>
                  <CardText color="rose">
                    <h4 className={classes.cardTitle}>Client View</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <form>
                    <GridContainer>
                      <GridItem xs={12} sm={10}>
                        <CustomInput
                          id="name"
                          labelText="Name *"
                          success={this.state.valid_name === ""}
                          error={this.state.valid_name === "false"}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: this.state.name,
                            onChange: this.handleNameChange,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={10}>
                        <CustomInput
                          id="token"
                          labelText="API Token"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: this.state.token,
                            disabled: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={10}>
                        <CustomInput
                          id="secret_key"
                          labelText="Secret Key"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: this.state.secret_key,
                            disabled: true,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                        Currency
                      </GridItem>
                    </GridContainer>
                    <br />
                    <GridContainer>
                      {this.state.currencies.map((currency, index) => (
                        <GridItem xs={12} sm={2} md={2} key={index}>
                          <div style={{ boxSizing: "content-box" }} >
                            <FormControlLabel
                              className={classes.formTool}
                              control={
                                <Checkbox
                                  id={"currency" + currency.attributes.id}
                                  label={currency.attributes.name}
                                  value={currency.attributes.id}
                                  name={"currency" + currency.attributes.id}
                                  checked={this.state.checkedValues.includes(
                                    currency.attributes.id
                                  )}
                                  onChange={this.handleCheckChange}
                                />
                              }
                              label={currency.attributes.name.toUpperCase()}
                            />
                          </div>
                        </GridItem>
                      ))}
                    </GridContainer>
                    <br />
                    <GridContainer>
                      <GridItem sm={12}>
                        <FormControl component="fieldset">
                          <FormLabel>Payment Process</FormLabel>
                          <RadioGroup
                            aria-label="payment process"
                            name="paymentProcess"
                            value={this.state.paymentProcess}
                            onChange={this.handlePaymentProcessChange}
                          >
                            <FormControlLabel
                              value="automatic"
                              control={<Radio />}
                              label="Automatic"
                            />
                            <FormControlLabel
                              value="manual"
                              control={<Radio />}
                              label="Manual"
                            />
                            <FormControlLabel
                              value="partial"
                              control={<Radio />}
                              label="Partial"
                            />
                          </RadioGroup>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <br />
                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                        Fee Deduction
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          }
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                checked={this.state.principle_within === true}
                                onChange={this.handleoptionsameClick}
                                value={true}
                                name="radio button"
                                aria-label="Include Fee in principle amount"
                                icon={
                                  <FiberManualRecord
                                    className={classes.radioUnchecked}
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord
                                    className={classes.radioChecked}
                                  />
                                }
                                classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot,
                                }}
                              />
                            }
                            classes={{
                              label: classes.label,
                              root: classes.labelRoot,
                            }}
                            label="Include Fee in principle amount"
                          />
                        </div>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          }
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                checked={this.state.principle_within === false}
                                onChange={this.handleoptionsameClick}
                                value={false}
                                name="radio button"
                                aria-label="Exclude Fee from principle amount"
                                icon={
                                  <FiberManualRecord
                                    className={classes.radioUnchecked}
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord
                                    className={classes.radioChecked}
                                  />
                                }
                                classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot,
                                }}
                              />
                            }
                            classes={{
                              label: classes.label,
                              root: classes.labelRoot,
                            }}
                            label="Exclude Fee from principle amount"
                          />
                        </div>
                      </GridItem>
                      <br />
                    </GridContainer>

                    {/* Fee By Currency */}
                    {this.state.feeable && (
                      <div>
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            {this.state.feeable ? "Fee By Currency" : ""}
                          </GridItem>
                        </GridContainer>
                        <br />
                        {this.state.wallet_currencies.map((currency, index) => (
                          <GridContainer>
                            <GridItem
                              xs={12}
                              sm={3}
                              md={1}
                              style={{ alignSelf: "center" }}
                            >
                              {currency.attributes.name.toUpperCase()}
                            </GridItem>
                            <GridItem xs={12} sm={3} md={2}>
                              <CustomInput
                                id={
                                  "fee" +
                                  this.showFee(currency.attributes.id, "id")
                                }
                                name={currency.attributes.id}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  onChange: (e) =>
                                    this.handleFeeChange(e, index),

                                  type: "number",
                                  disabled: currency.attributes.active,
                                  value:
                                    this.state.set_fee_id === index
                                      ? this.state.fee
                                      : this.showFee(
                                          currency.attributes.id,
                                          "val"
                                        ) !== "0.0"
                                      ? this.showFee(
                                          currency.attributes.id,
                                          "val"
                                        )
                                      : "0.0",
                                }}
                              />
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={3}
                              md={1}
                              style={{ alignSelf: "center" }}
                            >
                              <Button
                                id={"edit" + currency.attributes.id}
                                style={{
                                  display: currency.attributes.active
                                    ? "block"
                                    : "none",
                                }}
                                variant="contained"
                                color="rose"
                                size="sm"
                                onClick={() =>
                                  this.handleEdit(currency.attributes.id)
                                }
                              >
                                Edit
                              </Button>
                              <Button
                                id={"save" + currency.attributes.id}
                                name={currency.attributes.id}
                                variant="contained"
                                color="rose"
                                size="sm"
                                style={{
                                  display: currency.attributes.active
                                    ? "none"
                                    : "block",
                                }}
                                onClick={(e) =>
                                  this.handleSave(
                                    e,
                                    currency.attributes.id,
                                    this.showFee(currency.attributes.id, "id")
                                  )
                                }
                              >
                                Save
                              </Button>
                            </GridItem>

                            <GridItem
                              xs={6}
                              sm={3}
                              md={1}
                              style={{ alignSelf: "center" }}
                            >
                              <Button
                                id={"cancel" + currency.attributes.id}
                                variant="contained"
                                size="sm"
                                style={{
                                  display: currency.attributes.active
                                    ? "none"
                                    : "block",
                                }}
                                onClick={() =>
                                  this.handleCancel(currency.attributes.id)
                                }
                              >
                                Cancel
                              </Button>
                            </GridItem>
                          </GridContainer>
                        ))}
                      </div>
                    )}
                    <br />
                    {/* Wallet Balances */}
                    {this.state.is_wallet_enabled && (
                      <div>
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            {this.state.is_wallet_enabled
                              ? "Wallet Balances"
                              : ""}
                          </GridItem>
                        </GridContainer>
                        <br />
                        <br />
                        <GridContainer>
                          <GridItem xs={12} sm={2} md={1}></GridItem>
                          <GridItem xs={12} sm={2} md={2}>
                            BALANCE
                          </GridItem>
                          <GridItem xs={12} sm={2} md={2}>
                            AMOUNT
                          </GridItem>
                          <GridItem xs={12} sm={2} md={3}>
                            LOCKED
                          </GridItem>
                          <GridItem xs={12} sm={2} md={1}></GridItem>
                        </GridContainer>
                        {this.state.wallet_currencies.map((currency, index) => (
                          
                          wallet_currency.includes(currency.attributes.name) && (
                            <GridContainer>
                              <GridItem
                                xs={12}
                                sm={2}
                                md={1}
                                style={{ alignSelf: "center" }}
                              >
                                {currency.attributes.name.toUpperCase()}
                              </GridItem>
                              <GridItem xs={12} sm={2} md={2}>
                                <CustomInput
                                  onChange={(e) => this.handleFeeChange(e, index)}
                                  id={
                                    "wallet_balance" +
                                    this.showWallet(currency.attributes.id, "id")
                                  }
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    type: "number",
                                    disabled: true,
                                    value:
                                      this.showWallet(
                                        currency.attributes.id,
                                        "balance"
                                      ) !== "0.0"
                                        ? this.showWallet(
                                            currency.attributes.id,
                                            "balance"
                                          )
                                        : "0.0",
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={2} md={2}>
                                <CustomInput
                                  onChange={(e) => this.handleFeeChange(e, index)}
                                  id={
                                    "wallet_amt" +
                                    this.showWallet(currency.attributes.id, "id")
                                  }
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    type: "number",
                                    disabled: true,
                                    value:
                                      this.showWallet(
                                        currency.attributes.id,
                                        "amount"
                                      ) !== "0.0"
                                        ? this.showWallet(
                                            currency.attributes.id,
                                            "amount"
                                          )
                                        : "0.0",
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={2} md={2}>
                                <CustomInput
                                  onChange={(e) => this.handleFeeChange(e, index)}
                                  id={
                                    "wallet_amt" +
                                    this.showWallet(currency.attributes.id, "id")
                                  }
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    type: "number",
                                    disabled: true,
                                    value:
                                      this.showWallet(
                                        currency.attributes.id,
                                        "locked"
                                      ) !== "0.0"
                                        ? this.showWallet(
                                            currency.attributes.id,
                                            "locked"
                                          )
                                        : "0.0",
                                  }}
                                />
                              </GridItem>
                              <GridItem
                                xs={12}
                                sm={2}
                                md={1}
                                style={{ alignSelf: "center" }}
                              >
                                <Button
                                  id={"edit" + currency.attributes.id}
                                  size="sm"
                                  color="rose"
                                  onClick={() =>
                                    this.handleOpen(
                                      currency.attributes.name,
                                      currency.attributes.id
                                    )
                                  }
                                >
                                  {"Add " +
                                    currency.attributes.name.toUpperCase()}
                                </Button>
                                <Dialog
                                  open={this.state.open}
                                  onClose={this.handleClose}
                                  aria-labelledby="alert-dialog-title"
                                  aria-describedby="alert-dialog-description"
                                >
                                  <DialogTitle id="alert-dialog-title">
                                    {"Add Wallet Balance"}
                                  </DialogTitle>
                                  <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                      <div>
                                        <div>
                                          <FormControl
                                            required
                                            asterisk
                                            className={classes.formCont}
                                            align="center"
                                          >
                                            <CustomInput
                                              id={
                                                "addBalance" +
                                                currency.attributes.id
                                              }
                                              formControlProps={{
                                                fullWidth: true,
                                              }}
                                              inputProps={{
                                                onChange: (event) =>
                                                  this.handleBalanceChange(
                                                    event,
                                                    currency.attributes.id
                                                  ),
                                                type: "number",
                                                value: this.state.wallet_balance,
                                              }}
                                            />
                                          </FormControl>
                                        </div>
                                      </div>
                                    </DialogContentText>
                                  </DialogContent>

                                  <DialogActions>
                                    <Button onClick={this.handleClose} size="sm">
                                      Cancel
                                    </Button>
                                    <Button
                                      onClick={(e) =>
                                        this.handleAddBalance(
                                          e,
                                          currency.attributes.name
                                        )
                                      }
                                      size="sm"
                                      color="rose"
                                      autoFocus
                                    >
                                      Submit
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </GridItem>
                            </GridContainer>
                          )
                        ))}
                      </div>
                    )}

                    <br />
                    {/* Set Default Daily Limit (Update transaction limit to this value every midnight */}
                    {this.state.txn_limit_enabled && (
                      <div>
                        <GridContainer>
                          <GridItem xs={12} sm={10}>
                            {this.state.txn_limit_enabled
                              ? "Set Default Daily Limit (Update transaction limit to this value every midnight"
                              : ""}
                          </GridItem>
                        </GridContainer>
                        <br />
                        {this.state.wallet_currencies.map((currency, index) => (
                          <GridContainer>
                            <GridItem
                              xs={12}
                              sm={3}
                              md={1}
                              style={{ alignSelf: "center" }}
                            >
                              {currency.attributes.name.toUpperCase()}
                            </GridItem>
                            <GridItem xs={12} sm={3} md={2}>
                              <CustomInput
                                id={
                                  "limit" +
                                  this.showLimit(currency.attributes.id, "id")
                                }
                                name={currency.attributes.id}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  onChange: (e) =>
                                    this.handleLimitChange(e, index),

                                  type: "number",
                                  disabled: currency.attributes.status,
                                  value:
                                    this.state.set_limit_id === index
                                      ? this.state.limit
                                      : this.showLimit(
                                          currency.attributes.id,
                                          "val"
                                        ) !== "0.0"
                                      ? this.showLimit(
                                          currency.attributes.id,
                                          "val"
                                        )
                                      : "0.0",
                                }}
                              />
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={3}
                              md={1}
                              style={{ alignSelf: "center" }}
                            >
                              <Button
                                id={"edit" + currency.attributes.id}
                                style={{
                                  display: currency.attributes.status
                                    ? "block"
                                    : "none",
                                }}
                                variant="contained"
                                color="rose"
                                size="sm"
                                onClick={(e) =>
                                  this.handleLimitEdit(
                                    e,
                                    currency.attributes.id
                                  )
                                }
                              >
                                Edit
                              </Button>
                              <Button
                                id={"save" + currency.attributes.id}
                                name={currency.attributes.id}
                                variant="contained"
                                color="rose"
                                size="sm"
                                style={{
                                  display: currency.attributes.status
                                    ? "none"
                                    : "block",
                                }}
                                onClick={(e) =>
                                  this.handleLimitSave(
                                    e,
                                    currency.attributes.name,
                                    this.showLimit(
                                      currency.attributes.id,
                                      "id"
                                    ),
                                    currency.attributes.id
                                  )
                                }
                              >
                                Save
                              </Button>
                            </GridItem>

                            <GridItem
                              xs={6}
                              sm={3}
                              md={1}
                              style={{ alignSelf: "center" }}
                            >
                              <Button
                                id={"cancel" + currency.attributes.id}
                                variant="contained"
                                size="sm"
                                style={{
                                  display: currency.attributes.status
                                    ? "none"
                                    : "block",
                                }}
                                onClick={() =>
                                  this.handleLimitCancel(currency.attributes.id)
                                }
                              >
                                Cancel
                              </Button>
                            </GridItem>
                          </GridContainer>
                        ))}
                      </div>
                    )}

                    {/* Transaction Limit */}
                    {this.state.txn_limit_enabled && (
                      <div>
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            {this.state.txn_limit_enabled
                              ? "Transaction Limit"
                              : ""}
                          </GridItem>
                        </GridContainer>
                        <br />
                        <GridContainer>
                          <GridItem xs={12} sm={1}></GridItem>
                          <GridItem xs={12} sm={2}>
                            DAILY LIMIT
                          </GridItem>
                          <GridItem xs={12} sm={2}>
                            BALANCE
                          </GridItem>
                          <GridItem xs={12} sm={2}>
                            LOCKED
                          </GridItem>
                          <GridItem xs={12} sm={1}></GridItem>
                        </GridContainer>
                        {this.state.wallet_currencies.map((currency, index) => (
                          <GridContainer>
                            <GridItem
                              xs={12}
                              sm={2}
                              md={1}
                              style={{ alignSelf: "center" }}
                            >
                              {currency.attributes.name.toUpperCase()}
                            </GridItem>
                            <GridItem xs={12} sm={2}>
                              <CustomInput
                                id={
                                  "wallet_balance" +
                                  this.showLimit(currency.attributes.id, "id")
                                }
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  type: "number",
                                  disabled: true,
                                  value:
                                    this.showLimit(
                                      currency.attributes.id,
                                      "limit"
                                    ) !== "0.0"
                                      ? this.showLimit(
                                          currency.attributes.id,
                                          "limit"
                                        )
                                      : "0.0",
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={2} md={2}>
                              <CustomInput
                                id={
                                  "wallet_amt" +
                                  this.showLimit(currency.attributes.id, "id")
                                }
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  type: "number",
                                  disabled: true,
                                  value:
                                    this.showLimit(
                                      currency.attributes.id,
                                      "balance"
                                    ) !== "0.0"
                                      ? this.showLimit(
                                          currency.attributes.id,
                                          "balance"
                                        )
                                      : "0.0",
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={2} md={2}>
                              <CustomInput
                                onChange={(e) => this.handleFeeChange(e, index)}
                                id={
                                  "wallet_amt" +
                                  this.showLimit(currency.attributes.id, "id")
                                }
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  type: "number",
                                  disabled: true,
                                  value:
                                    this.showLimit(
                                      currency.attributes.id,
                                      "locked"
                                    ) !== "0.0"
                                      ? this.showLimit(
                                          currency.attributes.id,
                                          "locked"
                                        )
                                      : "0.0",
                                }}
                              />
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={2}
                              md={1}
                              style={{ alignSelf: "center" }}
                            >
                              <Button
                                id={"timelog" + currency.attributes.id}
                                size="sm"
                                color="rose"
                                onClick={(e) =>
                                  this.handleClientTimelog(
                                    e,
                                    this.showLimit(
                                      currency.attributes.id,
                                      "id"
                                    ),
                                    currency.attributes.name
                                  )
                                }
                              >
                                {"View Timelogs"}
                              </Button>
                            </GridItem>
                          </GridContainer>
                        ))}
                      </div>
                    )}
                  </form>

                  <br />
                  <br />
                  <GridContainer justify="center">
                    <GridItem>
                      <FormControl>
                        <Button onClick={(e) => this.showSubClient(e)}>
                          View Sub Client
                        </Button>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <Button
                          color="rose"
                          onClick={(e) => this.handleSubmit(e)}
                        >
                          Update
                        </Button>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <br />
                  {this.state.is_wallet_enabled && (
                    <div>
                      <GridContainer>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          style={{ height: "50px" }}
                        >
                          {" "}
                          <Button color="rose" onClick={this.handleShowTable}>
                            Wallet Versions
                          </Button>
                        </GridItem>
                      </GridContainer>
                      <br />

                      {this.state.show_table && (
                        <div>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={2}>
                              <Card>
                                <CardBody style={{ padding: "12px 20px" }}>
                                  <InputLabel className={classes.label}>
                                    Start Date
                                  </InputLabel>
                                  <br />
                                  <FormControl fullWidth>
                                    <Datetime
                                      timeFormat={false}
                                      inputProps={{ placeholder: "Start Date" }}
                                      id="start-date-picker-dialog"
                                      label="Start Date"
                                      dateFormat={"YYYY-MM-DD"}
                                      value={this.state.selectedDate}
                                      onChange={this.handleDateChange}
                                    />
                                  </FormControl>
                                </CardBody>
                              </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={2}>
                              <Card>
                                <CardBody style={{ padding: "12px 20px" }}>
                                  <InputLabel className={classes.label}>
                                    End Date
                                  </InputLabel>
                                  <br />
                                  <FormControl fullWidth>
                                    <Datetime
                                      timeFormat={false}
                                      inputProps={{ placeholder: "End Date" }}
                                      id="end-date-picker-dialog"
                                      label="End Date"
                                      dateFormat={"YYYY-MM-DD"}
                                      value={this.state.selectedendDate}
                                      onChange={this.handleEndDateChange}
                                    />
                                  </FormControl>
                                </CardBody>
                              </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={2}>
                              <Card>
                                <CardBody style={{ padding: "16px 20px" }}>
                                  <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                  >
                                    <InputLabel
                                      htmlFor="simple-select"
                                      className={classes.selectLabel}
                                    >
                                      Reason
                                    </InputLabel>
                                    <Select
                                      MenuProps={{
                                        className: classes.selectMenu,
                                      }}
                                      classes={{
                                        select: classes.select,
                                      }}
                                      value={this.state.status_value}
                                      onChange={this.handleStatusChange}
                                      inputProps={{
                                        name: "simpleSelect",
                                        id: "simple-select",
                                      }}
                                    >
                                      <MenuItem
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected:
                                            classes.selectMenuItemSelected,
                                        }}
                                        value="all"
                                        key="all"
                                      >
                                        All
                                      </MenuItem>
                                      <MenuItem
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected:
                                            classes.selectMenuItemSelected,
                                        }}
                                        value="DEBIT"
                                        key="DEBIT"
                                      >
                                        DEBIT
                                      </MenuItem>
                                      <MenuItem
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected:
                                            classes.selectMenuItemSelected,
                                        }}
                                        value="CREDIT"
                                        key="CREDIT"
                                      >
                                        CREDIT
                                      </MenuItem>
                                      <MenuItem
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected:
                                            classes.selectMenuItemSelected,
                                        }}
                                        value="TXN_DEBIT"
                                        key="TXN_DEBIT"
                                      >
                                        TXN_DEBIT
                                      </MenuItem>
                                      <MenuItem
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected:
                                            classes.selectMenuItemSelected,
                                        }}
                                        value="TXN_SUCCESS"
                                        key="TXN_SUCCESS"
                                      >
                                        TXN_SUCCESS
                                      </MenuItem>
                                      <MenuItem
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected:
                                            classes.selectMenuItemSelected,
                                        }}
                                        value="TXN_CREDIT"
                                        key="TXN_CREDIT"
                                      >
                                        TXN_CREDIT
                                      </MenuItem>
                                      <MenuItem
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected:
                                            classes.selectMenuItemSelected,
                                        }}
                                        value="TXN_REVERSAL"
                                        key="TXN_REVERSAL"
                                      >
                                        TXN_REVERSAL
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </CardBody>
                              </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={2}>
                              <Card>
                                <CardBody style={{ minHeight: "60px" }}>
                                  <FormControl fullWidth>
                                    <Button
                                      color="rose"
                                      onClick={this.onSearch}
                                    >
                                      Search
                                    </Button>
                                  </FormControl>
                                </CardBody>
                              </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={2}>
                              <Card>
                                <CardBody style={{ minHeight: "60px" }}>
                                  <FormControl fullWidth>
                                    <Button
                                      color="rose"
                                      onClick={this.handleExportExcel}
                                    >
                                      Export
                                    </Button>
                                  </FormControl>
                                </CardBody>
                              </Card>
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              <Card>
                                <CardHeader color="rose" text>
                                  <CardText color="rose">
                                    <h4 className={classes.cardTitle}>
                                      Wallet Versions
                                    </h4>
                                  </CardText>
                                </CardHeader>
                                <CardBody xs={12} sm={12} md={12}>
                                  <ReactTable
                                    data={versionsData}
                                    filterable
                                    columns={[
                                      {
                                        Header: "ID",
                                        accessor: "id",
                                      },
                                      {
                                        accessor: "txn_id",
                                        Header: "Transaction ID",
                                      },
                                      {
                                        Header: "Currency",
                                        accessor: "currency",
                                      },
                                      {
                                        Header: "Balance",
                                        accessor: "balance",
                                      },
                                      {
                                        Header: "Amount",
                                        accessor: "amount",
                                      },
                                      {
                                        accessor: "locked",
                                        Header: "Locked",
                                      },
                                      {
                                        accessor: "reason",
                                        Header: "Reason",
                                      },
                                      {
                                        accessor: "created_at",
                                        Header: "Action Performed At",
                                      },
                                      {
                                        accessor: "username",
                                        Header: "Action Performed By",
                                      },
                                    ]}
                                    defaultPageSize={100}
                                    minRows={0}
                                    showPaginationTop
                                    showPaginationBottom={false}
                                    pages={this.state.pages}
                                    className="-striped -highlight"
                                    // defaultFilterMethod={this.filterMethod}
                                    manual // this would indicate that server side pagination has been enabled
                                    onFetchData={(state, instance) => {
                                      this.testService(
                                        state.page,
                                        state.pageSize,
                                        state.sorted,
                                        state.filtered,
                                        state
                                      );
                                    }}
                                    ref={this.ReactTableRef}
                                  />
                                </CardBody>
                              </Card>
                            </GridItem>
                          </GridContainer>
                        </div>
                      )}
                    </div>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}
export default withStyles(cssstyles)(ShowClient);
