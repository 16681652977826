import React from "react";
// react component for creating dynamic tables
import { withStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import FormControl from "@material-ui/core/FormControl";
import CardText from "components/Card/CardText.js";
import axios from "axios";
import apiUrl from "../api";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";
import CustomSweet from "../Components/CustomSweet";
import Progress from "../Components/Progress";
import BankSettingDetails from "../BankSetting/BankSettingDetails.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { makeApiCall } from "utils/commonCheckFunctions";

const styling = (theme) => {
  return { ...styles(theme), ...cssstyles };
};

const userType = localStorage.getItem("user_type");
const SUPERADMINUSER = "superadmin";
const base_url = apiUrl;
// const tableRef = React.useRef(null);
const tableColumns = [
  "Bank Name",
  "Payment Gateway",
  "Minimum Amount",
  "Maximum Amount",
  "Fee",
  "Action",
];

const currencyList = [
  { name: "NGN", value: "ngn" },
  { name: "GHS", value: "ghs" },
  { name: "KES", value: "kes" },
  { name: "ZAR", value: "zar" },
  { name: "XOF", value: "xof" },
  { name: "PHP", value: "php" },
  { name: "AUD", value: "aud" },
  { name: "VND", value: "vnd" },
  { name: "IDR", value: "idr" },
  { name: "INR", value: "inr" },
  { name: "SGD", value: "sgd" },
  { name: "CNY", value: "cny" },
];
class BankSetting extends React.Component {
  state = {
    loading: false,
    bank_settings: [],
    gatewaysdata: [],
    settingdata: [],
    filterCurrency: "ngn",
    payment_gateway_all: [],
    propsTobeSent: [],
    baUsers: [],
    selectedBA: "",
  };

  componentDidMount() {
    if (userType !== SUPERADMINUSER) {
      this.getBankSettings();
    } else {
      this.getAllBaUsers();
    }
    this.getPaymentGateways();
  }

  componentDidUpdate(prevProps, prevState) {
    const { filterCurrency, selectedBA } = this.state;
    if (
      filterCurrency !== prevState.filterCurrency &&
      userType !== SUPERADMINUSER
    ) {
      this.getPaymentGateways();
      this.getBankSettingsData();
    } else if (userType === SUPERADMINUSER) {
      
      if (filterCurrency !== prevState.filterCurrency && selectedBA !== "") {
        this.getBankSettingsData();
        this.getPaymentGateways();

      } else if(selectedBA !== prevState.selectedBA){
        this.getBankSettings();
      }
    }
  }

  getAllBaUsers = async () => {
    const data = await makeApiCall({
      url: "/users.json",
      method: "get",
      params: { role: "ba" },
    });
    const { error = false } = data;
    if (!error) {
      this.setState({
        baUsers: data,
      });
    } else {
      const { message } = data;
      console.log(message);
    }
  };

  handleBankSettingButtonClick = (
    bankCode,
    bankId,
    minimumAmount,
    maximumAmount,
    fee,
    paymentGateway,
    currency,
    ...rest
  ) => {
    const [id] = rest;
    const isFormValid = this.validateForm(
      paymentGateway,
      minimumAmount,
      maximumAmount,
      fee
    );
    if (isFormValid) {
      this.updateOrCreateBankSetting(
        bankId,
        bankCode,
        minimumAmount,
        maximumAmount,
        fee,
        paymentGateway,
        currency,
        id
      );
    }
  };

  updateOrCreateBankSetting = (
    bankId,
    bankCode,
    minimumAmount,
    maximumAmount,
    fee,
    paymentGateway,
    currency,
    id
  ) => {
    const url = id ? "/bank_settings/" + id : "/bank_settings";
    const method = id ? "put" : "post";
    const message = id
      ? "Bank Setting successfully updated."
      : "Bank Setting successfully created.";
    this.setState({ loading: true });
    const params = userType === SUPERADMINUSER?{"ba_id":this.state.selectedBA}:{};
    axios({
      method: method,
      url: base_url + url + ".json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        "bank_setting[bank_id]": bankId,
        "bank_setting[bank_code]": bankCode,
        "bank_setting[currency]": currency,
        "bank_setting[minimum_amount]": minimumAmount,
        "bank_setting[maximum_amount]": maximumAmount,
        "bank_setting[fee]": fee,
        "bank_setting[payment_gateway_id]": paymentGateway,
        ...params,
      },
    })
      .then((result) => {
        //access the results here....
        const { bank_setting: bankSetting } = result.data;
        let updatedBankSettingData = [];
        if (bankSetting) {
          updatedBankSettingData = this.updateBankSettingData(bankSetting);
        }
        this.setState(
          {
            loading: false,
            alert: true,
            alert_msg: message,
            alert_title: "Bank Setting!",
            danger: false,
            warning: false,
            success: true,
            settingdata: updatedBankSettingData,
          },
          this.getBankSettingsData
        );
      })
      .catch((error) => {
        console.log("Error", error);
        this.setState({
          loading: false,
          alert: true,
          alert_msg: "Something went Wrong !!",
          alert_title: "Error!",
          danger: false,
          warning: false,
          success: true,
        });
      });
  };

  updateBankSettingData = (data = {}) => {
    const { settingdata } = this.state;

    let updatedSettingData = [];
    if (data.id) {
      const elementFound = settingdata.some((element) => {
        return element.id === data.id;
      });
      if (elementFound) {
        updatedSettingData = settingdata.map((element) => {
          if (element.id === data.id) {
            element = data;
          }

          return element;
        });
      } else {
        updatedSettingData = [...settingdata, data];
      }
      return updatedSettingData;
    }
    return settingdata;
  };

  handleBaChange = (event) => {
    const { value } = event.target;
    this.setState({
      selectedBA: value,
    });
  };

  getBAUserList() {
    const { classes } = this.props;
    if (userType === SUPERADMINUSER) {
      return (
        <Card>
        <CardBody>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel className={classes.selectLabel}>{"BA *"}</InputLabel>

          <Select
            MenuProps={{
              className: classes.selectMenu,
            }}
            classes={{
              select: classes.select,
            }}
            value={this.state.selectedBA}
            onChange={this.handleBaChange}
          >
            {this.state.baUsers.map((row) => (
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value={row.id}
                key={row.id}
              >
                {row.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        </CardBody>
      </Card>
      );
    } else {
      return null;
    }
  }

  validateForm = (payment_gate, min_amount, max_amount, fee) => {
    // console.log(payment_gate, min_amount, max_amount, fee);
    if (payment_gate === "" || payment_gate === "select") {
      alert("Please select Payment Gateway!!");
      return false;
    }
    if (min_amount === "") {
      alert("Please enter Minimum Amount!!");
      return false;
    }
    if (max_amount === "") {
      alert("Please enter Maximum Amount!!");
      return false;
    }
    if (fee === "") {
      alert("Please enter Fee!!");
      return false;
    }
    if (parseInt(min_amount) > parseInt(max_amount)) {
      alert(
        "Please enter Minimum Amount less then Maximum Amount",
        min_amount,
        max_amount
      );
      return false;
    }
    return true;
  };

  getData = () => {
    const { payment_gateway_all } = this.state;
    return this.state.propsTobeSent.map((row) => {
      console.log(row);
      return (
        <BankSettingDetails
          key={row.bankId}
          {...row}
          allPaymentGateway={payment_gateway_all}
          handleClick={this.handleBankSettingButtonClick}
        />
      );
    });
  };

  getBankSettings = () => {
    const params =
      userType === SUPERADMINUSER ? { ba_id: this.state.selectedBA } : {};
    axios({
      method: "get",
      url: base_url + "/bank_settings.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: { ...params },
    })
      .then((result) => {
        //access the results here....
        this.setState(
          {
            bank_settings: result.data.banks,
            gatewaysdata: result.data.payment_gateways,
            settingdata: result.data.bank_settings,
          },
          () => {
            this.getBankSettingsData();
          }
        );
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  handleCurrencyChange = (event) => {
    const { value } = event.target;

    this.setState({
      filterCurrency: value,
    });
    // this.setEnableButton(currency);
  };

  getBankSettingsData = () => {
    this.setState({ loading: true });

    setTimeout(() => {
      const { bank_settings, settingdata, filterCurrency } = this.state;
      let settings = [];

      const banks = bank_settings
        .filter((data) => {
          return data.attributes.currency === filterCurrency;
        })
        .map((data) => {
          const { id: bankId, ...rest } = data.attributes;
          return { bankId, ...rest };
        });

      const banksSettings = settingdata.filter((data) => {
        return data.currency === filterCurrency;
      });

      for (let value of banks) {
        let matchFound = false;
        for (let data of banksSettings) {
          if (data.bank_id === value.bankId) {
            matchFound = true;
            settings.push({ ...value, ...data });
          }
        }
        if (!matchFound) {
          settings.push(value);
        }
      }
      //  console.log(settings);
      this.setState({
        propsTobeSent: settings,
        loading: false,
      });
    }, 0);
  };

  getPaymentGateways = () => {
    const { filterCurrency: currency } = this.state;
    axios({
      method: "get",
      url: base_url + "/show_payment_gateways.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        currency: currency,
      },
    })
      .then((result) => {
        this.setState({ payment_gateway_all: result.data.payment_gateways });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  hideAlert = (times) => {
    this.setState({
      alert: false,
      alert_msg: "",
      alert_title: "",
      alert_type: "",
    });
  };

  getCurrencylist = () => {
    const { classes } = this.props;
    return currencyList.map((data) => {
      return (
        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value={data.value}
          key={data.value}
        >
          {data.name}
        </MenuItem>
      );
    });
  };

  render() {
    const { classes } = this.props;
    var settingData = this.getData();

    return (
      <div>
        <CustomSweet
          alert={this.state.alert}
          message={this.state.alert_msg}
          title={this.state.alert_title}
          hide_alert={this.hideAlert}
          danger={this.state.danger}
          warning={this.state.warning}
          success={this.state.success}
          times={this.state.times}
        />
        <div className={this.state.loading === true ? classes.main_screen : ""}>
          <div align="center">
            {this.state.loading === true && <Progress />}
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <Card style={{ minHeight: "70px" }}>
                <CardBody style={{ textAlign: "center" }}>
                  <FormControl fullWidth>
                    <h6>Filter banks by :</h6>
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody>
                  <FormControl fullWidth>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      {"Currency *"}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={this.state.filterCurrency}
                      onChange={this.handleCurrencyChange}
                      inputProps={{
                        name: "simpleSelect",
                      }}
                    >
                      {this.getCurrencylist()}
                    </Select>
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
             {this.getBAUserList()}
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <Card>
                  <CardHeader color="rose" text>
                    <CardText color="rose">
                      <h4 className={classes.cardTitle}>
                        {this.state.filterCurrency.toString().toUpperCase() +
                          " Bank Settings"}
                      </h4>
                    </CardText>
                  </CardHeader>
                  <CardBody>
                    <Table>
                      <TableHead>
                        <TableRow
                          className={
                            classes.tableRow + " " + classes.tableRowHead
                          }
                        >
                          {tableColumns.map((data) => {
                            return (
                              <TableCell
                                key={data}
                                className={
                                  classes.tableHeadCell +
                                  " " +
                                  classes.tableCell
                                }
                              >
                                {data}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>{settingData}</TableBody>
                    </Table>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </GridContainer>
        </div>
      </div>
    );
  }
}
export default withStyles(styling)(BankSetting);
