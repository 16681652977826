import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import moment from "moment";
import apiUrl from "../api";
import CardText from "components/Card/CardText.js";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import CustomInput from "components/CustomInput/CustomInput.js";
import Progress from "../Components/Progress";
import { makeApiCall } from "utils/commonCheckFunctions";

const base_url = apiUrl;
const userType = localStorage.getItem("user_type");
const SUPERADMINUSER = "superadmin";

let d = new Date();
d.setDate(d.getDate() - 1);
d.setHours(0);
d.setMinutes(0);
d.setSeconds(1);
let prev_date = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
//   " "
// let prev_time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();

let tempDate = new Date();
tempDate.setHours(23);
tempDate.setMinutes(59);
tempDate.setSeconds(59);
let date =
  tempDate.getFullYear() +
  "-" +
  (tempDate.getMonth() + 1) +
  "-" +
  tempDate.getDate();
//   " ";
// let time =
//   tempDate.getHours() +
//   ":" +
//   tempDate.getMinutes() +
//   ":" +
//   tempDate.getSeconds();

class Analytics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: 0,
      selectedDate: prev_date,
      selectedendDate: date,
      destination_currency: "",
      source_currency: "",
      conversion_logs: [],
      clients: [],
      client_name: "",
      sub_clients: [],
      status_value: "all",
      receiver_name: "",
      deposit_number: "",
      transactions: [],
      selectedBA: "",
      baUsers: [],
    };
  }
  componentDidMount() {
    if (userType !== SUPERADMINUSER) {
      this.getClients();
    } else {
      this.getAllBaUsers();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedBA: prevSelectedBA } = prevState;
    const { selectedBA } = this.state;
    if (userType === SUPERADMINUSER && selectedBA !== prevSelectedBA) {
      this.getClients();
    }
  }

  handleBAChange = (event) => {
    const {value } = event.target;
    console.log(value);
    this.setState({
      "selectedBA": value,
    });
  };

  getAllBaUsers = async () => {
    const data = await makeApiCall({
      url: "/users.json",
      method: "get",
      params: { role: "ba" },
    });
    const { error = false } = data;
    if (!error) {
      this.setState({
        baUsers: data,
      });
    } else {
      const { message } = data;
      console.log(message);
    }
  };

  getBAUserList = () => {
    const { classes } = this.props;
    if (userType === SUPERADMINUSER) {
      return (
          <GridItem xs={12} md={2} sm={6}>
            <Card>
              <CardBody style={{ padding: "12px 20px" }}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel className={classes.selectLabel}>
                    {"BA *"}
                  </InputLabel>

                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={this.state.selectedBA}
                    onChange={this.handleBAChange}
                    inputProps={{
                      name: "selectedBA",
                    }}
                  >
                    {this.state.baUsers.map((row) => (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={row.id}
                        key={row.id}
                      >
                        {row.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </CardBody>
            </Card>
          </GridItem>
      );
    } else {
      return null;
    }
  };

  getClients = () => {
    const { selectedBA } = this.state;
    const params = userType === SUPERADMINUSER ? { ba_id: selectedBA } : {};
    this.setState({ loading: true });
    // const base_url = window.location.origin;
    axios({
      method: "get",
      url: base_url + "/clients.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: { ...params },
    })
      .then((result) => {
        // console.log(result);
        if (result.data) {
          this.setState({
            clients: result.data.clients,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          show_error: true,
          error_message: error.response.data
            ? error.response.data.message
            : error.message,
        });
        this.setState({ loading: false });
        // swal1("Search!", error.response.data.error, "error");
      });
  };
  handleClientChange = (event) => {
    this.setState({ client_name: event.target.value });
  };
  handleChangeMultiple = (event) => {
    console.log("hello");
    // const { options } = event.target;
    // const value = [];
    // for (let i = 0, l = options.length; i < l; i += 1) {
    //   if (options[i].selected) {
    //     value.push(options[i].value);
    //   }
    // }
    console.log(this.state.sub_clients);
    this.setState({ sub_clients: event.target.value });
  };
  handleStatusChange = (event) => {
    this.setState({ status_value: event.target.value });
  };
  handleNameChange = (event) => {
    this.setState({ receiver_name: event.target.value });
  };
  handleDepositChange = (event) => {
    this.setState({ deposit_number: event.target.value });
  };
  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };
  handleEndDateChange = (date) => {
    this.setState({ selectedendDate: date });
  };
  onSearch = (event) => {
    event.preventDefault();
    // const base_url = window.location.origin;
    const startDate = moment(this.state.selectedDate).format("YYYY-MM-DD");
    const endDate = moment(this.state.selectedendDate).format("YYYY-MM-DD");
    // console.log(this, "this");
    this.setState({ loading: true });
    axios({
      method: "get",
      url: base_url + "/analytics.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        start_date: startDate,
        end_date: endDate,
        client:
          this.state.client_name !== "select" ? this.state.client_name : "",
        sub_clients: this.state.sub_clients,
        receiver_name: this.state.receiver_name,
        account_no: this.state.deposit_number,
        statusSearch: this.state.status_value,
      },
    })
      .then((result) => {
        console.log(result);
        if (result.data) {
          this.setState({
            transactions: result.data,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          show_error: true,
          error_message: error.response.data
            ? error.response.data.message
            : error.message,
        });
        this.setState({ loading: false });
        // swal1("Search!", error.response.data.error, "error");
      });
  };
  handleExportExcel = (event) => {
    event.preventDefault();
    const start_date = moment(this.state.selectedDate).format("YYYY-MM-DD");
    const end_date = moment(this.state.selectedendDate).format("YYYY-MM-DD");

    axios({
      method: "get",
      url: base_url + "/analytics.xls",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        "Content-type": "application/vnd.ms-excel",
      },
      responseType: "arraybuffer",
      params: {
        start_date: start_date,
        end_date: end_date,
        client:
          this.state.client_name !== "select" ? this.state.client_name : "",
        sub_clients: this.state.sub_clients,
        receiver_name: this.state.receiver_name,
        account_no: this.state.deposit_number,
        statusSearch: this.state.status_value,
      },
    }).then((result) => {
      if (result.data) {
        // debugger;
        // console.log(result.data);
        const url = window.URL.createObjectURL(
          new Blob([result.data], { type: "application/vnd.ms-excel" })
        );
        const link = document.createElement("a");
        const fileName =
          "Analytics_startDate=" +
          start_date +
          "&endDate=" +
          end_date +
          "&statusSearch=" +
          this.state.status_value +
          ".xls";
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        console.log(result.data);
      }
    });
  };
  get getData() {
    return this.state.transactions.map((row, index) => ({
      id: row.id,
      receiver_name: row.receiver_name,
      source_currency: row.source_currency,
      destination_currency: row.destination_currency,
      source_amount: row.source_amount,
      destination_amount: row.destination_amount,
      conversion_rate: row.conversion_rate,
      provider_name: row.provider_name,
      deposit_number: row.deposit_number,
      client_name: row.client_name,
      sub_client_name: row.sub_client_name,
      aasm_state: row.aasm_state,
      created_at: row.created_at,
      created_time: row.created_time,
    }));
  }
  get getXLSData() {
    return this.state.transactions.map((row, index) => [
      row.id,
      row.receiver_name,
      row.source_currency,
      row.destination_currency,
      row.source_amount,
      row.destination_amount,
      row.conversion_rate,
      row.provider_name,
      row.deposit_number,
      row.client_name,
      row.sub_client_name,
      row.aasm_state,
      row.created_at,
      row.created_time,
    ]);
  }
  filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
      : true;
  };
  render() {
    const { classes } = this.props;
    const transactionData = this.getData;
    return (
      <div>
        <div className={this.state.loading === true ? classes.main_screen : ""}>
          <div align="center">
            {this.state.loading === true && <Progress />}
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={2}>
              <Card>
                <CardBody style={{ padding: "8px 20px" }}>
                  <InputLabel className={classes.label}>Start Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      inputProps={{ placeholder: "Start Date" }}
                      id="start-date-picker-dialog"
                      label="Start Date"
                      dateFormat={"YYYY-MM-DD"}
                      value={this.state.selectedDate}
                      onChange={this.handleDateChange}
                    />
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={2}>
              <Card>
                <CardBody style={{ padding: "8px 20px" }}>
                  <InputLabel className={classes.label}>End Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      inputProps={{ placeholder: "End Date" }}
                      id="end-date-picker-dialog"
                      label="End Date"
                      dateFormat={"YYYY-MM-DD"}
                      value={this.state.selectedendDate}
                      onChange={this.handleEndDateChange}
                    />
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            {this.getBAUserList()}
            <GridItem xs={12} sm={12} md={2}>
              <Card>
                <CardBody style={{ padding: "12px 20px" }}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Status
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={this.state.status_value}
                      onChange={this.handleStatusChange}
                      inputProps={{
                        name: "simpleSelect",
                        id: "status-select",
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="all"
                        key="all"
                      >
                        All
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="successful"
                        key="successful"
                      >
                        Successful
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="failed"
                        key="failed"
                      >
                        failed
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="validated"
                        key="validated"
                      >
                        validated
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="processing"
                        key="processing"
                      >
                        processing
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="cancelled"
                        key="cancelled"
                      >
                        cancelled
                      </MenuItem>
                    </Select>
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody style={{ padding: "12px 20px" }}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Select Client Name
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={this.state.client_name}
                      onChange={this.handleClientChange}
                      inputProps={{
                        name: "simpleSelect",
                        id: "client-select",
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        key={"select"}
                        value={"select"}
                      >
                        {"Please select one"}
                      </MenuItem>{" "}
                      {this.state.clients.map((row) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          key={row.id}
                          value={row.name}
                        >
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody style={{ padding: "12px 20px" }}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Select Sub Client
                    </InputLabel>
                    <Select
                      multiple
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={this.state.sub_clients}
                      onChange={this.handleChangeMultiple}
                      inputProps={{
                        name: "simpleSelect",
                        id: "subclient-select",
                      }}
                    >
                      {" "}
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="Lanacional"
                        key="Lanacional"
                      >
                        Lanacional
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="Small World"
                        key="Small World"
                      >
                        Small World
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="OMNEX"
                        key="OMNEX"
                      >
                        OMNEX
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        key="Xpress Money"
                        value="Xpress Money"
                      >
                        Xpress Money
                      </MenuItem>
                      Others
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="NA"
                        key="NA"
                      >
                        Others
                      </MenuItem>
                    </Select>
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <Card>
                <CardBody style={{ padding: "2px 3px" }}>
                  <FormControl fullWidth>
                    <CustomInput
                      id="name"
                      labelText="Receiver's Name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: this.state.name,
                        onChange: this.handleNameChange,
                      }}
                    />
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <Card>
                <CardBody style={{ padding: "2px 3px" }}>
                  <FormControl fullWidth>
                    <CustomInput
                      id="deposit_number"
                      labelText="Deposit Number"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: this.state.deposit_number,
                        onChange: this.handleDepositChange,
                      }}
                    />
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <Card>
                <CardBody style={{ minHeight: "65px" }}>
                  <FormControl fullWidth>
                    <Button color="rose" onClick={this.onSearch}>
                      Search
                    </Button>
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <Card>
                <CardBody style={{ minHeight: "65px" }}>
                  <FormControl fullWidth>
                    <Button color="rose" onClick={this.handleExportExcel}>
                      Export
                    </Button>
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="rose" text>
                  <CardText color="rose">
                    <h4 className={classes.cardTitle}>Analytics</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={transactionData}
                    filterable
                    columns={[
                      {
                        Header: "ID",
                        accessor: "id",
                      },

                      {
                        Header: "Receiver Name",
                        accessor: "receiver_name",
                        filterMethod: this.filterMethod,
                      },
                      {
                        accessor: "source_currency",
                        Header: "Source Currency",
                        filterMethod: this.filterMethod,
                      },
                      {
                        accessor: "destination_currency",
                        Header: "Destination Currency",
                        filterMethod: this.filterMethod,
                      },

                      {
                        accessor: "source_amount",
                        Header: "Source Amount",
                      },
                      {
                        accessor: "destination_amount",
                        Header: "Destination Amount",
                      },
                      {
                        accessor: "conversion_rate",
                        Header: "Conversion Rate",
                      },
                      {
                        accessor: "provider_name",
                        Header: "Provider Name",
                      },
                      {
                        accessor: "deposit_number",
                        Header: "Deposit Number",
                      },
                      {
                        accessor: "client_name",
                        Header: "Client Name",
                        filterMethod: this.filterMethod,
                      },
                      {
                        accessor: "sub_client_name",
                        Header: "Sub Client Name",
                        filterMethod: this.filterMethod,
                      },
                      {
                        accessor: "aasm_state",
                        Header: "Status",
                        filterMethod: this.filterMethod,
                      },
                      {
                        accessor: "created_at",
                        Header: "Created At",
                      },

                      {
                        accessor: "created_time",
                        Header: "Created Time",
                      },
                    ]}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                    // defaultFilterMethod={this.filterMethod}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}
export default withStyles(cssstyles)(Analytics);
