import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Heading from "components/Heading/Heading.js";
import Button from "components/CustomButtons/Button.js";
import { Link } from "react-router-dom";

const styles = {
  erroCode: {
    padding: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
  },
  eye: {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    position: "relative",
    display: "inline-block",
    background: "#ccc",
    margin: "5px",
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: "45px",
      right: "3px",
      width: "20px",
      height: "20px",
      background: "#000",
      borderRadius: "50%",
    },
  },
  letterFive: {
    margin: "5px",
    color: "#000",
    fontSize: "100px",
    display: "inline-block",
    fontWeight: 600,
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

class FiveOFive extends Component {
  constructor(props) {
    super(props);
    this.eyeRef = React.createRef();
    this.eyeRef1 = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousemove", this.handleMouseMove);
  }

  handleMouseMove = (event) => {
    const { x, y } = event;
    const eyeObject = this.eyeRef.current.getBoundingClientRect();
    const eyeX = eyeObject.x + eyeObject.width / 2;
    const eyeY = eyeObject.y + eyeObject.width / 2;
    const rot = Math.atan2(y - eyeY, x - eyeX);
    const rad = (rot * 180) / Math.PI;
    this.eyeRef.current.style.transform = `rotate(${rad}deg)`;
    this.eyeRef1.current.style.transform = `rotate(${rad}deg)`;
  };

  componentWillUnmount() {
    document.removeEventListener("mousemove", this.handleMouseMove);
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.erroCode}>
          <div className={classes.letterFive}>5</div>
          <div className={classes.eye} ref={this.eyeRef}></div>
          <div className={classes.eye} ref={this.eyeRef1}></div>
        </div>
        <Heading
          textAlign="center"
          title="Internal Server Error"
          category="It's not you, it's us :("
        />
        <div className={classes.buttonContainer}>
          <Link to="/dashboard">
            <Button color="rose">Go To Home</Button>
          </Link>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(FiveOFive);
