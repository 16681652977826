import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import moment from "moment";
import CardText from "components/Card/CardText.js";
import axios from "axios";
import Datetime from "react-datetime";
import apiUrl from "../api";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
// import Table from "components/Table/Table.js";
// import CustomSweet from "../Components/CustomSweet";
import Progress from "../Components/Progress";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeApiCall } from "utils/commonCheckFunctions";
const userType = localStorage.getItem("user_type");
const SUPERADMINUSER = "superadmin";
const base_url = apiUrl;
let d = new Date();
d.setDate(d.getDate() - 1);
d.setHours(0);
d.setMinutes(0);
let prev_date = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();

let tempDate = new Date();
tempDate.setHours(23);
tempDate.setMinutes(59);
let date =
  tempDate.getFullYear() +
  "-" +
  (tempDate.getMonth() + 1) +
  "-" +
  tempDate.getDate();

class BankSettingLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: 0,
      selectedDate: prev_date,
      selectedendDate: date,
      bankSettingData: [],
      old_modified_val: [],
      action: [],
      attribute_name: [],
      selectedBA:"",
      baUsers:[],
    };
  }

  componentDidMount(){
    if(userType === SUPERADMINUSER){
    this.getAllBaUsers();}
  }
  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };
  handleEndDateChange = (date) => {
    this.setState({ selectedendDate: date });
  };
  get getData() {
    const {
      attribute_name,
      action,
      old_modified_val,
      bankSettingData,
    } = this.state;
    return bankSettingData.map((row, index) => ({
      id: row.id,
      user_name: row.user_name,
      bank_name: row.bank_name,
      currency: row.currency,
      attribute_name: attribute_name[index],
      old_modified_val: old_modified_val[index],
      new_value: row.new_value,
      action: action[index],
      created_at: row.created_at,
      created_time: row.created_time,
    }));
  }
  onSearch = (event) => {
    event.preventDefault();
    // const base_url = window.location.origin;
    const startDate = moment(this.state.selectedDate).format("YYYY-MM-DD");
    const endDate = moment(this.state.selectedendDate).format("YYYY-MM-DD");
    const params = userType === SUPERADMINUSER?{ba_id:this.state.selectedBA}:{};
    var data = [];
    var action = [];
    var old_val = [];
    var attribute = [];
    this.setState({ loading: true });
    axios({
      method: "get",
      url: base_url + "/bank_setting_logs.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        start_date: startDate,
        end_date: endDate,
        ...params,
      },
    })
      .then((result) => {
        //access the results here....
        //console.log(result.data.bank_setting_logs);
        data = result.data.bank_setting_logs;
        for (var i = 0; i < data.length; i++) {
          if (data[i].old_value === "{}") {
            old_val.push("-");
            action.push("Created");
          } else {
            old_val.push(data[i].old_value);
            action.push("Updated");
          }
          switch (data[i].modified_attribute) {
            case "payment_gateway":
              attribute.push("Payment Gateway");
              break;
            case "minimum_amount":
              attribute.push("Minimum Amount");
              break;
            case "maximum_amount":
              attribute.push("Maximum Amount");
              break;
            case "fee":
              attribute.push("Fee");
              break;
            default:
              attribute.push("");
          }
        }
        this.setState({
          bankSettingData: result.data.bank_setting_logs,
          old_modified_val: old_val,
          action: action,
          attribute_name: attribute,
          loading: false,
        });
      })
      .catch((error) => {
        console.log("Error", error);
        this.setState({ loading: false });
      });
  };
  handleBAChange = (event) => {
    const {value } = event.target;
    console.log(value);
    this.setState({
      "selectedBA": value,
    });
  };

  getAllBaUsers = async () => {
    const data = await makeApiCall({
      url: "/users.json",
      method: "get",
      params: { role: "ba" },
    });
    const { error = false } = data;
    if (!error) {
      this.setState({
        baUsers: data,
      });
    } else {
      const { message } = data;
      console.log(message);
    }
  };

  getBAUserList = () => {
    const { classes } = this.props;
    if (userType === SUPERADMINUSER) {
      return (
          <GridItem xs={12} md={3} sm={6}>
            <Card>
              <CardBody>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel className={classes.selectLabel}>
                    {"BA *"}
                  </InputLabel>

                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={this.state.selectedBA}
                    onChange={this.handleBAChange}
                    inputProps={{
                      name: "selectedBA",
                    }}
                  >
                    {this.state.baUsers.map((row) => (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={row.id}
                        key={row.id}
                      >
                        {row.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </CardBody>
            </Card>
          </GridItem>
      );
    } else {
      return null;
    }
  };
  filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
      : true;
  };
  render() {
    const { classes } = this.props;
    const settingData = this.getData;
    return (
      <div className={this.state.loading === true ? classes.main_screen : ""}>
        <div align="center">{this.state.loading === true && <Progress />}</div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={3}>
            <Card>
              <CardBody>
                <InputLabel className={classes.label}>Start Date</InputLabel>
                <br />
                <FormControl fullWidth>
                  <Datetime
                    timeFormat={false}
                    inputProps={{ placeholder: "Start Date" }}
                    id="start-date-picker-dialog"
                    label="Start Date"
                    dateFormat={"YYYY-MM-DD"}
                    value={this.state.selectedDate}
                    onChange={this.handleDateChange}
                  />
                </FormControl>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card>
              <CardBody>
                <InputLabel className={classes.label}>End Date</InputLabel>
                <br />
                <FormControl fullWidth>
                  <Datetime
                    timeFormat={false}
                    inputProps={{ placeholder: "End Date" }}
                    id="end-date-picker-dialog"
                    label="End Date"
                    dateFormat={"YYYY-MM-DD"}
                    value={this.state.selectedendDate}
                    onChange={this.handleEndDateChange}
                  />
                </FormControl>
              </CardBody>
            </Card>
          </GridItem>
          {this.getBAUserList()}
          <GridItem xs={12} sm={12} md={3}>
            <Card>
              <CardBody>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <Button color="rose" onClick={this.onSearch}>
                    Search
                  </Button>
                </FormControl>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4 className={classes.cardTitle}>Bank Setting Logs</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={settingData}
                  filterable
                  columns={[
                    {
                      Header: "ID",
                      accessor: "id",
                    },
                    {
                      accessor: "user_name",
                      Header: "Username",
                      filterMethod: this.filterMethod,
                    },
                    {
                      Header: "Bank Name",
                      accessor: "bank_name",
                      filterMethod: this.filterMethod,
                    },
                    {
                      Header: "Currency",
                      accessor: "currency",
                      filterMethod: this.filterMethod,
                    },
                    {
                      accessor: "attribute_name",
                      Header: "Attribute",
                      filterMethod: this.filterMethod,
                    },
                    {
                      accessor: "old_modified_val",
                      Header: "Previous Value",
                      filterMethod: this.filterMethod,
                    },
                    {
                      accessor: "new_value",
                      Header: "Modified Value",
                      filterMethod: this.filterMethod,
                    },
                    {
                      accessor: "action",
                      Header: "Action",
                      filterMethod: this.filterMethod,
                    },
                    {
                      accessor: "created_at",
                      Header: "Created At",
                    },
                    {
                      accessor: "created_time",
                      Header: "Created Time",
                    },
                  ]}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  // defaultFilterMethod={this.filterMethod}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
export default withStyles(cssstyles)(BankSettingLogs);
