import React, { useEffect, useState } from "react";
import * as yup from "yup";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import CustomSweet from "../Components/CustomSweet";
import Progress from "../Components/Progress";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import alertstyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import apiUrl from "../api";
import {
  makeStyles,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import {
  makeApiCall,
  sortObjectAlphabatically,
} from "../../utils/commonCheckFunctions";
const customStyles = {
  marginCustomTop: {
    marginTop: "20px",
  },
};
const useStyle = makeStyles({ ...cssstyles, ...alertstyles, ...customStyles });

const userType = localStorage.getItem("user_type");
const WIRETXNAGENT = "wiretxnagent";
const base_url = apiUrl;

const initialState = {
  sourceCurrency: "",
  sourceAmount: "",
  destinationAmount: "",
  destinationCurrency: "",
  clientTxnId: "",
  name: "",
  recipientEmail: "",
  recipientPhoneNumber: "",
  recipientCountry: "",
  recipientAddress: "",
  recipientCity: "",
  recipientDistrict: "",
  recipientPostalCode: "",
  recipientBankName: "",
  recipientBankCity: "",
  recipientBankCountry: "",
  recipientIban: "",
  client: "",
  conversionRate: "",
  selectedBA: "",
  accountType: "",
  swiftCode: "",
  loading: false,
  errors: {},
  dialogOpen: false,
  allCountries: [],
  recipientCountryList: [],
};
const initialAlertState = {
  alert: false,
  success: false,
  error: false,
  warning: false,
  message: "",
  title: "",
};

const sourceCurrencyList = [{ name: "USD", value: "usd" }];
const destinationCurrencyList = [{ name: "USD", value: "usd" }];

const DialogComponent = ({
  isOpen = false,
  confirmFunction,
  closeFunction,
}) => {
  return (
    <Dialog open={isOpen} onClose={closeFunction}>
      <DialogTitle>Do you want to Create Transaction?</DialogTitle>
      <DialogActions>
        <Button size="sm" onClick={closeFunction}>
          Cancel
        </Button>
        <Button size="sm" onClick={confirmFunction} color="success">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};


const getAccountTypeList = (classes, handleChange, errors, accountType) => {
  const acTypes = ["Wiretransfer-ach", "Wiretransfer-iban", "Wiretransfer-non-iban"]

  if (userType === WIRETXNAGENT) {
    return (
      <FormControl
        fullWidth
        className={classes.selectFormControl}
        error={errors.accountType !== undefined}
      >
        <InputLabel className={classes.selectLabel}>{"Account Type *"}</InputLabel>

        <Select
          MenuProps={{
            className: classes.selectMenu,
          }}
          classes={{
            select: classes.select,
          }}
          value={accountType}
          onChange={handleChange}
          inputProps={{
            name: "accountType",
          }}
        >
         {acTypes.map((type) => (
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={type}
              key={type}
            >
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  } else {
    return null;
  }
};

const ViewComponent = ({
  sourceCurrency,
  sourceAmount,
  destinationCurrency,
  clientTxnId,
  name,
  // destinationAmount,
  client,
  recipientEmail,
  recipientPhoneNumber,
  errors,
  handleChange,
  handleBlur,
  // handleSourceAmountChange,
  // handleDestinationAmountChange,
  selectedBA,
  accountType,
  swiftCode,
  handleCancel,
  handleSubmit,
  recipientCountryList,
  recipientCountry,
  recipientAddress,
  recipientCity,
  recipientDistrict,
  recipientPostalCode,
  recipientBankName,
  recipientBankCity,
  recipientBankCountry,
  recipientIban
}) => {
  const classes = useStyle();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" text>
            <CardText color="rose">
              <h4>Create Agent Wire Transaction</h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <form>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10}>
                  {getAccountTypeList(
                    classes,
                    handleChange,
                    errors,
                    accountType
                  )}
                </GridItem>
              </GridContainer>

              <GridContainer
                justify="center"
                className={classes.marginCustomTop}
              >
                <GridItem xs={12} md={3} sm={10}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    error={errors.sourceCurrency !== undefined}
                  >
                    <InputLabel className={classes.selectLabel}>
                      {"Source Currency*"}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={sourceCurrency}
                      onChange={handleChange}
                      inputProps={{
                        name: "sourceCurrency",
                      }}
                    >
                      {sourceCurrencyList.map((row) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={row.value}
                          key={row.value}
                        >
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} md={3} sm={10}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    error={errors.destinationCurrency !== undefined}
                  >
                    <InputLabel className={classes.selectLabel}>
                      {"Destination Currency*"}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={destinationCurrency}
                      onChange={handleChange}
                      inputProps={{
                        name: "destinationCurrency",
                      }}
                    >
                      {destinationCurrencyList.map((row) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={row.value}
                          key={row.value}
                        >
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    error={errors.destinationCurrency !== undefined}
                  >
                    <InputLabel className={classes.selectLabel}>
                      {"Recipient Country*"}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={recipientCountry}
                      onChange={handleChange}
                      inputProps={{
                        name: "recipientCountry",
                      }}
                    >
                      {recipientCountryList.map((row, index) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={row.attributes.code}
                          key={index}
                        >
                          {row.attributes.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Source Amount *"
                    error={errors.sourceAmount !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.sourceAmount !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: sourceAmount,
                      autoComplete: "off",
                      type: "number",

                      name: "sourceAmount",
                    }}
                  />
                </GridItem>
                {/* <GridItem xs={12} md={3} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Destination Amount"
                    formControlProps={{
                      fullWidth: true,
                      // disabled: true,
                    }}
                    inputProps={{
                      onChange: handleDestinationAmountChange,
                      value: destinationAmount,
                      autoComplete: "new-password",
                      name: "destinationAmount",
                      // readOnly: true,
                      type: "number",
                    }}
                  />
                </GridItem> */}
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Client Transaction Id*"
                    error={errors.clientTxnId !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.clientTxnId !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: clientTxnId,
                      autoComplete: "new-password",
                      name: "clientTxnId",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Name *"
                    error={errors.name !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.name !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: name,
                      autoComplete: "off",
                      name: "name",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Recipient Email*"
                    error={errors.recipientEmail !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.recipientEmail !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: recipientEmail,
                      autoComplete: "new-password",
                      name: "recipientEmail",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Recipient Phone Number*"
                    error={errors.recipientPhoneNumber !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.recipientPhoneNumber !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: recipientPhoneNumber,
                      autoComplete: "new-password",
                      name: "recipientPhoneNumber",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Recipient Address*"
                    error={errors.recipientPhoneNumber !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.recipientPhoneNumber !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: recipientAddress,
                      autoComplete: "new-password",
                      name: "recipientAddress",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Recipient City*"
                    error={errors.recipientPhoneNumber !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.recipientPhoneNumber !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: recipientCity,
                      autoComplete: "new-password",
                      name: "recipientCity",
                    }}
                  />
                </GridItem>
              </GridContainer>
              {accountType === "Wiretransfer-ach" ? <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Recipient District*"
                    error={errors.recipientPhoneNumber !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.recipientPhoneNumber !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: recipientDistrict,
                      autoComplete: "new-password",
                      name: "recipientDistrict",
                    }}
                  />
                </GridItem>
              </GridContainer>  : ''}
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Recipient Postal Code*"
                    error={errors.recipientPhoneNumber !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.recipientPhoneNumber !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: recipientPostalCode,
                      autoComplete: "new-password",
                      name: "recipientPostalCode",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Recipient Bank Name*"
                    error={errors.recipientPhoneNumber !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.recipientPhoneNumber !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: recipientBankName,
                      autoComplete: "new-password",
                      name: "recipientBankName",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Recipient Bank City*"
                    error={errors.recipientPhoneNumber !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.recipientPhoneNumber !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: recipientBankCity,
                      autoComplete: "new-password",
                      name: "recipientBankCity",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    error={errors.destinationCurrency !== undefined}
                  >
                    <InputLabel className={classes.selectLabel}>
                      {"Recipient Bank Country*"}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={recipientBankCountry}
                      onChange={handleChange}
                      inputProps={{
                        name: "recipientBankCountry",
                      }}
                    >
                      {recipientCountryList.map((row, index) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={row.attributes.code}
                          key={index}
                        >
                          {row.attributes.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Recipient IBAN / Account Number*"
                    error={errors.recipientPhoneNumber !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.recipientPhoneNumber !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: recipientIban,
                      autoComplete: "new-password",
                      name: "recipientIban",
                      onBlur: handleBlur
                    }}
                  />
                </GridItem>
              </GridContainer>

              {accountType === "Wiretransfer-ach" || accountType === "Wiretransfer-non-iban" ? <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Recipient Swift code / Routing Number*"
                    error={errors.recipientPhoneNumber !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.recipientPhoneNumber !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: swiftCode,
                      autoComplete: "new-password",
                      name: "swiftCode",
                    }}
                  />
                </GridItem>
              </GridContainer> : ''}

              <GridContainer
                justify="center"
                className={classes.marginCustomTop}
              >
                <GridItem xs={12} sm={12} md={3} style={{ height: "50px" }}>
                  <FormControl fullWidth>
                    <Button onClick={handleCancel}>Cancel</Button>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} md={3} sm={12}>
                  <FormControl fullWidth>
                    <Button color="rose" onClick={handleSubmit}>
                      Proceed To Pay
                    </Button>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

const schema = yup.object({
  ...(userType === WIRETXNAGENT
    ? { selectedBA: yup.string().required("Please Select BA !!!") }
    : {}),
  client: yup.string().required("Please Select Client !!!"),
  sourceCurrency: yup.string().required("Please Select Source Currency!!!"),
  destinationCurrency: yup
    .string()
    .required("Please Select  Destination Currency !!!"),
  sourceAmount: yup
    .number()
    .min(0, "Please Enter Valid Source Amount !!! ")
    .required("Please Enter Source Amount !!!"),
  clientTxnId: yup.string().required("Please Enter Client Transaction Id !!!"),
  name: yup.string().required("Please enter name !!!"),
  recipientEmail: yup
    .string()
    .email("Please enter valid Email !!!")
    .required("Please Enter Email !!!"),
  recipientPhoneNumber: yup
    .number()
    .required("Please Enter Recipient Phone Number !!!"),
  recipientCountry: yup.string().required("Please Select Recipient Country!!!"),
});

const getParams = (state = {}) => {
  const {
    client,
    clientTxnId,
    sourceCurrency,
    destinationCurrency,
    sourceAmount,
    accountType,
    name,
    recipientEmail,
    recipientPhoneNumber,
    recipientCountry,
    recipientAddress,
    recipientCity,
    recipientDistrict,
    recipientPostalCode,
    recipientBankName,
    recipientBankCity,
    recipientBankCountry,
    recipientIban,
    swiftCode
  } = state;

  const paramsToSend = {
    client_id: client,
    client_txn_id: clientTxnId.trim(),
    source_amount: sourceAmount,
    source_currency: sourceCurrency,
    destination_currency: destinationCurrency,
    account_type: accountType,
    name: name.trim(),
    recipient_email: recipientEmail.trim(),
    recipient_phone_number: recipientPhoneNumber.trim(),
    recipient_country: recipientCountry,
    recipient_address: recipientAddress,
    recipient_city: recipientCity,
    recipient_district: recipientDistrict,
    recipient_postal_code: recipientPostalCode,
    bic_swift: swiftCode,
    bank_name: recipientBankName,
    bank_city: recipientBankCity,
    bank_country: recipientBankCountry,
    deposit_number: recipientIban
  }
  if(accountType === "Wiretransfer-iban") {
    delete paramsToSend['bic_swift']
    delete paramsToSend['recipient_district']
    paramsToSend['iban'] = paramsToSend['deposit_number']
  }
  if(accountType === "Wiretransfer-non-iban") {
    delete paramsToSend['recipient_district']
  }
  return sortObjectAlphabatically(paramsToSend);
};

const AgentCreateWireTransaction = () => {
  const [viewState, setViewState] = useState(initialState);
  const [alertState, setAlertState] = useState(initialAlertState);
  const wallet = localStorage.getItem("walletStatus");
  const { id, ba_id } = JSON.parse(wallet);
  const classes = useStyle();

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "accountType") {
      setViewState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setViewState((prevState) => ({ ...prevState, [name]: value }));
    }
    setViewState((prevState) => ({ ...prevState, client: id }));
    setViewState((prevState) => ({ ...prevState, selectedBA: ba_id }));
  };

  const handleBlur = (event) => {
    if(event.target.value) {
      fetch(base_url + '/transactions/successful_wire_transactions?iban=' + event.target.value, {
        method: 'get',
        headers: {
          Authorization: `Bearer ${localStorage.getItem("session_token")}`
        }
      })
      .then((response) => {
        if(response.ok) {
          return response.json();
        } else {
          throw new Error('Server response wasn\'t OK');
        }
      })
      .then(function(res){
        if(res == null){
          console.log('record not found');
        }else{
          setViewState((prevState) => ({
            ...prevState,
            accountType: res.account_type,
            sourceCurrency: res.source_currency,
            destinationCurrency: res.destination_currency,
            recipientCountry: res.recipient_country,
            name: res.name,
            recipientEmail: res.recipient_email,
            recipientPhoneNumber: res.recipient_phone_number,
            recipientAddress: res.recipient_address,
            recipientCity: res.recipient_city,
            recipientPostalCode: res.recipient_postal_code,
            recipientBankName: res.bank_name,
            recipientBankCity: res.bank_city,
            recipientDistrict: res.recipient_district,
            recipientBankCountry: res.bank_country,
            swiftCode: res.bic_swift
          }));
        }
      });
    }
  }

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    if (viewState.destinationCurrency !== "") {
      if (viewState.allCountries.length > 0) {
        setViewState((prevState) => ({
          ...prevState,
          recipientCountryList: viewState.allCountries,
        }));
      }
    }
  }, [viewState.destinationCurrency]);


  const getCountries = async () => {
    const data = await makeApiCall({
      url: "/country_codes.json",
      method: "get",
    });
    const { error = false, message } = data;
    if (!error) {
      const { countries } = data;
      setViewState((prevState) => ({
        ...prevState,
        allCountries: countries,
      }));
    } else {
      console.log(message);
    }
  };

  const hideAlert = () => {
    setAlertState(initialAlertState);
  };

  const handleCleanform = () => {
    setViewState((prevState) => ({
      ...initialState,
      allCountries: prevState.allCountries,
    }));
  };

  const handleDialogConfirmation = () => {
    createSignature();
  };

  const handleDialogClose = () => {
    setViewState((prevState) => ({
      ...prevState,
      dialogOpen: false,
    }));
  };

  const handleSubmit = () => {
    schema
      .validate(viewState, {
        abortEarly: false,
      })
      .then(() => {

        setViewState((prevState) => ({
          ...prevState,
          dialogOpen: true,
        }));
      })
      .catch((errors) => {
        let allErrors = {};
        errors.inner.map((error) => {
          let [a] = error.path.split(".");
          allErrors[a] = error.message;
          return error;
        });
        console.log(allErrors);
        setViewState((prevState) => ({
          ...prevState,
          errors: allErrors,
        }));
      });
  };

  const createSignature = async () => {
    setViewState({ ...viewState, loading: true, dialogOpen: false });
    let params = getParams(viewState);
    const data = await makeApiCall({
      url: "/transactions/create_wiretransfer_signature",
      method: "post",
      params,
    });
    const { error = false, message } = data;
    if (error) {
      console.log(message);
      setViewState({ ...viewState, loading: false });
      setAlertState({
        alert: true,
        success: false,
        error: true,
        warning: false,
        message: "Something went wrong!!!",
        title: "Error",
      });
    } else {
      const { api_token, tonce, signature } = data;
      params = {
        ...params,
        api_token: api_token,
        tonce: tonce,
        signature: signature,
      };
      delete params.client_id; //as client_id is not required for transaction
      params = sortObjectAlphabatically(params);
      createTransaction(params);
    }
  };

  const createTransaction = async (params = {}) => {
    const data = await makeApiCall({
      url: "/api/v1/transactions_wiretransfer",
      method: "post",
      params,
    });
    const { error, message } = data;
    if (error) {
      console.log(message);
      let val = "Something Went wrong !!!";
      if (message.response.data.error) {
        const {
          response: { data },
        } = message;
        const {
          error: { message: errorMessage },
        } = data;
        val = errorMessage;
      }
      setViewState({ ...viewState, loading: false });
      setAlertState({
        alert: true,
        success: false,
        error: true,
        warning: false,
        message: val,
        title: "Error",
      });
    } else {
      // console.log(data);
      let successMessage = "Transaction Created Successfully!!!";
      setAlertState({
        alert: true,
        success: true,
        error: false,
        warning: false,
        message: successMessage,
        title: "Success",
      });
      handleCleanform();
    }
  };

  return (
    <>
      <CustomSweet
        alert={alertState.alert}
        message={alertState.message}
        title={alertState.title}
        hide_alert={hideAlert}
        danger={alertState.error}
        success={alertState.success}
      />
      <DialogComponent
        isOpen={viewState.dialogOpen}
        confirmFunction={handleDialogConfirmation}
        closeFunction={handleDialogClose}
      />
      <div className={viewState.loading ? classes.main_screen : ""}>
        <div>{viewState.loading && <Progress />} </div>
        <ViewComponent
          {...viewState}
          handleCancel={handleCleanform}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleBlur={handleBlur}
        />
      </div>
    </>
  );
};

export default AgentCreateWireTransaction;
