import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Datetime from "react-datetime";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import CardText from "components/Card/CardText.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

const currencies = [
  { abbrevation: "usd", symbol: "$" },
  { abbrevation: "ngn", symbol: "₦" },
  { abbrevation: "kes", symbol: "kes" },
  { abbrevation: "ghs", symbol: "GH₵" },
];

// let d = new Date();
// d.setDate(d.getDate() - 1);
// d.setHours(0);
// d.setMinutes(0);
// d.setSeconds(1);
// // let prev_date = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
// let prev_date =
//   d.getFullYear() +
//   "-" +
//   (d.getMonth() + 1) +
//   "-" +
//   d.getDate() +
//   " " +
//   d.getHours() +
//   ":" +
//   d.getMinutes();

// let tempDate = new Date();
// tempDate.setHours(23);
// tempDate.setMinutes(59);
// tempDate.setSeconds(59);
// let date =
//   tempDate.getFullYear() +
//   "-" +
//   (tempDate.getMonth() + 1) +
//   "-" +
//   tempDate.getDate() +
//   " " +
//   tempDate.getHours() +
//   ":" +
//   tempDate.getMinutes();
const today = new Date();
const yesterday = new Date();

yesterday.setDate(yesterday.getDate() - 1);
export default function Dashboard(props) {
  const classes = useStyles();
  const [source_currency, setSourceCurrency] = React.useState("ngn");
  const [dest_currency, setDestCurrency] = React.useState("ngn");
  const [client_id, setClient] = React.useState("All");
  const [status, setStatus] = React.useState("All");
  const [payment_gateway, setGateway] = React.useState("All");
  const [selectedDate, setSelectedDate] = React.useState(yesterday);
  const [selectedendDate, setSelectedEndDate] = React.useState(today);
  if (
    localStorage.getItem("session_token") === null ||
    localStorage.getItem("session_token") === ""
  ) {
    //window.location.href = '/';
    props.history.push("/auth/login-page");
  }
  const setSourceCurrencyChange = (event) => {
    setSourceCurrency(event.target.value);
    props.getSourceCurrencyFilter(event.target.value);
  };

  const setStatusChange = (event) => {
    // console.log(event.target.value);
    setStatus(event.target.value);
    props.getStatusFilter(event.target.value);
  };

  const setClientChange = (event) => {
    // console.log(event.target.value);
    setClient(event.target.value);
    props.getClientFilter(event.target.value);
  };

  const setGatewayChange = (event) => {
    // console.log(event.target.value);
    setGateway(event.target.value);
    props.getGatewayFilter(event.target.value);
  };

  const setDestCurrencyChange = (event) => {
    setDestCurrency(event.target.value);
    props.getDestCurrencyFilter(event.target.value);
  };
  const getCurrencySymbol = () => {
    const currency = currencies.find(
      (curr) => curr.abbrevation === dest_currency
    );
    return currency ? currency.symbol : "";
  };
  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    props.getEndDateFilter(date);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
    props.getStartDateFilter(date);
  };
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} md={12} lg={12}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4>Analytics</h4>
              </CardText>
            </CardHeader>
            <br />
            <br />
            <GridContainer>
              <GridItem xs={12} sm={6} md={6} lg={3}>
                <Card>
                  <CardHeader color="rose" text>
                    <CardText color="rose">
                      <h6>PICK START DATE</h6>
                    </CardText>
                  </CardHeader>
                  <CardBody style={{ padding: "20px 20px" }}>
                    <FormControl fullWidth>
                      <Datetime
                        inputProps={{ placeholder: "Start Date" }}
                        dateFormat={"YYYY-MMMM-DD"}
                        id="start-date-picker-dialog"
                        label="Start Date"
                        value={selectedDate}
                        onChange={handleDateChange}
                      />
                    </FormControl>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} lg={3}>
                <Card>
                  <CardHeader color="rose" text>
                    <CardText color="rose">
                      <h6>PICK END DATE</h6>
                    </CardText>
                  </CardHeader>
                  <CardBody style={{ padding: "20px 20px" }}>
                    <FormControl fullWidth>
                      <Datetime
                        inputProps={{ placeholder: "End Date" }}
                        id="end-date-picker-dialog"
                        label="End Date"
                        dateFormat={"YYYY-MMMM-DD"}
                        value={selectedendDate}
                        onChange={handleEndDateChange}
                      />
                    </FormControl>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={3}>
                <Card>
                  <CardHeader color="rose" text>
                    <CardText color="rose">
                      <h6>PICK CURRENCIES</h6>
                    </CardText>
                  </CardHeader>
                  <CardBody style={{ padding: "20px 20px" }}>
                    <div>
                      <FormControl
                        className={classes.selectFormControl}
                        style={{ align: "left", width: "70px" }}
                      >
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          inputProps={{
                            name: "simpleSelect",
                            id: "currency-select",
                            value: source_currency,
                            onChange: (e) => setSourceCurrencyChange(e),
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="usd"
                            key="usd"
                          >
                            USD
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="ngn"
                            key="ngn"
                          >
                            NGN
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="ghs"
                            key="ghs"
                          >
                            GHS
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="kes"
                            key="kes"
                          >
                            KES
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="inr"
                            key="inr"
                          >
                            INR
                          </MenuItem>
                        </Select>
                      </FormControl>
                      <span style={{ padding: "20px 20px" }}>
                        {"    →    "}
                      </span>

                      <FormControl
                        className={classes.selectFormControl}
                        style={{ align: "left", width: "70px" }}
                      >
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={dest_currency}
                          onChange={(e) => setDestCurrencyChange(e)}
                          inputProps={{
                            name: "simpleSelect",
                            id: "dest-select",
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="usd"
                            key="usd"
                          >
                            USD
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="ngn"
                            key="ngn"
                          >
                            NGN
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="ghs"
                            key="ghs"
                          >
                            GHS
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="kes"
                            key="kes"
                          >
                            KES
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="inr"
                            key="inr"
                          >
                            INR
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={3}>
                <Card>
                  <CardHeader color="rose" text>
                    <CardText color="rose">
                      <h6>PICK STATUS</h6>
                    </CardText>
                  </CardHeader>
                  <CardBody style={{ padding: "20px 20px" }}>
                    <FormControl
                      className={classes.selectFormControl}
                      fullWidth
                    >
                      <Select
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        inputProps={{
                          name: "simpleSelect",
                          id: "status-select",
                          value: status,
                          onChange: (e) => setStatusChange(e),
                        }}
                      >
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="All"
                          key="all"
                        >
                          All
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="processing"
                          key="processing"
                        >
                          Processing
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="validated"
                          key="validated"
                        >
                          Validated
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="successful"
                          key="successful"
                        >
                          Successful
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="failed"
                          key="failed"
                        >
                          Failed
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={3}>
                <Card>
                  <CardHeader color="rose" text>
                    <CardText color="rose">
                      <h6>PICK CLIENT</h6>
                    </CardText>
                  </CardHeader>
                  <CardBody style={{ padding: "20px 20px" }}>
                    <FormControl
                      className={classes.selectFormControl}
                      fullWidth
                    >
                      <Select
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        inputProps={{
                          name: "simpleSelect",
                          id: "client-select",
                          value: client_id,
                          onChange: (e) => setClientChange(e),
                        }}
                      >
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="All"
                          key="all"
                        >
                          All
                        </MenuItem>
                        {props.clients &&
                          props.clients.map((row) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={row.id}
                              key={row.id}
                            >
                              {row.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </CardBody>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={6} lg={3}>
                <Card>
                  <CardHeader color="rose" text>
                    <CardText color="rose">
                      <h6>PICK GATEWAY</h6>
                    </CardText>
                  </CardHeader>
                  <CardBody style={{ padding: "20px 20px" }}>
                    <FormControl
                      className={classes.selectFormControl}
                      fullWidth
                    >
                      <Select
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        inputProps={{
                          name: "simpleSelect",
                          id: "gateway-select",
                          value: payment_gateway,
                          onChange: (e) => setGatewayChange(e),
                        }}
                      >
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="All"
                          key="all"
                        >
                          All
                        </MenuItem>
                        {props.payment_gateway_all &&
                          props.payment_gateway_all.map((row) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={row.name}
                              key={row.id}
                            >
                              {row.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={6} md={6} lg={3}>
                <Card>
                  <CardHeader color="rose" text>
                    <CardText>
                      <h6>
                        TOTAL NUMBER SUCCESS <span>{"#"}</span>
                      </h6>
                    </CardText>
                  </CardHeader>
                  <CardBody style={{ padding: "20px 20px" }}>
                    <div style={{ alignSelf: "center" }}>
                      {props.total_number_success}
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={3}>
                <Card>
                  <CardHeader color="rose" text>
                    <CardText>
                      <h6>
                        TOTAL AMOUNT SUCCESS <span>{getCurrencySymbol()}</span>{" "}
                      </h6>
                    </CardText>
                  </CardHeader>
                  <CardBody style={{ padding: "20px 20px" }}>
                    <div style={{ alignSelf: "center" }}>
                      {props.total_success_amount}
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={3}>
                <Card>
                  <CardHeader color="rose" text>
                    <CardText>
                      <h6>
                        TOTAL NUMBER FAILED <span>{"#"}</span>
                      </h6>
                    </CardText>
                  </CardHeader>
                  <CardBody style={{ padding: "20px 20px" }}>
                    <div style={{ alignSelf: "center" }}>
                      {props.total_number_fail}
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={3}>
                <Card>
                  <CardHeader color="rose" text>
                    <CardText>
                      <h6>
                        TOTAL AMOUNT FAILED <span>{getCurrencySymbol()}</span>{" "}
                      </h6>
                    </CardText>
                  </CardHeader>
                  <CardBody style={{ padding: "20px 20px" }}>
                    <div style={{ alignSelf: "center" }}>
                      {props.total_fail_amount}
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={3}>
                <Card>
                  <CardHeader color="rose" text>
                    <CardText>
                      <h6>
                        TOTAL NUMBER VALIDATED <span>{"#"}</span>
                      </h6>
                    </CardText>
                  </CardHeader>
                  <CardBody style={{ padding: "20px 20px" }}>
                    <div style={{ alignSelf: "center" }}>
                      {props.total_number_valid}
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={3}>
                <Card>
                  <CardHeader color="rose" text>
                    <CardText>
                      <h6>
                        TOTAL AMOUNT VALIDATED{" "}
                        <span>{getCurrencySymbol()}</span>
                      </h6>
                    </CardText>
                  </CardHeader>
                  <CardBody style={{ padding: "20px 20px" }}>
                    <div style={{ alignSelf: "center" }}>
                      {props.total_valid_amount}
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={3}>
                <Card>
                  <CardHeader color="rose" text>
                    <CardText>
                      <h6>
                        TOTAL NUMBER PROCESSING <span>{"#"}</span>
                      </h6>
                    </CardText>
                  </CardHeader>
                  <CardBody style={{ padding: "20px 20px" }}>
                    <div style={{ alignSelf: "center" }}>
                      {props.total_number_process}
                    </div>
                  </CardBody>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={6} lg={3}>
                <Card>
                  <CardHeader color="rose" text>
                    <CardText>
                      <h6>
                        TOTAL AMOUNT PROCESSING{" "}
                        <span>{getCurrencySymbol()}</span>
                      </h6>
                    </CardText>
                  </CardHeader>
                  <CardBody style={{ padding: "20px 20px" }}>
                    <div style={{ alignSelf: "center" }}>
                      {props.total_process_amount}
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={3}>
                <Card>
                  <CardHeader color="rose" text>
                    <CardText>
                      <h6>
                        TOTAL NUMBER CANCELLED <span>{"#"}</span>
                      </h6>
                    </CardText>
                  </CardHeader>
                  <CardBody style={{ padding: "20px 20px" }}>
                    <div style={{ alignSelf: "center" }}>
                      {props.total_number_cancelled}
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={3}>
                <Card>
                  <CardHeader color="rose" text>
                    <CardText>
                      <h6>
                        TOTAL AMOUNT CANCELLED{" "}
                        <span>{getCurrencySymbol()}</span>
                      </h6>
                    </CardText>
                  </CardHeader>
                  <CardBody style={{ padding: "20px 20px" }}>
                    <div style={{ alignSelf: "center" }}>
                      {props.total_cancelled_amount}
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
