import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CardHeader from "components/Card/CardHeader.js";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// import InputLabel from "@material-ui/core/InputLabel";
import moment from "moment";
import CardText from "components/Card/CardText.js";
import axios from "axios";
import Check from "@material-ui/icons/Check";
import apiUrl from "../api";
import Close from "@material-ui/icons/Close";
import Dvr from "@material-ui/icons/Dvr";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import CustomSweet from "../Components/CustomSweet";
import Progress from "../Components/Progress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';

const base_url = apiUrl;
class Client extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: 0,
      loading: false,
      clients: [],
      open: false,
      currencies: [],
      check_daily: false,
      check_wallet: false,
      check_fee: false,
      name: "",
      txn_ngn: "",
      txn_ghs: "",
      txn_kes: "",
      txn_inr: "",
      valid_name: "",
      baUsers: [],
      selectedBA: "",
      valid_ba: true,
      checkedCurrencies: new Set(),
      paymentProcess:"",
      validPaymentProcess:true,
    };
    this.checkedCurrencies = new Set();
  }
  componentDidMount() {
    this.getCurrencies();
    this.getUsers("ba");
  }
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleBaChange = (event) => {
    this.setState({ selectedBA: event.target.value, valid_ba: true });
  };
  getBaUserDropDown = () => {
    const { classes } = this.props;
    return (
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel
          className={classes.selectLabel}
          error={!this.state.valid_ba}
        >
          {"BA *"}
        </InputLabel>

        <Select
          MenuProps={{
            className: classes.selectMenu,
          }}
          classes={{
            select: classes.select,
          }}
          value={this.state.selectedBA}
          onChange={this.handleBaChange}
        >
          {this.state.baUsers.map((row) => (
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={row.id}
              key={row.id}
            >
              {row.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  handleClose = () => {
    this.setState({ open: false, check_daily: false,paymentProcess:"" });
  };
  getClients = () => {
    // const base_url = window.location.origin;
    this.setState({ loading: true });
    axios({
      method: "get",
      url: base_url + "/clients.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {},
    })
      .then((result) => {
        this.setState({
          clients: result.data.clients,
          loading: false,
        });
      })
      .catch((error) => {
        console.log("Error", error);
        this.setState({ loading: false });
      });
  };
  getCurrencies = () => {
    // const base_url = window.location.origin;
    axios({
      method: "get",
      url: base_url + "/currencies.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {},
    })
      .then((result) => {
        //access the results here....
        this.setState({
          currencies: this.getAllCurrencies(result.data.currencies),
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  getUsers = (role) => {
    // const base_url = window.location.origin;
    // this.setState({ loading: true });
    axios({
      method: "get",
      url: base_url + "/users.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        role: role,
      },
    })
      .then((result) => {
        // console.log(result);
        if (result.data) {
          this.setState({
            baUsers: result.data,
            // loading: false,
          });
        }
      })
      .catch((error) => {
        // this.setState({
        //   loading: false,
        // });
        // swal1("Search!", error.response.data.error, "error");
      });
  };

  getAllCurrencies = (currencies = []) => {
    return currencies.filter((data) => {
      return data.attributes.type === "destination";
    });
  };
  handleShowClinets = (event) => {
    event.preventDefault();
    this.getClients();
  };
  get getData() {
    var x = this.state.clients;
    // console.log("x", x);
    return x.map((row, index) => ({
      id: row.id,
      name: row.name,
      client_api_token: row.client_api_tokens[0].api_token,
      secret_key: row.client_api_tokens[0].secret_key,
      expiration_date:
        row.client_api_tokens[0].expiration_date != null
          ? this.showDate(row.client_api_tokens[0].expiration_date)
          : "",
      action: this.showView(row),
      //   this.addDelete(row),
    }));
  }
  showDate = (date) => {
    const firstdate = new Date(date.toString());
    return <span>{moment(firstdate).format("YYYY-MM-DD HH:mm:ss")}</span>;
  };
  showView = (row) => {
    // const { classes } = this.props;
    return (
      <div>
        <Button
          justIcon
          round
          simple
          color="warning"
          // class={!this.state.agent_user ? "btn btn-link" : "hidden"}
          onClick={(e) => this.handleShow(e, row.id)}
          className="like"
          //   style={{ display: "block" }}
        >
          {/* Show */}
          <Dvr />
        </Button>{" "}
        <Button
          justIcon
          round
          simple
          color="danger"
          //   class={"btn-danger btn-s"}
          value={row.id}
          onClick={(e) => this.handleDelete(e, row.id)}
          className="like"
        >
          {/* Delete */}
          <Close />
        </Button>{" "}
      </div>
    );
  };
  handleShow = (event, id) => {
    event.preventDefault();
    // console.log(id);
    localStorage.setItem("client_id", id);
    // console.log(localStorage.getItem("id"));
    this.setState({ isClient: true });
    window.open("/admin/show_client/" + id, "_self");
  };
  addDelete = (row) => {
    // const { classes } = this.props;
    return (
      <button
        variant="contained"
        color="secondary"
        class={"btn-danger btn-s"}
        value={row.id}
        onClick={(e) => this.handleDelete(e, row.id)}
      >
        Delete
      </button>
    );
  };
  handleDelete = (event, id) => {
    event.preventDefault();
    var confirmation = window.confirm(
      "Are you sure you want to delete Client?"
    );

    if (confirmation) {
      this.setState({
        loading: true,
      });
      axios({
        method: "delete",
        url: base_url + "/clients/" + id + ".json",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        },
        params: {},
      })
        .then((result) => {
          const clients = this.state.clients.filter(
            (client) => client.id !== id
          );

          this.setState(
            {
              loading: false,
              alert: true,
              alert_msg: "Client deleted successfully.!",
              alert_title: "Client Delete!",
              danger: false,
              warning: false,
              success: true,
              times: "0",
              clients: clients,
            }
            // () => this.cleanInputs()
          );
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            loading: false,
            alert: true,
            alert_msg: error.response,
            alert_title: "Error!",
            danger: true,
            warning: false,
            success: false,
          });
        });
    }
  };
  handleChange = (event) => {
    const { name, checked } = event.target;
    // console.log(name,checked);
    this.setState({ [name]: checked });
  };
  // handleFeeChange = () => {
  //   this.setState({ check_fee: !this.state.check_fee });
  // };
  // handleWalletChange = () => {
  //   this.setState({ check_wallet: !this.state.check_wallet });
  // };
  handleCheckChange = (event) => {
    // console.log(typeof event.target.value);
    // console.log(event.target.checked);
    const { value } = event.target;
    const { checkedCurrencies } = this.state;
    if (checkedCurrencies.has(value)) {
      checkedCurrencies.delete(value);
    } else {
      checkedCurrencies.add(value);
    }
    this.setState({
      checkedCurrencies,
    });

    // for (const each of this.state.currencies) {
    // //  console.log(typeof each.attributes.id);
    //   if (each.id === event.target.value) {
    //     each.checked = event.target.checked;
    //     console.log("checked", event.target.checked);
    //   }
    // }
    // this.setState({});
  };
  handleLimitChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    this.setState({ [name]: value });
  };

  handlePaymentProcessChange = (event)=>{
    const {value} = event.target;
    this.setState({paymentProcess:value});
  }
  handleNameChange = (event) => {
    this.setState({ name: event.target.value });
  };
  // handleNgnChange = (event) => {
  //   this.setState({ txn_ngn: event.target.value });
  // };
  // handleGhsChange = (event) => {
  //   this.setState({ txn_ghs: event.target.value });
  // };
  // handleKesChange = (event) => {
  //   this.setState({ txn_kes: event.target.value });
  // };
  // handleInrChange = (event) => {
  //   this.setState({ txn_inr: event.target.value });
  // };
  clearForm = () => {
    this.setState((state) => ({
      check_daily: false,
      check_wallet: false,
      check_fee: false,
      name: "",
      txn_ngn: "",
      txn_ghs: "",
      txn_kes: "",
      txn_inr: "",
      selectedBA: "",
      checkedCurrencies: state.checkedCurrencies.clear(),
    }));
    //  this.checkedCurrencies.clear();
  };
  validateForm() {
    const { name, selectedBA,paymentProcess} = this.state;
    if (name === "") {
      this.setState({ valid_name: "false" });
      alert("Please enter Client Name!!");
      return false;
    }
    if (selectedBA === "") {
      this.setState({ valid_ba: false });
      alert("Please select BA user !!!");
      return false;
    }
    if(paymentProcess === ""){
      alert("Please select payment process type !!!");
      this.setState({validPaymentProcess:false});
      return false;
    }
    return true;
  }
  handleCreateClient = (event) => {
    event.preventDefault();
    // const base_url = window.location.origin;
    var check_currency = "";
    // var is_wallet_enabled = document.getElementById("walletable").checked;
    // var feeable = document.getElementById("feeable").checked;
    // var txn_limit_enabled = document.getElementById("txn_limit_enabled")
    //   .checked;
    // for (var i = 0; i < this.state.currencies.length; i++) {
    //   if (
    //     document.getElementById(
    //       "currency" + this.state.currencies[i].attributes.id
    //     ).checked
    //   ) {
    //     // console.log("currency", this.state.currencies[1]);
    //     check_currency += this.state.currencies[i].attributes.id + ",";
    //   }
    // }
    const { checkedCurrencies } = this.state;
    for (let val of checkedCurrencies) {
      check_currency += val + ",";
    }
    //  console.log(this.state);
    if (this.validateForm()) {
      this.setState({ loading: true });
      axios({
        method: "post",
        url: base_url + "/clients.json",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        },
        params: {
          currency: check_currency,
          "client[name]": this.state.name,
          "client[ba_id]": this.state.selectedBA,
          "client[is_wallet_enabled]": this.state.check_wallet,
          "client[feeable]": this.state.check_fee,
          "client[txn_limit_enabled]": this.state.check_daily,
          "client_txn_limit[ngn]": this.state.txn_ngn,
          "client_txn_limit[ghs]": this.state.txn_ghs,
          "client_txn_limit[kes]": this.state.txn_kes,
          "client_txn_limit[inr]": this.state.txn_inr,
          "client[manual_release]":this.state.paymentProcess === "manual",
        },
      })
        .then((result) => {
          //access the results here....
          // console.log(result);
          this.getClients();
          this.setState({
            loading: false,
            open: false,
            alert: true,
            alert_msg: "Client created successfully.!",
            alert_title: "Client Create!",
            danger: false,
            warning: false,
            success: true,
          });
        })
        .catch((error) => {
          console.log("Error", error.response);
          this.setState({
            loading: false,
            alert: true,
            alert_msg: error,
            alert_title: "Error!",
            danger: true,
            warning: false,
            success: false,
          });
        });
    }
  };
  filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
      : true;
  };
  hideAlert = (times) => {
    this.setState({
      alert: false,
      alert_msg: "",
      alert_title: "",
      alert_type: "",
    });
  };
  render() {
    const { classes } = this.props;
    const settingData = this.getData;
    const { checkedCurrencies,paymentProcess,validPaymentProcess} = this.state;
    return (
      <div>
        <CustomSweet
          alert={this.state.alert}
          message={this.state.alert_msg}
          title={this.state.alert_title}
          hide_alert={this.hideAlert}
          danger={this.state.danger}
          warning={this.state.warning}
          success={this.state.success}
          times={this.state.times}
        />
        <div className={this.state.loading === true ? classes.main_screen : ""}>
          <div align="center">
            {this.state.loading === true && <Progress />}
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <Button onClick={this.handleShowClinets}>
                      View Clients
                    </Button>
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <Button color="rose" onClick={this.handleOpen}>
                      Create
                    </Button>
                    <Dialog
                      open={this.state.open}
                      onClose={this.handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Create Client"}
                      </DialogTitle>
                      <DialogContent>
                        <div>
                          <div>
                            <CustomInput
                              labelText="Name *"
                              success={this.state.valid_name === ""}
                              error={this.state.valid_name === "false"}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                value: this.state.name,
                                onChange: this.handleNameChange,
                              }}
                            />
                          </div>
                          {this.getBaUserDropDown()}
                          <br></br>
                          <br></br>
                          <GridContainer>
                          <GridItem xs={12}>Currency</GridItem>
                          <br></br>
                          {this.state.currencies.map((currency) => (
                            <GridItem key={currency.attributes.id} xs={4} sm={3} md={2}>
                            <div >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    key={currency.attributes.id}
                                    id={"currency" + currency.attributes.id}
                                    label={currency.attributes.name}
                                    value={currency.attributes.id}
                                    checked={checkedCurrencies.has(
                                      currency.attributes.id + ""
                                    )}
                                    name={"currency" + currency.attributes.id}
                                    checkedIcon={
                                      <Check className={classes.checkedIcon} />
                                    }
                                    icon={
                                      <Check
                                        className={classes.uncheckedIcon}
                                      />
                                    }
                                    classes={{
                                      checked: classes.checked,
                                      root: classes.checkRoot,
                                    }}
                                    onChange={this.handleCheckChange}
                                  />
                                }
                                label={currency.attributes.name}
                              />
                            </div>
                            </GridItem>
                          ))}
                          </GridContainer>
                          <br></br>
                          <FormControl component="fieldset" error={!validPaymentProcess}>
                            <FormLabel>Payment Process</FormLabel>
                            <RadioGroup
                              aria-label="payment process"
                              name="paymentProcess"
                              value={paymentProcess}
                              onChange={this.handlePaymentProcessChange}
                            >
                              <FormControlLabel
                                value="automatic"
                                control={<Radio />}
                                label="Automatic"
                              />
                              <FormControlLabel
                                value="manual"
                                control={<Radio />}
                                label="Manual"
                              />
                            </RadioGroup>
                          </FormControl>
                          <br></br>
                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id={"walletable"}
                                  onChange={this.handleChange}
                                  checked={this.state.check_wallet}
                                  name="check_wallet"
                                  color="primary"
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={
                                    <Check className={classes.uncheckedIcon} />
                                  }
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot,
                                  }}
                                />
                              }
                              label="Applicable for Wallet"
                            />
                          </div>
                          <br></br>
                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id={"feeable"}
                                  onChange={this.handleChange}
                                  checked={this.state.check_fee}
                                  name="check_fee"
                                  color="primary"
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={
                                    <Check className={classes.uncheckedIcon} />
                                  }
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot,
                                  }}
                                />
                              }
                              label="Applicable for fee"
                            />
                          </div>
                          <br></br>
                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id={"txn_limit_enabled"}
                                  name="check_daily"
                                  onChange={this.handleChange}
                                  checked={this.state.check_daily}
                                  color="primary"
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={
                                    <Check className={classes.uncheckedIcon} />
                                  }
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot,
                                  }}
                                />
                              }
                              label="Applicable for daily transaction Limit"
                            />
                          </div>
                          <br></br>
                          {this.state.check_daily && (
                            <div>
                              <div>Set Initial Limits</div>
                              <div style={{ marginLeft: `2em` }}>
                                <CustomInput
                                  id="textNgn"
                                  labelText="Ngn"
                                  color="secondary"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    name: "txn_ngn",
                                    value: this.state.txn_ngn,
                                    onChange: this.handleLimitChange,
                                  }}
                                />
                              </div>
                              <div style={{ marginLeft: `2em` }}>
                                <CustomInput
                                  id="textGhs"
                                  labelText="Ghs"
                                  color="secondary"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    name: "txn_ghs",
                                    value: this.state.txn_ghs,
                                    onChange: this.handleLimitChange,
                                  }}
                                />
                              </div>
                              <div style={{ marginLeft: `2em` }}>
                                <CustomInput
                                  id="textInr"
                                  labelText="Inr"
                                  color="secondary"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    name: "txn_inr",
                                    value: this.state.txn_inr,
                                    onChange: this.handleLimitChange,
                                  }}
                                />
                              </div>
                              <div style={{ marginLeft: `2em` }}>
                                <CustomInput
                                  id="textKes"
                                  labelText="Kes"
                                  color="secondary"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    name: "txn_kes",
                                    value: this.state.txn_kes,
                                    onChange: this.handleLimitChange,
                                  }}
                                />
                              </div>
                            </div>
                          )}
                          
                          {/* </div> */}
                        </div>
                      </DialogContent>

                      <DialogActions>
                        <Button onClick={this.handleClose}>Cancel</Button>
                        <Button
                          onClick={(e) => this.handleCreateClient(e)}
                          color="rose"
                          autoFocus
                        >
                          Submit
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="rose" text>
                  <CardText color="rose">
                    <h4 className={classes.cardTitle}>Clients</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={settingData}
                    filterable
                    columns={[
                      {
                        Header: "ID",
                        accessor: "id",
                      },
                      {
                        accessor: "name",
                        Header: "Name",
                        filterMethod: this.filterMethod,
                      },
                      {
                        Header: "API Token",
                        accessor: "client_api_token",
                        filterMethod: this.filterMethod,
                      },
                      {
                        Header: "Secret Key",
                        accessor: "secret_key",
                        filterMethod: this.filterMethod,
                      },
                      {
                        Header: "Expiration Date",
                        accessor: "expiration_date",
                      },
                      {
                        accessor: "action",
                        Header: "Action",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                    // defaultFilterMethod={this.filterMethod}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}
export default withStyles(cssstyles)(Client);
