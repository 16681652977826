import React from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
// import CardBody from "components/Card/CardBody.js";
import CardText from "components/Card/CardText.js";
import Table from "components/Table/Table.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import apiUrl from "../api";

const base_url = apiUrl;

class WalletBalance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: "",
      client_id: "",
      usdBalance: "0.0",
      usdLocked: "0.0",
      usdAmount: "0.0",
      usdtBalance: "0.0",
      usdtLocked: "0.0",
      usdtAmount: "0.0",
      usdcBalance: "0.0",
      usdcLocked: "0.0",
      usdcAmount: "0.0"
    };
  }
  componentDidMount() {
    this.getBalances();
  }
  getBalances() {
    if (
      localStorage.getItem("session_token") === "" ||
      localStorage.getItem("session_token") === null
    ) {
      return;
    }
    axios({
      method: "get",
      url: base_url + "/get_wallet_balance.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: { currency: "usd" },
    })
    .then((result) => {
      this.setState({
        usdBalance: result.data.wallet.balance,
        usdLocked: result.data.wallet.locked,
        usdAmount: result.data.wallet.amount,
      });
    })
    .catch((error) => {
      console.log("Error", error.response);
    });

    axios({
      method: "get",
      url: base_url + "/get_wallet_balance.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: { currency: "usdt" },
    })
    .then((result) => {
      this.setState({
        usdtBalance: result.data.wallet.balance,
        usdtLocked: result.data.wallet.locked,
        usdtAmount: result.data.wallet.amount,
      });
    })
    .catch((error) => {
      console.log("Error", error.response);
    });

    axios({
      method: "get",
      url: base_url + "/get_wallet_balance.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: { currency: "usdc" },
    })
    .then((result) => {
      this.setState({
        usdcBalance: result.data.wallet.balance,
        usdcLocked: result.data.wallet.locked,
        usdcAmount: result.data.wallet.amount,
      });
    })
    .catch((error) => {
      console.log("Error", error.response);
    });
  }
  render() {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} md={12} lg={12}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4>Wallet Balance</h4>
                </CardText>
              </CardHeader>
              <br />
              <br />
              <GridContainer justify="space-between">
                <GridItem xs={12} sm={12} md={12}>
                  <Table
                    tableData={[
                      ["Wallet", "Balance", "Locked", "Amount"],
                      [
                        "USD Wallet ( $ )",
                        parseFloat(this.state.usdBalance).toFixed(2),
                        parseFloat(this.state.usdLocked).toFixed(2),
                        parseFloat(this.state.usdAmount).toFixed(2),
                      ],
                      [
                        "USDT Wallet ( $ )",
                        parseFloat(this.state.usdtBalance).toFixed(2),
                        parseFloat(this.state.usdtLocked).toFixed(2),
                        parseFloat(this.state.usdtAmount).toFixed(2),
                      ],
                      [
                        "USDC Wallet ( $ )",
                        parseFloat(this.state.usdcBalance).toFixed(2),
                        parseFloat(this.state.usdcLocked).toFixed(2),
                        parseFloat(this.state.usdcAmount).toFixed(2),
                      ]
                    ]}
                  />
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
export default withStyles(styles)(WalletBalance);
