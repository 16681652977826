import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
const styles = (theme) => ({
  loader: {
    position: "absolute",
    left: 600,
    top: 350,
    width: "100px !important",
    height: "100px !important",
    zIndex: 999,
  },
});
class Progress extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <CircularProgress
        className={classes.loader}
        color="secondary"
        disableShrink
      />
    );
  }
}
export default withStyles(styles)(Progress);
