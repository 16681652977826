/*!

=========================================================
* Material Dashboard PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

/* testing for setup */

import React,{Component} from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";

import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import "views/index.css";
const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/rtl" component={RtlLayout} />
      <Route path="/auth" component={AuthLayout} />
      <Route path="/admin" component={ProtectedComponent} />
      <Redirect from="/" to="/admin" />
      <Redirect from="*" to="/"/>
    </Switch>
  </Router>,
  document.getElementById("root")
);



function ProtectedComponent () {

 
  const token  = localStorage.getItem("session_token");
  const userType = localStorage.getItem("user_type");
  const validUserType = userType!==""&&userType!==null&&userType!==undefined;
  const valideToken = token!==""&&token!==null&&token!==undefined;

  return (validUserType && valideToken ? <AdminLayout userType={userType}/>
  :<Redirect  to ="/auth/login-page"/>)
  
}