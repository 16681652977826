import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import CustomInput from "components/CustomInput/CustomInput";
import React, { PureComponent } from "react";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from "components/CustomButtons/Button.js";

const styling = (theme) => {
  return { ...styles(theme), ...cssstyles };
};
class BankSettingDetails extends PureComponent {
  state = {
    paymentGateway: this.props.payment_gateway_id || "",
    minimumAmount: this.props.minimum_amount || "0.0",
    maximumAmount: this.props.maximum_amount || "0.0",
    fee: this.props.fee || "0.0",
  };

  componentDidUpdate(prevProps){
   const {payment_gateway_id:prevPaymentGatewayId,maximum_amount:prevMaximumAmount,minimum_amount:prevMinimumAmount,fee:prevFee} = prevProps;
   const {payment_gateway_id,maximum_amount,minimum_amount,fee} = this.props;
   if(prevMaximumAmount!==maximum_amount || prevPaymentGatewayId!==payment_gateway_id || prevMinimumAmount!==minimum_amount || prevFee!==fee){
     this.setState({
       paymentGateway:payment_gateway_id||"",
       maximumAmount:maximum_amount||"0.0",
       minimumAmount:minimum_amount||"0.0",
       fee:fee||"0.0",
     });
   }
  }

  handlePgChange = (event) => {
    const {payment_gateway_id,minimum_amount="0.0",maximum_amount="0.0",fee="0.0"} = this.props;
    const{value} = event.target;
    this.setState({
      paymentGateway: value,
      minimumAmount:payment_gateway_id === value?minimum_amount:"0.0",
      maximumAmount:payment_gateway_id === value?maximum_amount:"0.0",
      fee:payment_gateway_id === value?fee:"0.0",
    });
  };

  handleInputChange = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({
      [name]: value,
    });
  };

  getBankNameElement = () => {
    const { name } = this.props;
    return name;
  };

  getPaymentGateWayElement = () => {
    const { classes, allPaymentGateway = [] } = this.props;

    return (
      <FormControl className={classes.formControl} align="center" fullWidth>
        <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
          {"Gateway *"}
        </InputLabel>
        <Select
          value={this.state.paymentGateway}
          onChange={this.handlePgChange}
          classes={{
            select: classes.select,
          }}
          style={{textAlign:"left"}}
        >
          {allPaymentGateway.map((row) => (
            <MenuItem value={row.id} key={row.id}>
              {row.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  getMinimumAmountElement = () => {
    const { minimumAmount } = this.state;
    const handleChange = this.handleInputChange;

    return (
        <CustomInput
          labelText="Min Amount"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            name: "minimumAmount",
            value: minimumAmount,
            onChange: handleChange,
          }}
        />
    );
  };

  getMaximumAmountElement = () => {
    const { maximumAmount } = this.state;
    const handleChange = this.handleInputChange;
    return (
        <CustomInput
          labelText="Max Amount"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            name: "maximumAmount",
            value: maximumAmount,
            onChange: handleChange,
          }}
        />
    );
  };

  getFeeElement = () => {
    const { fee } = this.state;
    const handleChange = this.handleInputChange;

    return (
        <CustomInput
          labelText="Fee"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            name: "fee",
            value: fee,
            onChange: handleChange,
          }}
        />
    );
  };

  handleButtonClick = () => {
    const { code:bankCode, bankId, handleClick,id ,currency} = this.props;
    const {
      minimumAmount,
      maximumAmount,
      fee,
      paymentGateway,
    } = this.state;
    handleClick(
      bankCode,
      bankId,
      minimumAmount,
      maximumAmount,
      fee,
      paymentGateway,
      currency,
      id,
    );
  };

  getActionElement = () => {
    const {id} = this.props;
    return (
      <FormControl>
        <Button variant="contained" size="sm" onClick={this.handleButtonClick}>
          {id?"Update":"Add"}
        </Button>
      </FormControl>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <TableRow>
        <TableCell className={classes.tableCell}>
          {this.getBankNameElement()}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {this.getPaymentGateWayElement()}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {this.getMinimumAmountElement()}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {this.getMaximumAmountElement()}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {this.getFeeElement()}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {this.getActionElement()}
        </TableCell>
      </TableRow>
    );
  }
}

export default withStyles(styling)(BankSettingDetails);
