import React from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardText from "components/Card/CardText.js";
// import Table from "components/Table/Table.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import apiUrl from "../api";

const base_url = apiUrl;

class ShowFee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: "",
      client_id: "",
      ngnFee: "0.0",
      ghsFee: "0.0",
      kesFee: "0.0",
      inrFee: "0.0",
    };
  }

  componentDidMount() {
    this.getFees();
  }

  getFees = () => {
    if (
      localStorage.getItem("session_token") === "" ||
      localStorage.getItem("session_token") === null
    ) {
      return;
    }

    axios({
      method: "get",
      url: base_url + "/get_fee.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: { currency: "ngn" },
    })
      .then((result) => {
        // console.log(result);
        this.setState({
          ngnFee: result.data.fee,
        });
      })
      .catch((error) => {
        console.log("Error", error.response);
        // var text = "Error Loading";
      });

    axios({
      method: "get",
      url: base_url + "/get_fee.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: { currency: "ghs" },
    })
      .then((result) => {
        // console.log(result);
        this.setState({
          ghsFee: result.data.fee,
        });
      })
      .catch((error) => {
        console.log("Error", error.response);
        // var text = "Error Loading";
      });

    axios({
      method: "get",
      url: base_url + "/get_fee.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: { currency: "kes" },
    })
      .then((result) => {
        // console.log(result);
        this.setState({
          kesFee: result.data.fee,
        });
      })
      .catch((error) => {
        console.log("Error", error.response);
        // var text = "Error Loading";
      });

    axios({
      method: "get",
      url: base_url + "/get_fee.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: { currency: "inr" },
    })
      .then((result) => {
        // console.log(result);
        this.setState({
          inrFee: result.data.fee,
        });
      })
      .catch((error) => {
        console.log("Error", error.response);
        // var text = "Error Loading";
      });
  };

  render() {
    // const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} md={12} lg={12}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4>View Fee</h4>
                </CardText>
              </CardHeader>
              <br />
              <br />
              <GridContainer justify="space-between">
                <GridItem xs={12} sm={6} md={6} lg={3}>
                  <Card>
                    <CardHeader color="rose" text>
                      <CardText>
                        <h6>
                          NGN Fee <span>{"₦"}</span>
                        </h6>
                      </CardText>
                    </CardHeader>
                    <CardBody style={{ padding: "20px 20px" }}>
                      <div style={{ alignSelf: "center" }}>
                        {this.state.ngnFee}
                      </div>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={3}>
                  <Card>
                    <CardHeader color="rose" text>
                      <CardText>
                        <h6>
                          GHS Fee <span>{"₦"}</span>
                        </h6>
                      </CardText>
                    </CardHeader>
                    <CardBody style={{ padding: "20px 20px" }}>
                      <div style={{ alignSelf: "center" }}>
                        {this.state.ghsFee}
                      </div>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={3}>
                  <Card>
                    <CardHeader color="rose" text>
                      <CardText>
                        <h6>
                          KES Fee <span>{"₦"}</span>
                        </h6>
                      </CardText>
                    </CardHeader>
                    <CardBody style={{ padding: "20px 20px" }}>
                      <div style={{ alignSelf: "center" }}>
                        {this.state.kesFee}
                      </div>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={3}>
                  <Card>
                    <CardHeader color="rose" text>
                      <CardText>
                        <h6>
                          INR Fee <span>{"₦"}</span>
                        </h6>
                      </CardText>
                    </CardHeader>
                    <CardBody style={{ padding: "20px 20px" }}>
                      <div style={{ alignSelf: "center" }}>
                        {this.state.inrFee}
                      </div>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
export default withStyles(styles)(ShowFee);
