import React from "react";
// react component for creating dynamic tables
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
// @material-ui/icons
import CustomInput from "components/CustomInput/CustomInput.js";
// import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardText from "components/Card/CardText.js";
import CardHeader from "components/Card/CardHeader.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ReactTable from "react-table";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomSweet from "../Components/CustomSweet";
import Progress from "../Components/Progress";
import Close from "@material-ui/icons/Close";
import InputLabel from "@material-ui/core/InputLabel";
import apiUrl from "../api";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
// import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import Dvr from "@material-ui/icons/Dvr";
import UpdateIcon from "@material-ui/icons/Update";
import LockIcon from "@material-ui/icons/Lock";

const base_url = apiUrl;
const BAUSER = "ba";
const ADMINUSER = "admin";
const AGENTUSER = "agent";
const SUPPORTUSER = "support";
const TXNAGENT = "txnagent";
const WIRETXNAGENT = "wiretxnagent";
const CRYPTOAGENT = "cryptoagent";
const SUPERADMINUSER = "superadmin";
const listOfUserForBARequired = [ADMINUSER, SUPPORTUSER];
const listOfAgentForBARequired = [AGENTUSER,TXNAGENT,WIRETXNAGENT,CRYPTOAGENT];
const userType = localStorage.getItem("user_type");
const codeNotRequiredUserList = [BAUSER];

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      status: "",
      users: [],
      bausers: [],
      // user_id: 0,
      admin_user: false,
      agent_user: true,
      client_id: "",
      anchorEl: null,
      role: "",
      open: false,
      user_name: "",
      password: "",
      name: "",
      address: "",
      email: "",
      pincode: "",
      phone: "",
      code: "",
      selectedBA: "",
      clients: [],
      pass_open: false,
      new_password: "",
      confirm_password: "",
      actiontype: "",
      update_user_id: "",
      param: "",
      user_id: "",
      valid_confirm_password: "",
      valid_new_password: "",
      valid_code: "",
      valid_ba: true,
    };
    this.userTypes = [
      { name: "Super Admin", value: SUPERADMINUSER },
      { name: "BA", value: BAUSER },
      { name: "Admin", value: ADMINUSER },
      { name: "Agent", value: AGENTUSER },
      { name: "Txn Agent", value: TXNAGENT },
      { name: "Wire Txn Agent", value: WIRETXNAGENT },
      { name: "Crypto Agent", value: CRYPTOAGENT },
      { name: "Support", value: SUPPORTUSER },
    ];
  }
  handleClick = (event) => {
    // console.log("Targe", event.currentTarget);
    this.setState({ anchorEl: event.currentTarget });
  };
  handlePasswordModal = (event, id) => {
    this.setState({ pass_open: true, user_id: id });
  };
  handlePassClose = (event) => {
    this.setState({ pass_open: false, new_password: "", confirm_password: "" });
  };
  handleUserNameChange = (event) => {
    this.setState({ user_name: event.target.value, valid_user_name: "" });
  };
  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value, valid_password: "" });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleCloseDialogue = () => {
    this.cleanInputs();
    this.setState({ open: false });
  };
  handleAddressChange = (event) => {
    this.setState({ address: event.target.value, valid_address: "" });
  };
  handleEmailChange = (event) => {
    this.setState({ email: event.target.value, valid_email: "" });
  };
  handlePincodeChange = (event) => {
    this.setState({ pincode: event.target.value, valid_pincode: "" });
  };
  handlePhoneChange = (event) => {
    this.setState({ phone: event.target.value, valid_phone: "" });
  };
  handleCodeChange = (event) => {
    this.setState({ code: event.target.value, valid_code: "" });
  };
  handleMenuChange = (event) => {
    event.preventDefault();
    const role = event.target.value;
    this.setState({ role, selectedBA: "" });
    this.getUsers(role);

    // if ((role === AGENTUSER || role === TXNAGENT || role === WIRETXNAGENT) && userType !== SUPERADMINUSER) {
    //   this.getClients();
    // }
  };
  handleNameChange = (event) => {
    this.setState({ name: event.target.value, valid_name: "" });
  };
  handleOpen = () => {
    this.setState({ open: true, actiontype: "create" });
  };
  handleClientChange = (event) => {
    const { clients } = this.state;
    let client = clients.find((element) => {
      return element.id === event.target.value;
    })
    const baId = client.ba_id ? client.ba_id : "";

    this.setState({
      client_id: event.target.value,
      selectedBA: baId,
      valid_client_id: "",
    });
  };
  handleBaChange = (event) => {
    this.setState({ selectedBA: event.target.value, valid_ba: true,client_id:"" });
  };

  componentDidUpdate(prevProps,PrevState){
    const {selectedBA:prevBA} = PrevState;
    const {selectedBA,role} = this.state;
    if(prevBA !== selectedBA){
      if(userType === SUPERADMINUSER && listOfAgentForBARequired.includes(role)){
        this.getClients();
      }
    }
  }

  handlePasswordUpdate = () => {
    // event.preventDefault();
    // console.log(this.state.user_id);
    // const base_url = window.location.origin;
    var new_password = this.state.new_password;
    var confirm_password = this.state.confirm_password;
    if (new_password === "") {
      this.setState({
        valid_new_password: "false",
      });
      alert("Please enter new password");
      return;
    }
    if (confirm_password === "") {
      this.setState({
        valid_confirm_password: "false",
      });
      alert("Please enter confirm password");
      return;
    }
    if (new_password !== confirm_password) {
      this.setState({
        valid_confirm_password: "false",
        valid_new_password: "false",
      });
      alert("Passwords don't match");
      return;
    }
    axios({
      method: "put",
      url: base_url + "/users/update_password/" + this.state.user_id + ".json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        "Content-Type": "application/json",
      },
      params: { new_password: confirm_password },
    })
      .then((result) => {
        //access the results here....
        this.setState({ pass_open: false });
        this.setState({
          loading: false,
          alert: true,
          alert_msg: "User password updated successfully.!",
          alert_title: "User!",
          danger: false,
          warning: false,
          success: true,
          open: false,
          new_password: "",
          confirm_password: "",
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log("Error", error.response);
        this.setState({
          loading: false,
          alert: true,
          alert_msg: error,
          alert_title: "Error!",
          danger: true,
          warning: false,
          success: false,
        });
      });
  };

  handleUpdateUser = (event, id) => {
    this.setState({ loading: true, update_user_id: id });
    // const base_url = window.location.origin;
    axios({
      method: "get",
      url: base_url + "/users/" + id + ".json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        role: this.state.role,
      },
    })
      .then((result) => {
        // console.log(result);
        if (result.data) {
          this.setState({
            open: true,
            actiontype: "update",
            loading: false,
            user_name: result.data.username,
            name: result.data.name,
            email: result.data.email,
            address: result.data.address,
            pincode: result.data.pincode,
            phone: result.data.contact_no,
            code: result.data.code,
            client_id: result.data.client_id,
            selectedBA: result.data.ba_id,
          });
        }
      })
      .catch((error) => {
        this.setState({
          show_error: true,
          error_message: error.response.data
            ? error.response.data.message
            : error.message,
        });
        this.setState({ loading: false });
        // swal1("Search!", error.response.data.error, "error");
      });
  };

  getUsers = (role) => {
    // const base_url = window.location.origin;
    this.setState({ loading: true });
    axios({
      method: "get",
      url: base_url + "/users.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        role: role,
      },
    })
      .then((result) => {
        // console.log(result);
        if (result.data) {
          this.setState({
            users: role !== BAUSER ? result.data : this.state.users,
            bausers: role === BAUSER ? result.data : this.state.bausers,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          show_error: true,
          loading: false,
          error_message: error.response.data
            ? error.response.data.message
            : error.message,
        });
        // swal1("Search!", error.response.data.error, "error");
      });
  };

  getClients = () => {
     this.setState({ loading: true });
     const {selectedBA,role} = this.state;
      const param = userType === SUPERADMINUSER && listOfAgentForBARequired.includes(role)?{ba_id:selectedBA}:{};              
    // const base_url = window.location.origin;
    axios({
      method: "get",
      url: base_url + "/clients.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {...param},
    })
      .then((result) => {
        // console.log(result);
        if (result.data) {
          const { clients } = result.data;
          // const baId = clients.length > 0 ? clients[0].ba_id : "";

          this.setState({
            clients,
            loading: false
          });
        }
      })
      .catch((error) => {
        this.setState({
          show_error: true,
          loading: false,
          error_message: error.response.data
            ? error.response.data.message
            : error.message,
        });
        // swal1("Search!", error.response.data.error, "error");
      });
  };
  addPassword = (id) => {
    // const { classes } = this.props;

    return <div></div>;
  };
  confirmChange = (event) => {
    this.setState({
      confirm_password: event.target.value,
      valid_confirm_password: "",
    });
  };
  newChange = (event) => {
    this.setState({ new_password: event.target.value, valid_new_password: "" });
  };

  addShow = (row) => {
    // const { classes } = this.props;
    return (
      <div>
        <Button
          justIcon
          round
          simple
          color="warning"
          className="like"
          value={row.id}
          onClick={(e) => this.handleShow(e, row.id)}
        >
          <Dvr />
        </Button>{" "}
        <Button
          justIcon
          round
          simple
          className="like"
          color="success"
          onClick={(e) => this.handleUpdateUser(e, row.id)}
        >
          <UpdateIcon />
        </Button>{" "}
        <Button
          justIcon
          round
          simple
          className="like"
          color="danger"
          onClick={(e) => this.handleDelete(e, row.id)}
        >
          <Close />
        </Button>
        {""}
        <Button
          justIcon
          round
          simple
          className="like"
          color="primary"
          onClick={(e) => this.handlePasswordModal(e, row.id)}
        >
          <LockIcon />
        </Button>
        <Dialog
          open={this.state.pass_open}
          onClose={this.handlePassClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Reset Password"}</DialogTitle>
          <DialogContent>
            <div>
              <div>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={10}>
                    <CustomInput
                      labelText="New Password *"
                      error={this.state.valid_new_password === "false"}
                      id="password2"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "password",
                        autoComplete: "new-password",
                        value: this.state.new_password,
                        onChange: this.newChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </div>
              <div>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={10}>
                    <CustomInput
                      labelText="Confirm Password *"
                      error={this.state.valid_confirm_password === "false"}
                      id="password2"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "password",
                        autoComplete: "new-password",
                        value: this.state.confirm_password,
                        onChange: this.confirmChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handlePassClose}>Cancel</Button>
            <Button onClick={this.handlePasswordUpdate} color="rose" autoFocus>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  handleDelete = (event, id) => {
    event.preventDefault();
    // const base_url = window.location.origin;
    var confirmation = window.confirm(
      "Are you sure you want to delete setting?"
    );

    if (confirmation) {
      this.setState({
        loading: true,
      });
      axios({
        method: "put",
        url: base_url + "/users/delete/" + id + ".json",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        },
        params: {},
      })
        .then((result) => {
          const { role, bausers, users } = this.state;
          let usersObject = {};
          if (role === BAUSER) {
            const updatedList = bausers.filter((user) => user.id !== id);
            usersObject = { bausers: updatedList };
          } else {
            const updatedList = users.filter((user) => user.id !== id);
            usersObject = { users: updatedList };
          }

          //   console.log(this.state.param);
          this.setState(
            {
              loading: false,
              alert: true,
              alert_msg: "User deleted successfully.!",
              alert_title: "User Delete!",
              danger: false,
              warning: false,
              success: true,
              times: "0",
              valid_user_name: "",
              valid_password: "",
              valid_name: "",
              valid_address: "",
              valid_pincode: "",
              valid_phone: "",
              valid_client_id: "",
              valid_email: "",
              ...usersObject,
            },
            () => this.cleanInputs()
          );
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            loading: false,
            alert: true,
            alert_msg: error.response,
            alert_title: "Error!",
            danger: true,
            warning: false,
            success: false,
          });
        });
    }
  };

  handleCreateOrUpdateUser = (event) => {
    event.preventDefault();
    // const base_url = window.location.origin;
    const { actiontype, role, update_user_id } = this.state;
    const method = actiontype === "update" ? "put" : "post";
    const url =
      actiontype === "update" ? `/users/${update_user_id}.json` : "/users.json";
    const message = actiontype === "update" ? "updated" : "created";
    if (this.validateForm()) {
      this.setState({ loading: true });
      axios({
        method: method,
        url: base_url + url,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        },
        params: {
          "user[username]": this.state.user_name,
          "user[password]": this.state.password,
          "user[role]": role,
          "user[name]": this.state.name,
          "user[email]": this.state.email,
          "user[address]": this.state.address,
          "user[pincode]": this.state.pincode,
          "user[contact_no]": this.state.phone,
          "user[code]": role !== BAUSER ? this.state.code : "",
          "user[client_id]":
            role === AGENTUSER || role === TXNAGENT || role === WIRETXNAGENT || role === CRYPTOAGENT ? this.state.client_id : "",
          "user[ba_id]": role !== SUPERADMINUSER ? this.state.selectedBA : "",
        },
      })
        .then(() => {
          this.getUsers(this.state.role);
          this.setState(
            {
              loading: false,
              alert: true,
              alert_msg: `User ${message} successfully.!`,
              alert_title: "User!",
              danger: false,
              warning: false,
              success: true,
              open: false,
            },
            () => this.cleanInputs()
          );
        })
        .catch((error) => {
          console.log("Error", error);
          this.setState({
            loading: false,
            alert: true,
            alert_msg: "Something went wrong !!",
            alert_title: "User!",
            danger: true,
            warning: false,
            success: false,
          });
        });
    }
  };

  handleShow = (event, id) => {
    event.preventDefault();
    // console.log(id);
    window.open("/admin/show_user/" + id, "_blank");
  };

  cleanInputs() {
    this.setState({
      user_name: "",
      password: "",
      name: "",
      email: "",
      address: "",
      pincode: "",
      phone: "",
      code: "",
      client_id: "",
      selectedBA: "",
    });
  }

  validateForm() {
    const {
      name,
      user_name,
      password,
      email,
      address,
      phone,
      code,
      pincode,
      role,
      selectedBA,
      client_id,
    } = this.state;
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (user_name === "") {
      this.setState({ valid_user_name: "false" });
      alert("Please enter User Name!!");
      return false;
    }
    if (password === "" && this.state.actiontype === "create") {
      this.setState({ valid_password: "false" });
      alert("Please enter Password!!");
      return false;
    }
    if (name === "") {
      this.setState({ valid_name: "false" });
      alert("Please enter Name!!");
      return false;
    }
    if (email === "") {
      this.setState({ valid_email: "false" });
      alert("Please enter Email!!");
      return false;
    }
    if (reg.test(email) === false) {
      this.setState({ valid_email: "false" });
      alert("Invalid Email Address");
      return false;
    }
    if (selectedBA === "" && (listOfUserForBARequired.includes(role) ||(userType ===SUPERADMINUSER && listOfAgentForBARequired.includes(role)) )) {
      this.setState({ valid_ba: false });
      alert("Please select BA user !!!");
      return false;
    }
    if (address === "") {
      this.setState({ valid_address: "false" });
      alert("Please enter Address!!");
      return false;
    }

    if (pincode === "") {
      this.setState({ valid_pincode: "false" });
      alert("Please enter Pincode!!");
      return false;
    }

    if (phone === "") {
      this.setState({ valid_phone: "false" });
      alert("Please enter Contact Number!!");
      return false;
    }

    if ((role === AGENTUSER || role === TXNAGENT || role === WIRETXNAGENT || role === CRYPTOAGENT) && client_id === "") {
      this.setState({ valid_client_id: "false" });
      alert("Please enter Client!!");
      return false;
    }
    if (code === "" && role !== BAUSER) {
      this.setState({ valid_code: "false" });
      alert("Please enter Code!!");
      return false;
    }
    return true;
  }

  get getData() {
    let { role, users, bausers } = this.state;
    var x = role === BAUSER ? bausers : users;
    // console.log("x", x);
    return x.map((row) => ({
      id: row.id,
      username: row.username,
      email: row.email,
      client_name:
        role === AGENTUSER || role === TXNAGENT || role === WIRETXNAGENT || role === CRYPTOAGENT ? row.client_name : "",
      baName: listOfUserForBARequired.includes(role) ? row.ba_name : "",
      action: this.addShow(row),
    }));
  }

  hideAlert = (times) => {
    this.setState({
      alert: false,
      alert_msg: "",
      alert_title: "",
      alert_type: "",
    });
  };
  filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
      : true;
  };

  getUserTypes = (classes = {}) => {
    const userList = this.userTypes.filter((data) => {
      return userType === SUPERADMINUSER ? true : data.value !== SUPERADMINUSER;
    });

    return userList.map((data) => {
      return (
        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value={data.value}
          key={data.value}
        >
          {data.name}
        </MenuItem>
      );
    });
  };

  componentDidMount() {
    this.getUsers(BAUSER);
  }

  getBaUserDropDown = () => {
    const { role } = this.state;
    const { classes } = this.props;
    if (listOfUserForBARequired.includes(role)||(userType===SUPERADMINUSER && listOfAgentForBARequired.includes(role))) {
      return (
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel
            className={classes.selectLabel}
            error={!this.state.valid_ba}
          >
            {"BA *"}
          </InputLabel>

          <Select
            MenuProps={{
              className: classes.selectMenu,
            }}
            classes={{
              select: classes.select,
            }}
            value={this.state.selectedBA}
            onChange={this.handleBaChange}
          >
            {this.state.bausers.map((row) => (
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value={row.id}
                key={row.id}
              >
                {row.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
    return null;
  };

  render() {
    const { classes } = this.props;
    const userData = this.getData;
    const { role, loading, open, actiontype } = this.state;
    return (
      <div>
        <CustomSweet
          alert={this.state.alert}
          message={this.state.alert_msg}
          title={this.state.alert_title}
          hide_alert={this.hideAlert}
          danger={this.state.danger}
          warning={this.state.warning}
          success={this.state.success}
          times={this.state.times}
        />
        <div className={this.state.loading === true ? classes.main_screen : ""}>
          <div align="center">{loading && <Progress />}</div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Users Type
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={this.state.role}
                      onChange={this.handleMenuChange}
                      inputProps={{
                        name: "simpleSelect",
                        id: "users-select",
                      }}
                    >
                      {this.getUserTypes(classes)}
                    </Select>
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            {role !== "" && (
              <GridItem xs={12} sm={12} md={3}>
                <Card>
                  <CardBody>
                    <FormControl fullWidth>
                      <Button color="rose" onClick={this.handleOpen}>
                        {"Create " + this.state.role}
                      </Button>
                      <Dialog
                        open={open && role !== ""}
                        onClose={this.handleCloseDialogue}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {actiontype + " " + role}
                        </DialogTitle>
                        <DialogContent>
                          <CustomInput
                            labelText="Username *"
                            error={this.state.valid_user_name === "false"}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              value: this.state.user_name,
                              onChange: this.handleUserNameChange,
                              autoComplete: "new-password",
                            }}
                          />
                          {actiontype === "create" && (
                            <CustomInput
                              labelText="Password *"
                              error={this.state.valid_password === "false"}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                value: this.state.password,
                                onChange: this.handlePasswordChange,
                                autoComplete: "new-password",
                              }}
                            />
                          )}
                          <CustomInput
                            labelText="Name *"
                            error={this.state.valid_name === "false"}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              value: this.state.name,
                              onChange: this.handleNameChange,
                              autoComplete: "new-password",
                              // disableautocomplete
                            }}
                          />
                          <CustomInput
                            labelText="Email ID *"
                            error={this.state.valid_email === "false"}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              value: this.state.email,
                              onChange: this.handleEmailChange,
                              autoComplete: "new-password",
                            }}
                          />
                          {this.getBaUserDropDown()}
                          <CustomInput
                            labelText="Address *"
                            error={this.state.valid_address === "false"}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              value: this.state.address,
                              onChange: this.handleAddressChange,
                              autoComplete: "new-password",
                            }}
                          />
                          <CustomInput
                            labelText="Pincode *"
                            error={this.state.valid_pincode === "false"}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              value: this.state.pincode,
                              onChange: this.handlePincodeChange,
                              autoComplete: "new-password",
                            }}
                          />
                          <CustomInput
                            labelText="Contact Number *"
                            error={this.state.valid_phone === "false"}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              value: this.state.phone,
                              onChange: this.handlePhoneChange,
                              autoComplete: "new-password",
                            }}
                          />

                          {(role === AGENTUSER || role === TXNAGENT || role === WIRETXNAGENT || role === CRYPTOAGENT) && (
                            <FormControl
                              fullWidth
                              className={classes.selectFormControl}
                              error={this.state.valid_client_id === "false"}
                            >
                              <InputLabel
                                className={classes.selectLabel}
                                error={this.state.valid_client_id === "false"}
                              >
                                {"Client *"}
                              </InputLabel>

                              <Select
                                MenuProps={{
                                  className: classes.selectMenu,
                                }}
                                classes={{
                                  select: classes.select,
                                }}
                                value={this.state.client_id}
                                onChange={this.handleClientChange}
                                inputProps={{
                                  name: "simpleSelect",
                                }}
                              >
                                {this.state.clients.map((row) => (
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected,
                                    }}
                                    value={row.id}
                                    key={row.id}
                                  >
                                    {row.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                          {!codeNotRequiredUserList.includes(role) && (
                            <CustomInput
                              labelText="Code *"
                              error={this.state.valid_code === "false"}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                value: this.state.code,
                                onChange: this.handleCodeChange,
                                autoComplete: "new-password",
                              }}
                            />
                          )}
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={this.handleCloseDialogue}>
                            Cancel
                          </Button>
                          <Button
                            onClick={this.handleCreateOrUpdateUser}
                            color="rose"
                            autoFocus
                          >
                            {actiontype === "create" ? "Submit" : "Update"}
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </FormControl>
                  </CardBody>
                </Card>
              </GridItem>
            )}
          </GridContainer>
          {role !== "" && (
            <GridContainer>
              <GridItem xs={12}>
                <Card>
                  <CardHeader color="rose" text>
                    <CardText color="rose">
                      <h4> {"View all " + role + " Users"}</h4>
                    </CardText>
                  </CardHeader>
                  <CardBody>
                    <ReactTable
                      data={userData}
                      filterable
                      columns={[
                        {
                          Header: "ID",
                          accessor: "id",
                        },

                        {
                          Header: "Username",
                          accessor: "username",
                          filterMethod: this.filterMethod,
                        },
                        {
                          accessor: "email",
                          Header: "Email ID",
                          filterMethod: this.filterMethod,
                        },
                        {
                          accessor: "client_name",
                          Header: "Client Name",
                          show: role !== AGENTUSER && role !== TXNAGENT && role !== WIRETXNAGENT && role !== CRYPTOAGENT,
                          filterMethod: this.filterMethod,
                        },
                        {
                          accessor: "baName",
                          Header: "BA Name",
                          show: listOfUserForBARequired.includes(role),
                          filterMethod: this.filterMethod,
                        },
                        {
                          accessor: "action",
                          Header: "Action",
                          align: "left",
                          sortable: false,
                          filterable: false,
                        },
                      ]}
                      defaultPageSize={10}
                      showPaginationTop
                      showPaginationBottom={false}
                      className="-striped -highlight"
                      // defaultFilterMethod={this.filterMethod}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          )}
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(User);
