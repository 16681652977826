import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import CustomSweet from "../Components/CustomSweet";
import Progress from "../Components/Progress";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import SweetAlert from "react-bootstrap-sweetalert";
import alertstyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import apiUrl from "../api";
import { makeStyles } from "@material-ui/core";
import {
  getYearList,
  isObjectEmpty,
  monthsInYear,
  ValidateObjectAgainstSchema,
  ValidationTypes,
  makeApiCall,
  sortObjectAlphabatically,
} from "../../utils/commonCheckFunctions";

const AGENT = "agent";
const userType = localStorage.getItem("user_type");
const SUPERADMINUSER = "superadmin";

const customStyles = {
  expiryFormControl: {
    maxWidth: "110px",
  },
  expiryFormControlLabel: {
    fontSize: "14px",
  },
  marginCustomTop: {
    marginTop: "20px",
  },
};
const useStyle = makeStyles({ ...cssstyles, ...alertstyles, ...customStyles });

const schema = {
  selectedBA: userType === SUPERADMINUSER ? [ValidationTypes.required] : [],
  clientId: [ValidationTypes.required],
  clientTransactionId: [ValidationTypes.required],
  // accountNumber: [ValidationTypes.required],
  name: [ValidationTypes.required],
  email: [ValidationTypes.required, ValidationTypes.email],
  postalCode: [ValidationTypes.required],
  cardNumber: [ValidationTypes.required, ValidationTypes.maxLength(19)],
  expiryMonth: [ValidationTypes.required],
  expiryYear: [ValidationTypes.required],
  cardVerificationCode: [ValidationTypes.required],
  sourceCurrency: [ValidationTypes.required],
  destinationCurrency: [ValidationTypes.required],
  amount: [ValidationTypes.required],
};

const sourceCurrencyList = [
  { name: "USD", value: "usd" },
  { name: "GBP", value: "gbp" },
  { name: "CAD", value: "cad" },
  { name: "EUR", value: "eur" },
];

const destinationCurrencyList = [
  { name: "NGN", value: "ngn" },
  { name: "GHS", value: "ghs" },
  { name: "KES", value: "kes" },
  { name: "INR", value: "inr" },
  { name: "ZAR", value: "zar" },
  { name: "XOF", value: "xof" },
  { name: "PHP", value: "php" },
];

const getBAUserList = (classes, baUsers, handleChange, errors, selectedBA) => {
  if (userType === SUPERADMINUSER) {
    return (
      <FormControl
        fullWidth
        className={classes.selectFormControl}
        error={errors.selectedBA !== undefined}
      >
        <InputLabel className={classes.selectLabel}>{"BA *"}</InputLabel>

        <Select
          MenuProps={{
            className: classes.selectMenu,
          }}
          classes={{
            select: classes.select,
          }}
          value={selectedBA}
          onChange={handleChange}
          inputProps={{
            name: "selectedBA",
          }}
        >
          {baUsers.map((row) => (
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={row.id}
              key={row.id}
            >
              {row.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  } else {
    return null;
  }
};

const VisaDirectView = ({
  clientTransactionId,
  clientId,
  // accountNumber,
  name,
  expiryMonth,
  expiryYear,
  cardNumber,
  handleChange,
  sourceCurrency,
  destinationCurrency,
  postalCode,
  email,
  amount,
  cardVerificationCode,
  errors = {},
  handleSubmit,
  handleCancel,
  selectedBA,
  baUsers = [],
  allClients = [],
}) => {
  const classes = useStyle();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" text>
            <CardText color="rose">
              <h4>Create Visa Direct Transaction</h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <form>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10}>
                  {getBAUserList(
                    classes,
                    baUsers,
                    handleChange,
                    errors,
                    selectedBA
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    disabled={userType === AGENT}
                    error={errors.clientId !== undefined}
                  >
                    <InputLabel
                      className={classes.selectLabel}
                      error={errors.clientId !== undefined}
                    >
                      {"Clients *"}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={clientId}
                      onChange={handleChange}
                      inputProps={{
                        name: "clientId",
                      }}
                    >
                      {allClients.map((row) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={row.id}
                          key={row.id}
                        >
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <FormControl fullWidth>
                    <CustomInput
                      labelText="Client Id *"
                      error={errors.clientTransactionId !== undefined}
                      formControlProps={{
                        fullWidth: true,
                        error: errors.clientTransactionId !== undefined,
                      }}
                      inputProps={{
                        onChange: handleChange,
                        value: clientTransactionId,
                        autoComplete: "off",
                        name: "clientTransactionId",
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              {/* <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Account Number*"
                    error={errors.accountNumber !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.accountNumber !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: accountNumber,
                      autoComplete: "off",
                      name: "accountNumber",
                    }}
                  />
                </GridItem>
              </GridContainer> */}
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Name *"
                    error={errors.name !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.name !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: name,
                      autoComplete: "off",
                      name: "name",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Email *"
                    error={errors.email !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.email !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: email,
                      autoComplete: "off",
                      name: "email",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Postal Code *"
                    error={errors.postalCode !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.postalCode !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: postalCode,
                      autoComplete: "off",
                      name: "postalCode",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Card Number *"
                    error={errors.cardNumber !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.cardNumber !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: cardNumber,
                      autoComplete: "off",
                      type: "number",
                      name: "cardNumber",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer
                justify="center"
                className={classes.marginCustomTop}
              >
                <GridItem xs={12} md={3} sm={6}>
                  <FormControl
                    fullWidth
                    className={
                      (classes.selectFormControl, classes.expiryFormControl)
                    }
                    error={errors.expiryMonth !== undefined}
                  >
                    <InputLabel
                      className={
                        (classes.selectLabel, classes.expiryFormControlLabel)
                      }
                    >
                      {"Expiry Month*"}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={expiryMonth}
                      onChange={handleChange}
                      inputProps={{
                        name: "expiryMonth",
                      }}
                    >
                      {monthsInYear.map((row) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={row.value}
                          key={row.id}
                        >
                          {row.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} md={3} sm={6}>
                  <FormControl
                    fullWidth
                    className={
                      (classes.selectFormControl, classes.expiryFormControl)
                    }
                    error={errors.expiryYear !== undefined}
                  >
                    <InputLabel
                      className={
                        (classes.selectLabel, classes.expiryFormControlLabel)
                      }
                    >
                      {"Expiry Year*"}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={expiryYear}
                      onChange={handleChange}
                      inputProps={{
                        name: "expiryYear",
                      }}
                    >
                      {getYearList.year.map((row) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={row.fullYear}
                          key={row.id}
                        >
                          {row.fullYear}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <FormControl fullWidth>
                    <CustomInput
                      labelText="Card Verification Code *"
                      error={errors.cardVerificationCode !== undefined}
                      formControlProps={{
                        fullWidth: true,
                        error: errors.cardVerificationCode !== undefined,
                      }}
                      inputProps={{
                        onChange: handleChange,
                        value: cardVerificationCode,
                        autoComplete: "off",
                        name: "cardVerificationCode",
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer
                justify="center"
                className={classes.marginCustomTop}
              >
                <GridItem xs={12} md={3} sm={10}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    error={errors.sourceCurrency !== undefined}
                  >
                    <InputLabel className={classes.selectLabel}>
                      {"Source Currency*"}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={sourceCurrency}
                      onChange={handleChange}
                      inputProps={{
                        name: "sourceCurrency",
                      }}
                    >
                      {sourceCurrencyList.map((row) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={row.value}
                          key={row.value}
                        >
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} md={3} sm={10}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    error={errors.destinationCurrency !== undefined}
                  >
                    <InputLabel className={classes.selectLabel}>
                      {"Destination Currency*"}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={destinationCurrency}
                      onChange={handleChange}
                      inputProps={{
                        name: "destinationCurrency",
                      }}
                    >
                      {destinationCurrencyList.map((row) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={row.value}
                          key={row.value}
                        >
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Amount *"
                    error={errors.amount !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.amount !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: amount,
                      autoComplete: "off",
                      type: "number",

                      name: "amount",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer
                justify="center"
                className={classes.marginCustomTop}
              >
                <GridItem xs={12} sm={12} md={3} style={{ height: "50px" }}>
                  <FormControl fullWidth>
                    <Button onClick={handleCancel}>Cancel</Button>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} md={3} sm={12}>
                  <FormControl fullWidth>
                    <Button color="rose" onClick={handleSubmit}>
                      Proceed To Pay
                    </Button>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

const GetSweetAlert = ({ onConfirmation, onHide }) => {
  const classes = useStyle();
  return (
    <SweetAlert
      style={{ display: "block", marginTop: "-100px" }}
      title="Do you want to create Transaction ?"
      showCancel
      confirmBtnText="Yes"
      cancelBtnText="No"
      confirmBtnCssClass={classes.button + " " + classes.success}
      cancelBtnCssClass={classes.button + " " + classes.info}
      onConfirm={onConfirmation}
      onCancel={onHide}
    />
  );
};
const initialState = {
  name: "",
  // accountNumber: "",
  clientTransactionId: "",
  clientId: "",
  expiryMonth: "",
  expiryYear: "",
  cardNumber: "",
  cardVerificationCode: "",
  postalCode: "",
  email: "",
  amount: "",
  sourceCurrency: "",
  destinationCurrency: "",
  allClients: [],
  errors: {},
  selectedBA: "",
  baUsers: [],
  loading: false,
  sweetAlert: null,
};

const initialAlertState = {
  alert: false,
  success: false,
  error: false,
  warning: false,
  message: "",
  title: "",
};
const getParamList = ({
  name,
  // accountNumber,
  clientTransactionId,
  expiryMonth,
  expiryYear,
  cardNumber,
  postalCode,
  amount,
  sourceCurrency,
  destinationCurrency,
  clientId,
  email,
}) => {
  return {
    account_type: "Visa",
    card_number: cardNumber,
    // card_verification_code: "123",
    client_id: clientId,
    client_txn_id: clientTransactionId,
    // deposit_number: accountNumber,
    destination_currency: destinationCurrency,
    expiration_month: expiryMonth,
    expiration_year: expiryYear,
    name,
    recipient_email: email,
    recipient_postal_code: postalCode,
    source_amount: amount,
    source_currency: sourceCurrency,
  };
};

const getUpdatedClientlist = (list = []) => {
  const wallet = localStorage.getItem("walletStatus");
  if (userType === AGENT) {
    const { id } = JSON.parse(wallet);
    return { allClients: list, clientId: id };
  } else {
    return { allClients: list };
  }
};

const VisaDirectTransaction = () => {
  const [viewState, setViewState] = useState(initialState);
  const [alertState, setAlertState] = useState(initialAlertState);
  const classes = useStyle();

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "selectedBA") {
      setViewState((prevState) => ({
        ...prevState,
        [name]: value,
        client: "",
      }));
    } else {
      setViewState((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  useEffect(() => {
    if (userType !== SUPERADMINUSER) {
      getClients();
    } else {
      getAllBaUsers();
    }
  }, []);

  useEffect(() => {
    if (viewState.selectedBA) {
      getClients();
    }
  }, [viewState.selectedBA]);

  const onHide = () => {
    setViewState((prevState) => ({ ...prevState, sweetAlert: null }));
  };
  const getClients = async () => {
    const params =
      userType === SUPERADMINUSER ? { ba_id: viewState.selectedBA } : {};
    const data = await makeApiCall({
      url: "/clients.json",
      method: "get",
      params: params,
    });
    const { error = false, message } = data;
    if (!error) {
      const { clients } = data;
      const clientList = Array.isArray(clients) ? clients : [clients];
      // this.getClientData(result.data.clients);
      const clientData = getUpdatedClientlist(clientList);
      setViewState((prevState) => ({ ...prevState, ...clientData }));
    } else {
      console.log(message);
    }
  };

  const getAllBaUsers = async () => {
    const data = await makeApiCall({
      url: "/users.json",
      method: "get",
      params: { role: "ba" },
    });
    const { error = false } = data;
    if (!error) {
      setViewState((prevState) => ({
        ...prevState,
        baUsers: data,
      }));
    } else {
      const { message } = data;
      console.log(message);
    }
  };

  const hideAlert = () => {
    setAlertState(initialAlertState);
  };

  const handleCleanform = () => {
    setViewState((prevState) => ({
      ...initialState,
      allClients: userType === SUPERADMINUSER ? [] : prevState.allClients,
    }));
  };

  const handleSubmit = () => {
    const { isValid, errors } = validateForm();
    setViewState({ ...viewState, errors });
    // console.log("submit button clicked!!!");
    // console.log(errors);

    if (isValid) {
      // console.log(viewState);
      // console.log(getParamList(viewState));
      const view = (
        <GetSweetAlert onConfirmation={onConfirmation} onHide={onHide} />
      );
      setViewState((prevState) => ({ ...prevState, sweetAlert: view }));
    }
  };

  const onConfirmation = () => {
    createSignature();
  };

  const createSignature = async () => {
    setViewState({ ...viewState, loading: true });
    let params = getParamList(viewState);
    const data = await makeApiCall({
      url: "/transactions/create_visa_signature.json",
      method: "post",
      params,
    });
    const { error = false, message } = data;
    if (error) {
      console.log(message);
      setViewState({ ...viewState, loading: false });
      setAlertState({
        alert: true,
        success: false,
        error: true,
        warning: false,
        message: "Something went wrong!!!",
        title: "Error",
      });
    } else {
      const { api_token, tonce, signature } = data;
      params = {
        ...params,
        api_token: api_token,
        tonce: tonce,
        signature: signature,
      };
      delete params.client_id; //as client_id is not required for transaction
      params = sortObjectAlphabatically(params);
      createTransaction(params);
    }
  };

  const createTransaction = async (params = {}) => {
    const data = await makeApiCall({
      url: "/api/v1/transactions_visa_direct",
      method: "post",
      params,
    });
    const { error, message } = data;
    if (error) {
      console.log(message);
      let val = "Something Went wrong !!!";
      if (message.response.data.error) {
        const {
          response: { data },
        } = message;
        const {
          error: { message: errorMessage },
        } = data;
        val = errorMessage;
      }
      setViewState({ ...viewState, loading: false });
      setAlertState({
        alert: true,
        success: false,
        error: true,
        warning: false,
        message: val,
        title: "Error",
      });
    } else {
      setAlertState({
        alert: true,
        success: true,
        error: false,
        warning: false,
        message: "Transaction Created Successfully!!!",
        title: "Success",
      });
      setViewState((prevState) => ({
        ...initialState,
        allClients: prevState.allClients,
      }));
    }
  };

  const validateForm = () => {
    const errors = ValidateObjectAgainstSchema.validateObject(
      viewState,
      schema
    );
    return { isValid: isObjectEmpty(errors), errors };
  };

  return (
    <>
      <CustomSweet
        alert={alertState.alert}
        message={alertState.message}
        title={alertState.title}
        hide_alert={hideAlert}
        danger={alertState.error}
        success={alertState.success}
      />
      {viewState.sweetAlert}
      <div className={viewState.loading ? classes.main_screen : ""}>
        <div align="center">{viewState.loading && <Progress />}</div>
        <VisaDirectView
          {...viewState}
          handleCancel={handleCleanform}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      </div>
    </>
  );
};

export default VisaDirectTransaction;
