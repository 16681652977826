import React from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import apiUrl from "../api";
import CardBody from "components/Card/CardBody";
import ReactTable from "react-table";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

import { Currencies } from "../../constants/currencies.js";
import { makeApiCall } from "utils/commonCheckFunctions";

const base_url = apiUrl;
const  userType = localStorage.getItem("user_type");
const SUPERADMINUSER = "superadmin";

var variable = "12 Jan 2019";
var myVariable = "1 Jun 2020";

const today = new Date(myVariable);
const yesterday = new Date(variable);

yesterday.setDate(today.getDate() - 1);

const gateways = ["rubiesbank", "nibsspay"];

class ViewBalance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      currencies: [],
      columns: [],
      // nibssPayDetails: [],
      // rubiesPayDetails: [],
      balance: {
        // moneywave: {
        //   ngn: 0.0,
        //   ghs: 0.0,
        //   kes: 0.0,
        // },
        fundgate: {
          // ngn: 0.0,
          ghs: 0.0,
        },
        // nibsspay: {
        //   ngn: 0.0,
        // },
        // rubiesbank: {
        //   ngn: 0.0,
        // },
        transferzero: {
          usd: 0.0,
        },
        bloomremit: {
          php: 0.0,
        },
        ezipay:{
          xof: 0.0,
          ghs: 0.0,
        },
        emqservice:{
          usd: 0.0
        },
        paga:{
          ngn: 0.0
        },
        // terrapay: {
        //   usd: 0.0
        // },
        bitcoinvn: {
          usd: 0.0
        },
        more: {
          usd: 0.0
        },
        // instantcash: {
        //   usd: 0.0
        // }
      },
      wallet: {
        moneyline: {
          balance: 0,
          locked: 0,
          amount: 0,
        },
      },

      baUsers:[],
      selectedBA:"",
    };
    this.isComponentMounted = false;
  }
  componentDidMount() {
    this.isComponentMounted = true;
    if(userType!==SUPERADMINUSER){
      this.fetchData();
    }else{
    this.getAllBaUsers();
    }
  }

  componentDidUpdate(prevProps,prevState){
    const {selectedBA:prevSelectedBA} = prevState;
    const {selectedBA} = this.state;
    if(selectedBA!==prevSelectedBA && userType === SUPERADMINUSER){
      this.fetchData();
    }
  }

  fetchData = ()=>{
    this.fetchCurrencies();
    this.fetchClients();

    for (let gateway in this.state.balance) {
      for (let currency in this.state.balance[gateway]) {
        this.fetchBalance(gateway, currency);
      }
    }
  }


  handleNameChange=(event)=>{
    const {name,value} = event.target;
    this.setState({
      [name]:value,
    })
   }
 
   getAllBaUsers = async () => {
     const data = await makeApiCall({
       url: "/users.json",
       method: "get",
       params: { role: "ba" },
     });
     const { error = false } = data;
     if (!error) {
       this.setState({
         baUsers:data,
       });
     } else {
       const { message } = data;
       console.log(message);
     }
   };
 
   getBAUserList = () => {
     const { classes } = this.props;
     if (userType === SUPERADMINUSER) {
       return (
         <GridContainer>
           
         <GridItem xs={12} md={3} sm={6} style={{ marginTop: "10px" }}>
         <Card>
             <CardBody>
               <FormControl
                 fullWidth
                 className={classes.selectFormControl}
               >
                 <InputLabel className={classes.selectLabel}>
                   {"BA *"}
                 </InputLabel>
 
                 <Select
                   MenuProps={{
                     className: classes.selectMenu,
                   }}
                   classes={{
                     select: classes.select,
                   }}
                   value={this.state.selectedBA}
                   onChange={this.handleNameChange}
                   inputProps={{
                     name: "selectedBA",
                   }}
                 >
                   {this.state.baUsers.map((row) => (
                     <MenuItem
                       classes={{
                         root: classes.selectMenuItem,
                         selected: classes.selectMenuItemSelected,
                       }}
                       value={row.id}
                       key={row.id}
                     >
                       {row.name}
                     </MenuItem>
                   ))}
                 </Select>
               </FormControl>
               </CardBody>
               </Card>
         </GridItem>
 
         </GridContainer>
       );
     } else {
       return null;
     }
   }; 

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  fetchClients = () => {
    const params = userType === SUPERADMINUSER?{ba_id:this.state.selectedBA}:{};
    axios({
      method: "get",
      url: base_url + "/clients.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {...params},
    })
      .then((result) => {
        if (result.data && this.isComponentMounted) {
          const clients = result.data.clients.filter(
            (client) => client.is_wallet_enabled !== false
          );
          this.setState(
            {
              clients: clients,
            }
            // () => this.fetchMoneylineWalletBalance()
          );
        }
      })
      .catch((error) => {});
  };
  fetchCurrencies = () => {
    const params = userType === SUPERADMINUSER?{ba_id:this.state.selectedBA}:{};
    axios({
      method: "get",
      url: base_url + "/show_walletable_currencies.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {...params},
    })
      .then((result) => {
        if (result.data && this.isComponentMounted) {
          let res = [];
          res.push({
            accessor: "client_name",
            Header: "CLIENT NAME",
            sortable: false,
            filterable: false,
          });
          result.data.currencies.forEach(function(currency) {
            res.push({
              accessor: currency.attributes.name + "_wallet_balance",
              Header: String(currency.attributes.name.toUpperCase()),
              sortable: false,
              filterable: false,
            });
          });
          this.setState({
            currencies: result.data,
            columns: res,
          });
        }
      })
      .catch((error) => {});
  };

  fetchMoneylineWalletBalance = () => {
    if (this.state.clients.length > 0) {
      const client = this.state.clients.filter(
        (client) => client.name.toLowerCase() === "moneyline"
      );
      axios({
        method: "get",
        url: base_url + "/get_wallet_balance.json",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        },
        params: {
          client_id: client[0].id,
          currency: "ngn",
        },
      })
        .then((result) => {
          if (result.data) {
            let moneyline = {
              balance: isNaN(result.data.wallet.balance)
                ? "0.00"
                : result.data.wallet.balance,
              amount: isNaN(result.data.wallet.amount)
                ? "0.00"
                : result.data.wallet.amount,
              locked: isNaN(result.data.wallet.locked)
                ? "0.00"
                : result.data.wallet.locked,
            };

            this.setState({
              wallet: { ...this.state.wallet, moneyline },
            });
          }
        })
        .catch((error) => {});
    }
  };
  fetchBalance = (gateway, currency) => {
    const params = userType === SUPERADMINUSER?{ba_id:this.state.selectedBA}:{};
    axios({
      method: "get",
      url: base_url + `/get_balance/${gateway}.json`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: { currency,...params },
    })
      .then((result) => {
        if (!gateways.includes(gateway) && this.isComponentMounted) {
          let balance = {
            [gateway]: {
              ...this.state.balance[gateway],
              ...{
                [currency]: isNaN(result.data.balance)
                  ? "0.0"
                  : parseFloat(result.data.balance)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              },
            },
          };

          this.setState((prevState) => ({
            balance: { ...prevState.balance, ...balance },
          }));
        }
      })
      .catch((error) => {});
  };

  getUpdatedBalanceForPaymentGateways = (data) => {
    const { balance, balance_details } = data;

    if (balance_details) {
      return data.balance_details;
    } else if (balance) {
      return [data];
    }
    return [];
  };

  getWalletBalance = (data, currency) => {
    const currencyId = Currencies[currency];
    const wallet = data.wallets.filter(
      (wallet) => wallet.currency_id === currencyId
    );

    if (wallet.length > 0) {
      return (
        <div>
          <p>
            {parseFloat(wallet[0].balance)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </p>
        </div>
      );
    } else {
      return "0.0";
    }
  };

  /*fetching of currecies id can be done using loop*/
  get getData() {
    var x = this.state.clients;
    return x.map((row) => ({
      client_name: row.name,
      ngn_wallet_balance: this.getWalletBalance(row, "ngn"),
      ghs_wallet_balance: this.getWalletBalance(row, "ghs"),
      kes_wallet_balance: this.getWalletBalance(row, "kes"),
      inr_wallet_balance: this.getWalletBalance(row, "inr"),
      php_wallet_balance: this.getWalletBalance(row, "php"),
      zar_wallet_balance: this.getWalletBalance(row, "zar"),
      xof_wallet_balance: this.getWalletBalance(row, "xof"),
    }));
  }
  getBalancesRows = () => {
    return [
      // [
      //   "MONEYWAVE NGN BALANCE ( ₦ )",
      //   "N/A",
      //   this.state.balance["moneywave"]["ngn"],
      // ],
      // [
      //   "MONEYWAVE GHS BALANCE ( GH₵ )",
      //   "N/A",
      //   this.state.balance["moneywave"]["ghs"],
      // ],
      // [
      //   "MONEYWAVE KES BALANCE ( KES )",
      //   "N/A",
      //   this.state.balance["moneywave"]["kes"],
      // ],
      // [
      //   "FUNDGATE NGN BALANCE ( ₦ )",
      //   "N/A",
      //   this.state.balance["fundgate"]["ngn"],
      // ],
      [
        "FUNDGATE GHS BALANCE ( GH₵ )",
        "N/A",
        this.state.balance["fundgate"]["ghs"],
      ],
      [
        "Bloomremit PHP BALANCE ",
        "N/A",
        this.state.balance["bloomremit"]["php"],
      ],
      [
        "Ezipay GHS BALANCE ",
        "N/A",
        this.state.balance["ezipay"]["ghs"],
      ],
      [
        "Ezipay XOF BALANCE ",
        "N/A",
        this.state.balance["ezipay"]["xof"],
      ],
      // ...this.getNibsspayDetails(),
      // ...this.getRubiespayDetails(),
      [
        "TRANSFER ZERO BALANCE ( $ )",
        "N/A",
        this.state.balance["transferzero"]["usd"],
      ],
      [
        "Emqservice BALANCE ( $ )",
        "N/A",
        this.state.balance["emqservice"]["usd"],
      ],
      [
        "PAGA NGN BALANCE ( ₦ )",
        "N/A",
        this.state.balance["paga"]["ngn"]
      ],
      // [
      //   "Terrapay BALANCE ( $ )",
      //   "N/A",
      //   this.state.balance["terrapay"]["usd"]
      // ],
      [
        "Bitcoinvn BALANCE ( $ )",
        "N/A",
        this.state.balance["bitcoinvn"]["usd"]
      ],
      [
        "More BALANCE ( $ )",
        "N/A",
        this.state.balance["more"]["usd"]
      ],
      // [
      //   "InstantCash BALANCE ( $ )",
      //   "N/A",
      //   this.state.balance["instantcash"]["usd"]
      // ]
    ];
  };

  // getNibsspayDetails = () => {
  //   const { nibssPayDetails } = this.state;
  //   return nibssPayDetails.map((data) => {
  //     return [
  //       "NIBSSPAY NGN BALANCE ( ₦ )",
  //       data.account_number,
  //       isNaN(data.balance)
  //         ? "0.0"
  //         : parseFloat(data.balance)
  //             .toFixed(2)
  //             .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
  //     ];
  //   });
  // };

  // getRubiespayDetails = () => {
  //   const { rubiesPayDetails } = this.state;
  //   return rubiesPayDetails.map((data) => {
  //     return [
  //       "RUBIESBANK NGN BALANCE ( ₦ )",
  //       data.account_number,
  //       isNaN(data.balance)
  //         ? "0.0"
  //         : parseFloat(data.balance)
  //             .toFixed(2)
  //             .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
  //     ];
  //   });
  // };
  get getBalanceData() {
    const dataRow = this.getBalancesRows();
    return dataRow.map((row) => ({
      name: row[0],
      account_number: row[1],
      balance: row[2],
    }));
  }
  render() {
    const balanceData = this.getData;
    const pgbalanceData = this.getBalanceData;
    return (
      <div>
        {this.getBAUserList()}
        <GridContainer>
          <GridItem xs={12} md={12} lg={12}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4>View Payment Gateway Balance</h4>
                </CardText>
              </CardHeader>
              <br />
              <br />

              <GridContainer justify="space-between">
                <GridItem xs={12} sm={12} md={12}>
                  <ReactTable
                    data={pgbalanceData}
                    filterable
                    columns={[
                      {
                        Header: "NAME",
                        accessor: "name",
                        filterable: false,
                        sortable: false,
                      },
                      {
                        accessor: "account_number",
                        Header: "ACCOUNT NUMBER",
                        filterable: false,
                        sortable: false,
                      },
                      {
                        accessor: "balance",
                        Header: "BALANCE",
                        filterable: false,
                        sortable: false,
                      },
                    ]}
                    defaultPageSize={20}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} md={12} lg={12}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4>View Client Wallet Balances</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={balanceData}
                  filterable
                  columns={this.state.columns}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
export default withStyles(styles)(ViewBalance);
