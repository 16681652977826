import apiUrl from "../views/api";
import axios from "axios";

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export const isObjectEmpty = (empty) => {
  return (
    empty && Object.keys(empty).length === 0 && empty.constructor === Object
  );
};

export const formatDate = (date) => {
  return (
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
  );
};

export const formatTime = (date) => {
  return date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
}
export const isString = (x) => {
  return Object.prototype.toString.call(x) === "[object String]";
};

export const isValidObject = (x) => {
  return Object.prototype.toString.call(x) === "[object Object]";
};

export const monthsInYear = [
  {
    id: 1,
    value: "01",
    Name: "Jan",
    fullName: "January",
  },
  {
    id: 2,
    value: "02",
    Name: "Feb",
    fullName: "February",
  },
  {
    id: 3,
    value: "03",
    Name: "Mar",
    fullName: "March",
  },
  {
    id: 4,
    value: "04",
    Name: "Apr",
    fullName: "April",
  },
  {
    id: 5,
    value: "05",
    Name: "May",
    fullName: "May",
  },
  {
    id: 6,
    value: "06",
    Name: "Jun",
    fullName: "June",
  },
  {
    id: 7,
    value: "07",
    Name: "Jul",
    fullName: "July",
  },
  {
    id: 8,
    value: "08",
    Name: "Aug",
    fullName: "August",
  },
  {
    id: 9,
    value: "09",
    Name: "Sep",
    fullName: "September",
  },
  {
    id: 10,
    value: "10",
    Name: "Oct",
    fullName: "October",
  },
  {
    id: 11,
    value: "11",
    Name: "Nov",
    fullName: "November",
  },
  {
    id: 12,
    value: "12",
    Name: "Dec",
    fullName: "December",
  },
];

export const getYearList = (function() {
  const yearList = [];
  let currentYear = new Date().getFullYear();
  const maxYear = parseInt((currentYear + 40) / 10) * 10;
  for (let i = currentYear; i <= maxYear; i++) {
    yearList.push({
      id: i,
      year: i % 100,
      fullYear: i,
    });
  }
  return {
    year: yearList,
  };
})();

export const ValidationTypes = {
  email: "email",
  required: "required",
  empty: "empty",
  objectNotEmpty: "notempty",
  number: "number",
  maxLength: (length = 0) => ({ name: "maxLength", length: length }),
  minLength: (length = 1) => ({ name: "minLength", length: length }),
};
export const ValidateObjectAgainstSchema = (function() {
  const Validation = {
    required: (value, name) => {
      const isValid = value !== "" && value !== null && value !== undefined;
      const error = isValid ? {} : { [name]: `${name} is Required !!!` };
      return error;
    },
    email: (value, name) => {
      const isValid = emailRegex.test(value);
      const error = isValid ? {} : { [name]: `Enter valid ${name} !!!` };
      return error;
    },
    empty: (value, name) => {
      const isValid = isObjectEmpty(value);
      const error = isValid
        ? {}
        : { [name]: `${name} is not an empty object !!!` };
      return error;
    },
    notempty: (value, name) => {
      const isValid = !isObjectEmpty(value);
      const error = isValid ? {} : { [name]: `${name} is an empty object !!!` };
      return error;
    },
    maxLength: (value, name, rest) => {
      const { length = 0 } = rest;
      const isValid = value.toString().length <= length;
      const error = isValid
        ? {}
        : { [name]: `${name} should have max length of ${length} !!!` };
      return error;
    },

    minLength: (value, name, rest) => {
      const { length = 0 } = rest;
      const isValid = value.toString().length >= length;
      const error = isValid
        ? {}
        : { [name]: `${name} should have min length of ${length} !!!` };
      return error;
    },
  };

  const validateObject = (dataToBeValidated = {}, schema = {}) => {
    let errors = {};
    for (let key of Object.keys(schema)) {
      const value = dataToBeValidated[key];
      const isValidSchema =
        schema[key] !== undefined && Array.isArray(schema[key]);
      if (process.env.NODE_ENV !== "production" && !isValidSchema) {
        console.warn("Schema type is not valid !!!");
      }
      const validationsToRun = isValidSchema ? schema[key] : [];
      let error = {};
      for (let el of validationsToRun) {
        const validString = isString(el);
        if (validString) {
          error = Validation[el](value, key);
        } else {
          const { name, ...rest } = el;
          error = Validation[name](value, key, rest);
        }
        const empty = isObjectEmpty(error);
        errors = { ...errors, ...error };
        if (!empty) {
          break;
        }
      }
    }
    return errors;
  };
  return {
    validateObject: validateObject,
  };
})();

export const makeApiCall = async ({
  url = "",
  method = "get",
  params = {},
}) => {
  try {
    const result = await axios({
      method: method,
      url: apiUrl + url,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: params,
    });
    return result.data;
  } catch (err) {
    return {
      error: true,
      message: err,
    };
  }
};

export const sortObjectAlphabatically = (el = {}) => {
  const validObject = isValidObject(el);
  if (validObject) {
    const sortedObject = Object.keys(el)
      .sort()
      .reduce((prevValue, currentValue) => {
        prevValue[currentValue] = el[currentValue];
        return prevValue;
      }, {});
    return sortedObject;
  }
  throw new Error("Parameter is not a Valid Object");
};
