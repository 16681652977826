import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import moment from "moment";
import apiUrl from "../api";
import CardText from "components/Card/CardText.js";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
// import CustomInput from "components/CustomInput/CustomInput.js";
import Progress from "../Components/Progress";
import { makeApiCall } from "utils/commonCheckFunctions";

const base_url = apiUrl;
const userType = localStorage.getItem("user_type");
const SUPERADMINUSER = "superadmin";


let d = new Date();
d.setDate(d.getDate() - 1);
d.setHours(0);
d.setMinutes(0);
d.setSeconds(1);
let prev_date = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
//   " "
// let prev_time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();

let tempDate = new Date();
tempDate.setHours(23);
tempDate.setMinutes(59);
tempDate.setSeconds(59);
let date =
  tempDate.getFullYear() +
  "-" +
  (tempDate.getMonth() + 1) +
  "-" +
  tempDate.getDate();
//   " ";
// let time =
//   tempDate.getHours() +
//   ":" +
//   tempDate.getMinutes() +
//   ":" +
//   tempDate.getSeconds();

class CurrencyConversionLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: 0,
      selectedDate: prev_date,
      selectedendDate: date,
      destination_currency: "",
      source_currency: "",
      conversion_logs: [],
      selectedBA:"",
      baUsers:[],
    };
    this.sourceCurrencyList = [
      { name: "Select Source Currency", value: "all" },
      { name: "USD", value: "usd" },
      { name: "GBP", value: "gbp" },
      { name: "CAD", value: "cad" },
      { name: "EUR", value: "eur" },
    ];
    this.destinationCurrencyList = [
      { name: "Select Destination Currency", value: "all" },
      { name: "NGN", value: "ngn" },
      { name: "GHS", value: "ghs" },
      { name: "KES", value: "kes" },
      { name: "INR", value: "inr" },
      { name: "BRL", value: "brl" },
    ];
  }

  componentDidMount(){
    if(userType === SUPERADMINUSER){
      this.getAllBaUsers();
    }
  }
  handeleBaChange = (event)=>{
   const {name,value} = event.target;
   
   this.setState({
     [name]:value
   })

  }

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };
  handleEndDateChange = (date) => {
    this.setState({ selectedendDate: date });
  };
  handleDestinationChange = (event) => {
    this.setState({ destination_currency: event.target.value });
  };
  handleSourceChange = (event) => {
    this.setState({ source_currency: event.target.value });
  };
  onSearch = (event) => {
    event.preventDefault();
    // const base_url = window.location.origin;
    const startDate = moment(this.state.selectedDate).format("YYYY-MM-DD");
    const endDate = moment(this.state.selectedendDate).format("YYYY-MM-DD");
    const {selectedBA} = this.state;
    const isSuperAdmin = userType === SUPERADMINUSER;
    const params = isSuperAdmin?{ba_id:selectedBA}:{};
    if(isSuperAdmin && selectedBA===""){
      alert("Please select BA !!!");
      return;
    }
    this.setState({ loading: true });
    axios({
      method: "get",
      url: base_url + "/conversion_time_logs.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        start_date: startDate,
        end_date: endDate,
        source_currency:
          this.state.source_currency === "" ? null : this.state.source_currency,
        destination_currency:
          this.state.destination_currency === ""
            ? null
            : this.state.destination_currency,
            ...params,
      },
    })
      .then((result) => {
        if (result.data) {
          //   console.log("Data", result.data);
          this.setState({
            conversion_logs: result.data.conversion_time_logs,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          show_error: true,
          error_message: error.response.data
            ? error.response.data.message
            : error.message,
        });
        console.log("Error", error);
        this.setState({ loading: false });
      });
  };
  get getData() {
    return this.state.conversion_logs.map((row, index) => ({
      id: row.id,
      user_name: row.user_name,
      source_currency: row.source_currency,
      destination_currency: row.destination_currency,
      value: row.value,
      created_at: row.created_at,
      created_time: row.created_time,
    }));
  }
  filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
      : true;
  };

  getSourceCurrency = () => {
    const { classes } = this.props;
    return this.sourceCurrencyList.map((data) => {
      return (
        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value={data.value}
          key={data.value}
        >
          {data.name}
        </MenuItem>
      );
    });
  };

  getDestinationCurrency = () => {
    const { classes } = this.props;
    return this.destinationCurrencyList.map((data) => {
      return (
        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value={data.value}
          key={data.value}
        >
          {data.name}
        </MenuItem>
      );
    });
  };

  getAllBaUsers = async () => {
    const data = await makeApiCall({
      url: "/users.json",
      method: "get",
      params: { role: "ba" },
    });
    const { error = false } = data;
    if (!error) {
      this.setState({
        baUsers: data,
      });
    } else {
      const { message } = data;
      console.log(message);
    }
  };

  getBAUserList = () => {
    const { classes } = this.props;
    if (userType === SUPERADMINUSER) {
      return (
        <GridItem xs={12} sm={12} md={3}>
          <Card>
                <CardBody style={{ padding: "10px 20px" }}>
              <FormControl
                fullWidth
                className={classes.selectFormControl}
              >
                <InputLabel className={classes.selectLabel}>
                  {"BA *"}
                </InputLabel>

                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={this.state.selectedBA}
                  onChange={this.handeleBaChange}
                  inputProps={{
                    name: "selectedBA",
                  }}
                >
                  {this.state.baUsers.map((row) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={row.id}
                      key={row.id}
                    >
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              </CardBody>
              </Card>
        </GridItem>
      );
    } else {
      return null;
    }
  };

  render() {
    const { classes } = this.props;
    const timelogData = this.getData;
    return (
      <div>
        <div className={this.state.loading === true ? classes.main_screen : ""}>
          <div align="center">
            {this.state.loading === true && <Progress />}
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={2}>
              <Card>
                <CardBody style={{ padding: "5px 20px" }}>
                  <InputLabel className={classes.label}>Start Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      inputProps={{ placeholder: "Start Date" }}
                      id="start-date-picker-dialog"
                      label="Start Date"
                      dateFormat={"YYYY-MM-DD"}
                      value={this.state.selectedDate}
                      onChange={this.handleDateChange}
                    />
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={2}>
              <Card>
                <CardBody style={{ padding: "5px 20px" }}>
                  <InputLabel className={classes.label}>End Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      inputProps={{ placeholder: "End Date" }}
                      id="end-date-picker-dialog"
                      label="End Date"
                      dateFormat={"YYYY-MM-DD"}
                      value={this.state.selectedendDate}
                      onChange={this.handleEndDateChange}
                    />
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            {this.getBAUserList()}
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody style={{ padding: "10px 20px" }}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Source Currency
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={this.state.source_currency}
                      onChange={this.handleSourceChange}
                      inputProps={{
                        name: "simpleSelect",
                        id: "source-currency-select",
                      }}
                    >
                     {this.getSourceCurrency()}
                    </Select>
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody style={{ padding: "10px 20px" }}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Destination Currency
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={this.state.destination_currency}
                      onChange={this.handleDestinationChange}
                      inputProps={{
                        name: "simpleSelect",
                        id: "destination-currency-select",
                      }}
                    >
                     {this.getDestinationCurrency()}
                    </Select>
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={2}>
              <Card>
                <CardBody style={{ padding: "10px 20px" }}>
                  <FormControl fullWidth>
                    <Button color="rose" onClick={this.onSearch}>
                      Search
                    </Button>
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="rose" text>
                  <CardText color="rose">
                    <h4 className={classes.cardTitle}>
                      Currency Conversion Logs
                    </h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={timelogData}
                    filterable
                    columns={[
                      {
                        Header: "ID",
                        accessor: "id",
                      },

                      {
                        Header: "UserName",
                        accessor: "user_name",
                        filterMethod: this.filterMethod,
                      },
                      {
                        accessor: "source_currency",
                        Header: "Source Currency",
                        filterMethod: this.filterMethod,
                      },
                      {
                        accessor: "destination_currency",
                        Header: "Destination Currency",
                        filterMethod: this.filterMethod,
                      },

                      {
                        accessor: "value",
                        Header: "Value",
                      },
                      {
                        accessor: "created_at",
                        Header: "Created At",
                      },
                      {
                        accessor: "created_time",
                        Header: "Created Time",
                      },
                    ]}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                    // defaultFilterMethod={this.filterMethod}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}
export default withStyles(cssstyles)(CurrencyConversionLogs);
