import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import CustomSweet from "../Components/CustomSweet";
import Progress from "../Components/Progress";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import apiUrl from "../api";
import { makeApiCall } from "utils/commonCheckFunctions";

const base_url = apiUrl;
const userType = localStorage.getItem("user_type");
const SUPERADMINUSER = "superadmin";
class CreatePaymentPayKii extends Component {
  constructor(props) {
    super(props);
    this.state = {
      billerCategoryId: "",
      sku: "",
      currency: "",
      amount: "",
      customerId: "",
      clientPaymentId: "",
      input1: "",
      client: "",
      allBillerCategoryId: [],
      allSku: [],
      allCountries: [],
      country: "",
      allClients: [],
      selectedBA: "",
      baUsers: [],
      validBillerCategoryId: true,
      validSku: true,
      validCurrency: true,
      validAmount: true,
      validCustomerId: true,
      validClientPaymentId: true,
      validInput1: true,
      validClient: true,
      validCountry: true,
      alert: false,
      alertMsg: "",
      alertTitle: "",
      message: "",
      success: true,
      danger: false,
      warning: false,
      loading: false,
    };
  }

  componentDidMount() {
    if (userType !== SUPERADMINUSER) {
      this.getAllClients();
    } else {
      this.getAllBaUsers();
    }
    this.getAllCountries();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedBA: prevSelectedBA } = prevState;
    const { selectedBA } = this.state;
    if (prevSelectedBA !== selectedBA && userType === SUPERADMINUSER) {
      this.getAllClients();
    }
  }

  handleBillerCategoryIdChange = (event) => {
    this.setState(
      {
        billerCategoryId: event.target.value,
        validBillerCategoryId: true,
      },
      () => {
        this.getAllSku();
      }
    );
  };

  handleClientChange = (event) => {
    this.setState({
      client: event.target.value,
      validClient: true,
    });
  };

  handleSkuChange = (event) => {
    this.setState({
      sku: event.target.value,
      validSku: true,
    });
  };

  handleAmountChange = (event) => {
    this.setState({
      amount: event.target.value,
      validAmount: true,
    });
  };
  handleCusomerIdChange = (event) => {
    this.setState({
      customerId: event.target.value,
      validCustomerId: true,
    });
  };
  handleClientPaymentIdChange = (event) => {
    this.setState({
      clientPaymentId: event.target.value,
      validClientPaymentId: true,
    });
  };
  handleinput1Change = (event) => {
    this.setState({
      input1: event.target.value,
      validInput1: true,
    });
  };

  handleCountryChange = (event) => {
    const value = event.target.value;
    this.setState(
      {
        country: value,
        validCountry: true,
        currency: this.getCurrency(value),
        validCurrency: true,
        billerCategoryId: "",
        validBillerCategoryId: true,
        sku: "",
        validSku: true,
        allSku: [],
      },
      () => {
        this.getBillerCategoryId();
      }
    );
  };

  getCurrency = (country) => {
    const value = this.state.allCountries.filter((data) => {
      return data.attributes.name === country;
    });
    const {
      attributes: { currency },
    } = value[0];
    return currency;
  };

  getAllClients() {
    // const base_url = window.location.origin;
    const params =
    userType === SUPERADMINUSER ? { ba_id: this.state.selectedBA } : {};
    axios({
      method: "get",
      url: base_url + "/clients.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {...params},
    })
      .then((result) => {
        //access the results here....
        // console.log(result);
        this.setState({
          allClients: result.data.clients,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }

  getAllCountries = () => {
    axios({
      method: "get",
      url: base_url + "/country_codes.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
    })
      .then((result) => {
        //access the results here....
        this.setState({
          allCountries: result.data.countries,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getBillerCategoryId = () => {
    const { country } = this.state;
    if (country) {
      axios({
        method: "get",
        url: base_url + "/payments/getBillerCatalog",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        },
        params: {
          country_name: country,
        },
      })
        .then((result) => {
          //access the results here....
          this.setState({
            allBillerCategoryId: result.data,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  getAllSku = () => {
    axios({
      method: "get",
      url: base_url + "/payments/getBillerSku",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        biller_id: this.state.billerCategoryId,
      },
    })
      .then((result) => {
        //access the results here....
        this.setState({
          allSku: result.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getAllBaUsers = async () => {
    const data = await makeApiCall({
      url: "/users.json",
      method: "get",
      params: { role: "ba" },
    });
    const { error = false } = data;
    if (!error) {
      this.setState({
        baUsers: data,
      });
    } else {
      const { message } = data;
      console.log(message);
    }
  };

  validateForm() {
    const {
      billerCategoryId,
      sku,
      currency,
      amount,
      customerId,
      clientPaymentId,
      input1,
      client,
      selectedBA
    } = this.state;

    if (billerCategoryId === "") {
      alert("Please select Biller Category");
      this.setState({ validBillerCategoryId: false });
      return false;
    }
    if (sku === "") {
      this.setState({ validSku: false });
      alert("Please enter Sku");
      return false;
    }
    if (selectedBA === "" && userType === SUPERADMINUSER) {
      alert("Please select BA !!!");
      return false;
    }

    if (client === "") {
      this.setState({
        validClient: false,
      });
      alert("Please enter client");
      return false;
    }
    if (currency === "") {
      this.setState({ validCurrency: false });
      alert("Please select Currency");
      return false;
    }
    if (amount === "") {
      this.setState({ valid_amount: "false" });
      alert("Please enter Amount");
      return false;
    } else {
      if (!this.isAmountValid(amount)) {
        this.setState({ validAmount: false });
        alert("Please enter Valid Amount");
        return false;
      }
    }
    if (customerId === "") {
      this.setState({ validCustomerId: false });
      alert("Please enter Customer Id");
      return false;
    }
    if (clientPaymentId === "") {
      this.setState({ validClientPaymentId: false });
      alert("Please enter Client Payment ID");
      return false;
    }
    if (input1 === "") {
      this.setState({ validInput1: false });
      alert("Please enter Input1");
      return false;
    }
    return true;
  }

  isAmountValid = (value) => {
    let numberValue = Number(value);
    return !isNaN(numberValue) && numberValue > 0;
  };

  hideAlert = () => {
    this.setState({
      alert: false,
      message: "",
      alertTitle: "",
      danger: false,
      success: true,
      warning: false,
    });
  };

  handlepay = (event) => {
    event.preventDefault();
    if (this.validateForm()) {
      this.setState({ loading: true });
      this.createSignature();
    }
  };

  createSignature = () => {
    const {
      billerCategoryId,
      sku,
      amount,
      currency,
      customerId,
      clientPaymentId,
      input1,
      client,
    } = this.state;
    let paramList =
      "amount=" +
      amount +
      "&biller_catagory_id=" +
      billerCategoryId +
      "&client_pmt_id=" +
      clientPaymentId +
      "&currency=" +
      currency.toLowerCase() +
      "&customer_id=" +
      customerId +
      "&input1=" +
      input1 +
      "&sku=" +
      sku +
      "&client_id=" +
      client;
    this.setState({ loading: true });
    axios({
      method: "post",
      url: base_url + "/payments/create_paykii_signature.json?" + paramList,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {},
    })
      .then((result) => {
        //access the results here....
        // console.log(result);
        this.setState({ loading: false });
        this.doTransaction(result.data);
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log("Error", error);
        // swal("Error!", "Error. Something went wrong. Please retry.", "error");
      });
  };

  cleanInputs() {
    this.setState({
      billerCategoryId: "",
      sku: "",
      currency: "",
      amount: "",
      customerId: "",
      clientPaymentId: "",
      input1: "",
      country:"",
      allBillerCategoryId:[],
      allSku:[],
      client:"",
      selectedBA:"",
    });
  }

  doTransaction(data) {
    const {
      billerCategoryId,
      sku,
      amount,
      currency,
      customerId,
      clientPaymentId,
      input1,
      client,
    } = this.state;
    let paramList =
      "api_token=" +
      data["api_token"] +
      "&amount=" +
      amount +
      "&biller_catagory_id=" +
      billerCategoryId +
      "&customer_id=" +
      customerId +
      "&currency=" +
      currency.toLowerCase() +
      "&client_pmt_id=" +
      clientPaymentId +
      "&input1=" +
      input1 +
      "&sku=" +
      sku +
      "&tonce=" +
      data["tonce"] +
      "&signature=" +
      data["signature"];
    this.setState({ loading: true });
    var confirmation = window.confirm(
      "Are you sure you want to create payment?"
    );
    if (confirmation) {
      axios({
        method: "post",
        url: base_url + "/api/v1/payments?" + paramList,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        },
        params: {},
      })
        .then((result) => {
          //access the results here....
          //   console.log(result);
          this.setState(
            {
              loading: false,
              alert: true,
              message: "Payment successfully initiated.!",
              alertTitle: "Payment!",
              danger: false,
              warning: false,
              success: true,
              times: "0",
            },
            () => this.cleanInputs()
          );
          // window.location.reload();
        })
        .catch((error) => {
          // console.log("Error", error.response.data);
          this.setState({
            loading: false,
            alert: true,
            message: error.response.data.error.message,
            alertTitle: "Error!",
            danger: true,
            warning: false,
            success: false,
          });
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  handleBaChange=(event)=>{
    this.setState({
      selectedBA:event.target.value,
      client:"",
      allClients: [],
    })
   }
 
   getBAUserList() {
     const { classes } = this.props;
     if (userType === SUPERADMINUSER) {
       return (
         <FormControl fullWidth className={classes.selectFormControl}>
           <InputLabel className={classes.selectLabel}>{"BA *"}</InputLabel>
 
           <Select
             MenuProps={{
               className: classes.selectMenu,
             }}
             classes={{
               select: classes.select,
             }}
             value={this.state.selectedBA}
             onChange={this.handleBaChange}
           >
             {this.state.baUsers.map((row) => (
               <MenuItem
                 classes={{
                   root: classes.selectMenuItem,
                   selected: classes.selectMenuItemSelected,
                 }}
                 value={row.id}
                 key={row.id}
               >
                 {row.name}
               </MenuItem>
             ))}
           </Select>
         </FormControl>
       );
     } else {
       return null;
     }
   }

  render() {
    const { classes } = this.props;
    return (
      <>
        <CustomSweet
          alert={this.state.alert}
          message={this.state.message}
          title={this.state.alertTitle}
          hide_alert={this.hideAlert}
          danger={this.state.danger}
          warning={this.state.warning}
          success={this.state.success}
        />
        <div className={this.state.loading === true ? classes.main_screen : ""}>
          <div align="center">
            {this.state.loading === true && <Progress />}
          </div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" text>
                  <CardText color="rose">
                    <h4 className={classes.cardTitle}>Create Payment</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <form>
                    <GridContainer justify="center">
                      <GridItem xs={12} md={6} sm={10}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                            success={this.state.validCountry}
                            error={!this.state.validCountry}
                          >
                            {"Country *"}
                          </InputLabel>

                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.country}
                            onChange={this.handleCountryChange}
                            inputProps={{
                              name: "simpleSelect",
                            }}
                          >
                            {this.state.allCountries.map((row) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value={`${row.attributes.name}`}
                                key={row.attributes.id}
                              >
                                {row.attributes.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      <GridItem xs={12} md={6} sm={10}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                            success={this.state.validBillerCategoryId}
                            error={!this.state.validBillerCategoryId}
                          >
                            {"Biller Category *"}
                          </InputLabel>

                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.billerCategoryId}
                            onChange={this.handleBillerCategoryIdChange}
                            inputProps={{
                              name: "simpleSelect",
                            }}
                          >
                            {this.state.allBillerCategoryId.map((row) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value={row.biller_id}
                                key={row.id}
                              >
                                {row.biller_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      <GridItem
                        xs={12}
                        md={6}
                        sm={10}
                        style={{ height: "50px" }}
                      >
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                            success={this.state.validSku}
                            error={!this.state.validSku}
                          >
                            {"Sku *"}
                          </InputLabel>

                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.sku}
                            onChange={this.handleSkuChange}
                            inputProps={{
                              name: "simpleSelect",
                            }}
                            autoWidth
                          >
                            {this.state.allSku.map((row) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value={row.sku}
                                key={row.id}
                              >
                                {`${row.sku} (${row.description})`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      <GridItem xs={12} md={6} sm={10}>
                      {this.getBAUserList()}
                      </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      <GridItem xs={12} md={6} sm={10}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                            success={this.state.validClient}
                            error={!this.state.validClient}
                          >
                            {"Client *"}
                          </InputLabel>

                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.client}
                            onChange={this.handleClientChange}
                            inputProps={{
                              name: "simpleSelect",
                            }}
                          >
                            {this.state.allClients.map((row) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value={row.id}
                                key={row.id}
                              >
                                {row.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      <GridItem
                        xs={12}
                        md={6}
                        sm={10}
                        style={{ height: "50px" }}
                      >
                        <CustomInput
                          labelText="Currency *"
                          success={this.state.validCurrency}
                          error={!this.state.validCurrency}
                          formControlProps={{
                            fullWidth: true,
                            disabled: true,
                          }}
                          inputProps={{
                            value: this.state.currency,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      <GridItem
                        xs={12}
                        md={6}
                        sm={10}
                        style={{ height: "50px" }}
                      >
                        <CustomInput
                          labelText="Amount *"
                          success={this.state.validAmount}
                          error={!this.state.validAmount}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: this.state.amount,
                            onChange: this.handleAmountChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      <GridItem
                        xs={12}
                        md={6}
                        sm={10}
                        style={{ height: "50px" }}
                      >
                        <CustomInput
                          labelText="Customer Id *"
                          success={this.state.validCustomerId}
                          error={!this.state.validCustomerId}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: this.state.customerId,
                            onChange: this.handleCusomerIdChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      <GridItem
                        xs={12}
                        md={6}
                        sm={10}
                        style={{ height: "50px" }}
                      >
                        <CustomInput
                          labelText="Client Payment Id *"
                          success={this.state.validClientPaymentId}
                          error={!this.state.validClientPaymentId}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: this.state.clientPaymentId,
                            onChange: this.handleClientPaymentIdChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      <GridItem
                        xs={12}
                        md={6}
                        sm={10}
                        style={{ height: "50px" }}
                      >
                        <CustomInput
                          labelText="Input1 *"
                          success={this.state.validInput1}
                          error={!this.state.validInput1}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: this.state.input1,
                            onChange: this.handleinput1Change,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                    <br />
                    <GridContainer justify="center">
                      <GridItem
                        xs={12}
                        md={3}
                        sm={3}
                        style={{ height: "50px" }}
                      >
                        <FormControl fullWidth>
                          <Button color="rose" onClick={this.handlepay}>
                            Proceed To Pay
                          </Button>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </>
    );
  }
}

export default withStyles(cssstyles)(CreatePaymentPayKii);
