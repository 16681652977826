import React from "react";
// react component for creating dynamic tables
import axios from "axios";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import Table from "components/Table/Table.js";
import apiUrl from "../api";
import { withStyles } from "@material-ui/core/styles";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const base_url = apiUrl;
class Timelog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transaction_id: "",
      user_id: "",
      timelogs: [],
      txn_id: "",
    };
  }
  loadtable(txn_id) {
    // const base_url = window.location.origin;
    console.log(this.state.txn_id);
    axios({
      method: "get",
      url: base_url + "/transactions/" + txn_id + "/timelogs.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
    }).then((result) => {
      //access the results here....
      console.log(result);
      this.setState({
        timelogs: result.data,
      });
    });
  }
  componentDidMount() {
    const path = window.location.href;
    const txn_id = path.substring(path.lastIndexOf("/") + 1);
    console.log(path, txn_id);
    if (txn_id !== "") {
      this.setState({ txn_id: txn_id }, () => {});
    }
    this.loadtable(txn_id);
  }
  get getTimelog() {
    if (this.state.timelogs !== "") {
      return this.state.timelogs.map((row) => [
        [row.id],
        [row.transaction_id],
        [row.payment_gateway],
        [row.action],
        [row.message],
        [row.ref_no],
        [row.created_at],
        [row.user_id],
      ]);
    } else {
      return [];
    }
  }
  render() {
    const { classes } = this.props;
    console.log(this.getData);
    const timelogData = this.getTimelog;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4 className={classes.cardTitle}>Transaction Timelog</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="primary"
                  tableHead={[
                    "Transaction ID",
                    "Payment Gateway",
                    "Action",
                    "Message",
                    "Payment Gateway Transaction ID",
                    "Action Performed At",
                    "Action Performed By",
                  ]}
                  tableData={timelogData}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
export default withStyles(cssstyles)(Timelog);
