import React from "react";
// react component for creating dynamic tables
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
// @material-ui/icons
import CustomInput from "components/CustomInput/CustomInput.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import CustomSweet from "../Components/CustomSweet";
import Progress from "../Components/Progress";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import SweetAlert from "react-bootstrap-sweetalert";
import alertstyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import apiUrl from "../api";
import { makeApiCall } from "utils/commonCheckFunctions";

const base_url = apiUrl;
const recipientInformationRequired = [
  "zar",
  "php",
  "xof",
  "eur",
  "gbp",
  "aud",
  "vnd",
  "idr",
  "inr",
  "kes",
  "cny",
  "sgd",
  "ghs",
  "ugx",
  "rwf",
  "xaf",
  "brl",
  "usd",
  "mxn",
  "pen",
  "ars",
  "bdt",
  "egp",
  "aed",
  "pkr",
  "hkd",
  "myr",
  "thb",
  "try",
  "krw",
  "clp",
  "cop",
  "crc",
  "nzd"
];
const senderInformationRequired = [
  "zar",
  "php",
  "xof",
  "eur",
  "gbp",
  "aud",
  "vnd",
  "idr",
  "inr",
  "kes",
  "cny",
  "sgd",
  "ghs",
  "ugx",
  "rwf",
  "xaf",
  "brl",
  "usd",
  "mxn",
  "pen",
  "ars",
  "bdt",
  "egp",
  "aed",
  "pkr",
  "hkd",
  "myr",
  "thb",
  "try",
  "krw",
  "clp",
  "cop",
  "crc",
  "nzd"
];
const ibanCurrencyList = ["xof", "eur", "gbp", "aed", "egp"];
const bicSwiftCurrencyList = ["eur", "gbp", "sgd", "ghs", "usd", "php", "cny", "vnd", "mxn", "pen", "ars", "bdt", "egp", "aed", "pkr", "zar", "thb", "try", "aud", "brl", "krw" , "clp", "cop", "crc", "nzd"];
const sortCodeCurrencyList = ['gbp'];
const mandatoryBicSwiftCurrencyList = ["sgd", "usd", "mxn", "pen", "ars", "bdt", "egp", "aed", "pkr", "zar", "try", "aud", "brl", "krw" , "clp", "cop", "crc"];
const noProviderCurrencyList = ["eur", "gbp", "usd", "pen", "ars", "cop", "crc"];
const bankNameList = ["eur", "gbp", "usd", "mxn", "pen", "ars", "aud", "clp", "cop", "crc"];
const requireRecipientId = ['ars', 'kes', 'mxn', 'pen', 'thb', 'aed', 'bdt', 'egp', 'krw' , 'clp', 'cop', 'crc'];
const requireSenderProvince = ['usd', 'mxn', 'inr'];
const requireBankSubCode = ['brl'];
const recipientCountrySet = new Set([
  "inr",
  "xof",
  "cny",
  "sgd",
  "idr",
  "php",
  "ghs",
  "ugx",
  "rwf",
  "xaf",
  "kes",
  "zar",
  "eur",
  "gbp",
  "aud",
  "vnd",
  "ngn",
  "brl",
  "usd",
  "mxn",
  "pen",
  "ars",
  "bdt",
  "egp",
  "aed",
  "pkr",
  "htg",
  "sll",
  "hkd",
  "myr",
  "thb",
  "try",
  "krw",
  "clp",
  "cop",
  "crc",
  "nzd"
]);
const senderCountryList = [
  {
    value: "US",
    name: "US"
  },
  {
    value: "CA",
    name: "Canada"
  },
  {
    value: "PH",
    name: "Philippines",
  },{
    value: "NG",
    name: "Nigeria",
  },
  {
    value: "IN",
    name: "India"
  },
  {
    value: "KE",
    name: "Kenya"
  },
  {
    value: "GH",
    name: "Ghana"
  },
  {
    value: "UK",
    name: "United Kingdom"
  }
];
const TransactionType = [
  { name: "Bank", value: "Bank" },
  { name: "Cash", value: "Cash" },
  { name: "Mobile", value: "Mobile" },
  { name: "Wire", value: "Wire" }
];
const senderGenderList = [
  { name: "Male", value: "Male" },
  { name: "Female", value: "Female" },
];
const segmentType = [
  { name: "Individual", value: "individual" },
  { name: "Business", value: "business" },
];
const BANK_WIRE = ['Bank', 'Wire']

const CASH = "Cash";
const BANK = "Bank";
const MOBILE = "Mobile";
const WIRE = "Wire";
const providersNotRequiredTxnType = [CASH];
const SUPERADMINUSER = "superadmin";
const userType = localStorage.getItem("user_type");

const styles = {
  input_underline: {
    "&:after": {
      borderColor: "#4caf50",
    },
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#D2D2D2" + "!important",
      borderWidth: "1px !important",
    },
    "& + p": {
      fontWeight: "300",
    },
  },
  input_underline_error: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#D2D2D2" + "!important",
      borderWidth: "1px !important",
    },
    "& + p": {
      fontWeight: "300",
    },
    "&:after": {
      borderColor: "#f44336",
    },
  },

  input_label_success: {
    color: "#4caf50 !important",
    fontSize: "14px",
  },
  input_label_error: {
    color: "#f44336 !important",
    fontSize: "14px",
  },
  user_input: {
    height: "unset",
    "&,&::placeholder": {
      fontSize: "14px",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: "400",
      lineHeight: "1.42857",
      opacity: "1",
    },
  },
};

class CreateTransaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: 0,
      txn_type: "",
      client_all: [],
      version: "",
      currency: "",
      provider_all: [],
      baUsers:[],
      params: "",
      selectedBA:"",
      client: "",
      provider: "",
      source_curr: "",
      dest_curr: "",
      curr: "",
      amount: "",
      source_amount: "",
      ifsc: "",
      bicSwift: "",
      sortCode: "",
      client_txn_id: "",
      deposit_number: "",
      name: "",
      sender_name: "",
      sender_last_name: "",
      sender_street: "",
      sender_add: "",
      sender_city: "",
      sender_country_dob: "",
      sender_date_of_birth: "",
      sender_country: "",
      email: "",
      senderIdType: "",
      senderIdNumber: "",
      senderMunicipality: "",
      senderProvince: "",
      transactionType: "Cashin",
      sender_mobile_phone_country: "",
      sender_mobile_phone_number: "",
      sender_type: "individual",
      zipcode: "",
      gender: "",
      agent_code: "",
      agent_name: "",
      sender_show: false,
      recipientCity: "",
      recipientEmail: "",
      recipientIdType: "",
      recipientIdNumber: "",
      recipientPhone: "",
      recipientPostalCode: "",
      recipientStreet: "",
      recipientReasonCode: "",
      recipientAddress: "",
      recipient_date_of_birth: "",
      recipient_province_of_birth: "",
      recipient_city_of_birth: "",
      recipient_town_name: "",
      recipient_district: "",
      recipient_country_sub_division: "",
      recipient_country_of_birth: "",
      branch_name: "",
      iban: "",
      loading: false,
      param: "",
      valid_txn_type: "",
      valid_client: "",
      valid_provider: "",
      valid_client_txn_id: "",
      valid_curr: "",
      valid_source_curr: "",
      valid_dest_curr: "",
      valid_amount: "",
      valid_source_amount: "",
      valid_deposit_number: "",
      valid_name: "",
      sweetAlert: null,
      recipientReasonCodeList: [],
      memo: "",
      bankName: "",
      conversionRate: "",
      destinationAmount: "",
      xofCountryList: [],
      recipientCountryList: {},
      recipientCountry: "",
      cpf_tax_id: "",
      recipient_type: "individual",
      bank_sub_code: ""
    };
    this.walletInfo = localStorage.getItem("walletStatus");
    this.userType = localStorage.getItem("user_type");
    this.sourceCurrencyList = [
      { name: "USD", value: "usd" },
      { name: "GBP", value: "gbp" },
      { name: "CAD", value: "cad" },
      { name: "EUR", value: "eur" },
    ];
    this.destinationCurrencyList = [
      { name: "NGN", value: "ngn" },
      { name: "GHS", value: "ghs" },
      { name: "KES", value: "kes" },
      { name: "INR", value: "inr" },
      { name: "ZAR", value: "zar" },
      { name: "XOF", value: "xof" },
      { name: "PHP", value: "php" },
      { name: "USD", value: "usd" },
      { name: "EUR", value: "eur" },
      { name: "GBP", value: "gbp" },
      { name: "AUD", value: "aud" },
      { name: "VND", value: "vnd" },
      { name: "IDR", value: "idr" },
      { name: "SGD", value: "sgd" },
      { name: "CNY", value: "cny" },
      { name: "UGX", value: "ugx" },
      { name: "RWF", value: "rwf" },
      { name: "XAF", value: "xaf" },
      { name: "BRL", value: "brl" },
      { name: "MXN", value: "mxn" },
      { name: "PEN", value: "pen" },
      { name: "ARS", value: "ars" },
      { name: "BDT", value: "bdt" },
      { name: "EGP", value: "egp" },
      { name: "AED", value: "aed" },
      { name: "PKR", value: "pkr" },
      { name: "HTG", value: "htg" },
      { name: "SLL", value: "sll" },
      { name: "HKD", value: "hkd" },
      { name: "MYR", value: "myr" },
      { name: "THB", value: "thb" },
      { name: "TRY", value: "try" },
      { name: "KRW", value: "krw" },
      { name: "CLP", value: "clp" },
      { name: "COP", value: "cop" },
      { name: "CRC", value: "crc" },
      { name: "NZD", value: "nzd" }
    ];
  }
  componentDidMount() {
    if (localStorage.getItem("user_id")) {
      this.setState({ user_id: localStorage.getItem("user_id") });
    }
    if(userType !== SUPERADMINUSER){
    this.getAllClients();}else{
      this.getAllBaUsers();
    }
    this.getAllRecipientCodes();
    this.getCountries();
  }

  getAllBaUsers=async()=>{
   const data = await makeApiCall({
     url:"/users.json",
     method:"get",
     params:{role:"ba"}
   });
   const {error =false} = data;
   if(!error){
    this.setState({
      baUsers:data,
    })
   }else{
     const {message}= data;
     console.log(message);
   }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      source_curr: prevSourceCurrency,
      dest_curr: prevV2DestCurr,
      recipientCountry: prevRecipientCountry,
      selectedBA:prevSelectedBA
    } = prevState;
    const {
      source_curr: sourceCurrency,
      dest_curr: v2DestCurr,
      recipientCountry,
      version,
      selectedBA
    } = this.state;
    if (version === "v2") {
      if (
        prevSourceCurrency !== sourceCurrency ||
        prevV2DestCurr !== v2DestCurr ||
        prevRecipientCountry !== recipientCountry
      ) {
        if (
          sourceCurrency !== "" &&
          v2DestCurr !== "" &&
          recipientCountry !== ""
        ) {
          this.getExchangeValue(sourceCurrency, v2DestCurr, recipientCountry);
        }
      }
    }
    if(prevSelectedBA !== selectedBA && userType === SUPERADMINUSER){
        this.getAllClients();
    }
  }
  onConfirmation = () => {
    let params = this.createParamList(this.attributes, "");
    // console.log(params);
    this.createSignature(params, this.attributes);
    this.setState({
      sweetAlert: null,
    });
  };

  getCountries = async () => {
    const data = await makeApiCall({
      url: "/country_codes.json",
      method: "get",
    });
    const { error = false, message } = data;
    if (!error) {
      const { countries } = data;
      const countryList = {};
      for (let element of countries) {
        const { attributes } = element;
        let currencyToMatch = attributes.currency.toLowerCase();
        if (recipientCountrySet.has(currencyToMatch)) {
          if (!countryList[currencyToMatch]) {
            countryList[currencyToMatch] = [element];
          } else {
            countryList[currencyToMatch].push(element);
          }
        }
      }
      // const countryList = countries.filter((element) => {
      //   const { attributes } = element;
      //   return ["XOF"].includes(attributes.currency);
      // });
      // const clientList = Array.isArray(clients) ? clients : [clients];
      // // this.getClientData(result.data.clients);
      // const clientData = getUpdatedClientlist(clientList);
      this.setState({
        recipientCountryList: countryList,
      });
      // console.log(data);
    } else {
      console.log(message);
    }
  };

  hideSweetAlert = () => {
    this.setState({
      sweetAlert: null,
    });
  };

  getAllRecipientCodes = () => {
    axios({
      method: "get",
      url: base_url + "/show_transfer_reasons.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {},
    })
      .then((result) => {
        //access the results here....
        // console.log(result.data);
        this.setState({
          recipientReasonCodeList: result.data.reasons,
        });
        // this.getClientData(result.data.clients);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  handleTypeChange = (event) => {
    this.setState(
      {
        txn_type: event.target.value,
        valid_txn_type: "",
        provider: "",
        valid_provider: "",
        provider_all: [],
      },
      () => {
        const { curr, dest_curr } = this.state;
        if (curr || dest_curr) {
          this.getProvider(curr || dest_curr);
        }
      }
    );
  };
  handleClientChange = (event) => {
    this.setState({ client: event.target.value, valid_client: "" });
  };
  handleProviderChange = (event) => {
    this.setState({ provider: event.target.value, valid_provider: "" });
  };
  handleClientTxnChange = (event) => {
    this.setState({
      client_txn_id: event.target.value,
      valid_client_txn_id: "",
    });
  };
  handleCurrencyChange = (event) => {
    this.setState({
      curr: event.target.value,
      valid_curr: "",
      provider: "",
      valid_provider: "",
      bankName: "",
    });
    this.getProvider(event.target.value);
  };

  /* on currency change trigger conversion rate api */
  handleSourceCurrencyChange = (event) => {
    const { value } = event.target;
    this.setState({
      source_curr: value,
      valid_source_curr: "",
      source_amount: "",
      valid_source_amount: "",
      recipientCountry: "",
      destinationAmount: "",
      conversionRate: "",
    });
    // const { dest_curr, source_curr } = this.state;
    // if (dest_curr !== "" && value !== source_curr) {
    //   this.getExchangeValue(value, dest_curr);
    // }
  };

  handleDestCurrencyChange = (event) => {
    const { value } = event.target;
    this.setState({
      dest_curr: value,
      valid_dest_curr: "",
      provider: "",
      valid_provider: "",
      bankName: "",
      source_amount: "",
      valid_source_amount: "",
      recipientCountry: "",
      destinationAmount: "",
      conversionRate: "",
    });
    // const { dest_curr, source_curr } = this.state;
    // if (source_curr !== "" && value !== dest_curr) {
    //   this.getExchangeValue(source_curr, value);
    // }
    this.getProvider(event.target.value);
  };

  handleAmountChange = (event) => {
    this.setState({ amount: event.target.value, valid_amount: "" });
  };

  handleSourceAmountChange = (event) => {
    const { value } = event.target;
    const { conversionRate } = this.state;

    if (conversionRate) {
      const val = conversionRate - 0;
      const src = value ? value - 0 : value;
      const dest = value ? src * val : "";
      this.setState({
        source_amount: src,
        destinationAmount: dest ? dest.toFixed(5) : "",
        valid_source_amount: "",
      });
    } else {
      this.setState({
        source_amount: value,
        destinationAmount: 0,
        valid_source_amount: "",
      });
    }
  };

  handleDestinationAmountChange = (event) => {
    const { value } = event.target;
    const { conversionRate } = this.state;

    if (conversionRate) {
      const val = conversionRate - 0;
      const dest = value ? value - 0 : "";
      const sourceAmount = value && val !== 0 ? dest / val : "";
      this.setState({
        source_amount: sourceAmount ? sourceAmount.toFixed(5) : "",
        destinationAmount: dest,
      });
    } else {
      const dest = value ? value - 0 : "";
      this.setState({
        destinationAmount: dest,
      });
    }
  };

  handleifscChange = (event) => {
    this.setState({ ifsc: event.target.value });
  };
  handleDepositChange = (event) => {
    this.setState({
      deposit_number: event.target.value,
      valid_deposit_number: "",
    });
  };

  handleBlur = (event) => {
    if(event.target.value) {
      fetch(base_url + '/transactions/successful_transactions?deposit_number=' + event.target.value, {
        method: 'get',
        headers: {
          Authorization: `Bearer ${localStorage.getItem("session_token")}`
        }
      })
      .then((response) => {
        if(response.ok) {
          return response.json();
        } else {
          throw new Error('Server response wasn\'t OK');
        }
      })
      .then((res) => {
        if(res == null){
          console.log('record not found');
        }else{
          if(res.api_version === 'v1')
          {
            this.setState({
              txn_type: res.account_type,
              selectedBA: res.ba_id,
              client: res.client_id,
              version: res.api_version,
              curr: (res.currency || ''),
              recipientCountry: res.recipient_country,
              ifsc: (res.ifsc || ''),
              provider: res.account_code,
              bankName: (res.bank_name || ''),
              sortCode: (res.sort_code || ''),
              name: (res.name || '')
            });
          }
          else{
            this.setState({
              txn_type: res.account_type,
              selectedBA: res.ba_id,
              client: res.client_id,
              version: (res.api_version || 'v2'),
              accountType: res.account_type,
              source_curr: res.source_currency,
              dest_curr: res.destination_currency,
              recipientCountry: res.recipient_country,
              ifsc: (res.ifsc || ''),
              provider: res.account_code,
              bankName: (res.bank_name || ''),
              sortCode: (res.sort_code || ''),
              name: (res.name || ''),
              iban: (res.iban || ''),
              bicSwift: res.bic_swift,
              sender_name: (res.sender_first_name || ''),
              sender_last_name: (res.sender_last_name || ''),
              sender_street: (res.sender_street || ''),
              sender_add: (res.sender_address || ''),
              sender_city: (res.sender_city || ''),
              senderProvince: (res.sender_province || ''),
              sender_country_dob: (res.sender_country_of_birth || ''),
              sender_date_of_birth: (res.sender_date_of_birth || ''),
              sender_country: (res.sender_country || ''),
              email: (res.sender_email || ''),
              senderIdType: (res.sender_id_type || 'ID'),
              senderIdNumber: (res.sender_id_number || ''),
              sender_mobile_phone_country: (res.sender_mobile_phone_country || ''),
              sender_mobile_phone_number: (res.sender_mobile_phone_number || ''),
              sender_type: (res.sender_type || 'individual'),
              zipcode: (res.sender_zipcode || ''),
              gender: (res.sender_gender || ''),
              recipientCity: (res.recipient_city || ''),
              recipientEmail: (res.recipient_email || ''),
              recipient_type: (res.recipient_type || 'individual'),
              recipientPhone: (res.recipient_phone_number || ''),
              recipientPostalCode: (res.recipient_postal_code || ''),
              recipientStreet: (res.recipient_street || ''),
              recipientAddress: (res.recipient_address || ''),
              recipient_date_of_birth: (res.recipient_date_of_birth || ''),
              recipient_city_of_birth: (res.recipient_city_of_birth || ''),
              recipient_town_name: (res.recipient_town_name || ''),
              recipient_district: (res.recipient_district || ''),
              recipient_province_of_birth: (res.recipient_province_of_birth || ''),
              recipient_country_sub_division: (res.recipient_country_sub_division || ''),
              recipient_country_of_birth: (res.recipient_country_of_birth || ''),
              cpf_tax_id: (res.cpf_tax_id || ''),
              bank_sub_code: (res.bank_sub_code || '')
            });
          };
          this.getProvider(res.destination_currency);
        }
      });
    }
  }


  handleNameChange = (event) => {
    this.setState({ name: event.target.value, valid_name: "" });
  };
  handleSenderNameChange = (event) => {
    this.setState({ sender_name: event.target.value });
  };
  handleSenderLastNameChange = (event) => {
    this.setState({ sender_last_name: event.target.value });
  };
  handleSenderStreetChange = (event) => {
    this.setState({
      sender_street: event.target.value,
    });
  };
  handleSenderAddChange = (event) => {
    this.setState({ sender_add: event.target.value });
  };
  handleSenderCityChange = (event) => {
    this.setState({ sender_city: event.target.value });
  };
  handleSenderProvinceChange = (event) => {
    this.setState({ senderProvince: event.target.value });
  };
  handleSenderCountryDobChange = (event) => {
    this.setState({ sender_country_dob: event.target.value });
  };
  handleSenderDobChange = (event) => {
    this.setState({ sender_date_of_birth: event.target.value });
  };
  handleSenderCountryChange = (event) => {
    this.setState({ sender_country: event.target.value });
  };
  handleEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };
  handleSenderIdTypeChange = (event) => {
    this.setState({ senderIdType: event.target.value, valid_sender_id_type: "" });
  };
  handleSenderMobCountryChange = (event) => {
    this.setState({ sender_mobile_phone_country: event.target.value });
  };
  handlePhoneChange = (event) => {
    this.setState({ sender_mobile_phone_number: event.target.value });
  };
  handleSenderTypeChange = (event) => {
    this.setState({ sender_type: event.target.value });
  };
  handleZipChange = (event) => {
    this.setState({ zipcode: event.target.value });
  };
  handleGenderChange = (event) => {
    this.setState({ gender: event.target.value });
  };
  handleAgentCodeChange = (event) => {
    this.setState({ agent_code: event.target.value });
  };
  handleAgentNameChange = (event) => {
    this.setState({ agent_name: event.target.value });
  };
  handleTaxIdChange = (event) => {
    this.setState({ cpf_tax_id: event.target.value });
  };
  handleMemoChange = (event) => {
    this.setState({ memo: event.target.value });
  };
  handleRecipientIdTypeChange = (event) => {
    this.setState({ recipientIdType: event.target.value, valid_recipient_id_type: "" })
  };
  handleRecipientTypeChange = (event) => {
    this.setState({ recipient_type: event.target.value });
  };
  handleBankSubCodeChange = (event) => {
    this.setState({ bank_sub_code: event.target.value });
  };

  handleRecipientChange = (event) => {
    const { name, value } = event.target;
    if (name === "recipientCountry") {
      this.setState({
        [name]: value,
        source_amount: "",
        valid_source_amount: "",
        destinationAmount: "",
        conversionRate: "",
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  handleBaChange=(event)=>{
    const{value} = event.target;
    this.setState({
      selectedBA:value,
    });
  }

  handleRecipientCodeChange = (event) => {
    this.setState({
      recipientReasonCode: event.target.value,
    });
  };

  isMemoVisible = () => {
    const { dest_curr } = this.state;
    if(dest_curr === 'usd'){
      return true
    }
    // if (version === "v1") {
    //   return curr === "ngn" && provider === "900999"; // 900999 is code for bank Now Now Express
    // } else if (version === "v2") {
    //   return dest_curr === "ngn" && provider === "900999";
    // }
    return false;
  };

  handleVersionChange = (e) => {
    // var curr = "";
    this.setState({
      provider_all: [],
      curr: "",
      source_curr: "",
      dest_curr: "",
      amount: "",
      source_amount: "",
      conversionRate: "",
      destinationAmount: "",
    });
    this.setState({ version: e.target.value, valid_version: "" });
  };

  async getExchangeValue(
    sourceCurrency,
    destinationCurrency,
    destinationCountry
  ) {
    const { client,txn_type } = this.state;

    const data = await makeApiCall({
      url: "/get_conversion_rate.json",
      method: "get",
      params: {
        destination_currency: destinationCurrency,
        source_currency: sourceCurrency,
        source_amount: 1,
        recipient_country: destinationCountry,
        client_id: client,
        ...(txn_type==="Cash"?{txn_type:txn_type}:{}),
      },
    });

    const { error = false, message } = data;
    if (!error) {
      const { currency_conversion_rate } = data;

      this.setState({
        conversionRate: currency_conversion_rate,
      });
    } else {
      console.log(message);
    }
  }

  getAllClients() {
    // const base_url = window.location.origin;
    // console.log(base_url);
    const paramsToBeUsed = userType === SUPERADMINUSER?{ba_id:this.state.selectedBA}:{};
      axios({
      method: "get",
      url: base_url + "/clients.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {...paramsToBeUsed},
    })
      .then((result) => {
        //access the results here....
        // console.log(result.data.clients);
        this.getClientData(result.data.clients);
      })
      .catch((error) => {
        console.log("Error", error);
      });
    
  }

  getBAUserList(){
    const {classes} = this.props;
    if(userType === SUPERADMINUSER){
    return(<FormControl fullWidth className={classes.selectFormControl}>
      <InputLabel
        className={classes.selectLabel}
      >
        {"BA *"}
      </InputLabel>

      <Select
        MenuProps={{
          className: classes.selectMenu,
        }}
        classes={{
          select: classes.select,
        }}
        value={this.state.selectedBA}
        onChange={this.handleBaChange}
      >
        {this.state.baUsers.map((row) => (
          <MenuItem
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={row.id}
            key={row.id}
          >
            {row.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>)}else{
      return null;
    }
  }

  getProvider(currency) {
    var baseurl = apiUrl;
    var provider_type = this.state.txn_type;
    // console.log(base_url, provider_type);
    console.log(BANK_WIRE.includes(provider_type))
    if (BANK_WIRE.includes(provider_type)) {
      baseurl = baseurl + "/show_banks.json";
    } else if (provider_type === MOBILE) {
      baseurl = baseurl + "/show_providers.json";
    } else if (provider_type === CASH) {
      return;
    } else {
      alert("Please select Transaction Type!!");
      return;
    }
    axios({
      method: "get",
      url: baseurl,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        currency: currency,
      },
    })
      .then((result) => {
        //access the results here....
        if (BANK_WIRE.includes(provider_type)) {
          this.setState({
            provider_all: result.data.banks,
          });
        } else if (provider_type === "Mobile") {
          this.setState({
            provider_all: result.data.providers,
          });
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }
  handlepay = (event) => {
    event.preventDefault();
    var attributes = {};
    const { dest_curr, txn_type, version, curr } = this.state;
    attributes["account_type"] = this.state.txn_type.trim();
    attributes["client_id"] = this.state.client;
    attributes["account_code"] = this.state.provider.trim();
    attributes["bank_name"] = this.state.bankName.trim();
    attributes["client_txn_id"] = this.state.client_txn_id.trim();

    if (
      version === "v2" &&
      (!ibanCurrencyList.includes(dest_curr) || !BANK_WIRE.includes(txn_type))
    ) {
      attributes["deposit_number"] = this.state.deposit_number.trim();
    } else if (
      version === "v1" &&
      (!ibanCurrencyList.includes(curr) || !BANK_WIRE.includes(txn_type))
    ) {
      attributes["deposit_number"] = this.state.deposit_number.trim();
    }
    else if (
      version === "v2" && BANK_WIRE.includes(txn_type) && 
      sortCodeCurrencyList.includes(dest_curr))
    {
      attributes["deposit_number"] = this.state.deposit_number.trim();
    }

    if(version === 'v1' && txn_type === "Mobile")
      attributes["recipient_email"] = this.state.recipientEmail.trim();

    attributes["name"] = this.state.name.trim();
    attributes["ifsc"] = this.state.ifsc.trim();
    attributes["sender_first_name"] = this.state.sender_name.trim();
    attributes["sender_last_name"] = this.state.sender_last_name.trim();
    attributes["sender_street"] = this.state.sender_street.trim();
    attributes["sender_address"] = this.state.sender_add.trim();
    attributes["sender_city"] = this.state.sender_city.trim();
    attributes[
      "sender_country_of_birth"
    ] = this.state.sender_country_dob.trim();
    attributes["sender_date_of_birth"] = this.state.sender_date_of_birth.trim();
    attributes["sender_country"] = this.state.sender_country.trim();
    attributes["sender_email"] = this.state.email.trim();
    attributes["sender_id_type"] = this.state.senderIdType.trim();
    attributes["sender_id_number"] = this.state.senderIdNumber.trim();
    attributes[
      "sender_mobile_phone_country"
    ] = this.state.sender_mobile_phone_country.trim();
    attributes[
      "sender_mobile_phone_number"
    ] = this.state.sender_mobile_phone_number.trim();
    attributes["sender_type"] = this.state.sender_type.trim();
    attributes["sender_zipcode"] = this.state.zipcode.trim();
    attributes["sender_gender"] = this.state.gender.trim();
    attributes["cpf_tax_id"] = this.state.cpf_tax_id.trim();
    attributes["agent_name"] = this.state.agent_name.trim();
    attributes["agent_code"] = this.state.agent_code.trim();
    attributes["api_version"] = this.state.version.trim();
    attributes["memo"] = this.state.memo.trim();
    if (attributes["api_version"] === "v1") {
      attributes["currency"] = this.state.curr.trim();
      attributes["amount"] = this.state.amount.trim();
    } else if (attributes["api_version"] === "v2") {
      attributes["source_currency"] = this.state.source_curr.trim();
      attributes["destination_currency"] = this.state.dest_curr.trim();
      attributes["source_amount"] = this.state.source_amount;
    }
    if(txn_type !== 'Cash' && requireSenderProvince.includes(dest_curr)){
      attributes["sender_province"] = this.state.senderProvince.trim();
    }
    if (
      version === "v2" &&
      ibanCurrencyList.includes(dest_curr) &&
      BANK_WIRE.includes(txn_type)
    ) {
      attributes["iban"] = this.state.iban.trim();
    } else if (
      version === "v1" &&
      ibanCurrencyList.includes(curr) &&
      BANK_WIRE.includes(txn_type)
    ) {
      attributes["iban"] = this.state.iban.trim();
    }
    if (
      bicSwiftCurrencyList.includes(dest_curr) ||
      bicSwiftCurrencyList.includes(curr)
    ) {
      attributes["bic_swift"] = this.state.bicSwift.trim();
    }
    if (requireBankSubCode.includes(dest_curr)) {
      attributes["bank_sub_code"] = this.state.bank_sub_code.trim();
    }
    if (recipientInformationRequired.includes(dest_curr)) {
      attributes["recipient_city"] = this.state.recipientCity.trim();
      attributes["recipient_email"] = this.state.recipientEmail.trim();
      attributes["recipient_id_type"] = this.state.recipientIdType.trim();
      attributes["recipient_id_number"] = this.state.recipientIdNumber.trim();
      attributes["recipient_type"] = this.state.recipient_type.trim();
      attributes["recipient_phone_number"] = this.state.recipientPhone.trim();
      attributes[
        "recipient_postal_code"
      ] = this.state.recipientPostalCode.trim();
      attributes["recipient_street"] = this.state.recipientStreet.trim();
      attributes["recipient_address"] = this.state.recipientAddress.trim();
      attributes["recipient_date_of_birth"] = this.state.recipient_date_of_birth.trim();
      attributes["recipient_province_of_birth"] = this.state.recipient_province_of_birth.trim();
      attributes["recipient_city_of_birth"] = this.state.recipient_city_of_birth.trim();
      attributes["recipient_town_name"] = this.state.recipient_town_name.trim();
      attributes["recipient_district"] = this.state.recipient_district.trim();
      attributes["recipient_country_sub_division"] = this.state.recipient_country_sub_division.trim();
      attributes["recipient_country_of_birth"] = this.state.recipient_country_of_birth.trim();
      attributes["branch_name"] = this.state.branch_name.trim();
      attributes[
        "transfer_reason_code"
      ] = this.state.recipientReasonCode.trim();
    }
    attributes["recipient_country"] = this.state.recipientCountry.trim();
    if (providersNotRequiredTxnType.includes(txn_type)) {
      attributes["sender_municipality"] = this.state.senderMunicipality.trim();
      attributes["sender_province"] = this.state.senderProvince.trim();
      attributes["transaction_type"] = this.state.transactionType.trim();
    }
    if (version === "v2" && 
      sortCodeCurrencyList.includes(dest_curr)
    ) {
      attributes["sort_code"] = this.state.sortCode.trim();
    }
    this.attributes = attributes;
    if (this.validateForm()) {
      this.setState({
        sweetAlert: this.getSweetAlert(),
      });
    }
  };

  getSweetAlert = () => {
    const { classes } = this.props;
    return (
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="Do you want to create Transaction ?"
        showCancel
        confirmBtnText="Yes"
        cancelBtnText="No"
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.info}
        onConfirm={this.onConfirmation}
        onCancel={this.hideSweetAlert}
      />
    );
  };

  createSignature(params, attributes) {
    // const base_url = window.location.origin;
    // console.log("Params", params);
    this.setState({ loading: true });
    // console.log(params);
    axios({
      method: "post",
      url: base_url + "/transactions/create_signature.json?" + params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {},
    })
      .then((result) => {
        //access the results here....
        // console.log(result);
        this.setState({ loading: false });
        this.doTransaction(attributes, result.data);
      })
      .catch((error) => {
        console.log("Error", error);
        this.setState({
          loading: false,
          alert: true,
          alert_msg: error.response.data.error
            ? error.response.data.error.message
            : error.message,
          alert_title: "Create Signature!",
          danger: true,
          warning: false,
          success: false,
        });
      });
  }
  doTransaction(attributes, data) {
    var params = this.createParamList(attributes, data);
    // console.log(params);
    // const base_url = window.location.origin;
    this.setState({ loading: true });
    axios({
      method: "post",
      url: base_url + "/api/v1/transactions?" + params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {},
    })
      .then((result) => {
        this.setState(
          {
            loading: false,
            alert: true,
            alert_msg: "Transaction successfully initiated.!",
            alert_title: "Transaction!",
            danger: false,
            warning: false,
            success: true,
            times: "0",
          },
          () => this.cleanInput()
        );
      })
      .catch((error) => {
        this.setState({
          loading: false,
          alert: true,
          alert_msg: error.response.data.error.message,
          alert_title: "Error!",
          danger: true,
          warning: false,
          success: false,
        });
      });
  }

  getSourceCurrency = () => {
    const { classes } = this.props;
    return this.sourceCurrencyList.map((data) => {
      return (
        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value={data.value}
          key={data.value}
        >
          {data.name}
        </MenuItem>
      );
    });
  };

  getDestinationCurrency = () => {
    const { classes } = this.props;
    return this.destinationCurrencyList
      .filter((data) => {
        if (providersNotRequiredTxnType.includes(this.state.txn_type)) {
          return data.value === "php";
        } else {
          return data;
        }
      })
      .map((data) => {
        return (
          <MenuItem
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={data.value}
            key={data.value}
          >
            {data.name}
          </MenuItem>
        );
      });
  };

  cleanInput() {
    this.setState({
      agent_name: "",
      agent_code: "",
      gender: "",
      zipcode: "",
      client: "",
      provider: "",
      source_curr: "",
      dest_curr: "",
      curr: "",
      amount: "",
      source_amount: "",
      destinationAmount: "",
      ifsc: "",
      deposit_number: "",
      name: "",
      sender_name: "",
      sender_last_name: "",
      sender_street: "",
      sender_add: "",
      client_txn_id: "",
      sender_city: "",
      sender_country_dob: "",
      email: "",
      sender_country: "",
      phone: "",
      sender_type: "individual",
      txn_type: "",
      provider_all: [],
      sender_mobile_phone_number: "",
      sender_mobile_phone_country: "",
      sender_date_of_birth: "",
      recipientCity: "",
      recipientEmail: "",
      recipientIdType: "",
      recipientIdNumber: "",
      recipientPhone: "",
      recipientPostalCode: "",
      recipientStreet: "",
      recipientReasonCode: "",
      recipientCountry: "",
      recipientAddress: "",
      recipient_date_of_birth: "",
      recipient_city_of_birth: "",
      recipient_town_name: "",
      recipient_district: "",
      recipient_country_sub_division: "",
      recipient_country_of_birth: "",
      branch_name: "",
      recipient_province_of_birth: "",
      version: "",
      memo: "",
      iban: "",
      bicSwift: "",
      sortCode: "",
      bankName: "",
      senderIdNumber: "",
      senderIdType:"",
      senderMunicipality:"",
      senderProvince:"",
      selectedBA:"",
      cpf_tax_id: "",
      recipient_type: "individual",
      bank_sub_code: ""
    });
  }

  createParamList(attributes, data) {
    var param_list = "";
    const { dest_curr, txn_type, curr, version } = this.state;
    // console.log(attributes);
    if (data !== "") {
      if (attributes["api_version"] === "v1") {
        param_list =
          "account_type=" +
          attributes["account_type"] +
          "&api_token=" +
          data["api_token"] +
          "&amount=" +
          attributes["amount"] +
          "&api_version=" + 
          attributes["api_version"] +
          "&currency=" +
          attributes["currency"] +
          "&client_txn_id=" +
          attributes["client_txn_id"];
        if (!ibanCurrencyList.includes(curr) || !BANK_WIRE.includes(txn_type)) {
          // param_list += "&deposit_number=" + attributes["deposit_number"];
          if(!providersNotRequiredTxnType.includes(txn_type)){
            param_list += "&deposit_number=" + attributes["deposit_number"];}
        }
        param_list +=
          "&name=" +
          attributes["name"].trim().replace(/ {1,}/g, "+") +
          "&tonce=" +
          data["tonce"] +
          "&signature=" +
          data["signature"];
      } else if (attributes["api_version"] === "v2") {
        param_list =
          "account_type=" +
          attributes["account_type"] +
          "&api_token=" +
          data["api_token"] +
          "&api_version=" + 
          attributes["api_version"] +
          "&source_amount=" +
          attributes["source_amount"] +
          "&source_currency=" +
          attributes["source_currency"] +
          "&destination_currency=" +
          attributes["destination_currency"] +
          "&client_txn_id=" +
          attributes["client_txn_id"];
        if (!ibanCurrencyList.includes(dest_curr) || !BANK_WIRE.includes(txn_type)) {
          if(!providersNotRequiredTxnType.includes(txn_type)){
          param_list += "&deposit_number=" + attributes["deposit_number"];}
        }
        if (BANK_WIRE.includes(txn_type) && 
          sortCodeCurrencyList.includes(dest_curr) && 
          attributes["deposit_number"] !== ""
        )
        {
          param_list += "&deposit_number=" + attributes["deposit_number"];
        }
        param_list +=
          "&name=" +
          attributes["name"].trim().replace(/ {1,}/g, "+") +
          "&tonce=" +
          data["tonce"] +
          "&signature=" +
          data["signature"];
      }
    } else {
      if (attributes["api_version"] === "v1") {
        param_list =
          "account_type=" +
          attributes["account_type"] +
          "&api_version=" +
          attributes["api_version"] +
          "&amount=" +
          attributes["amount"] +
          "&currency=" +
          attributes["currency"] +
          "&client_id=" +
          attributes["client_id"] +
          "&client_txn_id=" +
          attributes["client_txn_id"];
        if (!ibanCurrencyList.includes(dest_curr) || !BANK_WIRE.includes(txn_type)) {
          // param_list += "&deposit_number=" + attributes["deposit_number"];
          if(!providersNotRequiredTxnType.includes(txn_type)){
            param_list += "&deposit_number=" + attributes["deposit_number"];}
        }
        param_list +=
          "&name=" + attributes["name"].trim().replace(/ {1,}/g, "+");
      } else if (attributes["api_version"] === "v2") {
        param_list =
          "account_type=" +
          attributes["account_type"] +
          "&api_version=" +
          attributes["api_version"] +
          "&source_amount=" +
          attributes["source_amount"] +
          "&source_currency=" +
          attributes["source_currency"] +
          "&destination_currency=" +
          attributes["destination_currency"] +
          "&client_id=" +
          attributes["client_id"] +
          "&client_txn_id=" +
          attributes["client_txn_id"];
        if (!ibanCurrencyList.includes(dest_curr) || !BANK_WIRE.includes(txn_type)) {
          if(!providersNotRequiredTxnType.includes(txn_type)){
            param_list += "&deposit_number=" + attributes["deposit_number"];}
          // param_list += "&deposit_number=" + attributes["deposit_number"];
        }
        if (BANK_WIRE.includes(txn_type) && 
            sortCodeCurrencyList.includes(dest_curr) &&
            attributes["deposit_number"] !== ""
          )
          {
            param_list += "&deposit_number=" + attributes["deposit_number"];
          }

        param_list +=
          "&name=" + attributes["name"].trim().replace(/ {1,}/g, "+");
      }
    }

    if (version === "v1" && txn_type === 'Mobile' && attributes["recipient_email"] !== "")
      param_list += "&recipient_email=" + attributes["recipient_email"].replace(/ {1,}/g, "+");
    if (attributes["account_code"] !== "")
      param_list += "&account_code=" + attributes["account_code"];
    if (attributes["bank_name"] !== "") {
      param_list += "&bank_name=" + attributes["bank_name"];
    }
    if (attributes["agent_code"] !== "")
      param_list += "&agent_code=" + attributes["agent_code"];
    if (attributes["agent_name"] !== "")
      param_list +=
        "&agent_name=" + attributes["agent_name"].replace(/ {1,}/g, "+");

    if (
      version === "v2" &&
      ibanCurrencyList.includes(dest_curr) &&
      BANK_WIRE.includes(txn_type) && attributes["iban"] !== ""
    ) {
      param_list += "&iban=" + attributes["iban"].replace(/ {1,}/g, "+");
    } else if (
      version === "v1" &&
      ibanCurrencyList.includes(curr) &&
      BANK_WIRE.includes(txn_type)
    ) {
      param_list += "&iban=" + attributes["iban"].replace(/ {1,}/g, "+");
    }
    if ((
      bicSwiftCurrencyList.includes(dest_curr) ||
      bicSwiftCurrencyList.includes(curr)) && attributes["bic_swift"] !== ""
    ) {
      param_list +=
        "&bic_swift=" + attributes["bic_swift"].replace(/ {1,}/g, "+");
    }
    if (requireBankSubCode.includes(dest_curr) && attributes["bank_sub_code"] !== "") {
      param_list +=
        "&bank_sub_code=" + attributes["bank_sub_code"].replace(/ {1,}/g, "+");
    }
    if (recipientInformationRequired.includes(dest_curr) && attributes["branch_name"] !== "" && version === "v2")
        param_list +=
          "&branch_name=" +
          attributes["branch_name"].replace(/ {1,}/g, "+");

    if (recipientInformationRequired.includes(dest_curr)) {
      if (attributes["recipient_city"] !== "")
        param_list +=
          "&recipient_city=" +
          attributes["recipient_city"].replace(/ {1,}/g, "+");

      if (attributes["recipient_email"] !== "")
        param_list +=
          "&recipient_email=" +
          attributes["recipient_email"].replace(/ {1,}/g, "+");

      if (attributes["recipient_id_number"] !== "")
        param_list +=
          "&recipient_id_number=" +
          attributes["recipient_id_number"].replace(/ {1,}/g, "+");

      if (attributes["recipient_phone_number"] !== "")
        param_list +=
          "&recipient_phone_number=" +
          attributes["recipient_phone_number"].replace(/ {1,}/g, "+");

      if (attributes["recipient_postal_code"] !== "")
        param_list +=
          "&recipient_postal_code=" +
          attributes["recipient_postal_code"].replace(/ {1,}/g, "+");

      if (attributes["recipient_street"] !== "")
        param_list +=
          "&recipient_street=" +
          attributes["recipient_street"].replace(/ {1,}/g, "+");

      if (attributes["recipient_address"] !== "")
        param_list +=
          "&recipient_address=" +
          attributes["recipient_address"].replace(/ {1,}/g, "+");

      if (attributes["recipient_date_of_birth"] !== "")
        param_list +=
          "&recipient_date_of_birth=" +
          attributes["recipient_date_of_birth"].replace(/ {1,}/g, "+");

      if (attributes["recipient_city_of_birth"] !== "")
        param_list +=
          "&recipient_city_of_birth=" +
          attributes["recipient_city_of_birth"].replace(/ {1,}/g, "+");

      if (attributes["recipient_town_name"] !== "")
        param_list +=
          "&recipient_town_name=" +
          attributes["recipient_town_name"].replace(/ {1,}/g, "+");

      if (attributes["recipient_district"] !== "")
        param_list +=
          "&recipient_district=" +
          attributes["recipient_district"].replace(/ {1,}/g, "+");

      if (attributes["recipient_province_of_birth"] !== "")
        param_list +=
          "&recipient_province_of_birth=" +
          attributes["recipient_province_of_birth"].replace(/ {1,}/g, "+");

      if (attributes["recipient_country_sub_division"] !== "")
        param_list +=
          "&recipient_country_sub_division=" +
          attributes["recipient_country_sub_division"].replace(/ {1,}/g, "+");

      if (attributes["recipient_country_of_birth"] !== "")
        param_list +=
          "&recipient_country_of_birth=" +
          attributes["recipient_country_of_birth"].replace(/ {1,}/g, "+");

      if (attributes["transfer_reason_code"] !== "")
        param_list +=
          "&transfer_reason_code=" +
          attributes["transfer_reason_code"].replace(/ {1,}/g, "+");
      
      if (attributes["recipient_type"] !== "")
        param_list +=
          "&recipient_type=" + attributes["recipient_type"].replace(/ {1,}/g, "+");
    }
    if (
      (recipientCountrySet.has(dest_curr) && dest_curr !== "") ||
      (recipientCountrySet.has(curr) && curr !== "")
    ) {
      param_list +=
        "&recipient_country=" +
        attributes["recipient_country"].replace(/ {1,}/g, "+");
    }
    if (attributes["sender_street"] !== "")
      param_list +=
        "&sender_street=" + attributes["sender_street"].replace(/ {1,}/g, "+");
    if (attributes["sender_address"] !== "")
      param_list +=
        "&sender_address=" +
        attributes["sender_address"].replace(/ {1,}/g, "+");
    if (attributes["sender_city"] !== "")
      param_list +=
        "&sender_city=" + attributes["sender_city"].replace(/ {1,}/g, "+");
    if (attributes["sender_province"] !== "" && txn_type !== 'Cash' && requireSenderProvince.includes(dest_curr))
        param_list +=
        "&sender_province=" +
        attributes["sender_province"].replace(/ {1,}/g, "+");
    if (attributes["sender_country"] !== "")
      param_list +=
        "&sender_country=" +
        attributes["sender_country"].replace(/ {1,}/g, "+");
    if (attributes["sender_country_of_birth"] !== "")
      param_list +=
        "&sender_country_of_birth=" +
        attributes["sender_country_of_birth"].replace(/ {1,}/g, "+");
    if (attributes["sender_date_of_birth"] !== "")
      param_list +=
        "&sender_date_of_birth=" +
        attributes["sender_date_of_birth"].replace(/ {1,}/g, "+");
    if (attributes["sender_email"] !== "")
      param_list +=
        "&sender_email=" + attributes["sender_email"].replace(/ {1,}/g, "+");
    if (providersNotRequiredTxnType.includes(txn_type) || requireRecipientId.includes(dest_curr)) {
      param_list +=
        "&sender_id_type=" +
        attributes["sender_id_type"].replace(/ {1,}/g, "+");
      param_list +=
        "&recipient_id_type=" +
        attributes["recipient_id_type"].replace(/ {1,}/g, "+");
    }else if(attributes["sender_id_type"] !== "") {
      param_list +=
        "&sender_id_type=" +
        attributes["sender_id_type"].replace(/ {1,}/g, "+");
    };

    if (attributes["sender_id_number"] !== "")
      param_list +=
        "&sender_id_number=" +
        attributes["sender_id_number"].replace(/ {1,}/g, "+");

    if (attributes["sender_first_name"] !== "")
      param_list +=
        "&sender_first_name=" +
        attributes["sender_first_name"].replace(/ {1,}/g, "+");
    if (attributes["sender_gender"] !== "")
      param_list +=
        "&sender_gender=" + attributes["sender_gender"].replace(/ {1,}/g, "+");
    if (attributes["sender_last_name"] !== "")
      param_list +=
        "&sender_last_name=" +
        attributes["sender_last_name"].replace(/ {1,}/g, "+");
    if (attributes["sender_mobile_phone_country"] !== "")
      param_list +=
        "&sender_mobile_phone_country=" +
        attributes["sender_mobile_phone_country"].replace(/ {1,}/g, "+");
    if (attributes["sender_mobile_phone_number"] !== "")
      param_list +=
        "&sender_mobile_phone_number=" +
        attributes["sender_mobile_phone_number"].replace(/ {1,}/g, "+");
    if (attributes["sender_type"] !== "")
      param_list +=
        "&sender_type=" + attributes["sender_type"].replace(/ {1,}/g, "+");
    if (attributes["sender_zipcode"] !== "")
      param_list +=
        "&sender_zipcode=" +
        attributes["sender_zipcode"].replace(/ {1,}/g, "+");
    if (attributes["ifsc"] !== "")
      param_list += "&ifsc=" + attributes["ifsc"].replace(/ {1,}/g, "+");
    if (attributes["cpf_tax_id"] !== "")
      param_list += "&cpf_tax_id=" + attributes["cpf_tax_id"].replace(/ {1,}/g, "+");
    if (providersNotRequiredTxnType.includes(txn_type)) {
      param_list +=
        "&sender_municipality=" +
        attributes["sender_municipality"].replace(/ {1,}/g, "+");
      param_list +=
        "&sender_province=" +
        attributes["sender_province"].replace(/ {1,}/g, "+");
      // param_list +=
      //   "&transaction_type=" +
      //   attributes["transaction_type"].replace(/ {1,}/g, "+");
    }
    if (
      version === "v2" &&
      sortCodeCurrencyList.includes(dest_curr) && attributes["sort_code"] !== ""
    ) {
      param_list +=
        "&sort_code=" + attributes["sort_code"].replace(/ {1,}/g, "+");
    }
    //  if (attributes["sender_gender"] !== "")
    //     param_list += "&sender_gender=" + attributes["sender_gender"].replace(/ {1,}/g, "+");

    if (attributes["memo"] !== "")
      param_list += "&memo=" + attributes["memo"].replace(/ {1,}/g, "+");
    // this.setState( { params: param_list });
    return param_list;
  }
  validateForm() {
    const {
      txn_type,
      client,
      version,
      curr,
      provider,
      amount,
      source_amount,
      source_curr,
      dest_curr,
      ifsc,
      name,
      client_txn_id,
      deposit_number,
      memo,
      sender_name,
      sender_last_name,
      sender_street,
      sender_add,
      sender_city,
      sender_country_dob,
      sender_date_of_birth,
      sender_country,
      sender_mobile_phone_country,
      sender_mobile_phone_number,
      email,
      zipcode,
      recipientCity,
      recipientStreet,
      recipientReasonCode,
      recipientPostalCode,
      recipientEmail,
      recipientIdType,
      recipientIdNumber,
      recipientPhone,
      recipientAddress,
      recipient_date_of_birth,
      recipient_city_of_birth,
      recipient_town_name,
      recipient_district,
      recipient_province_of_birth,
      recipient_country_sub_division,
      recipient_country_of_birth,
      branch_name,
      iban,
      bicSwift,
      sortCode,
      bankName,
      recipientCountry,
      senderIdNumber,
      senderIdType,
      senderMunicipality,
      senderProvince,
      selectedBA,
      cpf_tax_id,
      bank_sub_code
    } = this.state;
    const isMemoVisible = this.isMemoVisible();
    let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    let streetRegex = /[0-9]\s[a-zA-Z0-9]/;
    if (txn_type === "" || txn_type === "select") {
      this.setState({ valid_txn_type: "false" });
      alert("Please select Transaction Type!!");

      return false;
    }
    if(selectedBA==="" && userType===SUPERADMINUSER){
      alert("Please select BA !!!");
      return false;
    }  
    if (!client) {
      this.setState({ valid_client: "false" });
      alert("Please select Client ID!!");
      return false;
    } else if (version === "" || version === "select") {
      this.setState({ valid_version: "false" });
      alert("Please select API Version!!");
      return false;
    } else if (version === "v1") {
      if (curr === "" || curr === "select") {
        this.setState({ valid_curr: "false" });
        alert("Please select Currency!!");
        return false;
      } else if (curr === "inr" && ifsc === "") {
        alert("Please enter IFSC!!");
        return false;
      } else if (amount === "") {
        this.setState({ valid_amount: "false" });
        alert("Please enter Amount!!");
        return false;
      }
    } else if (version === "v2") {
      if (source_curr === "" || source_curr === "select") {
        this.setState({ valid_source_curr: "false" });
        alert("Please select Source Currency!!");
        return false;
      } else if (source_amount === "") {
        this.setState({ valid_source_amount: "false" });
        alert("Please enter Source Amount!!");
        return false;
      } else if (dest_curr === "" || dest_curr === "select") {
        this.setState({ valid_dest_curr: "false" });
        alert("Please select Destination Currency!!");
        return false;
      } else if (dest_curr === "inr" && ifsc === "") {
        alert("Please enter IFSC!!");
        return false;
      }
    }
    if (recipientCountry === "") {
      alert("Please select Recipient Country !! !!");
      return false;
    }

    if (provider === "" || provider === "select") {
      // console.log(curr, dest_curr);

      // console.log()
      if (
        ((!noProviderCurrencyList.includes(dest_curr) && dest_curr !== "") ||
          (!noProviderCurrencyList.includes(curr) && curr !== "")) &&
        ((curr !== "inr" && curr !== "") ||
          (dest_curr !== "inr" && dest_curr !== ""))
      ) {
        if (!providersNotRequiredTxnType.includes(txn_type)) {
          this.setState({ valid_provider: "false" });
          alert("Please select Bank/Provider!!");
          return false;
        }
      }
    }
    if (
      (bankNameList.includes(dest_curr) || bankNameList.includes(curr)) &&
      bankName.trim() === ""
    ) {
      if (!providersNotRequiredTxnType.includes(txn_type)) {
        alert("Please enter Bank Name!!");
        return false;
      }
    }
    if (client_txn_id === "") {
      this.setState({ valid_client_txn_id: "false" });
      alert("Please enter Client Transaction ID!!");
      return false;
    }
    if (
      (!ibanCurrencyList.includes(dest_curr) && dest_curr !== "") ||
      (!ibanCurrencyList.includes(curr) && curr !== "") ||
      !BANK_WIRE.includes(txn_type)
    ) {
      if (
        deposit_number === "" &&
        !providersNotRequiredTxnType.includes(txn_type)
      ) {
        this.setState({ valid_deposit_number: "false" });
        alert("Please enter Deposit Number!!");
        return false;
      }
    }
    if (name === "") {
      this.setState({ valid_name: "false" });
      alert("Please enter Name!!");
      return false;
    }

    if (
      version === "v2" &&
      ibanCurrencyList.includes(dest_curr) &&
      iban === "" &&
      BANK_WIRE.includes(txn_type) && !sortCodeCurrencyList.includes(dest_curr)
    ) {
      alert("Please enter Iban!!");
      return false;
    } else if (
      version === "v1" &&
      ibanCurrencyList.includes(curr) &&
      iban === "" &&
      BANK_WIRE.includes(txn_type)
    ) {
      alert("Please enter Iban!!");
      return false;
    }
    if (
      (mandatoryBicSwiftCurrencyList.includes(dest_curr) ||
        mandatoryBicSwiftCurrencyList.includes(curr)) &&
      bicSwift.trim() === ""
    ) {
      alert("Please enter bic Swift!!");
      return false;
    }

    if (requireBankSubCode.includes(dest_curr) && bank_sub_code.trim() === "") {
      alert("Please enter bank sub code!!");
      return false;
    }

    // if (isMemoVisible) {
    //   if (sender_name === "") {
    //     alert("Please enter Sender First Name !!");
    //     return false;
    //   }
    //   if (email === "") {
    //     alert("Please enter email !!");
    //     return false;
    //   }
    //   if (!emailRegex.test(email)) {
    //     alert("Please enter valid Email !!");
    //     return false;
    //   }
    //   if (sender_mobile_phone_number === "") {
    //     alert("Please enter sender mobile phone number !!");
    //     return false;
    //   }
    //   if (memo === "") {
    //     alert("Please enter Memo !!!");
    //     return false;
    //   }
    // }

    if (senderInformationRequired.includes(dest_curr)) {
      if (sender_name === "") {
        alert("Please enter Sender First Name !!");
        return false;
      }

      if (sender_last_name === "") {
        alert("Please enter Sender First Name !!");
        return false;
      }

      if (sender_street === "") {
        alert("Please enter Sender Street !!");
        return false;
      }
      if (
        providersNotRequiredTxnType.includes(txn_type) &&
        senderMunicipality === ""
      ) {
        alert("Please enter Sender Municipality !!");
        return false;
      }
      if (
        (providersNotRequiredTxnType.includes(txn_type) || requireSenderProvince.includes(dest_curr)) &&
        senderProvince === ""
      ) {
        alert("Please enter Sender Province !!");
        return false;
      }

      if (sender_add === "") {
        alert("Please enter Sender Address !!");
        return false;
      }

      if (sender_city === "") {
        alert("Please enter Sender First Name !!");
        return false;
      }

      if (sender_country_dob === "") {
        alert("Please enter Sender Country of Birth !!");
        return false;
      }

      if (sender_date_of_birth === "") {
        alert("Please enter Sender Date of Birth !!");
        return false;
      } else if (!/^\d{4}\-\d{1,2}\-\d{1,2}$/.test(sender_date_of_birth)) {
        alert("Please enter Sender Date of Birth in yyyy-mm-dd Format !!");
        return false;
      }

      if (sender_country === "") {
        alert("Please enter Sender Country !!");
        return false;
      }

      if (email === "") {
        alert("Please enter email !!");
        return false;
      }

      if (!emailRegex.test(email)) {
        alert("Please enter valid Email !!");
        return false;
      }
      if (
        (providersNotRequiredTxnType.includes(txn_type) || dest_curr == 'kes') &&
        senderIdType === ""
      ) {
        alert("Please enter Sender Id Type !!");
        return false;
      }
      if (senderIdNumber === "") {
        alert("Please enter Sender Id Number !!");
        return false;
      }

      if (sender_mobile_phone_country === "") {
        alert("Please enter Sender Mobile Phone Country !!");
        return false;
      }

      if (sender_mobile_phone_number === "") {
        alert("Please enter Sender Mobile Phone Number !!");
        return false;
      }

      if (zipcode === "") {
        alert("Please enter Zipcode !!");
        return false;
      }
    }

    if (curr === "ZAR" || recipientInformationRequired.includes(dest_curr)) {
      if (recipientCity === "") {
        alert("Please enter Recipient City !!");
        return false;
      }
      if (recipientEmail === "") {
        alert("Please enter Recipient Email !! !!");
        return false;
      }
      if (!emailRegex.test(recipientEmail)) {
        alert("Please enter valid Recipient Email !!");
        return false;
      }

      if (
        (providersNotRequiredTxnType.includes(txn_type) || requireRecipientId.includes(dest_curr)) &&
        recipientIdType === ""
      ) {
        alert("Please enter Recipient Id Type !!");
        return false;
      }

      if (recipientIdNumber === "" && requireRecipientId.includes(dest_curr)) {
        alert("Please enter Recipient Id Number !!");
        return false;
      }
      if (recipientPhone === "") {
        alert("Please enter Recipient Phone Number !!");
        return false;
      }
      if (recipientPostalCode === "") {
        alert("Please enter Recipient Postal Code !! !!");
        return false;
      }
      if (recipientStreet === "") {
        alert("Please enter Recipient Street !! !!");
        return false;
      }
      if (!streetRegex.test(recipientStreet)) {
        alert("Please enter valid Recipient Street !! !!");
        return false;
      }
      if (recipientAddress === "") {
        alert("Please enter Recipient Address !! !!");
        return false;
      }
      if (recipientReasonCode === "") {
        alert("Please enter Recipient Reason !! !!");
        return false;
      }
    }

    return true;
  }

  handleShowInfo = (event) => {
    event.preventDefault();
    this.setState({ sender_show: !this.state.sender_show });
  };
  hideAlert = (times) => {
    this.setState({
      alert: false,
      alert_msg: "",
      alert_title: "",
      alert_type: "",
    });
  };

  getMobileProviders = () => {
    const { classes } = this.props;
    return this.state.provider_all.map((row) => (
      <MenuItem
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected,
        }}
        key={row.code}
        value={row.code}
      >
        {row.name}
      </MenuItem>
    ));
  };

  getBankProviders = () => {
    const { classes } = this.props;
    return this.state.provider_all.map((row) => (
      <MenuItem
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected,
        }}
        key={row.attributes.code}
        value={row.attributes.code}
      >
        {row.attributes.name}
      </MenuItem>
    ));
  };
  getClientData = (clients) => {
    let arrayOfClients = [];

    if (!Array.isArray(clients)) {
      arrayOfClients = [clients];
    } else {
      arrayOfClients = clients;
    }

    if (this.userType === "agent") {
      if (this.walletInfo) {
        const { id } = JSON.parse(this.walletInfo);
        const isClientAvailable = arrayOfClients.some((data) => data.id === id);
        this.setState({
          client_all: arrayOfClients,
          client: isClientAvailable ? id : "",
        });
      }
    } else {
      this.setState({
        client_all: arrayOfClients,
      });
    }
  };

  render() {
    const { classes } = this.props;
    const isMemoVisible = this.isMemoVisible();
    const {
      dest_curr,
      curr,
      txn_type,
      version,
      recipientCountryList,
    } = this.state;
    let recipientCountryListToBeShown = [];
    if (version === "v2") {
      recipientCountryListToBeShown = recipientCountryList[dest_curr]
        ? recipientCountryList[dest_curr]
        : [];
    } else {
      recipientCountryListToBeShown = recipientCountryList[curr]
        ? recipientCountryList[curr]
        : [];
    }

    return (
      <div>
        <CustomSweet
          alert={this.state.alert}
          message={this.state.alert_msg}
          title={this.state.alert_title}
          hide_alert={this.hideAlert}
          danger={this.state.danger}
          warning={this.state.warning}
          success={this.state.success}
          times={this.state.times}
        />
        <div className={this.state.loading === true ? classes.main_screen : ""}>
          <div align="center">
            {this.state.loading === true && <Progress />}
          </div>
          {this.state.sweetAlert}
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" text>
                  <CardText color="rose">
                    <h4>Create Transaction</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <GridContainer justify="center">
                    <GridItem xs={12} md={6} sm={10}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                          error={this.state.valid_txn_type === "false"}
                        >
                          {"Transaction Type *"}
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.txn_type}
                          onChange={this.handleTypeChange}
                          inputProps={{
                            name: "simpleSelect",
                            id: "transaction-select",
                          }}
                        >
                          {TransactionType.map((data) => {
                            return (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value={data.value}
                                key={data.value}
                              >
                                {data.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="center">
                    <GridItem xs={12} md={6} sm={10}>
                    {this.getBAUserList()}
                    </GridItem>
                  </GridContainer>   
                  <GridContainer justify="center">
                    <GridItem xs={12} md={6} sm={10}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                        disabled={this.userType === "agent"}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                          error={this.state.valid_client === "false"}
                        >
                          {"Clients *"}
                        </InputLabel>

                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.client}
                          onChange={this.handleClientChange}
                          inputProps={{
                            name: "simpleSelect",
                            id: "client-select",
                          }}
                        >
                          {this.state.client_all.map((row) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={row.id}
                              key={row.id}
                            >
                              {row.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="center">
                    <GridItem xs={12} md={6} sm={10}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                          error={this.state.valid_version === "false"}
                        >
                          {"API Version *"}
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.version}
                          onChange={this.handleVersionChange}
                          inputProps={{
                            name: "simpleSelect",
                            id: "version-select",
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={"v1"}
                          >
                            Version 1
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={"v2"}
                          >
                            Version 2
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  {this.state.version === "v1" && (
                    <div>
                      <GridContainer justify="center">
                        <GridItem xs={12} md={3} sm={10}>
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                            error={this.state.valid_curr === "false"}
                            style={{ height: "50px" }}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              {"Currency *"}
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.curr}
                              onChange={this.handleCurrencyChange}
                              inputProps={{
                                name: "simpleSelect",
                                id: "currency-select",
                              }}
                            >
                              {this.getDestinationCurrency()}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} md={3} sm={10}>
                          <FormControl style={{ height: "50px" }} fullWidth>
                            <InputLabel
                              className={
                                this.state.valid_amount === "false"
                                  ? classes.input_label_error
                                  : classes.input_label_success
                              }
                            >
                              Amount *
                            </InputLabel>
                            <Input
                              value={this.state.amount}
                              error={this.state.valid_amount === "false"}
                              classes={{
                                underline:
                                  this.state.valid_amount === "false"
                                    ? classes.input_underline_error
                                    : classes.input_underline,
                                input: classes.user_input,
                              }}
                              onChange={this.handleAmountChange}
                              autoComplete="off"
                            />
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      {recipientCountrySet.has(curr) && curr !== "" ? (
                        <GridContainer justify="center">
                          <GridItem xs={12} md={6} sm={10}>
                            <FormControl
                              fullWidth
                              className={classes.selectFormControl}
                            >
                              <InputLabel className={classes.selectLabel}>
                                {"Recipient Country*"}
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu,
                                }}
                                classes={{
                                  select: classes.select,
                                }}
                                value={this.state.recipientCountry}
                                onChange={this.handleRecipientChange}
                                inputProps={{
                                  name: "recipientCountry",
                                }}
                              >
                                {recipientCountryListToBeShown.map((row) => (
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected,
                                    }}
                                    value={row.attributes.code}
                                    key={row.attributes.code}
                                  >
                                    {row.attributes.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </GridItem>
                        </GridContainer>
                      ) : null}
                    </div>
                  )}
                  {this.state.version === "v2" && (
                    <div>
                      <GridContainer justify="center">
                        <GridItem xs={12} md={3} sm={10}>
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                            style={{ height: "50px" }}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                              error={this.state.valid_source_curr === "false"}
                            >
                              {"Source Currency *"}
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.source_curr}
                              onChange={this.handleSourceCurrencyChange}
                              inputProps={{
                                name: "simpleSelect",
                                id: "source-currency-select",
                              }}
                            >
                              {this.getSourceCurrency()}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem
                          xs={12}
                          md={3}
                          sm={10}
                          style={{ height: "50px" }}
                        >
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                              error={this.state.valid_dest_curr === "false"}
                            >
                              {"Destination Currency *"}
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.dest_curr}
                              onChange={this.handleDestCurrencyChange}
                              inputProps={{
                                name: "simpleSelect",
                                id: "destination-currency-select",
                              }}
                            >
                              {this.getDestinationCurrency()}
                            </Select>
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      {recipientCountrySet.has(dest_curr) &&
                      dest_curr !== "" ? (
                        <GridContainer justify="center">
                          <GridItem xs={12} md={6} sm={10}>
                            <FormControl
                              fullWidth
                              className={classes.selectFormControl}
                            >
                              <InputLabel className={classes.selectLabel}>
                                {"Recipient Country*"}
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu,
                                }}
                                classes={{
                                  select: classes.select,
                                }}
                                value={this.state.recipientCountry}
                                onChange={this.handleRecipientChange}
                                inputProps={{
                                  name: "recipientCountry",
                                }}
                              >
                                {recipientCountryListToBeShown.map((row) => (
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected,
                                    }}
                                    value={row.attributes.code}
                                    key={row.attributes.code}
                                  >
                                    {row.attributes.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </GridItem>
                        </GridContainer>
                      ) : null}
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          md={3}
                          sm={5}
                          style={{ height: "50px" }}
                        >
                          <FormControl fullWidth>
                            <InputLabel
                              className={
                                this.state.valid_amount === "false"
                                  ? classes.input_label_error
                                  : classes.input_label_success
                              }
                            >
                              Source Amount *
                            </InputLabel>
                            <Input
                              value={this.state.source_amount}
                              error={this.state.valid_source_amount === "false"}
                              classes={{
                                underline:
                                  this.state.valid_source_amount === "false"
                                    ? classes.input_underline_error
                                    : classes.input_underline,
                                input: classes.user_input,
                              }}
                              onChange={this.handleSourceAmountChange}
                              autoComplete="off"
                              inputProps={{
                                type: "number",
                              }}
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem
                          xs={12}
                          md={3}
                          sm={5}
                          style={{ height: "50px" }}
                        >
                          <FormControl fullWidth>
                            <InputLabel>Destination Amount *</InputLabel>
                            <Input
                              value={this.state.destinationAmount}
                              onChange={this.handleDestinationAmountChange}
                              autoComplete="off"
                              inputProps={{
                                type: "number",
                              }}
                            />
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                    </div>
                  )}
                  {(this.state.curr === "inr" ||
                    this.state.dest_curr === "inr") && (
                    <div>
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          md={6}
                          sm={10}
                          style={{ height: "50px" }}
                        >
                          <FormControl fullWidth>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                placeholder: "Ifsc",
                                value: this.state.ifsc,
                                onChange: this.handleifscChange,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                    </div>
                  )}
                  {!(
                    noProviderCurrencyList.includes(dest_curr) ||
                    noProviderCurrencyList.includes(curr) ||
                    providersNotRequiredTxnType.includes(txn_type)
                  ) ? (
                    <GridContainer justify="center">
                      <GridItem xs={12} md={6} sm={10}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                          style={{ height: "40px" }}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                            error={this.state.valid_provider === "false"}
                          >
                            {this.state.curr === "inr" ||
                            this.state.dest_curr === "inr"
                              ? "Bank/Provider"
                              : "Bank/Provider *"}
                          </InputLabel>

                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.provider}
                            onChange={this.handleProviderChange}
                            inputProps={{
                              name: "simpleSelect",
                              id: "provider-select",
                            }}
                          >
                            {this.state.txn_type === "Mobile"
                              ? this.getMobileProviders()
                              : this.getBankProviders()}
                          </Select>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  ) : null}
                  {(bankNameList.includes(dest_curr) ||
                    bankNameList.includes(curr)) &&
                  BANK_WIRE.includes(txn_type) ? (
                    <GridContainer justify="center">
                      <GridItem
                        xs={12}
                        md={6}
                        sm={10}
                        style={{ height: "50px" }}
                      >
                        <FormControl fullWidth>
                          <CustomInput
                            labelText="Bank Name"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              name: "bankName",
                              value: this.state.bankName,
                              onChange: this.handleRecipientChange,
                              autoComplete: "off",
                            }}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  ) : null}
                  <GridContainer justify="center">
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          labelText="Client Transaction ID *"
                          success={this.state.valid_client_txn_id === ""}
                          error={this.state.valid_client_txn_id === "false"}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: this.state.client_txn_id,
                            onChange: this.handleClientTxnChange,
                            autoComplete: "off",
                          }}
                        />
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  {sortCodeCurrencyList.includes(dest_curr) ?
                    <GridContainer justify="center">
                      <GridItem
                        xs={12}
                        md={6}
                        sm={10}
                        style={{ height: "50px" }}
                      >
                        <FormControl fullWidth>
                          <CustomInput
                            labelText="Deposit Number"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              onChange: this.handleDepositChange,
                              onBlur: this.handleBlur,
                              value: this.state.deposit_number,
                              autoComplete: "off",
                            }}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer> : (((ibanCurrencyList.includes(dest_curr) &&
                    dest_curr !== "") ||
                    (ibanCurrencyList.includes(curr) && curr !== "")) &&
                    BANK_WIRE.includes(txn_type)) ||
                  providersNotRequiredTxnType.includes(txn_type) ? null : (
                    <GridContainer justify="center">
                      <GridItem
                        xs={12}
                        md={6}
                        sm={10}
                        style={{ height: "50px" }}
                      >
                        <FormControl fullWidth>
                          <CustomInput
                            labelText="Deposit Number *"
                            success={this.state.valid_deposit_number === ""}
                            error={this.state.valid_deposit_number === "false"}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              onChange: this.handleDepositChange,
                              onBlur: this.handleBlur,
                              value: this.state.deposit_number,
                              autoComplete: "off"
                            }}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  )}
                  {version == 'v2' && (sortCodeCurrencyList.includes(dest_curr)) &&
                  BANK_WIRE.includes(txn_type) ? (
                    <GridContainer justify="center">
                      <GridItem
                        xs={12}
                        md={6}
                        sm={10}
                        style={{ height: "50px" }}
                      >
                        <FormControl fullWidth>
                          <CustomInput
                            labelText="Sort Code"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              name: "sortCode",
                              value: this.state.sortCode,
                              onChange: this.handleRecipientChange,
                              autoComplete: "off",
                            }}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  ) : null}
                  <GridContainer justify="center">
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <CustomInput
                          labelText="Name *"
                          success={this.state.valid_name === ""}
                          error={this.state.valid_name === "false"}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: this.handleNameChange,
                            value: this.state.name,
                            autoComplete: "off",
                          }}
                        />
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  {this.state.version === "v1" && this.state.txn_type === 'Mobile' && <GridContainer justify="center">
                    <GridItem
                      xs={12}
                      md={6}
                      sm={10}
                      style={{ height: "50px" }}
                    >
                      <FormControl fullWidth>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            placeholder: "Recipient Email",
                            name: "recipientEmail",
                            value: this.state.recipientEmail,
                            onChange: this.handleRecipientChange,
                            autoComplete: "off",
                          }}
                        />
                      </FormControl>
                    </GridItem>
                  </GridContainer>}
                  {(ibanCurrencyList.includes(dest_curr) ||
                    ibanCurrencyList.includes(curr)) &&
                  BANK_WIRE.includes(txn_type) ? (
                    <GridContainer justify="center">
                      <GridItem
                        xs={12}
                        md={6}
                        sm={10}
                        style={{ height: "50px" }}
                      >
                        <FormControl fullWidth>
                          <CustomInput
                            labelText="IBAN"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              name: "iban",
                              value: this.state.iban,
                              onChange: this.handleRecipientChange,
                              autoComplete: "off",
                            }}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  ) : null}
                  {(bicSwiftCurrencyList.includes(dest_curr) ||
                    bicSwiftCurrencyList.includes(curr)) &&
                  BANK_WIRE.includes(txn_type) ? (
                    <GridContainer justify="center">
                      <GridItem
                        xs={12}
                        md={6}
                        sm={10}
                        style={{ height: "50px" }}
                      >
                        <FormControl fullWidth>
                          <CustomInput
                            labelText="bic Swift"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              name: "bicSwift",
                              value: this.state.bicSwift,
                              onChange: this.handleRecipientChange,
                              autoComplete: "off",
                            }}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  ) : null}
                  {(requireBankSubCode.includes(dest_curr) ||
                    requireBankSubCode.includes(curr)) && BANK_WIRE.includes(txn_type) ? (
                    <GridContainer justify="center">
                      <GridItem
                        xs={12}
                        md={6}
                        sm={10}
                        style={{ height: "50px" }}
                      >
                        <FormControl fullWidth>
                          <CustomInput
                            labelText="Bank Sub Code"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              name: "bank_sub_code",
                              value: this.state.bank_sub_code,
                              onChange: this.handleBankSubCodeChange,
                              autoComplete: "off",
                            }}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  ) : null}
                  {this.state.sender_show && (
                    <div>
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          md={6}
                          sm={10}
                          style={{ height: "50px" }}
                        >
                          <FormControl fullWidth>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                placeholder: "Sender First Name",
                                value: this.state.sender_name,
                                onChange: this.handleSenderNameChange,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          md={6}
                          sm={10}
                          style={{ height: "50px" }}
                        >
                          <FormControl fullWidth>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                placeholder: "Sender Last Name",
                                value: this.state.sender_last_name,
                                onChange: this.handleSenderLastNameChange,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          md={6}
                          sm={10}
                          style={{ height: "50px" }}
                        >
                          <FormControl fullWidth>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                placeholder: "Sender Street Name",
                                value: this.state.sender_street,
                                onChange: this.handleSenderStreetChange,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          md={6}
                          sm={10}
                          style={{ height: "50px" }}
                        >
                          <FormControl fullWidth>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                placeholder: "Sender Address",
                                value: this.state.sender_add,
                                onChange: this.handleSenderAddChange,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          md={6}
                          sm={10}
                          style={{ height: "50px" }}
                        >
                          <FormControl fullWidth>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                placeholder: "Sender City",
                                value: this.state.sender_city,
                                onChange: this.handleSenderCityChange,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      {providersNotRequiredTxnType.includes(txn_type) ? (
                        <>
                          <GridContainer justify="center">
                            <GridItem
                              xs={12}
                              md={6}
                              sm={10}
                              style={{ height: "50px" }}
                            >
                              <FormControl fullWidth>
                                <CustomInput
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    placeholder: "Sender Municipality",
                                    value: this.state.senderMunicipality,
                                    onChange: this.handleRecipientChange,
                                    autoComplete: "off",
                                    name: "senderMunicipality",
                                  }}
                                />
                              </FormControl>
                            </GridItem>
                          </GridContainer>
                          </>  
                        ) : null}
                        {providersNotRequiredTxnType.includes(txn_type) || requireSenderProvince.includes(dest_curr) ? (
                          <>
                            <GridContainer justify="center">
                              <GridItem
                                xs={12}
                                md={6}
                                sm={10}
                                style={{ height: "50px" }}
                              >
                                <FormControl fullWidth>
                                  <CustomInput
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      placeholder: "Sender Province",
                                      value: this.state.senderProvince,
                                      onChange: this.handleSenderProvinceChange,
                                      autoComplete: "off",
                                      name: "senderProvince",
                                    }}
                                  />
                                </FormControl>
                              </GridItem>
                            </GridContainer>
                          </>
                        ) : null}
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          md={6}
                          sm={10}
                          style={{ height: "50px" }}
                        >
                          <FormControl fullWidth>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                placeholder: "Sender Country Of Birth",
                                value: this.state.sender_country_dob,
                                onChange: this.handleSenderCountryDobChange,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          md={6}
                          sm={10}
                          style={{ height: "50px" }}
                        >
                          <FormControl fullWidth>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                placeholder: "Sender Date Of Birth(YYYY-MM-DD)",
                                value: this.state.sender_date_of_birth,
                                onChange: this.handleSenderDobChange,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          md={6}
                          sm={10}
                          style={{ height: "50px" }}
                        >
                          <FormControl fullWidth style={{ marginTop: "20px" }}>
                            {/* <CustomInput
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                placeholder: "Sender Country",
                                value: this.state.sender_country,
                                onChange: this.handleSenderCountryChange,
                                autoComplete: "off",
                              }}
                            /> */}
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              {"Sender Country"}
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.sender_country}
                              onChange={this.handleSenderCountryChange}
                              inputProps={{
                                name: "simpleSelect",
                                id: "version-select",
                              }}
                            >
                              {senderCountryList.map((data) => {
                                return (
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected,
                                    }}
                                    value={data.value}
                                    key={data.value}
                                  >
                                    {data.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          md={6}
                          sm={10}
                          style={{ height: "50px" }}
                        >
                          <FormControl fullWidth>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                placeholder: "Email",
                                value: this.state.email,
                                onChange: this.handleEmailChange,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      {providersNotRequiredTxnType.includes(txn_type) ? (
                        <GridContainer justify="center">
                          <GridItem
                            xs={12}
                            md={6}
                            sm={10}
                            style={{ height: "50px" }}
                          >
                            <FormControl fullWidth>
                              <CustomInput
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  placeholder: "Sender Id Type",
                                  value: this.state.senderIdType,
                                  onChange: this.handleRecipientChange,
                                  autoComplete: "off",
                                  name: "senderIdType",
                                }}
                              />
                            </FormControl>
                          </GridItem>
                        </GridContainer>
                      ) : <GridContainer justify="center">
                        <GridItem xs={12} md={6} sm={10}
                        style={{ height: "50px" }}
                        >
                        <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                            style={{ marginTop: "20px" }}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                              error={this.state.valid_sender_id_type === "false"}
                            >
                              {"Sender ID Type "}
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.senderIdType}
                              onChange={this.handleSenderIdTypeChange}
                              inputProps={{
                                name: "simpleSelect",
                                id: "senderIdType"
                              }}
                            >
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value={"ID"}
                              >
                                Identity Card
                              </MenuItem>
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value={"PP"}
                              >
                                Passport
                              </MenuItem>
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value={"O"}
                              >
                                Other
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                      </GridContainer>}
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          md={6}
                          sm={10}
                          style={{ height: "50px" }}
                        >
                          <FormControl fullWidth>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                placeholder: "Sender ID Number",
                                value: this.state.senderIdNumber,
                                onChange: this.handleRecipientChange,
                                name: "senderIdNumber",
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          md={6}
                          sm={10}
                          style={{ height: "50px" }}
                        >
                          <FormControl fullWidth>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                placeholder: "Sender Mobile Phone Country",
                                value: this.state.sender_mobile_phone_country,
                                onChange: this.handleSenderMobCountryChange,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          md={6}
                          sm={10}
                          style={{ height: "50px" }}
                        >
                          <FormControl fullWidth>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                placeholder: "Sender Mobile Phone Number",
                                value: this.state.sender_mobile_phone_number,
                                onChange: this.handlePhoneChange,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          md={6}
                          sm={10}
                          style={{ height: "50px" }}
                        >
                          <FormControl fullWidth style={{ marginTop: "20px" }}>
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              {"Sender Type"}
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.sender_type}
                              onChange={this.handleSenderTypeChange}
                              inputProps={{
                                name: "senderTypeSelect"
                              }}
                            >
                              {segmentType.map((data) => {
                                return (
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected,
                                    }}
                                    value={data.value}
                                    key={data.value}
                                  >
                                    {data.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          md={6}
                          sm={10}
                          style={{ height: "50px" }}
                        >
                          <FormControl fullWidth>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                placeholder: "Sender Zipcode",
                                value: this.state.zipcode,
                                onChange: this.handleZipChange,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          md={6}
                          sm={10}
                          style={{ height: "50px" }}
                        >
                          <FormControl fullWidth style={{ marginTop: "20px" }}>
                            {/* <CustomInput
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                placeholder: "Sender Gender",
                                value: this.state.gender,
                                onChange: this.handleGenderChange,
                                autoComplete: "off",
                              }}
                            /> */}
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              {"Sender Gender"}
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.gender}
                              onChange={this.handleGenderChange}
                              inputProps={{
                                name: "simpleSelect",
                                id: "version-select",
                              }}
                            >
                              {senderGenderList.map((data) => {
                                return (
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected,
                                    }}
                                    value={data.value}
                                    key={data.value}
                                  >
                                    {data.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      {recipientInformationRequired.includes(dest_curr) ? (
                        <>
                          <GridContainer justify="center">
                            <GridItem
                              xs={12}
                              md={6}
                              sm={10}
                              style={{ height: "50px" }}
                            >
                              <FormControl fullWidth>
                                <CustomInput
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    placeholder: "Recipient City",
                                    name: "recipientCity",
                                    value: this.state.recipientCity,
                                    onChange: this.handleRecipientChange,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </GridItem>
                          </GridContainer>
                          <GridContainer justify="center">
                            <GridItem
                              xs={12}
                              md={6}
                              sm={10}
                              style={{ height: "50px" }}
                            >
                              <FormControl fullWidth>
                                <CustomInput
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    placeholder: "Recipient Email",
                                    name: "recipientEmail",
                                    value: this.state.recipientEmail,
                                    onChange: this.handleRecipientChange,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </GridItem>
                          </GridContainer>
                          {requireRecipientId.includes(dest_curr) ? (<GridContainer justify="center">
                            <GridItem xs={12} md={6} sm={10}
                            style={{ height: "50px" }}>
                              <FormControl
                                fullWidth
                                className={classes.selectFormControl}
                                style={{ marginTop: "20px" }}
                              >
                                <InputLabel
                                  htmlFor="simple-select"
                                  className={classes.selectLabel}
                                  error={this.state.valid_recipient_id_type === "false"}
                                >
                                  {"Recipient ID Type *"}
                                </InputLabel>
                                <Select
                                  MenuProps={{
                                    className: classes.selectMenu,
                                  }}
                                  classes={{
                                    select: classes.select,
                                  }}
                                  value={this.state.recipientIdType}
                                  onChange={this.handleRecipientIdTypeChange}
                                  inputProps={{
                                    name: "simpleSelect",
                                    id: "recipientIdType"
                                  }}
                                >
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected,
                                    }}
                                    value={"ID"}
                                  >
                                    Identity Card
                                  </MenuItem>
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected,
                                    }}
                                    value={"PP"}
                                  >
                                    Passport
                                  </MenuItem>
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected,
                                    }}
                                    value={"O"}
                                  >
                                    Other
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </GridItem>
                          </GridContainer>) : null}
                          {requireRecipientId.includes(dest_curr) ? (<GridContainer justify="center">
                            <GridItem
                              xs={12}
                              md={6}
                              sm={10}
                              style={{ height: "50px" }}
                            >
                              <FormControl fullWidth>
                                <CustomInput
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    placeholder: "Recipient Id Number",
                                    name: "recipientIdNumber",
                                    value: this.state.recipientIdNumber,
                                    onChange: this.handleRecipientChange,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </GridItem>
                          </GridContainer>) : null}
                          <GridContainer justify="center">
                            <GridItem
                              xs={12}
                              md={6}
                              sm={10}
                              style={{ height: "50px" }}
                            >
                              <FormControl fullWidth style={{ marginTop: "20px" }}>
                                <InputLabel
                                  htmlFor="simple-select"
                                  className={classes.selectLabel}
                                >
                                  {"Recipient Type"}
                                </InputLabel>
                                <Select
                                  MenuProps={{
                                    className: classes.selectMenu,
                                  }}
                                  classes={{
                                    select: classes.select,
                                  }}
                                  value={this.state.recipient_type}
                                  onChange={this.handleRecipientTypeChange}
                                  inputProps={{
                                    name: "recipientTypeSelect"
                                  }}
                                >
                                  {segmentType.map((data) => {
                                    return (
                                      <MenuItem
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected: classes.selectMenuItemSelected,
                                        }}
                                        value={data.value}
                                        key={data.value}
                                      >
                                        {data.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </GridItem>
                          </GridContainer>
                          <GridContainer justify="center">
                            <GridItem
                              xs={12}
                              md={6}
                              sm={10}
                              style={{ height: "50px" }}
                            >
                              <FormControl fullWidth>
                                <CustomInput
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    placeholder: "Recipient Phone",
                                    name: "recipientPhone",
                                    value: this.state.recipientPhone,
                                    onChange: this.handleRecipientChange,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </GridItem>
                          </GridContainer>
                          <GridContainer justify="center">
                            <GridItem
                              xs={12}
                              md={6}
                              sm={10}
                              style={{ height: "50px" }}
                            >
                              <FormControl fullWidth>
                                <CustomInput
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    placeholder: "Recipient Postal Code",
                                    name: "recipientPostalCode",
                                    value: this.state.recipientPostalCode,
                                    onChange: this.handleRecipientChange,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </GridItem>
                          </GridContainer>
                          <GridContainer justify="center">
                            <GridItem
                              xs={12}
                              md={6}
                              sm={10}
                              style={{ height: "50px" }}
                            >
                              <FormControl fullWidth>
                                <CustomInput
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    placeholder: "Recipient Street",
                                    name: "recipientStreet",
                                    value: this.state.recipientStreet,
                                    onChange: this.handleRecipientChange,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </GridItem>
                          </GridContainer>
                          <GridContainer justify="center">
                            <GridItem
                              xs={12}
                              md={6}
                              sm={10}
                              style={{ height: "50px" }}
                            >
                              <FormControl fullWidth>
                                <CustomInput
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    placeholder: "Recipient Address",
                                    name: "recipientAddress",
                                    value: this.state.recipientAddress,
                                    onChange: this.handleRecipientChange,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </GridItem>
                          </GridContainer>
                          <GridContainer justify="center">
                            <GridItem
                              xs={12}
                              md={6}
                              sm={10}
                              style={{ height: "50px" }}
                            >
                              <FormControl fullWidth>
                                <CustomInput
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    placeholder: "Recipient Date of Birth",
                                    name: "recipient_date_of_birth",
                                    value: this.state.recipient_date_of_birth,
                                    onChange: this.handleRecipientChange,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </GridItem>
                          </GridContainer>
                          <GridContainer justify="center">
                            <GridItem
                              xs={12}
                              md={6}
                              sm={10}
                              style={{ height: "50px" }}
                            >
                              <FormControl fullWidth>
                                <CustomInput
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    placeholder: "Recipient City of Birth",
                                    name: "recipient_city_of_birth",
                                    value: this.state.recipient_city_of_birth,
                                    onChange: this.handleRecipientChange,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </GridItem>
                          </GridContainer>
                          <GridContainer justify="center">
                            <GridItem
                              xs={12}
                              md={6}
                              sm={10}
                              style={{ height: "50px" }}
                            >
                              <FormControl fullWidth>
                                <CustomInput
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    placeholder: "Recipient Town Name",
                                    name: "recipient_town_name",
                                    value: this.state.recipient_town_name,
                                    onChange: this.handleRecipientChange,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </GridItem>
                          </GridContainer>
                          <GridContainer justify="center">
                            <GridItem
                              xs={12}
                              md={6}
                              sm={10}
                              style={{ height: "50px" }}
                            >
                              <FormControl fullWidth>
                                <CustomInput
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    placeholder: "Recipient District",
                                    name: "recipient_district",
                                    value: this.state.recipient_district,
                                    onChange: this.handleRecipientChange,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </GridItem>
                          </GridContainer>
                          <GridContainer justify="center">
                            <GridItem
                              xs={12}
                              md={6}
                              sm={10}
                              style={{ height: "50px" }}
                            >
                              <FormControl fullWidth>
                                <CustomInput
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    placeholder: "Recipient Province of Birth",
                                    name: "recipient_province_of_birth",
                                    value: this.state.recipient_province_of_birth,
                                    onChange: this.handleRecipientChange,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </GridItem>
                          </GridContainer>
                          <GridContainer justify="center">
                            <GridItem
                              xs={12}
                              md={6}
                              sm={10}
                              style={{ height: "50px" }}
                            >
                              <FormControl fullWidth>
                                <CustomInput
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    placeholder: "Recipient Country Sub Division",
                                    name: "recipient_country_sub_division",
                                    value: this.state.recipient_country_sub_division,
                                    onChange: this.handleRecipientChange,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </GridItem>
                          </GridContainer>
                          <GridContainer justify="center">
                            <GridItem
                              xs={12}
                              md={6}
                              sm={10}
                              style={{ height: "50px" }}
                            >
                              <FormControl fullWidth>
                                <CustomInput
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    placeholder: "Recipient Country Of Birth",
                                    name: "recipient_country_of_birth",
                                    value: this.state.recipient_country_of_birth,
                                    onChange: this.handleRecipientChange,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </GridItem>
                          </GridContainer>
                          <GridContainer justify="center">
                            <GridItem
                              xs={12}
                              md={6}
                              sm={10}
                              style={{ height: "50px" }}
                            >
                              <FormControl fullWidth>
                                <CustomInput
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    placeholder: "Recipient Branch Name",
                                    name: "branch_name",
                                    value: this.state.branch_name,
                                    onChange: this.handleRecipientChange,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </GridItem>
                          </GridContainer>
                          <GridContainer justify="center">
                            <GridItem
                              xs={12}
                              md={6}
                              sm={10}
                              style={{ marginTop: "10px" }}
                            >
                              <FormControl
                                fullWidth
                                className={classes.selectFormControl}
                              >
                                <InputLabel
                                  htmlFor="simple-select"
                                  className={classes.selectLabel}
                                >
                                  {"Recipient Reason"}
                                </InputLabel>
                                <Select
                                  MenuProps={{
                                    className: classes.selectMenu,
                                  }}
                                  classes={{
                                    select: classes.select,
                                  }}
                                  value={this.state.recipientReasonCode}
                                  onChange={this.handleRecipientCodeChange}
                                  inputProps={{
                                    name: "simpleSelect",
                                    id: "transaction-select",
                                  }}
                                >
                                  {this.state.recipientReasonCodeList.map(
                                    (data) => {
                                      const { reason, code } = data.attributes;
                                      return (
                                        <MenuItem
                                          classes={{
                                            root: classes.selectMenuItem,
                                            selected:
                                              classes.selectMenuItemSelected,
                                          }}
                                          value={code + ""}
                                          key={code}
                                        >
                                          {reason}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </Select>
                              </FormControl>
                            </GridItem>
                          </GridContainer>
                        </>
                      ) : null}

                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          md={6}
                          sm={10}
                          style={{ height: "50px" }}
                        >
                          <FormControl fullWidth>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                placeholder: "CPF / Tax ID",
                                value: this.state.cpf_tax_id,
                                onChange: this.handleTaxIdChange,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </GridItem>
                      </GridContainer>

                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          md={6}
                          sm={10}
                          style={{ height: "50px" }}
                        >
                          <FormControl fullWidth>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                placeholder: "Agent Code",
                                value: this.state.agent_code,
                                onChange: this.handleAgentCodeChange,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          md={6}
                          sm={10}
                          style={{ height: "50px" }}
                        >
                          <FormControl fullWidth>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                placeholder: "Agent Name",
                                value: this.state.agent_name,
                                onChange: this.handleAgentNameChange,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      {isMemoVisible && (
                        <GridContainer justify="center">
                          <GridItem
                            xs={12}
                            md={6}
                            sm={10}
                            style={{ height: "80px" }}
                          >
                            <FormControl fullWidth>
                              <CustomInput
                                id="placeholder"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  placeholder: "Memo",
                                  value: this.state.memo,
                                  onChange: this.handleMemoChange,
                                  autoComplete: "off",
                                }}
                              />
                            </FormControl>
                          </GridItem>
                        </GridContainer>
                      )}
                    </div>
                  )}
                  <br />
                  <br />
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={3} style={{ height: "50px" }}>
                      <FormControl fullWidth>
                        <Button onClick={this.handleShowInfo}>
                          Add Sender/Agent Details
                        </Button>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={3} sm={12}>
                      <FormControl fullWidth>
                        <Button color="rose" onClick={this.handlepay}>
                          Proceed To Pay
                        </Button>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}
export default withStyles({ ...cssstyles, ...styles, ...alertstyles })(
  CreateTransaction
);
