import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import CustomSweet from "../Components/CustomSweet";
import Progress from "../Components/Progress";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
// import SweetAlert from "react-bootstrap-sweetalert";
import alertstyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { makeStyles } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import { mobileProviderList } from "utils/MobileProvider.js";
import {
  isObjectEmpty,
  ValidateObjectAgainstSchema,
  ValidationTypes,
  makeApiCall,
} from "../../utils/commonCheckFunctions";
import { countryCodes } from "utils/commonConstant";

const AGENT = "agent";
const TXNAGENT = "txnagent";

const userType = localStorage.getItem("user_type");
const TransactionTypes = [
  {
    name: "Bank",
    value: "Bank",
  },
  {
    name: "Mobile",
    value: "Mobile",
  },
];
const customStyles = {
  expiryFormControl: {
    maxWidth: "110px",
  },
  expiryFormControlLabel: {
    fontSize: "14px",
  },
  marginCustomTop: {
    marginTop: "20px",
  },
};
const useStyle = makeStyles({ ...cssstyles, ...alertstyles, ...customStyles });

let timerId;
let timerIdFee;
const debounce = (func, delay) => {
  if (timerId) {
    clearTimeout(timerId);
  }

  timerId = setTimeout(func, delay);
};
const debounceFee = (func, delay) => {
  if (timerIdFee) {
    clearTimeout(timerIdFee);
  }

  timerIdFee = setTimeout(func, delay);
};

const schema = {
  transactionType: [ValidationTypes.required],
  sourceCurrency: [ValidationTypes.required],
  destinationCountry: [ValidationTypes.required],
  provider: [ValidationTypes.required],
  amount: [ValidationTypes.required],
  depositNumber: [ValidationTypes.required],
  name: [ValidationTypes.required],
  iban: [],
  senderFirstName: [ValidationTypes.required],
  senderLastName: [ValidationTypes.required],
  senderMobileNumber: [ValidationTypes.required],
  recieverMobileNumber: [],
};

const sourceCurrencyList = [{ name: "NGN", value: "ngn" }];

const destinationCurrencyList = [
  { name: "GHS", value: "ghs" },
  { name: "KES", value: "kes" },
  { name: "XOF", value: "xof" },
];

const xofCountryCodes = ["CI", "ML", "TG", "BJ", "NE", "GW"];

const getDepositNumberUi = (
  transactionType,
  handleChange,
  countryCode,
  destinationCountry,
  depositNumber,
  errors
) => {
  if (
    !xofCountryCodes.includes(destinationCountry) ||
    transactionType !== "Bank"
  ) {
    if (transactionType === "Bank") {
      return (
        <GridContainer justify="center">
          <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
            <CustomInput
              labelText="Deposit Number/ Mobile Number*"
              error={errors.depositNumber !== undefined}
              formControlProps={{
                fullWidth: true,
                error: errors.depositNumber !== undefined,
              }}
              inputProps={{
                onChange: handleChange,
                value: depositNumber,
                autoComplete: "new-password",
                name: "depositNumber",
              }}
            />
          </GridItem>
        </GridContainer>
      );
    } else {
      return (
        <GridContainer justify="center">
          <GridItem xs={12} md={2} sm={3} style={{ height: "50px" }}>
            <CustomInput
              labelText="Country Code*"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: countryCode,
                autoComplete: "new-password",
                name: "countryCode",
                readOnly: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} md={4} sm={7} style={{ height: "50px" }}>
            <CustomInput
              labelText="Deposit Number/ Mobile Number*"
              error={errors.depositNumber !== undefined}
              formControlProps={{
                fullWidth: true,
                error: errors.depositNumber !== undefined,
              }}
              inputProps={{
                onChange: handleChange,
                value: depositNumber,
                autoComplete: "new-password",
                name: "depositNumber",
              }}
            />
          </GridItem>
        </GridContainer>
      );
    }
  }
  return null;
};

const AgentCreateTransactionView = ({
  transactionType,
  sourceCurrency,
  destinationCountry,
  provider,
  amount,
  exchangedValue,
  // accountNumber,
  name,
  depositNumber,
  iban,
  handleChange,
  errors = {},
  handleSubmit,
  handleCancel,
  allProviders = [],
  allCountries = [],
  senderFirstName,
  senderLastName,
  senderMobileNumber,
  recieverMobileNumber,
  fee,
  countryCode,
  handleSourceAmountChange,
  handleDestinationAmountChange,
}) => {
  const classes = useStyle();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" text>
            <CardText color="rose">
              <h4>Create Transaction</h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <form>
              <GridContainer
                justify="center"
                className={classes.marginCustomTop}
              >
                <GridItem xs={12} md={6} sm={10}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    error={errors.transactionType !== undefined}
                  >
                    <InputLabel className={classes.selectLabel}>
                      {"Transaction Type*"}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={transactionType}
                      onChange={handleChange}
                      inputProps={{
                        name: "transactionType",
                      }}
                    >
                      {TransactionTypes.map((row) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={row.value}
                          key={row.value}
                        >
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer
                justify="center"
                className={classes.marginCustomTop}
              >
                <GridItem xs={12} md={3} sm={10}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    error={errors.sourceCurrency !== undefined}
                    disabled={true}
                  >
                    <InputLabel className={classes.selectLabel}>
                      {"Source Currency*"}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={sourceCurrency}
                      onChange={handleChange}
                      inputProps={{
                        name: "sourceCurrency",
                      }}
                    >
                      {sourceCurrencyList.map((row) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={row.value}
                          key={row.value}
                        >
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} md={3} sm={10}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    error={errors.destinationCountry !== undefined}
                  >
                    <InputLabel className={classes.selectLabel}>
                      {"Destination Country*"}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={destinationCountry}
                      onChange={handleChange}
                      inputProps={{
                        name: "destinationCountry",
                      }}
                    >
                      {allCountries.map((row) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={row.attributes.code}
                          key={row.attributes.code}
                        >
                          {row.attributes.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    error={errors.provider !== undefined}
                  >
                    <InputLabel
                      className={classes.selectLabel}
                      error={errors.provider !== undefined}
                    >
                      {"Banks/Provider *"}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={provider}
                      onChange={handleChange}
                      inputProps={{
                        name: "provider",
                      }}
                    >
                      {allProviders.map((row) => {
                        const { attributes } = row;
                        let name = "",
                          code = "",
                          id = "";
                        if (attributes !== undefined) {
                          name = attributes.name;
                          code = attributes.code;
                          id = attributes.id;
                        } else {
                          name = row.name;
                          code = row.code;
                          id = row.id;
                        }
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={code}
                            key={id}
                          >
                            {name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>

              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Source Amount *"
                    error={errors.amount !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.amount !== undefined,
                    }}
                    inputProps={{
                      onChange: handleSourceAmountChange,
                      value: amount,
                      autoComplete: "off",
                      type: "number",

                      name: "amount",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={3} sm={5} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Destination Amount"
                    formControlProps={{
                      fullWidth: true,
                      // disabled: true,
                    }}
                    inputProps={{
                      onChange: handleDestinationAmountChange,
                      value: exchangedValue,
                      autoComplete: "new-password",
                      name: "destinationAmount",
                      // readOnly: true,
                      type: "number",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={3} sm={5} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Fee "
                    formControlProps={{
                      fullWidth: true,
                      disabled: true,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: fee,
                      autoComplete: "new-password",
                      name: "fee",
                      readOnly: true,
                    }}
                  />
                </GridItem>
              </GridContainer>
              {getDepositNumberUi(
                transactionType,
                handleChange,
                countryCode,
                destinationCountry,
                depositNumber,
                errors
              )}
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Account Holder Name *"
                    error={errors.name !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.name !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: name,
                      autoComplete: "off",
                      name: "name",
                    }}
                  />
                </GridItem>
              </GridContainer>
              {xofCountryCodes.includes(destinationCountry) &&
                transactionType === "Bank" && (
                  <GridContainer justify="center">
                    <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                      <CustomInput
                        labelText="Iban*"
                        error={errors.iban !== undefined}
                        formControlProps={{
                          fullWidth: true,
                          error: errors.iban !== undefined,
                        }}
                        inputProps={{
                          onChange: handleChange,
                          value: iban,
                          autoComplete: "new-password",
                          name: "iban",
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                )}
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Sender First Name*"
                    error={errors.senderFirstName !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.senderFirstName !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: senderFirstName,
                      autoComplete: "new-password",
                      name: "senderFirstName",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Sender Last Name*"
                    error={errors.senderLastName !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.senderLastName !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: senderLastName,
                      autoComplete: "new-password",
                      name: "senderLastName",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Sender Mobile Number*"
                    error={errors.senderMobileNumber !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.senderMobileNumber !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: senderMobileNumber,
                      autoComplete: "new-password",
                      name: "senderMobileNumber",
                      type: "number",
                    }}
                  />
                </GridItem>
              </GridContainer>
              {transactionType === "Bank" && (
                <GridContainer justify="center">
                  <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                    <CustomInput
                      labelText="Reciever Mobile Number*"
                      error={errors.recieverMobileNumber !== undefined}
                      formControlProps={{
                        fullWidth: true,
                        error: errors.recieverMobileNumber !== undefined,
                      }}
                      inputProps={{
                        onChange: handleChange,
                        value: recieverMobileNumber,
                        autoComplete: "new-password",
                        name: "recieverMobileNumber",
                        type: "number",
                      }}
                    />
                  </GridItem>
                </GridContainer>
              )}
              <GridContainer
                justify="center"
                className={classes.marginCustomTop}
              >
                <GridItem xs={12} sm={12} md={3} style={{ height: "50px" }}>
                  <FormControl fullWidth>
                    <Button onClick={handleCancel}>Cancel</Button>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} md={3} sm={12}>
                  <FormControl fullWidth>
                    <Button color="rose" onClick={handleSubmit}>
                      Proceed To Pay
                    </Button>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

// const memoisedView = React.memo(VisaDirectView);

// const GetSweetAlert = ({ onConfirmation, onHide }) => {
//   const classes = useStyle();
//   return (
//     <SweetAlert
//       style={{ display: "block", marginTop: "-100px" }}
//       title="Do you want to create Transaction ?"
//       showCancel
//       confirmBtnText="Yes"
//       cancelBtnText="No"
//       confirmBtnCssClass={classes.button + " " + classes.success}
//       cancelBtnCssClass={classes.button + " " + classes.info}
//       onConfirm={onConfirmation}
//       onCancel={onHide}
//     />
//   );
// };
const initialState = {
  transactionType: "",
  clientId: "",
  clientTransactionId: "",
  sourceCurrency: "ngn",
  destinationCurrency: "",
  destinationCountry: "",
  provider: "",
  amount: "",
  exchangedValue: "",
  fee: "",
  depositNumber: "",
  name: "",
  iban: "",
  senderFirstName: "",
  senderLastName: "",
  senderMobileNumber: "",
  recieverMobileNumber: "",
  dialogOpen: false,
  countryCode: "",
  conversionRate: "",

  allCountries: [],
  allClients: [],
  allProviders: [],
  errors: {},
  loading: false,
  sweetAlert: null,
};

const initialAlertState = {
  alert: false,
  success: false,
  error: false,
  warning: false,
  message: "",
  title: "",
};

const getCountryName = (countryList=[],destinationCountry)=>{
  const foundElement = countryList.filter((el)=>el.attributes.code===destinationCountry);
  if(foundElement.length>0){
   return foundElement[0].attributes.code;
  }
  return "";
};

const getParamList = ({
  transactionType,
  clientId,
  sourceCurrency,
  destinationCountry,
  provider,
  amount,
  depositNumber,
  name,
  iban,
  senderFirstName,
  senderLastName,
  senderMobileNumber,
  allCountries = [],
}) => {
  return {
    api_version: "v2",
    account_type: transactionType,
    client_id: clientId,
    // deposit_number: accountNumber,
    source_currency: sourceCurrency,
    country_code: destinationCountry,
    recipient_country: getCountryName(allCountries, destinationCountry),
    account_code: provider,
    source_amount: amount,
    deposit_number: depositNumber.trim(),
    iban: iban.trim(),
    name: name.trim(),
    sender_first_name: senderFirstName.trim(),
    sender_last_name: senderLastName.trim(),
    sender_mobile_phone_number: senderMobileNumber,
  };
};

const getUpdatedClientlist = (list = []) => {
  const wallet = localStorage.getItem("walletStatus");
  if (userType === AGENT || userType === TXNAGENT) {
    const { id } = JSON.parse(wallet);
    return { allClients: list, clientId: id };
  } else {
    return { allClients: list };
  }
};


const getextraAttributes = () => {
  return {
    sender_street: "1625 Charles",
    sender_address: "St Unit 3",
    sender_city: "Whitby",
    sender_country_of_birth: "Nigeria",
    sender_date_of_birth: "1990-01-01",
    sender_country: "CA",
    // sender_email: "interafrica@alphafortress.com",
    sender_email: "interafrica1@alphafortress.com",
    sender_gender : 'Male', 
    sender_id_number : "A199267869",
    sender_mobile_phone_country: "CA",
    sender_zipcode: "L1N 1C1",
    recipient_city: "Senegal",
    recipient_email: "interafrica@alphafortress.com",
    recipient_postal_code: "001100",
    recipient_street: "14 main street",
    transfer_reason_code: "185",
  };
};

const DialogComponent = ({
  isOpen = false,
  payByBank,
  payByCash,
  closeFunction,
}) => {
  return (
    <Dialog open={isOpen} onClose={closeFunction}>
      <DialogTitle>How do you want to pay?</DialogTitle>
      <DialogActions>
        <Button onClick={payByCash} color="success">
          Pay By Cash
        </Button>
        <Button onClick={payByBank} color="primary">
          Pay By Bank
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AgentCreateTransaction = () => {
  const [viewState, setViewState] = useState(initialState);
  const [alertState, setAlertState] = useState(initialAlertState);
  const classes = useStyle();
  const handleChange = (event) => {
    const { name, value } = event.target;
    setViewState({ ...viewState, [name]: value });
  };

  const handleSourceAmountChange = (event) => {
    const { name, value } = event.target;
    const { conversionRate } = viewState;
    if (conversionRate) {
      const val = conversionRate - 0;
      const src = value ? value - 0 : value;
      const dest = value ? src * val : "";
      setViewState((prevState) => ({
        ...prevState,
        [name]: src,
        exchangedValue: dest ? dest.toFixed(5) : "",
      }));
    } else {
      setViewState((prevState) => ({
        ...prevState,
        [name]: value,
        exchangedValue: 0,
      }));
    }
  };

  const handleDestinationAmountChange = (event) => {
    const { value } = event.target;
    const { conversionRate } = viewState;
    if (conversionRate) {
      const val = conversionRate - 0;
      const dest = value ? value - 0 : "";
      const sourceAmount = value && val !== 0 ? dest / val : "";
      setViewState((prevState) => ({
        ...prevState,
        exchangedValue: dest,
        amount: sourceAmount ? sourceAmount.toFixed(5) : "",
      }));
    } else {
      setViewState((prevState) => ({
        ...prevState,
        exchangedValue: value,
        amount: "",
      }));
    }
  };

  useEffect(() => {
    getClients();
    getCountries();
  }, []);

  useEffect(() => {
    if (
      viewState.sourceCurrency !== "" &&
      viewState.destinationCountry !== ""
    ) {
      setViewState((prevState) => ({
        ...prevState,
        exchangedValue: "",
        fee: "",
        amount: "",
      }));
      debounce(getExchangeValue, 0);
    }
  }, [viewState.destinationCountry, viewState.sourceCurrency]);

  useEffect(() => {
    if (viewState.amount !== "") {
      setViewState((prevState) => ({
        ...prevState,
        fee: "",
      }));
      debounceFee(getFeeValue, 500);
    }
  }, [viewState.amount]);

  useEffect(() => {
    setViewState((prevState) => ({
      ...prevState,
      provider: "",
      allProviders: [],
    }));
    if (
      viewState.transactionType !== "" &&
      viewState.destinationCountry !== ""
    ) {
      const currency = viewState.destinationCountry === "GH" ? "ghs" : viewState.destinationCountry === "KE" ? "kes" : "xof";
      if (viewState.transactionType === "Bank") {
        getAllProviders(viewState.transactionType, currency);
      } else {
        setViewState((prevState) => ({
          ...prevState,
          allProviders: mobileProviderList[viewState.destinationCountry],
          countryCode: countryCodes[viewState.destinationCountry],
        }));
      }
    }
  }, [viewState.transactionType, viewState.destinationCountry]);

  const getExchangeValue = async () => {
    const { clientId, destinationCountry, sourceCurrency } = viewState;
    const currency = destinationCountry === "GH" ? "ghs" : viewState.destinationCountry === "KE" ? "kes" : "xof";
    const data = await makeApiCall({
      url: "/get_conversion_rate.json",
      method: "get",
      params: {
        destination_currency: currency,
        source_currency: sourceCurrency,
        source_amount: 1,
        client_id: clientId,
        recipient_country:getCountryName(viewState.allCountries,destinationCountry),
      },
    });
    const { error = false, message } = data;
    if (!error) {
      const { currency_conversion_rate } = data;
      setViewState((prevState) => ({
        ...prevState,
        conversionRate: currency_conversion_rate,
      }));
    } else {
      console.log(message);
    }
  };

  const getFeeValue = async () => {
    const { clientId, sourceCurrency, amount } = viewState;
    const data = await makeApiCall({
      url: "/get_source_fee.json",
      method: "get",
      params: {
        source_currency: sourceCurrency,
        amount: amount,
        client_id: clientId,
      },
    });
    const { error = false, message } = data;
    if (!error) {
      const { source_fee } = data;

      setViewState((prevState) => ({
        ...prevState,
        fee: prevState.amount === amount ? source_fee : "",
      }));
    } else {
      console.log(message);
    }
  };
  const onHide = () => {
    // setViewState((prevState) => ({ ...prevState, sweetAlert: null }));
    setViewState((prevState) => ({ ...prevState, dialogOpen: false }));
  };
  const getClients = async () => {
    const data = await makeApiCall({ url: "/clients.json", method: "get" });
    const { error = false, message } = data;
    if (!error) {
      const { clients } = data;
      const clientList = Array.isArray(clients) ? clients : [clients];
      // this.getClientData(result.data.clients);
      const clientData = getUpdatedClientlist(clientList);
      setViewState((prevState) => ({ ...prevState, ...clientData }));
    } else {
      console.log(message);
    }
  };

  const getCountries = async () => {
    const data = await makeApiCall({
      url: "/country_codes.json",
      method: "get",
    });
    const { error = false, message } = data;
    if (!error) {
      const { countries } = data;
      const countryList = countries.filter((element) => {
        const { attributes } = element;
        return ["GHS", "XOF", "KES"].includes(attributes.currency);
      });
      // const clientList = Array.isArray(clients) ? clients : [clients];
      // // this.getClientData(result.data.clients);
      // const clientData = getUpdatedClientlist(clientList);
      setViewState((prevState) => ({
        ...prevState,
        allCountries: countryList,
      }));
      // console.log(data);
    } else {
      console.log(message);
    }
  };

  const getAllProviders = async (transactionType, destinationCurrency) => {
    const subUrl =
      transactionType === "Bank" ? "/show_banks.json" : "/show_providers.json";
    const data = await makeApiCall({
      url: subUrl,
      method: "get",
      params: { currency: destinationCurrency },
    });
    const { error = false, message } = data;
    if (!error) {
      const { banks, providers } = data;
      if (banks) {
        setViewState((prevState) => ({
          ...prevState,
          allProviders: banks,
          countryCode: "",
        }));
      } else {
        setViewState((prevState) => ({
          ...prevState,
          allProviders: providers,
          countryCode: "",
        }));
      }
    } else {
      console.log(message);
    }
  };

  const hideAlert = () => {
    setAlertState(initialAlertState);
  };

  const handleCleanform = () => {
    setViewState((prevState) => ({
      ...initialState,
      allClients: prevState.allClients,
      clientId: prevState.clientId,
      allCountries: prevState.allCountries,
    }));
  };

  const handleCashPayment = () => {
    createSignature("cash");
  };

  const handleBankPayment = () => {
    createSignature("bank");
  };

  const handleSubmit = () => {
    const { isValid, errors } = validateForm();
    setViewState({ ...viewState, errors });
    // console.log("submit button clicked!!!");
    // console.log(errors);

    if (isValid) {
      // console.log(viewState);
      // console.log(getParamList(viewState));
      // const view = (
      //   <GetSweetAlert onConfirmation={onConfirmation} onHide={onHide} />
      // );
      setViewState((prevState) => ({ ...prevState, dialogOpen: true }));
    } else {
      // console.log("submit button clicked!!!");
      console.log(errors);
    }
  };

  // const onConfirmation = () => {
  //   // createSignature();
  //   // console.log(viewState);
  // };

  const createSignature = async (paymentMethod) => {
    setViewState({ ...viewState, loading: true, dialogOpen: false });
    let params = getParamList(viewState);
    let destinationCurrency = "ghs";
    const {
      transactionType,
      recieverMobileNumber,
      depositNumber,
      destinationCountry,
      countryCode,
    } = viewState;
    if (destinationCountry === "GH") {
      destinationCurrency = "ghs";
    }
    else if (destinationCountry === "KE") {
      destinationCurrency = "kes";
    } else {
      destinationCurrency = "xof";
    }
    /*changing parmas according to destination currency and transaction type*/
    if (destinationCurrency === "xof" && transactionType === "Bank") {
      delete params.deposit_number;
      params["recipient_phone_number"] = recieverMobileNumber;
      const extraParams = getextraAttributes();
      params = { ...params, ...extraParams };
    } else if (destinationCurrency === "xof" && transactionType === "Mobile") {
      delete params.iban;
      params["recipient_phone_number"] = depositNumber;
      const extraParams = getextraAttributes();
      params = { ...params, ...extraParams };
    } else {
      if (transactionType === "Bank") {
        params["recipient_phone_number"] = recieverMobileNumber;
        const extraParams = getextraAttributes();
        params = { ...params, ...extraParams };
      } else if (transactionType === "Mobile") {
        params["recipient_phone_number"] = depositNumber;
        const extraParams = getextraAttributes();
        params = { ...params, ...extraParams };
      }
      delete params.iban;
    }
    const { deposit_number } = params;
    if (
      deposit_number &&
      transactionType === "Mobile" &&
      destinationCountry !== "CI"
    ) {
      const updatedDepositnumber = countryCode + params.deposit_number;
      params.deposit_number = updatedDepositnumber;
      params["recipient_phone_number"] = updatedDepositnumber;
    } else if (
      deposit_number &&
      transactionType === "Mobile" &&
      destinationCountry === "CI"
    ) {
      const updatedDepositnumber = "0" + params.deposit_number;
      params.deposit_number = updatedDepositnumber;
      params["recipient_phone_number"] = updatedDepositnumber;
    }
    params["client_txn_id"] = uuidv4().trim();
    params["destination_currency"] = destinationCurrency;
    params["pay_by"] = paymentMethod;
    const data = await makeApiCall({
      url: "/transactions/create_signature.json",
      method: "post",
      params,
    });
    const { error = false, message } = data;
    if (error) {
      console.log(message);
      setViewState({ ...viewState, loading: false });
      setAlertState({
        alert: true,
        success: false,
        error: true,
        warning: false,
        message: "Something went wrong!!!",
        title: "Error",
      });
    } else {
      const { api_token, tonce, signature } = data;
      params = {
        ...params,
        api_token: api_token,
        tonce: tonce,
        signature: signature,
      };
      delete params.client_id; //as client_id is not required for transaction
      createTransaction(params);
    }
  };

  const createTransaction = async (params = {}) => {
    const data = await makeApiCall({
      url: "/api/v1/transactions",
      method: "post",
      params,
    });
    const { error, message } = data;
    if (error) {
      console.log(message);
      let val = "Something Went wrong !!!";
      if (message.response.data.error) {
        const {
          response: { data },
        } = message;
        const {
          error: { message: errorMessage },
        } = data;
        val = errorMessage;
      }
      setViewState({ ...viewState, loading: false });
      setAlertState({
        alert: true,
        success: false,
        error: true,
        warning: false,
        message: val,
        title: "Error",
      });
    } else {
      // console.log(data);
      const paymentMethod = params["pay_by"];
      let successMessage = "Transaction Created Successfully!!!";
      if (paymentMethod === "bank") {
        const { verify_code } = data;
        successMessage = (
          <>
            <b style={{ fontSize: "16px" }}>{verify_code}</b>
            <br />
            This is your transfer reference number, please mention this in the
            notes / description when creating the bank transfer.
          </>
        );
      }
      setAlertState({
        alert: true,
        success: true,
        error: false,
        warning: false,
        message: successMessage,
        title: "Success",
      });
      handleCleanform();
    }
  };

  const validateForm = () => {
    const { destinationCountry, transactionType } = viewState;
    if (
      xofCountryCodes.includes(destinationCountry) &&
      transactionType === "Bank"
    ) {
      schema["depositNumber"] = [];
      schema["iban"] = [ValidationTypes.required];
      schema["recieverMobileNumber"] = [ValidationTypes.required];
    } else {
      schema["depositNumber"] = [ValidationTypes.required];
      schema["iban"] = [];
      schema["recieverMobileNumber"] = [];
    }
    const errors = ValidateObjectAgainstSchema.validateObject(
      viewState,
      schema
    );
    return { isValid: isObjectEmpty(errors), errors };
  };

  return (
    <>
      <CustomSweet
        alert={alertState.alert}
        message={alertState.message}
        title={alertState.title}
        hide_alert={hideAlert}
        danger={alertState.error}
        success={alertState.success}
      />
      <DialogComponent
        isOpen={viewState.dialogOpen}
        payByBank={handleBankPayment}
        payByCash={handleCashPayment}
        closeFunction={onHide}
      />
      {/* {viewState.sweetAlert} */}
      <div className={viewState.loading ? classes.main_screen : ""}>
        <div align="center">{viewState.loading && <Progress />}</div>
        <AgentCreateTransactionView
          {...viewState}
          handleCancel={handleCleanform}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleSourceAmountChange={handleSourceAmountChange}
          handleDestinationAmountChange={handleDestinationAmountChange}
        />
      </div>
    </>
  );
};

export default AgentCreateTransaction;
