import React from "react";
// react component for creating dynamic tables
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
// @material-ui/icons
import CustomInput from "components/CustomInput/CustomInput.js";
import CardText from "components/Card/CardText.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import FormControl from "@material-ui/core/FormControl";
import Table from "components/Table/Table.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomSweet from "../Components/CustomSweet";
import Progress from "../Components/Progress";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import Close from "@material-ui/icons/Close";
import BlockIcon from "@material-ui/icons/Block";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import apiUrl from "../api";
import actionCableUrl from "../action_cable_api";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ReceiptIcon from "@material-ui/icons/Receipt";


const base_url = apiUrl;
const actioncable = require("actioncable");
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  main_screen: {
    backgroundColor: "##71716e",
    opacity: 0.2,
    zIndex: 500,
  },
  receiptButton: {
    height: "25px !important",
    width: "25px !important"
  }
};

const ADMIN = "admin";
const AGENT = "agent";
const TXNAGENT = "txnagent";
const WIRETXNAGENT = "wiretxnagent";
const CRYPTOAGENT = "cryptoagent";
const userType = localStorage.getItem("user_type");
const CASH = "Cash";
const IMGFORMAT = ['jpg', 'jpeg', 'png']

class SearchTransaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: 0,
      txn_id: "",
      ref_code: "",
      client_txn_id: "",
      payment_gateway_txn_id: "",
      verifyCode:"",
      transaction: "",
      admin_user: false,
      agent_user: false,
      support_user: false,
      superAdminUser:false,
      opened: false,
      manopen: false,
      txnid: "",
      currency: "",
      payment_gate: "",
      account_type: "",
      payment_gateway_all: [],
      timelogs: [],
      loadtimelog: false,
      txn: "",
      modalopen: false,
      reason_all: [],
      block_reason: "",
      loading: false,
      radio: "with_same",
      loadtransactions: false,
      disableSubmit: false,
      blackListed: false
    };
    this.cable = actioncable.createConsumer(
      actionCableUrl
    );
  }
  componentDidMount() {
    if (localStorage.getItem("session_token")) {
      // this.setState({ user_id: localStorage.getItem("user_id") }, () => {});
     
      this.setupSubscription();
    }
  }
  
  componentWillUnmount(){
    if(this.cableChannel !== undefined){
      this.cableChannel.unsubscribe();

    }
    this.cable.disconnect();
  }



  setupSubscription() {
    // console.log("good", App.cable.subscriptions);
    this.cableChannel = this.cable.subscriptions.create("TransactionsChannel", {
      received: (data) => {
        //  console.log("Data", data);
        const transaction = this.state.transaction;
        if (transaction!=="" && transaction.txn.id === data.transaction.txn.id) {
        this.setState(
          {
            txn: data["transaction"],
            transaction: data["transaction"],
            timelogs: data["timelogs"],
            loadtimelog: true,
            loadtransactions: true,
          },
        );
        }
      },
    });

  }



  handleTxnChange = (event) => {
    this.setState({ txn_id: event.target.value });
  };
  handleClienttxnChange = (event) => {
    this.setState({ client_txn_id: event.target.value });
  };
  handlePaymenttxnChange = (event) => {
    this.setState({ payment_gateway_txn_id: event.target.value });
  };

  handleVerifyCodeChange = (event)=>{
    const {value} = event.target;
    this.setState({verifyCode:value});
  }

  handleRefCodeChange = (event)=>{
    const {value} = event.target;
    this.setState({ref_code: value});
  }

  handlePaymentUuidChange = (event)=>{
    const {value} = event.target;
    this.setState({payment_uuid: value});
  }

  onSearch = (event) => {
    event.preventDefault();
    // const base_url = window.location.origin;
    var txn_text = "";
    if (localStorage.getItem("txn_id")) {
      txn_text = localStorage.getItem("txn_id");
      localStorage.removeItem("txn_id");
    } else {
      txn_text = this.state.txn_id;
    }
    this.setState({ loading: true });
    axios({
      method: "get",
      url: base_url + "/transactions/search_transaction.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        client_txn_id: this.state.client_txn_id,
        ref_no: this.state.payment_gateway_txn_id,
        transaction_id: txn_text,
        verify_code:this.state.verifyCode,
        ref_code: this.state.ref_code,
        payment_uuid: this.state.payment_uuid
      },
    })
      .then((result) => {
        //access the results here....
        // this.setState({ transactionData: result.data });
        // console.log(result);
        if (result.data) {
          this.setState({
            transaction: result.data.transaction,
            admin_user: result.data.admin_user,
            agent_user: result.data.agent_user,
            support_user: result.data.support_user,
            superAdminUser:result.data.super_admin,
            timelogs: result.data.timelogs,
            loadtimelog: result.data.agent_user ? false : true,
            loading: false,
            loadtransactions: true,
            blackListed: result.data.transaction.black_listed
          });
          // console.log(this.state.transactions);
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          show_error: true,
          // error_message: error.response.data
          //   ? error.response.data.message
          //   : error.message,
        });
        this.setState({ loading: false });
        console.log("Error", error);
        this.setState({
          loading: false,
          alert: true,
          // alert_msg: error.response.data.message,
          alert_title: "Search!",
          danger: true,
          warning: false,
          success: false,
        });
        this.clearInput();
      });
  };
  clearInput() {
    this.setState({ transaction: "", timelogs: [] });
  }
  handlePaid = (event, txn_id) => {
    event.preventDefault();
    console.log(txn_id);
    var confirmation = window.confirm(
      "Are you sure you want to mark paid this transaction?"
    );
    if (confirmation) {
      // const base_url = window.location.origin;
      this.setState({ loading: true });
      axios({
        method: "put",
        url: base_url + "/transactions/" + txn_id + "/mark_paid.json",
        params: {},
        headers: {
          Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        },
      })
        .then((result) => {
          //access the results here....
          this.setState({ open: false }, () => console.log("Success Paid"));
          this.setState({
            loading: false,
            alert: true,
            alert_msg: "You have successfully mark paid! " + txn_id + "!",
            alert_title: "Marked Paid!",
            danger: false,
            warning: false,
            success: true,
          });
        })
        .catch((error) => {
          this.setState({ open: false }, () =>
            console.log("Mark Paid", error.response.data.message)
          );
          this.setState({
            loading: false,
            alert: true,
            alert_msg: error.response.data.message,
            alert_title: "Mark Paid!",
            danger: true,
            warning: false,
            success: false,
          });
        });
    }
  };
  handlefail = (event, txn_id) => {
    event.preventDefault();
    // console.log(txn_id);
    var confirmation = window.confirm(
      "Are you sure you want to mark fail this transaction?"
    );
    if (confirmation) {
      // const base_url = window.location.origin;
      this.setState({ loading: true });
      axios({
        method: "put",
        url: base_url + "/transactions/" + txn_id + "/mark_failed.json",
        params: {},
        headers: {
          Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        },
      })
        .then((result) => {
          //access the results here....
          this.setState({ open: false }, () => console.log("Success Failed"));
          this.setState({
            loading: false,
            alert: true,
            alert_msg:
              "You have successfully mark failed Transaction " + txn_id + "!",
            alert_title: "Marked Failed!",
            danger: false,
            warning: false,
            success: true,
          });
        })
        .catch((error) => {
          this.setState({ open: false }, () =>
            console.log("Mark Failed", error.response.data.message)
          );
          this.setState({
            loading: false,
            alert: true,
            alert_msg: error.response.data.message,
            alert_title: "Mark Failed!",
            danger: true,
            warning: false,
            success: false,
          });
        });
    }
  };
  handlecancel = (event, txn_id) => {
    event.preventDefault();
    // console.log(txn_id);
    var confirmation = window.confirm(
      "Are you sure you want to mark cancel this transaction?"
    );
    if (confirmation) {
      // const base_url = window.location.origin;
      this.setState({ loading: true });
      axios({
        method: "put",
        url: base_url + "/transactions/" + txn_id + "/mark_cancelled.json",
        params: {},
        headers: {
          Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        },
      })
        .then((result) => {
          //access the results here....
          this.setState({ open: false }, () =>
            console.log("Success Cancelled")
          );
          this.setState({
            loading: false,
            alert: true,
            alert_msg:
              "You have successfully mark paid Cancelled " + txn_id + "!",
            alert_title: "Marked Cancelled!",
            danger: false,
            warning: false,
            success: true,
          });
        })
        .catch((error) => {
          this.setState({ open: false }, () =>
            console.log("Mark Cancelled", error.response.data.message)
          );
          this.setState({
            loading: false,
            alert: true,
            alert_msg: error.response.data.message,
            alert_title: "Mark Cancelled!",
            danger: true,
            warning: false,
            success: false,
          });
        });
    }
  };

  handleRelease = (txn_id)=>{
    if(this.state.blackListed){
      return alert("recipient's email domain is black listed, please update the email.")
    }

    let confirmation = window.confirm(
      "Are you sure you want to release this transaction?"
    );
    if(confirmation){
      this.setState({ loading: true });
      axios({
        method: "put",
        url: base_url + "/transactions/" + txn_id + "/mark_release_transaction.json",
        params: {},
        headers: {
          Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        },
      })
        .then((result) => {
          //access the results here....
          // this.setState({ open: false }, () => console.log("Success Paid"));
          this.setState({
            loading: false,
            alert: true,
            alert_msg: "You have successfully released! " + txn_id + "!",
            alert_title: "Release Transaction!",
            danger: false,
            warning: false,
            success: true,
          });
        })
        .catch((error) => {
          // this.setState({ open: false }, () =>
          //   console.log("Mark Paid", error.response.data.message)
          // );
          this.setState({
            loading: false,
            alert: true,
            alert_msg: error.response.data.message,
            alert_title: "Release Transaction!!",
            danger: true,
            warning: false,
            success: false,
          });
        });
    }
    
  }

  handlemanualClickOpen = (event, txn) => {
    this.setState({
      manopen: true,
      txnid: txn.id,
      currency: txn.destination_currency,
      payment_gate: txn.payment_gateway,
      account_type: txn.account_type,
    });
  };
  paidButton = (txn, auto_retry, txn_id) => {
    return (
      <div>
        <Button
          justIcon
          round
          simple
          color="success"
          size="sm"
          tx_id={txn.id}
          onClick={(e) => this.handlePaid(e, txn_id)}
          style={
            ["successful", "failed"].includes(txn.aasm_state) ||
            !(this.state.admin_user || this.state.superAdminUser) ||
            auto_retry
              ? { display: "none" }
              : { display: "block" }
          }
        >
          <CheckCircleIcon />
        </Button>
      </div>
    );
  };
  handleoptionsameClick = (e) => {
    console.log(this.state.account_type);
    this.setState({ radio: e.target.value });
    if (e.target.value === "with_diff") {
      this.setState({ opened: true, radio: e.target.value });
      if (this.state.account_type === "Bank" || this.state.account_type === "Wire") {
        this.getPaymentGateways();
      } else {
        this.getProviderPaymentGateways();
      }
    } else {
      this.setState({ opened: false, radio: e.target.value });
    }
  };
  reprocessButton = (txn, auto_retry) => {
    const { opened } = this.state;
    const { classes } = this.props;
    return (
      <div>
        <input type="hidden" name="original_gateway" id={txn.payment_gateway} />
        <input
          type="hidden"
          name="original_currency"
          id={txn.original_currency}
        />
        <input type="hidden" name="account_type" id={txn.account_type} />
        <input type="hidden" name="provider_name" id={txn.provider_name} />

        <Button
          justIcon
          round
          simple
          color="info"
          size="sm"
          tx_id={txn.id}
          onClick={(e) => this.handlemanualClickOpen(e, txn)}
          style={
            ["successful", "failed", "processing", "validated"].includes(
              txn.aasm_state
            ) ||
            !(this.state.admin_user || this.state.superAdminUser) ||
            auto_retry
              ? { display: "none" }
              : { display: "block" }
          }
        >
          <SettingsBackupRestoreIcon />
        </Button>
        <span style={{ marginLeft: "45%" }}></span>
        <Dialog
          open={this.state.manopen}
          onClose={this.handlemanClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Manual Reprocess"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <FormControlLabel
                control={
                  <Radio
                    checked={this.state.radio === "with_same"}
                    onChange={this.handleoptionsameClick}
                    value="with_same"
                    name="radio button"
                    aria-label="With Same Payment Gateway"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="With Same Payment Gateway"
              />
              <div
                className={
                  classes.checkboxAndRadio +
                  " " +
                  classes.checkboxAndRadioHorizontal
                }
              >
                <FormControlLabel
                  control={
                    <Radio
                      checked={this.state.radio === "with_diff"}
                      onChange={this.handleoptionsameClick}
                      value="with_diff"
                      name="radio button"
                      aria-label="With Different Payment Gateway"
                      icon={
                        <FiberManualRecord className={classes.radioUnchecked} />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label="With Different Payment Gateway"
                />
              </div>
              {this.state.radio === "with_diff" ? (
                <Select
                  labelId="label"
                  id="pgselect"
                  value={this.state.payment_gate}
                  onChange={this.handlepgChange}
                >
                  {this.state.payment_gateway_all.map((row) => {
                    const { attributes } = row;
                    let name = "",
                      id = "";
                    if (attributes !== undefined) {
                      name = attributes.name;
                      id = attributes.id;
                    } else {
                      name = row.name;
                      id = row.id;
                    } 
                    
                    return(
                    <MenuItem
                      key={id}
                      value={name}
                    >
                      {name}
                    </MenuItem>
                  )})}
                </Select>
              ):null}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={this.handlemanClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={(e) => this.handlereprocess(e)}
              color="primary"
              autoFocus
              disabled={this.state.disableSubmit}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };
  handlereprocess = (event) => {
    this.setState({disableSubmit: true});
    event.preventDefault();
    // console.log("first", this.state.txnid);
    // const base_url = window.location.origin;
    this.setState({ loading: true });
    axios({
      method: "put",
      url:
        base_url +
        "/transactions/" +
        this.state.txnid +
        "/manual_reprocess.json",
      params: { payment_gateway: this.state.payment_gate },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
    })
      .then((result) => {
        //access the results here....
        this.setState({ manopen: false }, () =>
          console.log("Success Reprocess")
        );
        this.setState({
          loading: false,
          alert: true,
          alert_msg:
            "You have successfully manual reprocess Transaction " +
            this.state.txn_id +
            "!",
          alert_title: "Marked Reprocess!",
          danger: false,
          warning: false,
          success: true,
        });
      })
      .catch((error) => {
        this.setState({ manopen: false }, () =>
          console.log("Reprocess Error", error.response.data.message)
        );
        this.setState({
          loading: false,
          alert: true,
          alert_msg: error.response.data.message,
          alert_title: "Manual Reprocess!",
          danger: true,
          warning: false,
          success: false,
        });
      });
  };
  handlemanualClickOpen = (event, txn) => {
    this.setState({
      manopen: true,
      txnid: txn.id,
      currency: txn.destination_currency,
      payment_gate: txn.payment_gateway,
      account_type: txn.account_type,
      disableSubmit: false
    });
  };
  handlemanClose = () => {
    this.setState({ manopen: false });
    this.setState({ opened: false });
  };

  getPaymentGateways = () => {
    // const base_url = window.location.origin;
    console.log(this.state);
    axios({
      method: "get",
      url: base_url + "/show_payment_gateways.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        currency: this.state.currency,
      },
    })
      .then((result) => {
        //access the results here....
        this.setState({ payment_gateway_all: result.data.payment_gateways });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  getProviderPaymentGateways = () => {
    // const base_url = window.location.origin;
    axios({
      method: "get",
      url: base_url + "/show_provider_payment_gateways.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        currency: this.state.currency,
      },
    })
      .then((result) => {
        //access the results here....
        this.setState({
          payment_gateway_all: result.data.provider_payment_gateways,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  failButton = (txn, auto_retry, txn_id) => {
    return (
      <div>
        <Button
          justIcon
          round
          simple
          color="danger"
          tx_id={txn.id}
          onClick={(e) => this.handlefail(e, txn_id)}
          style={
            ["successful", "failed"].includes(txn.aasm_state) ||
            !(this.state.admin_user || this.state.superAdminUser) ||
            auto_retry
              ? { display: "none" }
              : { display: "block" }
          }
        >
          <Close />
        </Button>
      </div>
    );
  };
  cancelButton = (txn, auto_retry, txn_id) => {
    return (
      <div>
        <Button
          justIcon
          round
          simple
          color="warning"
          tx_id={txn.id}
          onClick={(e) => this.handlecancel(e, txn_id)}
          style={
            ["successful", "failed", "cancelled"].includes(txn.aasm_state) ||
            !(this.state.admin_user || this.state.superAdminUser) ||
            auto_retry ||
            txn.aasm_state === "validated" ? { display: "none" } : { display: "block" }
          }
        >
          <WarningIcon />
        </Button>
      </div>
    );
  };

  releaseButton = (txn, txn_id) => {
    return (
      <div>
        <Button
          justIcon
          round
          simple
          onClick={() => this.handleRelease(txn_id)}
          style={
            txn.hold &&
            (this.state.admin_user || this.state.superAdminUser) && txn.aasm_state === "processing"
              ? { display: "block" }
              : { display: "none" }
          }
        >
         <AccessTimeIcon color="primary"/>
        </Button>
      </div>
    );
  };

  handleBlockTxnClass = (btn_type, suspect) => {
    switch (btn_type) {
      case "block": {
        var button_class =
          suspect === true || !(this.state.admin_user || this.state.superAdminUser)
            ? "after-block"
            : "danger btn-small";
        break;
      }
      case "release": {
        button_class =
          suspect === true && (this.state.admin_user || this.state.superAdminUSer)
            ? "primary btn-small"
            : "hidden";
        break;
      }
      default:
        button_class = "";
    }
    return button_class;
  };
  handleBlockTxnValue = (suspect) => {
    var button_value =
      suspect === true && (this.state.admin_user || this.state.support_user|| this.state.superAdminUser)
        ? "BLOCKED"
        : this.state.agent_user
        ? ""
        : (this.state.admin_user || this.state.superAdminUser)
        ? "Block"
        : "";
    return button_value;
  };
  blockButton = (txn, auto_retry, txn_id) => {
    if (!txn.check_for_auto_retry)
      return (
        <div>
          <Button
            justIcon
            round
            simple
            color="danger"
            size="sm"
            id="blockTxn"
            style={
              txn.txn.suspect === false && (this.state.admin_user || this.state.superAdminUser)
                ? { display: "block" }
                : { display: "none" }
            }
            value={this.handleBlockTxnValue(txn.txn.suspect)}
            tx_id={txn.id}
            onClick={this.handleOpen}
          >
            <BlockIcon />
          </Button>
          <Button
            justIcon
            round
            simple
            size="sm"
            id="blockTxn"
            color="info"
            style={
              txn.txn.suspect === true && (this.state.admin_user || this.state.superAdminUSer)
                ? { display: "block" }
                : { display: "none" }
            }
            value={"Release Transaction"}
            tx_id={txn.id}
            onClick={this.handleReleaseTransaction}
          >
            <NewReleasesIcon />
          </Button>
          <Dialog
            open={this.state.modalopen}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Add Details"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div>
                  <label>Reason for blocking</label>
                </div>
                <div>
                  <Select
                    labelId="label"
                    id="reasonselect"
                    onChange={this.handlereasonChange}
                    value={this.state.block_reason}
                  >
                    {this.state.reason_all.map((row) => (
                      <MenuItem key={row.urid} value={row.urid}>
                        {row.status}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div>
                  <FormControl fullWidth>
                    <CustomInput
                      labelText="Comments"
                      id="comments"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "comments",
                      }}
                    />
                  </FormControl>
                </div>
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={this.handleClose}>Cancel</Button>
              <Button
                onClick={(e) => this.handleBlockRule(e)}
                color="rose"
                autoFocus
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
  };
  handleBlockRule = (event) => {
    event.preventDefault();
    if (this.state.block_reason === "") {
      alert("Please select one reason out of the dropdown.");
      return;
    } else {
      if (document.getElementById("comments").value === "") {
        alert("Please add a comment.", this.state.transaction.txn.id);
        return;
      }
    }
    var confirmation = window.confirm(
      "Are you sure you want to block this transaction?"
    );
    if (confirmation) {
      var urid = this.state.block_reason;
      // const base_url = window.location.origin;
      this.setState({ loading: true });
      axios({
        method: "post",
        url: base_url + "/add_blocklist_rule.json",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        },
        params: {
          id: this.state.transaction.txn.id,
          urid: urid,
          comment: document.getElementById("comments").value,
        },
      })
        .then((result) => {
          //access the results here....
          // console.log(result);
          // console.log(result.data);
          if (result.data === true) {
            this.setState({
              loading: false,
              alert: true,
              alert_msg:
                "You have successfully successfully blocked " +
                this.state.transaction.txn_id +
                "!",
              alert_title: "Blocked!",
              danger: false,
              warning: false,
              success: true,
            });
            this.setState({ modalopen: false }, () =>
              console.log("Success Block")
            );
          } else if (Array.isArray(result.data)) {
            //status = false
            this.setState({ modalopen: false }, () => console.log("Error"));
            this.setState({
              loading: false,
              alert: true,
              alert_msg: result.data[1],
              alert_title: "Could not block the transaction. !",
              danger: true,
              warning: false,
              success: false,
            });
          } else {
            this.setState({ modalopen: false }, () => console.log("Error"));
            this.setState({
              loading: false,
              alert: true,
              alert_msg: "Could not block the transaction!",
              alert_title: "Not Blocked !",
              danger: true,
              warning: false,
              success: false,
            });
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("Error", error);
        });
    }
  };
  handleReleaseTransaction = (event) => {
    event.preventDefault();

    if(this.state.blackListed){
      return alert("recipient's email domain is black listed, please update the email.")
    }

    var confirmation = window.confirm(
      "Are you sure you want to release this transaction?"
    );
    if (confirmation) {
      // const base_url = window.location.origin;
      this.setState({ loading: true });
      axios({
        method: "put",
        url:
          base_url +
          "/release_transaction/" +
          this.state.transaction.txn.id +
          ".json",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        },
        params: {},
      })
        .then((result) => {
          //access the results here....
          // console.log(result.data);
          this.setState({
            loading: false,
            alert: true,
            alert_msg: "This transaction has been successfully released.",
            alert_title: "Released!",
            danger: false,
            warning: false,
            success: true,
          });
          document.getElementById("btn-searchtxn").click();
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("Error", error);
        });
    }
  };
  handlepgChange = (event) => {
    this.setState({ payment_gate: event.target.value });
  };
  handlereasonChange = (event) => {
    this.setState({ block_reason: event.target.value });
  };
  handleClose = () => {
    this.setState({ modalopen: false, block_reason: "" });
  };
  handleOpen = () => {
    if (document.getElementById("blockTxn").value !== "BLOCKED") {
      this.setState({ modalopen: true });
      this.getBlockReason();
    }
  };
  getBlockReason = () => {
    // const base_url = window.location.origin;
    axios({
      method: "get",
      url: base_url + "/get_response_codes_with_status_list.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {},
    })
      .then((result) => {
        //access the results here....
        // console.log(result.data[0].urid);
        this.setState({
          reason_all: result.data,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  getTransactionDetails = (transaction={})=>{
    if(transaction.payment_gateway ==="Transferzero" || transaction.payment_gateway ==="Bloomremit"){
     return {
       sourceCurrency:transaction.source_input_currency,
       sourceAmount:transaction.source_input_amount,
     }
    }
    return {
      sourceCurrency:transaction.source_currency,
      sourceAmount:transaction.source_amount,
     }

  }

  getReceiptButton = (txn_id) => {
    return (
      <div>
      {txn_id} 
        <Button
          justIcon
          simple
          color="success"
          size="sm"
          onClick={(e) => this.getReceipt(e, txn_id)}
        >
          <ReceiptIcon className={this.props.classes.receiptButton} />
        </Button>
      </div>
    );
  };

  getReceipt = (e, txn_id) => {
    e.preventDefault();
    axios({
      method: "get",
      url: base_url + '/transactions/' + txn_id + "/receipt",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        'Content-type': 'application/json'
      }
    })
    .then((res) => {
      if(res.data){
        let d = res.data
        if(IMGFORMAT.includes(res.data.extension)){
          let image = new Image();
          image.src = "data:image/"+ res.data.extension + ";base64," + res.data.file;

          let w = window.open("");
          w.document.write(image.outerHTML);
        }else if(res.data.extension == 'pdf'){
          let pdfWindow = window.open("")
          pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
            encodeURI(res.data.file) + "'></iframe>"
          )
        }else{
          alert('Proof of Payment format '+ res.data.extension +' is not supported');
        }
      }else{
        alert('Proof of Payment is not available');
      }
    })
    .then((error) => {
      console.log('error', error)
    })
  };

  get getData() {
    const transaction = this.state.transaction;

    const {sourceCurrency,sourceAmount} = this.getTransactionDetails(transaction.txn);

    if (this.state.transaction !== "") {
      // console.log(transaction.txn.suspect);
      if (!this.state.agent_user) {
        return [
          ["Transaction ID", this.getReceiptButton(transaction.txn.id)],
          ["Source Currency", sourceCurrency],
          ["Destination Currency", transaction.txn.destination_currency],
          ["Source Amount", sourceAmount],
          ["Destination Amount", transaction.txn.destination_amount],
          ["FX Gain", transaction.txn.fx_gain],
          ["Fee", transaction.txn.fee],
          ["Conversion Rate", transaction.txn.conversion_rate],
          ["Exchange Rate", transaction.txn.exchange_rate],
          ["Commission Rate", transaction.txn.commission_rate],
          ["Commission Percentage", transaction.txn.commission_percentage],
          ["Source Conversion Rate", transaction.txn.source_conversion_rate],
          ["Status", transaction.txn.aasm_state],
          ["Suspect", transaction.txn.suspect.toString()],
          ["Release", transaction.txn.release.toString()],
          ["Client Name", transaction.client_name],
          ["Sub Client", transaction.txn.sub_client_name],
          ["Client Txn Id", transaction.txn.client_txn_id],
          ["Verify Code", transaction.txn.verify_code],
          ["Internal Transaction ID", transaction.txn.ref_code],
          ["Payment Gateway Transaction ID", transaction.txn.ref_no],
          ["Payment UUID", transaction.txn.payment_uuid],
          ...(transaction.txn.account_type !== CASH
            ? []
            : [["DACN", transaction.txn.transferzero_txn_id]]),
          ["Transaction Type", transaction.txn.account_type],
          ["Payment Gateway", transaction.txn.payment_gateway],
          ...(transaction.txn.bank_name
            ? []
            : [["Provider", transaction.txn.provider_name]]),
          ...(transaction.txn.bank_name
            ? [["Bank Name",transaction.txn.bank_name]]
            : []),
          ["Receiver Name", transaction.txn.name],
          ["Receiver Deposit Number", transaction.txn.deposit_number],
          ["IFSC", transaction.txn.ifsc],
          ["Created At", transaction.created_at_datetime],
          ["Updated At", transaction.updated_at_datetime],
          ["Gateway Message", transaction.txn.gateway_message],
          ["Response Message", transaction.txn.response_message],
          ["Sender First Name", transaction.txn.sender_first_name],
          ["Sender Last Name", transaction.txn.sender_last_name],
          ["Sender Street Name",transaction.txn.sender_street],
          ["Sender Address", transaction.txn.sender_address],
          ...(transaction.txn.account_type !== CASH
            ? []
            : [["Sender Municipality", transaction.txn.sender_municipality],["Sender Province",transaction.txn.sender_province]]),
          ["Sender City", transaction.txn.sender_city],
          ["Sender Country of Birth", transaction.txn.sender_country_of_birth],
          ["Sender Date of Birth", transaction.txn.sender_date_of_birth],
          ["Sender Country", transaction.txn.sender_country],
          ["Sender Email", transaction.txn.sender_email],
          ...(transaction.txn.account_type !== CASH
            ? []
            : [["Sender Id Type", transaction.txn.sender_id_type]]),
          ["Sender Id Number",transaction.txn.sender_id_number],
          [
            "Sender Mobile Phone Country",
            transaction.txn.sender_mobile_phone_country,
          ],
          [
            "Sender Mobile Phone Number",
            transaction.txn.sender_mobile_phone_number,
          ],
          ["Sender Type", transaction.txn.sender_type],
          ["Sender Zipcode", transaction.txn.sender_zipcode],
          ["Sender Gender", transaction.txn.sender_gender],
          ...(transaction.txn.payment_gateway === "Moneywave"
            ? [["Auto Retry Count", transaction.txn.auto_retry_count]]
            : []),
          ["Agent Name", transaction.txn.agent_name],
          ["Agent Code", transaction.txn.agent_code],
          ["Memo", transaction.txn.memo],
          [
            "Manual Reprocess",
            this.reprocessButton(transaction.txn, transaction.txn.auto_retry),
          ],
          [
            "Mark Fail",
            this.failButton(
              transaction.txn,
              transaction.txn.auto_retry,
              transaction.txn.id
            ),
          ],
          [
            "Mark Cancel",
            this.cancelButton(
              transaction.txn,
              transaction.txn.auto_retry,
              transaction.txn.id
            ),
          ],
          [
            "Mark Paid",
            this.paidButton(
              transaction.txn,
              transaction.txn.auto_retry,
              transaction.txn.id
            ),
          ],
          [
            "Block Transaction",
            this.blockButton(
              transaction,
              transaction.txn.auto_retry,
              transaction.txn.id
            ),
          ],
          [
            "Release Transaction",
            this.releaseButton(
              transaction.txn,
              transaction.txn.id
            ),
          ],
        ];
      } else {
        return [
          ["Transaction ID", this.getReceiptButton(transaction.txn.id)],
          ["Source Currency", transaction.txn.source_currency],
          ["Destination Currency", transaction.txn.destination_currency],
          ["Source Amount", transaction.txn.source_amount],
          ["Destination Amount", transaction.txn.destination_amount],
          ["FX Gain", transaction.txn.fx_gain],
          ["Fee", transaction.txn.fee],
          ["Conversion Rate", transaction.txn.conversion_rate],
          ["Exchange Rate", transaction.txn.exchange_rate],
          ["Commission Rate", transaction.txn.commission_rate],
          ["Commission Percentage", transaction.txn.commission_percentage],
          ["Source Conversion Rate", transaction.txn.source_conversion_rate],
          ["Status", transaction.txn.aasm_state],
          ["Suspect", transaction.txn.suspect.toString()],
          ["Release", transaction.txn.release.toString()],
          ["Client Name", transaction.client_name],
          ["Sub Client", transaction.txn.sub_client_name],
          ["Client Txn Id", transaction.txn.client_txn_id],
          ["Verify Code", transaction.txn.verify_code],
          ["Internal Transaction ID", transaction.txn.ref_code],
          ["Payment Gateway Transaction ID", transaction.txn.ref_no],
          ["Payment UUID", transaction.txn.payment_uuid],
          ...(transaction.txn.account_type !== CASH
            ? []
            : [["DACN", transaction.txn.transferzero_txn_id]]),
          ["Transaction Type", transaction.txn.account_type],
          // ["Payment Gateway", transaction.txn.payment_gateway],
          ...(transaction.txn.bank_name
            ? []
            : [["Provider", transaction.txn.provider_name]]),
          ...(transaction.txn.bank_name
            ? [["Bank Name",transaction.txn.bank_name]]
            : []),
          ["Receiver Name", transaction.txn.name],
          ["Receiver Deposit Number", transaction.txn.deposit_number],
          ["IFSC", transaction.txn.ifsc],
          ["Created At", transaction.created_at_datetime],
          ["Updated At", transaction.updated_at_datetime],
          // ["Gateway Message", transaction.txn.gateway_message],
          ["Response Message", transaction.txn.response_message],
          ["Sender First Name", transaction.txn.sender_first_name],
          ["Sender Last Name", transaction.txn.sender_last_name],
          ["Sender Street Name",transaction.txn.sender_street],
          ["Sender Address", transaction.txn.sender_address],
          ...(transaction.txn.account_type !== CASH
            ? []
            : [["Sender Municipality", transaction.txn.sender_municipality],["Sender Province",transaction.txn.sender_province]]),
          ["Sender City", transaction.txn.sender_city],
          ["Sender Country of Birth", transaction.txn.sender_country_of_birth],
          ["Sender Date of Birth", transaction.txn.sender_date_of_birth],
          ["Sender Country", transaction.txn.sender_country],
          ["Sender Email", transaction.txn.sender_email],
          ...(transaction.txn.account_type !== CASH
            ? []
            : [["Sender Id Type", transaction.txn.sender_id_type]]),
          ["Sender Id Number",transaction.txn.sender_id_number],
          [
            "Sender Mobile Phone Country",
            transaction.txn.sender_mobile_phone_country,
          ],
          [
            "Sender Mobile Phone Number",
            transaction.txn.sender_mobile_phone_number,
          ],
          ["Sender Type", transaction.txn.sender_type],
          ["Sender Zipcode", transaction.txn.sender_zipcode],
          ["Sender Gender", transaction.txn.sender_gender],
         ...(transaction.txn.payment_gateway === "Moneywave"
            ? [["Auto Retry Count", transaction.txn.auto_retry_count]]
            : []),
          ["Agent Name", transaction.txn.agent_name],
          ["Agent Code", transaction.txn.agent_code],
          ["Memo", transaction.txn.memo],
          [
            "Block Transaction",
            this.blockButton(
              transaction,
              transaction.txn.auto_retry,
              transaction.txn.id
            ),
          ],
        ];
      }
    } else {
      return [];
    }
  }
  get getTimelog() {
    if (this.state.timelogs !== "") {
      return this.state.timelogs.map((row) => [
        [row.id],
        [row.transaction_id],
        [row.payment_gateway],
        [row.action],
        [row.message],
        [row.ref_no],
        [row.payment_uuid],
        [row.created_at],
        [row.user_id],
      ]);
    } else {
      return [];
    }
  }
  handleCSVDownload = (e) => {
    var csv = [];
    var rows = this.getData;
    // console.log(rows);
    csv.push(["Transaction"]);
    csv.push(["Attribute", "Value"]);
    var attr = rows[0][0];
    var attr_value = rows[0][1];
    // csv.push([attr, attr_value])
    for (var i = 0; i < rows.length - 5; i++) {
      attr = rows[i][0];
      attr_value = rows[i][1];
      csv.push([attr, attr_value]);
    }
    // console.log(csv);
    csv.push([]);

    rows = document.querySelectorAll("table#allTimelogs tr");
    console.log(rows);
    if (rows.length) {
      csv.push(["Timelogs"]);
      for (i = 0; i < rows.length; i++) {
        var row = [],
          cols = rows[i].querySelectorAll("td, th");
        for (var j = 0; j < cols.length; j++) row.push(cols[j].innerText);
        csv.push(row.join(", "));
      }
    }
    var csvFile;
    var downloadLink;
    csvFile = new Blob([csv.join("\n")], { type: "text/csv" });
    downloadLink = document.createElement("a");
    downloadLink.download = "Transaction";
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  };
  handleXLSDownload = (e) => {
    var csv = [];
    var rows = this.getData;
    // console.log(rows);
    csv.push(["Transaction"]);
    csv.push(["Attribute", "Value"]);
    var attr = rows[0][0];
    var attr_value = rows[0][1];
    // csv.push([attr, attr_value])
    for (var i = 0; i < rows.length - 5; i++) {
      attr = rows[i][0];
      attr_value = rows[i][1];
      csv.push([attr, attr_value]);
    }
    // console.log(csv);
    csv.push([]);

    rows = document.querySelectorAll("table#allTimelogs tr");
    if (rows.length) {
      csv.push(["Timelogs"]);
      for (i = 0; i < rows.length; i++) {
        var row = [],
          cols = rows[i].querySelectorAll("td, th");
        for (var j = 0; j < cols.length; j++) row.push(cols[j].innerText);
        csv.push(row.join(", "));
      }
    }
    var csvFile;
    var downloadLink;
    csvFile = new Blob([csv.join("\n")], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    downloadLink = document.createElement("a");
    downloadLink.download = "Transaction";
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  };
  hideAlert = () => {
    this.setState({
      alert: false,
      alert_msg: "",
      alert_title: "",
      alert_type: "",
    });
  };
  render() {
    const { classes } = this.props;
    const transactionData = this.getData;
    const timelogData = this.getTimelog;

    // console.log(transactionData);
    return (
      <div>
        <CustomSweet
          alert={this.state.alert}
          message={this.state.alert_msg}
          title={this.state.alert_title}
          hide_alert={this.hideAlert}
          danger={this.state.danger}
          warning={this.state.warning}
          success={this.state.success}
        />
        <div className={this.state.loading === true ? classes.main_screen : ""}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody style={{ padding: "5px 20px" }}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <CustomInput
                      labelText="Transaction ID"
                      id="txn_id"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: this.handleTxnChange,
                        type: "txn_id",
                      }}
                    />
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody style={{ padding: "5px 20px" }}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <CustomInput
                      labelText="Client Txn ID"
                      id="client-txn-id"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: this.handleClienttxnChange,
                        type: "client-txn-id",
                      }}
                    />
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            {userType !== AGENT && userType !== TXNAGENT && userType !== WIRETXNAGENT && userType !== CRYPTOAGENT ?(<GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody style={{ padding: "5px 20px" }}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <CustomInput
                      labelText="Payment Gateway Txn ID"
                      id="payment-txn-id"
                      inputProps={{
                        onChange: this.handlePaymenttxnChange,
                        type: "payment-txn-id",
                      }}
                    />
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>):null}
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody style={{ padding: "5px 20px" }}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <CustomInput
                      labelText="Verify Code"
                      id="verify-code"
                      inputProps={{
                        onChange: this.handleVerifyCodeChange,
                      }}
                    />
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody style={{ padding: "5px 20px" }}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <CustomInput
                      labelText="Reference Code"
                      id="ref_code"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: this.handleRefCodeChange,
                        type: "ref_code",
                      }}
                    />
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody style={{ padding: "5px 20px" }}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <CustomInput
                      labelText="Payment UUID"
                      id="payment_uuid"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: this.handlePaymentUuidChange,
                        type: "payment_uuid",
                      }}
                    />
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody style={{ minHeight: "85px" }}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <Button
                      color="rose"
                      onClick={this.onSearch}
                      id="btn-searchtxn"
                    >
                      Search
                    </Button>
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            {/* <GridItem xs={12} sm={12} md={2}>
              <Card>
                <CardBody>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <Button color="rose" onClick={this.handleCSVDownload}>
                      Export To CSV
                    </Button>
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem> */}
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody style={{ minHeight: "85px" }}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <Button color="rose" onClick={this.handleXLSDownload}>
                      Export To XLS
                    </Button>
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>

          {this.state.loadtransactions && (
            <GridContainer>
              <div align="center">
                {this.state.loading === true && <Progress />}
              </div>
              <GridItem xs={12}>
                <Card>
                  <CardHeader color="rose" text>
                    <CardText color="rose">
                      <h4 className={classes.cardTitle}>Search Transaction</h4>
                    </CardText>
                  </CardHeader>
                  <CardBody>
                    <Table
                      tableHeaderColor="primary"
                      tableHead={["Attribute", "Value"]}
                      tableData={transactionData}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          )}
          <div>
            {this.state.loadtimelog && (
              <GridContainer>
                <GridItem xs={12}>
                  <Card>
                    <CardHeader color="rose" text>
                      <CardText color="rose">
                        <h4 className={classes.cardTitle}>
                          Transaction Timelog2
                        </h4>
                      </CardText>
                    </CardHeader>
                    <CardBody>
                      <Table
                        tableHeaderColor="primary"
                        tableHead={[
                          "ID",
                          "Transaction ID",
                          "Payment Gateway",
                          "Action",
                          "Message",
                          "Payment Gateway Transaction ID",
                          "Action Performed At",
                          "Action Performed By",
                        ]}
                        tableData={timelogData}
                        id="allTimelogs"
                      />
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(SearchTransaction);
