import React, { useEffect, useState } from "react";
import TransactionFeeRows from "./Rows";
import * as yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import alertstyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
const useStyles = makeStyles({ ...cssstyles, ...alertstyles });

const schema = yup.object({
  selectedCurrency: yup.string().required("Please Select Currency !!!"),
});
const initialState = {
  selectedCurrency: "",
  errors: {},
};
const View = ({
  dialogOpen = false,
  passedState,
  action = "",
  closeFunction,
  currentId,
  sourceCurrenciesList = [],
  handleSubmitClick,
  sourceCurrency,
  editingFieldId,
}) => {
  const [state, setState] = useState(initialState);
  const classes = useStyles();

  useEffect(() => {
    setState((prevSate) => ({
      ...prevSate,
      selectedCurrency: sourceCurrency,
      errors: {},
    }));
  }, [sourceCurrency]);

  const handleChange = (event) => {
    const { value } = event.target;
    // console.log(value);
    setState((prevState) => ({
      ...prevState,
      selectedCurrency: value,
    }));
  };
  const handleSubmit = (rows) => {
    schema
      .validate(
        { selectedCurrency: state.selectedCurrency },
        {
          abortEarly: false,
        }
      )
      .then(() => {
        const currency = state.selectedCurrency;
        setState((prevState) => ({
          ...prevState,
          errors: {},
          selectedCurrency: "",
        }));
        if (action === "add") {
          handleSubmitClick({ rows, sourceCurrency: currency });
        } else if (action === "update") {
          handleSubmitClick({
            rows,
            sourceCurrency: state.selectedCurrency,
            id: editingFieldId,
          });
        }
      })
      .catch((err) => {
        let allErrors = {};
        err.inner.map((error) => {
          allErrors[error.path] = error.message;
          return error;
        });
        setState((prevState) => ({
          ...prevState,
          errors: allErrors,
        }));
      });
  };
  return (
    <Dialog open={dialogOpen} onClose={closeFunction}>
      <DialogTitle>
        {action === "add" ? "Add" : "Update"} Transaction Fee
      </DialogTitle>
      <DialogContent>
        <GridContainer className={classes.marginCustomTop}>
          <GridItem xs={6} md={4} sm={6}>
            <FormControl
              fullWidth
              className={classes.selectFormControl}
              error={state.errors.selectedCurrency !== undefined}
              disabled={action === "update"}
            >
              <InputLabel className={classes.selectLabel}>
                {"Source Currency*"}
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={state.selectedCurrency}
                onChange={handleChange}
                inputProps={{
                  name: "selectedCurrency",
                }}
              >
                {sourceCurrenciesList.map((row) => (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={row.attributes.name}
                    key={row.attributes.id}
                  >
                    {row.attributes.name.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
        <TransactionFeeRows
          passedState={passedState}
          action={action}
          handleSubmit={handleSubmit}
          currentId={currentId}
        />
      </DialogContent>
    </Dialog>
  );
};

export default View;
