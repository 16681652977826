import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import CustomSweet from "../Components/CustomSweet";
import Progress from "../Components/Progress";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
// import SweetAlert from "react-bootstrap-sweetalert";
import alertstyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { makeStyles } from "@material-ui/core";
import { makeApiCall } from "utils/commonCheckFunctions";

const userType = localStorage.getItem("user_type");
const SUPERADMINUSER = "superadmin";

const initialState = {
  transactionType:"Bank",
  destinatioCurrency: "",
  countryList: [],
  country: "",
  paymentGateway: "",
  paymentGateWayList: [],
  selectedBA:"",
  auto_release: false,
  baUsers:[],
  loading: false,
  selectedCountryObject: {},
  selectedCountryMapId: "", //this is for post and update
  totalApiCount: 0,
};
const initialAlertState = {
  alert: false,
  success: false,
  error: false,
  warning: false,
  message: "",
  title: "",
};
const useStyle = makeStyles({ ...cssstyles, ...alertstyles });

const TransactionTypes = [{name:"Bank",value:"Bank"},{name:"Mobile",value:"Mobile"}]


const  getBAUserList=(classes,selectedBA,baUsers,handleChange)=>{
  if (userType === SUPERADMINUSER) {
    return (
      <GridItem xs={12} md={2} sm={10}>
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel className={classes.selectLabel}>{"BA *"}</InputLabel>

        <Select
          MenuProps={{
            className: classes.selectMenu,
          }}
          classes={{
            select: classes.select,
          }}
          value={selectedBA}
          onChange={handleChange}
          inputProps={{
            name: "selectedBA",
          }}
        >
          {baUsers.map((row) => (
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={row.id}
              key={row.id}
            >
              {row.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </GridItem>
    );
  } else {
    return null;
  }
}

const CountryWiseSettingsView = ({
  handleChange,
  country,
  countryList,
  paymentGateWayList,
  paymentGateway,
  handleSubmit,
  selectedCountryMapId,
  transactionType,
  baUsers,
  selectedBA,
  auto_release
}) => {
  const classes = useStyle();
  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4>Country Wise Payment Gateway Selection</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <GridContainer>
              <GridItem xs={12} md={2} sm={10}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    // error={errors.destinationCountry !== undefined}
                    disabled
                  >
                    <InputLabel className={classes.selectLabel}>
                      {"Transaction Type*"}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={transactionType}
                      onChange={handleChange}
                      inputProps={{
                        name: "country",
                      }}
                    >
                      {TransactionTypes.map((row, index) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={row.value}
                          key={index}
                        >
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                  {getBAUserList(classes,selectedBA,baUsers,handleChange)}
                <GridItem xs={12} md={2} sm={10}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    // error={errors.destinationCountry !== undefined}
                  >
                    <InputLabel className={classes.selectLabel}>
                      {"Country*"}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={country}
                      onChange={handleChange}
                      inputProps={{
                        name: "country",
                      }}
                    >
                      {countryList.map((row, index) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={row.attributes.code}
                          key={index}
                        >
                          {row.attributes.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={8} md={2} sm={8}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    // error={errors.paymentGateway !== undefined}
                  >
                    <InputLabel className={classes.selectLabel}>
                      {"Payment Gateways"}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={paymentGateway}
                      onChange={handleChange}
                      inputProps={{
                        name: "paymentGateway",
                      }}
                    >
                      {paymentGateWayList.map((row) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={row.name}
                          key={row.id}
                        >
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={3} md={2} sm={3}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    style={{marginTop: '25px'}}>
                    <label>
                      <input type="checkbox" 
                        onChange={handleChange}
                        value={auto_release}
                        checked={auto_release}
                        name="auto_release"
                        />
                      Auto Release
                    </label>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} md={2} sm={10} style={{marginTop:"10px"}}>
                  <Button variant="contained"  onClick={handleSubmit}>
                    {selectedCountryMapId ? "Update" : "Add"}
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
};

const CountryWiseSettings = () => {
  const [viewState, setViewState] = useState(initialState);
  const [alertState, setAlertState] = useState(initialAlertState);

  const hideAlert = () => {
    setAlertState(initialAlertState);
  };

  useEffect(() => {
    getCountries();
    getAllBaUsers();
  }, []);

  useEffect(() => {
    if (viewState.totalApiCount === 0 && viewState.loading) {
      setViewState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  }, [viewState.totalApiCount]);

  const handleSubmitOrUpdate = async () => {
    const {
      paymentGateway,
      selectedCountryObject,
      selectedCountryMapId,
      selectedBA,
      auto_release
    } = viewState;

    setViewState((prevState) => ({
      ...prevState,
      totalApiCount: prevState.totalApiCount + 1,
      loading: true,
    }));
    const url = selectedCountryMapId
      ? `/countrywise_payment_gateways/${selectedCountryMapId}.json`
      : "/countrywise_payment_gateways.json";
    const data = await makeApiCall({
      url: url,
      method: selectedCountryMapId ? "put" : "post",
      params: {
        "countrywise_payment_gateway[name]": paymentGateway,
        "countrywise_payment_gateway[destination_currency]": selectedCountryObject.attributes.currency.toLowerCase(),
        "countrywise_payment_gateway[country_name]": selectedCountryObject.attributes.code,
        "countrywise_payment_gateway[auto_release]": auto_release,
        "ba_id": selectedBA
      },
    });
    const { error = false, message } = data;
    if (!error) {
      if (selectedCountryMapId) {
        const { id, name, auto_release } = data;
        setViewState((prevState) => ({
          ...prevState,
          totalApiCount: prevState.totalApiCount - 1,
          paymentGateway: name,
          selectedCountryMapId: id,
          auto_release: auto_release
        }));
        setAlertState({
          alert:true,
          message:"SuccessFully Updated Settings !!!!",
          success:true,
          error:false,
          warning:false,
          title:"Success"
       })
      } else {
        const {
          id,
          countrywise_payment_gateway: { name, auto_release },
        } = data;
        setViewState((prevState) => ({
          ...prevState,
          totalApiCount: prevState.totalApiCount - 1,
          paymentGateway: name,
          selectedCountryMapId: id,
          auto_release: auto_release
        }));
        setAlertState({
          alert:true,
          message:"SuccessFully Created Settings !!!!",
          success:true,
          error:false,
          warning:false,
          title:"Success"
       })
      }
    } else {
      console.log(message);
      setViewState((prevState) => ({
        ...prevState,
        totalApiCount: prevState.totalApiCount - 1,
      }));
      setAlertState({
        alert:true,
        message:"Something Went Wrong !!!!",
        success:false,
        error:true,
        warning:false,
        title:"Error"
     })
    }
  };

  useEffect(() => {
    if (viewState.country !== "") {
      const selectedCountryDetails = viewState.countryList.filter((el) => {
        return el.attributes.code === viewState.country;
      });
      setViewState((prevState) => ({
        ...prevState,
        totalApiCount: 2,
        selectedCountryObject: selectedCountryDetails[0],
        loading: true,
      }));
      async function getClientSourceCurrencyMaps() {
        const params = userType === SUPERADMINUSER?{ba_id:viewState.selectedBA}:{};
        const data = await makeApiCall({
          url: "/show_countrywise_payment_gateway.json",
          method: "get",
          params: {
            country_name: selectedCountryDetails[0].attributes.name,
            currency: selectedCountryDetails[0].attributes.currency,
            ...params,
          },
        });
        const { error = false, message } = data;
        if (!error) {
          const {
            countrywise_payment_gateway
          } = data;
          console.log(countrywise_payment_gateway)
          setViewState((prevState) => ({
            ...prevState,
            totalApiCount: prevState.totalApiCount - 1,
            paymentGateway: countrywise_payment_gateway?countrywise_payment_gateway.name:"",
            selectedCountryMapId: countrywise_payment_gateway?countrywise_payment_gateway.id:"",
            auto_release: countrywise_payment_gateway?countrywise_payment_gateway.auto_release:""
          }));
          // console.log(data);
        } else {
          console.log(message);
          setViewState((prevState) => ({
            ...prevState,
            totalApiCount: prevState.totalApiCount - 1,
          }));
        }
      }
      async function getPaymentGateways() {
        const currency = selectedCountryDetails[0].attributes.currency;
        const data = await makeApiCall({
          url: "/show_payment_gateways.json",
          method: "get",
          params: {
            currency: currency.toLowerCase(),
          },
        });
        const { error = false, message } = data;
        if (!error) {
          const { payment_gateways } = data;
          setViewState((prevState) => ({
            ...prevState,
            totalApiCount: prevState.totalApiCount - 1,
            paymentGateWayList: payment_gateways,
          }));
        } else {
          setViewState((prevState) => ({
            ...prevState,
            totalApiCount: prevState.totalApiCount - 1,
          }));
          console.log(message);
        }
      }
      getClientSourceCurrencyMaps();
      getPaymentGateways();
    }
  }, [viewState.country, viewState.countryList]);

  const getAllBaUsers = async () => {
    const data = await makeApiCall({
      url: "/users.json",
      method: "get",
      params: { role: "ba" },
    });
    const { error = false } = data;
    if (!error) {

      console.log(data)
      setViewState((prevState)=>({
        ...prevState,
        baUsers: data,
      }))
    } else {
      const { message } = data;
      console.log(message);
    }
  };

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    if(name === "selectedBA"){
      setViewState((prevState)=>({
        ...prevState,
        [name]:value,
        country:"",
        paymentGateway:"",
        auto_release: ""
      }))
    }
    else if(name === "auto_release")
    {
      setViewState((prevState)=>({
        ...prevState,
        [name]: checked,
      }))
    }
    else{
      setViewState((prevState)=>({
        ...prevState,
        [name]:value
      }))
    }
  };
  const getCountries = async () => {
    const data = await makeApiCall({
      url: "/countrywise_country_codes.json",
      method: "get",
    });
    const { error = false, message } = data;
    if (!error) {
      const { countries } = data;

      // const clientList = Array.isArray(clients) ? clients : [clients];
      // // this.getClientData(result.data.clients);
      // const clientData = getUpdatedClientlist(clientList);
      setViewState((prevState) => ({
        ...prevState,
        countryList: countries,
      }));
      // console.log(data);
    } else {
      console.log(message);
    }
  };
  const classes = useStyle();
  return (
    <>
      <CustomSweet
        alert={alertState.alert}
        message={alertState.message}
        title={alertState.title}
        hide_alert={hideAlert}
        danger={alertState.error}
        success={alertState.success}
      />
      {/* {viewState.sweetAlert} */}
      <div className={viewState.loading ? classes.main_screen : ""}>
        <div align="center">{viewState.loading && <Progress />}</div>
        <CountryWiseSettingsView
          handleChange={handleChange}
          {...viewState}
          handleSubmit={handleSubmitOrUpdate}
        />
      </div>
    </>
  );
};

export default CountryWiseSettings;
