import React from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
// import CardBody from "components/Card/CardBody.js";
import CardText from "components/Card/CardText.js";
import Table from "components/Table/Table.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import apiUrl from "../api";

const base_url = apiUrl;

class DailyTransactionLimit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: "",
      client_id: "",
      ngnBalance: "0.0",
      ngnLocked: "0.0",
      ngnLimit: "0.0",
      ghsBalance: "0.0",
      ghsLocked: "0.0",
      ghsLimit: "0.0",
      kesBalance: "0.0",
      kesLocked: "0.0",
      kesLimit: "0.0",
      inrBalance: "0.0",
      inrLocked: "0.0",
      inrLimit: "0.0",
    };
  }
  componentDidMount() {
    this.getBalances();
  }
  getBalances() {
    // const base_url = window.location.origin;
    // Moneywave NGN Balance
    if (
      localStorage.getItem("session_token") === "" ||
      localStorage.getItem("session_token") === null
    ) {
      return;
    }
    axios({
      method: "get",
      url: base_url + "/get_transaction_limit.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: { currency: "ngn" },
    })
      .then((result) => {
        // console.log(result);
        this.setState({
          ngnBalance: result.data.client_txn_limit.balance,
          ngnLocked: result.data.client_txn_limit.locked,
          ngnLimit: result.data.client_txn_limit.limit_value,
        });
      })
      .catch((error) => {
        console.log("Error", error.response);
        // var text = "Error Loading";
      });

    axios({
      method: "get",
      url: base_url + "/get_transaction_limit.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: { currency: "ghs" },
    })
      .then((result) => {
        // console.log(result);
        this.setState({
          ghsBalance: result.data.client_txn_limit.balance,
          ghsLocked: result.data.client_txn_limit.locked,
          ghsLimit: result.data.client_txn_limit.limit_value,
        });
      })
      .catch((error) => {
        console.log("Error", error.response);
        // var text = "Error Loading";
      });

    axios({
      method: "get",
      url: base_url + "/get_transaction_limit.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: { currency: "kes" },
    })
      .then((result) => {
        // console.log(result);
        this.setState({
          kesBalance: result.data.client_txn_limit.balance,
          kesLocked: result.data.client_txn_limit.locked,
          kesLimit: result.data.client_txn_limit.limit_value,
        });
      })
      .catch((error) => {
        console.log("Error", error.response);
        // var text = "Error Loading";
      });

    axios({
      method: "get",
      url: base_url + "/get_transaction_limit.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: { currency: "inr" },
    })
      .then((result) => {
        // console.log(result);
        this.setState({
          inrBalance: result.data.client_txn_limit.balance,
          inrLocked: result.data.client_txn_limit.locked,
          inrLimit: result.data.client_txn_limit.limit_value,
        });
      })
      .catch((error) => {
        console.log("Error", error.response);
        // var text = "Error Loading";
      });
  }
  render() {
    // const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} md={12} lg={12}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4>Daily Transaction Limit</h4>
                </CardText>
              </CardHeader>
              <br />
              <br />
              <GridContainer justify="space-between">
                <GridItem xs={12} sm={12} md={12}>
                  <Table
                    tableData={[
                      [
                        "Currency Transaction Limit",
                        "Daily Limit",
                        "Locked",
                        "Limit",
                      ],
                      [
                        "NGN Transaction Limit ( ₦ )",
                        parseInt(this.state.ngnBalance).toFixed(2),
                        parseInt(this.state.ngnLocked).toFixed(2),
                        parseInt(this.state.ngnLimit).toFixed(2),
                      ],
                      [
                        "GHS Transaction Limit ( GH₵ )",
                        parseInt(this.state.ghsBalance).toFixed(2),
                        parseInt(this.state.ghsLocked).toFixed(2),
                        parseInt(this.state.ghsLimit).toFixed(2),
                      ],
                      [
                        "KES Transaction Limit ( KES )",
                        parseInt(this.state.kesBalance).toFixed(2),
                        parseInt(this.state.kesLocked).toFixed(2),
                        parseInt(this.state.kesLimit).toFixed(2),
                      ],
                      [
                        "INR Transaction Limit ( ₹ )",
                        parseInt(this.state.inrBalance).toFixed(2),
                        parseInt(this.state.inrLocked).toFixed(2),
                        parseInt(this.state.inrLimit).toFixed(2),
                      ],
                    ]}
                  />
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
export default withStyles(styles)(DailyTransactionLimit);
