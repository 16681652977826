import React from "react";
// react component for creating dynamic tables
import axios from "axios";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Assignment from "@material-ui/icons/Assignment";
import Table from "components/Table/Table.js";
import apiUrl from "../api";
import { withStyles } from "@material-ui/core/styles";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import moment from "moment";
const base_url = apiUrl;
class ClientTimelog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client_id: "",
      currency: localStorage.getItem("currency"),
      timelogs: [],
      loading: false,
    };
  }
  componentDidMount() {
    const path = window.location.href;
    const client_id = path.substring(path.lastIndexOf("/") + 1);
    this.setState({ show_user_id: client_id });
    this.loadtable(client_id);
  }
  loadtable(client_id) {
    // const base_url = window.location.origin;
    console.log(this.state.client_id);
    axios({
      method: "get",
      url: base_url + "/show_client_timelogs.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        currency: localStorage.getItem("currency"),
        id: client_id,
      },
    }).then((result) => {
      //access the results here....
      console.log(result);
      this.setState({
        timelogs: result.data.timelogs,
      });
    });
  }
  showTime(str) {
    var datetime = new Date(str);
    return <span>{moment(datetime).format("YYYY-MM-DD HH:mm:ss")}</span>;
  }
  get getTimelog() {
    if (this.state.timelogs !== "") {
      return this.state.timelogs.map((row, index) => [
        row.id,
        row.txn_id,
        row.client_txn_limit_id,
        row.default_value,
        row.limit_value,
        row.balance,
        row.locked,
        row.total_destination_amount,
        row.reason,
        this.showTime(row.created_at),
        row.username,
      ]);
    } else {
      return [];
    }
  }
  render() {
    const { classes } = this.props;
    const timelogData = this.getTimelog;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  {localStorage.getItem("currency").toUpperCase() +
                    " Client Timelogs"}
                </h4>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="primary"
                  tableHead={[
                    "ID",
                    "Transaction ID",
                    "Client Transaction Limit ID",
                    "Default Value",
                    "Limit Value",
                    "Balance",
                    "Locked",
                    "Total Destination Amount",
                    "Reason",
                    "Action Performed At",
                    "Action Performed By",
                  ]}
                  tableData={timelogData}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
export default withStyles(cssstyles)(ClientTimelog);
