import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ReactTable from "react-table";
import Button from "components/CustomButtons/Button.js";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import Close from "@material-ui/icons/Close";
import { Component } from "react";
import { PureComponent } from "react";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeApiCall } from "../../utils/commonCheckFunctions";
const CANCEL = "cancel",
  FAIL = "fail",
  SUCCESS = "success",
  REPROCESS = "reprocess",
  RELEASE = "release";

const checkboxConditionArray = {
  [CANCEL]: ["processing"],
  [SUCCESS]: ["processing", "validated", "cancelled"],
  [REPROCESS]: ["cancelled"],
  [FAIL]: ["processing", "validated", "cancelled"],
};

const dialogMessage = {
  [CANCEL]: "Are you sure you want to mark cancel this transaction?",
  [SUCCESS]: "Are you sure you want to mark paid this transaction?",
  [REPROCESS]: "Are you sure you want to manually reprocess this transaction?",
  [FAIL]: "Are you sure you want to mark fail this transaction?",
  [RELEASE]: "Are you sure you want to release this transaction?",
};
const bulkDialogMessages = {
  [CANCEL]:
    "Are you sure you want to mark cancel all these transactions? having ids :",
  [SUCCESS]:
    "Are you sure you want to mark paid all these transactions? having ids :",
  [REPROCESS]:
    "Are you sure you want to manually reprocess all these transactions? having ids :",
  [FAIL]:
    "Are you sure you want to mark fail all these transactions? having ids :",
  [RELEASE]:
    "Are you sure you want to release all these transactions? having ids :",
};
const dialogTitle = {
  [CANCEL]: "Mark Cancel",
  [SUCCESS]: "Mark Paid",
  [REPROCESS]: "Manual Reprocess",
  [FAIL]: "Mark Fail",
  [RELEASE]: "Release Transaction",
};
const checkboxListName = {
  [CANCEL]: "cancelCheckboxList",
  [SUCCESS]: "successfulCheckboxList",
  [REPROCESS]: "reprocessCheckboxList",
  [FAIL]: "failCheckboxList",
  [RELEASE]: "releaseCheckboxList",
};

const headerCheckboxName = {
  [CANCEL]: "cancelAll",
  [SUCCESS]: "successfulAll",
  [REPROCESS]: "reprocessAll",
  [FAIL]: "failAll",
  [RELEASE]: "releaseAll",
};
const DialogComponent = ({
  isOpen = false,
  submitClick,
  closeFunction,
  message,
  title,
  showSubmitButton,
}) => {
  return (
    <Dialog open={isOpen} onClose={closeFunction} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeFunction}>Cancel</Button>
        {showSubmitButton ? (
          <Button onClick={submitClick} color="rose" autoFocus>
            Ok
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};
const DialogReprocessComponent = ({
  isOpen = false,
  submitClick,
  closeFunction,
  title,
  allRowData,
  selectedList,
  isBulk = false,
  transactionId,
}) => {
  const [paymentGatewayType, setPaymentGatewayType] = useState("withSame");
  const [allPaymentGateway, setAllPaymentGateway] = useState([]);
  const [details, setDetails] = useState({
    currency: "",
    providerType: "Bank",
    paymentGateway: "",
  });
  useEffect(() => {
    if (!isBulk && transactionId) {
      findElement(transactionId);
    } else if (isBulk && selectedList.size > 0) {
      let id = selectedList.values().next().value;
      findElement(id);
    }
  }, []);

  const findElement = (id) => {
    let foundElement = allRowData.filter((element) => {
      return element.txn.id === Number(id);
    });
    if (foundElement.length > 0) {
      setDetails({
        currency: foundElement[0].txn.destination_currency,
        providerType: foundElement[0].txn.account_type,
        paymentGateway: foundElement[0].txn.payment_gateway,
      });
      getAllPaymentGateways(
        foundElement[0].txn.account_type,
        foundElement[0].txn.destination_currency
      );
      console.log(foundElement[0].txn.destination_currency,foundElement[0].txn.payment_gateway,foundElement[0].txn.account_type)
    }
  };
  const handleChange = (event) => {
    const { value } = event.target;
    setDetails((prevState) => ({
      ...prevState,
      paymentGateway: value,
    }));
  };
  const handleRadioChange = (event) => {
    const { value } = event.target;
    setPaymentGatewayType(value);
  };
  const getAllPaymentGateways = async (
    transactionType,
    destinationCurrency
  ) => {
    const subUrl =
      (transactionType === "Bank" || transactionType === "Wire")
        ? "/show_payment_gateways.json"
        : "/show_provider_payment_gateways.json";
    const data = await makeApiCall({
      url: subUrl,
      method: "get",
      params: { currency: destinationCurrency },
    });
    const { error = false, message } = data;
    if (!error) {
      const { payment_gateways, provider_payment_gateways } = data;
      if (payment_gateways) {
        setAllPaymentGateway(payment_gateways);
      } else {
        setAllPaymentGateway(provider_payment_gateways);
      }
    } else {
      console.log(message);
    }
  };

  const getDivElement = () => {
    if ((!isBulk && transactionId) || (isBulk && selectedList.size > 0)) {
      return (
        <>
          <DialogContent>
            <RadioGroup
              aria-label="payment gateway type"
              name="paymentGatewayType"
              value={paymentGatewayType}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="withSame"
                control={<Radio />}
                label="With same Payment Gateway"
              />
              <FormControlLabel
                value="withDifferent"
                control={<Radio />}
                label="With different Payment Gateway"
              />
            </RadioGroup>
            {paymentGatewayType !== "withSame"? <FormControl fullWidth disabled={paymentGatewayType === "withSame"}>
              <InputLabel>Payment Gateway</InputLabel>
              <Select value={details.paymentGateway} onChange={handleChange}>
                {allPaymentGateway.map((row) =>{
                   const { attributes } = row;
                   let name = "",
                     id = "";
                   if (attributes !== undefined) {
                     name = attributes.name;
                     id = attributes.id;
                   } else {
                     name = row.name;
                     id = row.id;
                   } 
                  return(
                  <MenuItem key={id} value={name}>
                    {name}
                  </MenuItem>
                )})}
              </Select>
            </FormControl>:null}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeFunction}>Cancel</Button>
            <Button
              onClick={() =>
                submitClick({
                  paymentGatewayType,
                  paymentGateway: details.paymentGateway,
                  isBulk,
                  id: transactionId,
                })
              }
              color="rose"
              autoFocus
            >
              Submit
            </Button>
          </DialogActions>
        </>
      );
    } else {
      return (
        <>
          <DialogContent>
            <DialogContentText>No Item Selected !!</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeFunction}>close</Button>
          </DialogActions>
        </>
      );
    }
  };

  return (
    <Dialog open={isOpen} onClose={closeFunction}>
      <DialogTitle>{title}</DialogTitle>
      {getDivElement()}
    </Dialog>
  );
};
const ActionButton = ({ row, handleClick, label }) => {
  const {
    original: { txn },
  } = row;
  const { id } = txn;
  return (
    <>
      <Button color="info" size="sm" onClick={() => handleClick(id)}>
        {label}
      </Button>
    </>
  );
};

const RowCheckBoxWithButton = ({
  row,
  // columnProps: {
  //   rest: {
  handleOnChange,
  handleButtonClick, //this function will be called when dialog proceed button is clicked
  handleDialogOpen,
  dialogMessage,
  title,
  icon,
  typeOfAction = CANCEL,
  adminUser = false,
  buttonColor,
}) => {
  const {
    original: { txn },
  } = row;
  const { id } = txn;
  const handleDialogClick = () => {
    handleButtonClick(id, typeOfAction, adminUser);
  };
  const shouldRender = shouldRenderComponent(typeOfAction, adminUser, txn);

  return (
    <>
      {shouldRender ? (
        <div
          style={{
            display: "flex",
            flexFlow: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            justIcon
            round
            simple
            color={buttonColor}
            onClick={() => {
              if (typeOfAction !== REPROCESS) {
                handleDialogOpen(
                  handleDialogClick,
                  dialogMessage,
                  title,
                  typeOfAction,
                  false
                );
              } else {
                handleDialogOpen(id, false);
              }
            }}
          >
            {icon}
          </Button>
          <input
            // checked={isSlected}
            name={checkboxListName[typeOfAction]}
            value={txn.id}
            onChange={handleOnChange}
            type="checkbox"
          />
        </div>
      ) : null}
    </>
  );
};
const shouldRenderComponent = (action = CANCEL, adminUser, txn) => {
  if (action === RELEASE) {
    return txn.hold && adminUser && txn.aasm_state === "processing";
  }
  if (action === CANCEL) {
    return (
      checkboxConditionArray[action].includes(txn.aasm_state) && adminUser
      // !(txn.aasm_state === "validated" && txn.payment_gateway !== "Wemabank")
    );
  }
  return checkboxConditionArray[action].includes(txn.aasm_state) && adminUser;
  // return true;
};

const BulkTransactionCheckbox = ({
  handleOnChange,
  listName,
  action,
  icon,
  buttonColor,
  id,
  headerLabel,
  handleButtonClick,
  handleDialogOpen,
}) => {
  const handleClick = () => {
    if (action !== REPROCESS) {
      handleDialogOpen(
        () => {
          handleButtonClick(action);
        },
        bulkDialogMessages[action],
        dialogTitle[action],
        action,
        true
      );
    } else {
      handleButtonClick(null, true);
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p>{headerLabel}</p>
      <Button justIcon round simple color={buttonColor} onClick={handleClick}>
        {icon}
      </Button>
      <input
        // checked={isSlected}
        name={checkboxListName[action]}
        onChange={() => {
          handleOnChange(id, listName, action);
        }}
        type="checkbox"
        id={id}
      />
    </div>
  );
};

class TableView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cancelAll: false,
      failAll: false,
      successfulAll: false,
      reprocessAll: false,
      releaseAll: false,
      isOpen: false,
      isReprocessOpen: false,
      submitClick: () => {},
      closeFunction: this.closeDialog,
      message: "",
      title: "",
      showDialogSubmitButton: true,
    };
    this.AllCheckboxes = {
      cancelCheckboxList: new Set(),
      failCheckboxList: new Set(),
      successfulCheckboxList: new Set(),
      reprocessCheckboxList: new Set(),
      releaseCheckboxList: new Set(),
    };
    this.reprocessId = null;
    this.bulkReprocess = false;
    this.reactTable = React.createRef();
    this.resetAllCheckboxList = this.resetAllCheckboxList.bind(this);
    this.handleCheckAndUncheckOnTableChange = this.handleCheckAndUncheckOnTableChange.bind(
      this
    );
    this.handleHeaderSelectCheckbox = this.handleHeaderSelectCheckbox.bind(
      this
    );
   this.isApiCall = false;
  }
  componentDidUpdate(prevProps) {
    const divElement = document.getElementById("react-table-rows");
    divElement.innerHTML = `<h5>Total Count: ${
      this.reactTable.current.getResolvedState().sortedData.length
    }</h5>`;
    // here we can implement clear all checkboxes on new data updation
    if(this.isApiCall){
      document.getElementById("reprocessAll").checked= false;
      document.getElementById("cancelAll").checked= false;
      document.getElementById("failAll").checked= false;
      document.getElementById("successfulAll").checked= false;
      document.getElementById("releaseAll").checked= false;

      this.AllCheckboxes.cancelCheckboxList.clear();
      this.AllCheckboxes.releaseCheckboxList.clear();
      this.AllCheckboxes.reprocessCheckboxList.clear();
      this.AllCheckboxes.failCheckboxList.clear();
      this.AllCheckboxes.successfulCheckboxList.clear();
      
      this.setState({
        cancelAll: false,
        failAll: false,
        successfulAll: false,
        reprocessAll: false,
        releaseAll: false,
      })
      this.isApiCall = false;
    }
  }

  resetAllCheckboxList() {
    document.querySelectorAll('input[type="checkbox"]').forEach(e => e.checked = false);

    this.AllCheckboxes.cancelCheckboxList.clear();
    this.AllCheckboxes.releaseCheckboxList.clear();
    this.AllCheckboxes.reprocessCheckboxList.clear();
    this.AllCheckboxes.failCheckboxList.clear();
    this.AllCheckboxes.successfulCheckboxList.clear();

    this.setState({
      cancelAll: false,
      failAll: false,
      successfulAll: false,
      reprocessAll: false,
      releaseAll: false,
    })

  }

  handleHeaderSelectCheckbox(headerId, name, action) {
    const el = document.getElementsByName(name);
    const headerElement = document.getElementById(headerId);
    const { [name]: list } = this.AllCheckboxes;

    const sortedCheckboxArray = this.getSelectedCheckboxArray();
    // console.log(headerElement.checked);
    if (headerElement.checked) {
      sortedCheckboxArray.forEach((element) => {
        if (action !== RELEASE) {
          if (checkboxConditionArray[action].includes(element.txn.aasm_state)) {
            list.add(element.txn.id + "");
          }
        } else if (element.txn.hold && element.txn.aasm_state === "processing") {
          list.add(element.txn.id + "");
        }
      });
      el.forEach((element) => {
        if (list.has(element.value)) {
          element.checked = true;
        }
      });
      this.setState({
        [headerId]: true,
      });
    } else {
      list.clear();
      el.forEach((element) => {
        element.checked = false;
      });
      this.setState({
        [headerId]: false,
      });
    }
    // console.log(list);
  }
  handleSingleTransaction = async (id, action) => {
    this.closeDialog();
    this.isApiCall = true;
    const { setLoading, setAlert } = this.props;
    setLoading(true);
    const urls = {
      [CANCEL]: `/transactions/${id}/mark_cancelled.json`,
      [RELEASE]: `/transactions/${id}/mark_release_transaction.json`,
      [SUCCESS]: `/transactions/${id}/mark_paid.json`,
      [FAIL]: `/transactions/${id}/mark_failed.json`,
    };
    const successMessages = {
      [CANCEL]: `Marked Canecelled Successfully !!!`,
      [RELEASE]: `Marked Released Successfully !!!`,
      [SUCCESS]: `Marked Paid Successfully !!!`,
      [FAIL]: `Marked Failed Successfully !!!`,
    };
    const data = await makeApiCall({ url: urls[action], method: "put" });
    const { error, message } = data;
    if (error) {
      let val = "Something Went wrong !!!";
      try {
        if (message.response.data.error) {
          const {
            response: { data },
          } = message;
          const {
            error: { message: errorMessage },
          } = data;
          val = errorMessage;
        }
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
      setAlert({
        alert: true,
        message: val,
        title: "Error",
        success: false,
        error: true,
        warning: false,
      });
    } else {
      setLoading(false);
      setAlert({
        alert: true,
        message: successMessages[action],
        title: "Success",
      });
      const list = this.AllCheckboxes[checkboxListName[action]];
    }
  };
  handleBulkTransaction = async (action) => {
    this.isApiCall = true;
    this.closeDialog();
    const { setLoading, setAlert } = this.props;
    setLoading(true);
    const urls = {
      [CANCEL]: `/transactions/mark_multiple_cancel.json`,
      [RELEASE]: `/transactions/mark_multiple_release_hold.json`,
      [SUCCESS]: `/transactions/mark_multiple_paid.json`,
      [FAIL]: `/transactions/mark_multiple_failed.json`,
    };
    const successMessages = {
      [CANCEL]: `Marked Canecelled Successfully !!!`,
      [RELEASE]: `Marked Released Successfully !!!`,
      [SUCCESS]: `Marked Paid Successfully !!!`,
      [FAIL]: `Marked Failed Successfully !!!`,
    };
    const list = this.AllCheckboxes[checkboxListName[action]];
    let arrayToBeSent = Array.from(list);
    // if(action === RELEASE){
    // arrayToBeSent = JSON.stringify(arrayToBeSent);
    // }
    const data = await makeApiCall({
      url: urls[action],
      method: "put",
      params: { txn_ids: arrayToBeSent },
    });
    const { error, message } = data;
    if (error) {
      let val = "Something Went wrong !!!";
      try {
        if (message.response.data.error) {
          const {
            response: { data },
          } = message;
          const {
            error: { message: errorMessage },
          } = data;
          val = errorMessage;
        }
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
      setAlert({
        alert: true,
        message: val,
        title: "Error",
        success: false,
        error: true,
        warning: false,
      });
    } else {
      setLoading(false);
      setAlert({
        alert: true,
        message: successMessages[action],
        title: "Success",
      });
    }
  };

  handleManualReprocess = async ({
    isBulk,
    paymentGateway,
    paymentGatewayType,
    id,
  }) => {
    this.closeReprocessDialog();
    this.isApiCall  = true;
    const { setLoading, setAlert } = this.props;
    setLoading(true);
    const url = isBulk
      ? `/transactions/multiple_manual_reprocess.json`
      : `/transactions/${id}/manual_reprocess.json`;
    let transactionArray = [];
    if (isBulk) {
      if (paymentGatewayType === "withSame") {
        transactionArray = this.props.data
          .filter((row) => {
            return this.AllCheckboxes.reprocessCheckboxList.has(
              row.txn.id + ""
            );
          })
          .map((element) => {
            return {
              id: element.txn.id,
              pg: element.txn.payment_gateway,
            };
          });
      } else {
        transactionArray = this.props.data
          .filter((row) => {
            return this.AllCheckboxes.reprocessCheckboxList.has(
              row.txn.id + ""
            );
          })
          .map((element) => {
            return {
              id: element.txn.id,
              pg: paymentGateway,
            };
          });
      }
    }
    const paramsTobeSent = isBulk
      ? { txn_array: JSON.stringify(transactionArray) }
      : {payment_gateway: paymentGateway};
    const data = await makeApiCall({
      url: url,
      method: "put",
      params: paramsTobeSent,
    });
    const { error, message } = data;
    if (error) {
      let val = "Something Went wrong !!!";
      try {
        if (message.response.data.error) {
          const {
            response: { data },
          } = message;
          const {
            error: { message: errorMessage },
          } = data;
          val = errorMessage;
        }
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
      setAlert({
        alert: true,
        message: val,
        title: "Error",
        success: false,
        error: true,
        warning: false,
      });
    } else {
      setLoading(false);
      setAlert({
        alert: true,
        message: "Marked Reprocess Succesfully !!",
        title: "Success",
      });
    }
  };
  openReprocessDialog = (id, isBulk = false) => {
    this.bulkReprocess = isBulk;
    this.reprocessId = id;
    this.setState({
      isReprocessOpen: true,
    });
  };
  handleShowTransaction = (id) => {
    localStorage.setItem("txn_id", id);
    window.open("/admin/showtransaction/" + id, "_blank");
  };
  handleShowTimeLog = (id) => {
    localStorage.setItem("txn_id", id);
    window.open("/admin/timelog/" + id, "_blank");
  };

  handleCheckAndUncheckOnTableChange(listname, action) {
    const divElement = document.getElementById("react-table-rows");
    divElement.innerHTML = `<h5>Total Count: ${
      this.reactTable.current.getResolvedState().sortedData.length
    }</h5>`;
    const headerElement = document.getElementById(headerCheckboxName[action]);
    let { [headerCheckboxName[action]]: isChecked } = this.state;
    if (isChecked) {
      setTimeout(() => {
        headerElement.checked = true;
      }, 0); // main thread escaping
    }
    const el = document.getElementsByName(listname);
    const { [listname]: list } = this.AllCheckboxes;
    el.forEach((element) => {
      if (list.has(element.value)) {
        element.checked = true;
      } else {
        element.checked = false;
      }
    });
  }

  handleSelectCheckbox = (event) => {
    const { name, value } = event.target;
    const { [name]: list } = this.AllCheckboxes;
    if (list.has(value)) {
      list.delete(value);
    } else {
      list.add(value);
    }
    // console.log(list);
  };

  closeDialog = () => {
    this.setState({
      isOpen: false,
    });
  };
  closeReprocessDialog = () => {
    this.reprocessId = null;
    this.setState({
      isReprocessOpen: false,
    });
  };
  openDialog = (buttonClick, message, title, action, isBulk = false) => {
    let messageTobeDisplayed = message;
    const list = this.AllCheckboxes[checkboxListName[action]];
    if (isBulk) {
      if (list.size === 0) {
        messageTobeDisplayed = "No Item Selected !!!";
      } else {
        messageTobeDisplayed = messageTobeDisplayed + Array.from(list);
      }
    }
    const dilogObject = {
      isOpen: true,
      submitClick: buttonClick,
      message: messageTobeDisplayed,
      title,
      showDialogSubmitButton: !isBulk || (isBulk && list.size > 0),
    };
    this.setState({
      ...dilogObject,
    });
  };
  getSelectedCheckboxArray = () => {
    const filteredData = this.reactTable.current.getResolvedState().sortedData;
    const filteredArray = filteredData.map(option => option._original);
    return Array.from(filteredArray);
  }

  render() {
    const { data, adminUser = false, agentUser = false ,superAdminUser = false, supportUser = false} = this.props;
    const {
      // cancelAll,
      // failAll,
      // successAll,
      // reprocessAll,
      isOpen,
      submitClick,
      closeFunction,
      message,
      title,
      isReprocessOpen,
      showDialogSubmitButton,
    } = this.state;
    return (
      <>
        <div align="right" id="react-table-rows"></div>
        <DialogComponent
          isOpen={isOpen}
          closeFunction={closeFunction}
          submitClick={submitClick}
          title={title}
          message={message}
          showSubmitButton={showDialogSubmitButton}
        />
        {isReprocessOpen ? (
          <DialogReprocessComponent
            isOpen={isReprocessOpen}
            allRowData={data}
            closeFunction={this.closeReprocessDialog}
            selectedList={this.AllCheckboxes.reprocessCheckboxList}
            submitClick={this.handleManualReprocess}
            title="Reprocess Transaction"
            transactionId={this.reprocessId}
            isBulk={this.bulkReprocess}
          />
        ) : null}
        <ViewOfTable
          data={data}
          // successAll={successAll}
          handleAllSelectCheckbox={this.handleHeaderSelectCheckbox}
          openDialog={this.openDialog}
          reprocessDialog={this.openReprocessDialog}
          adminUser={adminUser}
          // cancelAll={cancelAll}
          // failAll={failAll}
          // reprocessAll={reprocessAll}
          agentUser={agentUser}
          superAdminUser={superAdminUser}
          supportUser={supportUser}
          handleSelectCheckbox={this.handleSelectCheckbox}
          resetAllCheckboxList={this.resetAllCheckboxList}
          handleTableUpdateChange={this.handleCheckAndUncheckOnTableChange}
          handleShowTransaction={this.handleShowTransaction}
          handleShowTimeLog={this.handleShowTimeLog}
          handleSingleTransaction={this.handleSingleTransaction}
          handleBulkTransaction={this.handleBulkTransaction}
          refToTable={this.reactTable}
        />
      </>
    );
  }
}

export default TableView;

class ViewOfTable extends PureComponent {
  // state = {
  //   reactTable: React.createRef(),
  // };
  componentDidMount() {
    const { refToTable } = this.props;
    const divElement = document.getElementById("react-table-rows");
    divElement.innerHTML = `<h5>Total Count: ${
      refToTable.current.getResolvedState().sortedData.length
    }</h5>`;
  }
  // componentDidUpdate(prevProps){
  //   console.log(prevProps.data === this.props.data);
  // }
  render() {
    const {
      openDialog,
      handleAllSelectCheckbox,
      // successAll,
      adminUser,
      data,
      // cancelAll,
      // failAll,
      // reprocessAll,
      handleTableUpdateChange,
      agentUser,
      supportUser,
      handleSelectCheckbox,
      resetAllCheckboxList,
      handleShowTransaction,
      handleShowTimeLog,
      reprocessDialog,
      handleSingleTransaction,
      handleBulkTransaction,
      refToTable,
      superAdminUser
    } = this.props;
    // const { reactTable } = this.state;
    return (
      <ReactTable
        data={data}
        ref={refToTable}
        columns={[
          {
            Header: "ID",
            id: "id",

            accessor: (row) => row.txn.id,
            //   filterMethod: filterFirstMethod,
            sortable: true,
            filterable: true,
          },

          {
            Header: (
              <BulkTransactionCheckbox
                listName="successfulCheckboxList"
                icon={<CheckCircleIcon />}
                action={SUCCESS}
                buttonColor="success"
                handleOnChange={handleAllSelectCheckbox}
                id={headerCheckboxName[SUCCESS]}
                headerLabel="Mark Paid"
                handleButtonClick={handleBulkTransaction}
                handleDialogOpen={openDialog}
              />
            ),
            sortable: false,
            filterable: false,
            show: adminUser||superAdminUser,

            Cell: (row) => (
              <RowCheckBoxWithButton
                adminUser={adminUser||superAdminUser}
                row={row}
                typeOfAction={SUCCESS}
                handleDialogOpen={openDialog}
                dialogMessage={dialogMessage[SUCCESS]}
                title={dialogTitle[SUCCESS]}
                icon={<CheckCircleIcon />}
                buttonColor="success"
                handleOnChange={handleSelectCheckbox}
                handleButtonClick={handleSingleTransaction}
              />
            ),
          },
          {
            Header: (
              <BulkTransactionCheckbox
                icon={<SettingsBackupRestoreIcon />}
                action={REPROCESS}
                listName="reprocessCheckboxList"
                buttonColor="info"
                handleOnChange={handleAllSelectCheckbox}
                id={headerCheckboxName[REPROCESS]}
                headerLabel="Reprocess"
                handleButtonClick={reprocessDialog}
              />
            ),
            accessor: "reprocess",
            sortable: false,
            filterable: false,
            show: adminUser||superAdminUser,
            Cell: (row) => (
              <RowCheckBoxWithButton
                adminUser={adminUser||superAdminUser}
                row={row}
                typeOfAction={REPROCESS}
                handleDialogOpen={reprocessDialog}
                dialogMessage={dialogMessage[REPROCESS]}
                title={dialogTitle[REPROCESS]}
                handleOnChange={handleSelectCheckbox}
                icon={<SettingsBackupRestoreIcon />}
                buttonColor="info"
              />
            ),
          },
          {
            Header: (
              <BulkTransactionCheckbox
                icon={<Close />}
                action={FAIL}
                listName="failCheckboxList"
                buttonColor="danger"
                handleOnChange={handleAllSelectCheckbox}
                id={headerCheckboxName[FAIL]}
                headerLabel="Mark Fail"
                handleButtonClick={handleBulkTransaction}
                handleDialogOpen={openDialog}
              />
            ),
            // accessor: row=>row.txn.id,
            accessor: "fail",
            sortable: false,
            filterable: false,
            show: adminUser||superAdminUser,
            Cell: (row) => (
              <RowCheckBoxWithButton
                adminUser={adminUser||superAdminUser}
                row={row}
                typeOfAction={FAIL}
                // handleButtonClick={(...rest) => {
                //   console.log(rest);
                // }}
                handleDialogOpen={openDialog}
                dialogMessage={dialogMessage[FAIL]}
                title={dialogTitle[FAIL]}
                handleOnChange={handleSelectCheckbox}
                icon={<Close />}
                buttonColor="danger"
                handleButtonClick={handleSingleTransaction}
              />
            ),
          },
          {
            Header: (
              <BulkTransactionCheckbox
                icon={<WarningIcon />}
                action={CANCEL}
                listName="cancelCheckboxList"
                buttonColor="warning"
                handleOnChange={handleAllSelectCheckbox}
                id={headerCheckboxName[CANCEL]}
                headerLabel="Cancel"
                handleButtonClick={handleBulkTransaction}
                handleDialogOpen={openDialog}
              />
            ),
            // accessor: "cancel_button",
            // accessor: row=>row.txn.id,
            accessor: "cancel",
            sortable: false,
            filterable: false,
            show: adminUser||superAdminUser,
            Cell: (row) => (
              <RowCheckBoxWithButton
                adminUser={adminUser||superAdminUser}
                row={row}
                typeOfAction={CANCEL}
                // handleButtonClick={(...rest) => {
                //   console.log(rest);
                // }}
                handleDialogOpen={openDialog}
                dialogMessage={dialogMessage[CANCEL]}
                title={dialogTitle[CANCEL]}
                handleOnChange={handleSelectCheckbox}
                icon={<WarningIcon />}
                buttonColor="warning"
                handleButtonClick={handleSingleTransaction}
              />
            ),
          },
          {
            Header: (
              <BulkTransactionCheckbox
                listName={checkboxListName[RELEASE]}
                icon={<AccessTimeIcon />}
                action={RELEASE}
                buttonColor="primary"
                handleOnChange={handleAllSelectCheckbox}
                id={headerCheckboxName[RELEASE]}
                headerLabel="Release"
                handleButtonClick={handleBulkTransaction}
                handleDialogOpen={openDialog}
              />
            ),
            sortable: false,
            filterable: false,
            show: adminUser||superAdminUser,

            Cell: (row) => (
              <RowCheckBoxWithButton
                adminUser={adminUser||superAdminUser}
                row={row}
                typeOfAction={RELEASE}
                handleDialogOpen={openDialog}
                dialogMessage={dialogMessage[RELEASE]}
                title={dialogTitle[RELEASE]}
                icon={<AccessTimeIcon />}
                buttonColor="primary"
                handleOnChange={handleSelectCheckbox}
                handleButtonClick={handleSingleTransaction}
              />
            ),
          },
          {
            Header: "Suspect",
            id: "suspect",
            accessor: (row) => (row.txn.suspect ? "True" : "False"),
            sortable: false,
            filterable: true,
            //   filterMethod: filterMethod,
          },
          {
            id: "destination_amount",
            accessor: (row) => row.txn.destination_amount,
            Header: "Destination Amount",
            sortable: false,
            filterable: false,
          },
          {
            id: "aasm_state",
            accessor: (row) => row.txn.aasm_state,
            Header: "Status",
            sortable: false,
            filterable: true,
          },
          {
            accessor: "client_name",
            Header: "Client",
            sortable: false,
            filterable: true,
          },
          {
            id: "sub_client_name",
            accessor: (row) => row.txn.sub_client_name,
            Header: "Sub Client",
            sortable: false,
            filterable: true,
          },
          {
            id: "client_txn_id",
            accessor: (row) => row.txn.client_txn_id,
            Header: "Client Txn Id",
            sortable: false,
            filterable: true,
          },
          {
            Header: "Provider",
            id: "provider_name",
            accessor: (row) => row.txn.bank_name,
            sortable: false,
            filterable: true,
          },
          {
            Header: "Receiver Name",
            id: "name",
            accessor: (row) => row.txn.name,
            sortable: false,
            filterable: true,
          },
          {
            id: "payment_gateway",
            accessor: (row) => row.txn.payment_gateway,
            Header: "Payment Gateway",
            show: agentUser ? false : supportUser ? false : true,
            sortable: false,
            filterable: true,
          },
          // {
          //   id: "gateway_message",
          //   accessor: (row) => row.txn.gateway_message,
          //   Header: "Gateway Message",
          //   show: !agentUser,
          //   sortable: false,
          //   filterable: true,
          // },

          {
            id: "deposit_number",
            accessor: (row) => row.txn.deposit_number,
            Header: "Receiver Deposit Number",
            sortable: false,
            filterable: true,
          },
          {
            Header: "Show",
            // accessor: "cancel_button",
            // accessor: row=>row.txn.id,
            accessor: "show",
            sortable: false,
            filterable: false,
            show: !supportUser,
            Cell: (row) => (
              <ActionButton
                handleClick={handleShowTransaction}
                row={row}
                label="show"
              />
            ),
          },
          // {
          //   accessor: "show_button",
          //   Header: "Show",
          //   sortable: false,
          //   filterable: false,
          // },
          {
            id: "source_currency",
            accessor: (row) =>
              row.txn.source_input_currency
                ? row.txn.source_input_currency
                : row.txn.source_currency,
            Header: "Source Currency",
            sortable: false,
            filterable: true,
          },
          {
            id: "destination_currency",
            accessor: (row) => row.txn.destination_currency,
            Header: "Destination Currency",
            sortable: false,
            filterable: true,
          },
          {
            id: "source_amount",
            accessor: (row) =>
              row.txn.source_input_amount
                ? row.txn.source_input_amount
                : row.txn.source_amount,
            Header: "Source Amount",
            sortable: false,
            filterable: false,
          },
          {
            id: "conversion_rate",
            accessor: (row) => row.txn.conversion_rate,
            Header: "Conversion Rate",
            sortable: false,
            filterable: true,
          },
          {
            id: "ref_no",
            accessor: (row) => row.txn.ref_no,
            Header: "Payment Gateway Transaction ID",
            sortable: false,
            filterable: true,
          },
          {
            accessor: "created_at_date",
            Header: "Created At",
            sortable: false,
            filterable: false,
          },

          {
            accessor: "created_time",
            Header: "Created At Time",
            sortable: false,
            filterable: false,
          },
          {
            accessor: "updated_at_date",
            Header: "Updated At",
            sortable: false,
            filterable: false,
          },
          {
            accessor: "updated_time",
            Header: "Updated At Time",
            sortable: false,
            filterable: false,
          },
          {
            Header: "TimeLog",
            // accessor: "cancel_button",
            // accessor: row=>row.txn.id,
            accessor: "show timelog",
            sortable: false,
            show: !supportUser,
            filterable: false,
            Cell: (row) => (
              <ActionButton
                handleClick={handleShowTimeLog}
                row={row}
                label="timelog"
              />
            ),
          },
          // {
          //   //  / accessor: "show_timelog",
          //   Header: "Timelog",
          //   show: !agentUser,
          //   sortable: false,
          //   filterable: false,
          // },
        ]}
        filterable
        minRows={0}
        defaultPageSize={100}
        showPaginationTop
        showPaginationBottom={false}
        className="-striped -highlight"
        onFilteredChange={() => {
          resetAllCheckboxList();
          //  console.log(reactTable.current.getResolvedState());
          handleTableUpdateChange(checkboxListName[CANCEL], CANCEL);
          handleTableUpdateChange(checkboxListName[SUCCESS], SUCCESS);
          handleTableUpdateChange(checkboxListName[REPROCESS], REPROCESS);
          handleTableUpdateChange(checkboxListName[FAIL], FAIL);
        }}
        onPageChange={() => {
          //  console.log(reactTable.current.getResolvedState());
          handleTableUpdateChange(checkboxListName[CANCEL], CANCEL);
          handleTableUpdateChange(checkboxListName[SUCCESS], SUCCESS);
          handleTableUpdateChange(checkboxListName[REPROCESS], REPROCESS);
          handleTableUpdateChange(checkboxListName[FAIL], FAIL);
        }}
        onPageSizeChange={() => {
          //  console.log(reactTable.current.getResolvedState());
          handleTableUpdateChange(checkboxListName[CANCEL], CANCEL);
          handleTableUpdateChange(checkboxListName[SUCCESS], SUCCESS);
          handleTableUpdateChange(checkboxListName[REPROCESS], REPROCESS);
          handleTableUpdateChange(checkboxListName[FAIL], FAIL);
        }}
        onSortedChange={() => {
          //  console.log(reactTable.current.getResolvedState());
          handleTableUpdateChange(checkboxListName[CANCEL], CANCEL);
          handleTableUpdateChange(checkboxListName[SUCCESS], SUCCESS);
          handleTableUpdateChange(checkboxListName[REPROCESS], REPROCESS);
          handleTableUpdateChange(checkboxListName[FAIL], FAIL);
        }}
      />
    );
  }
}
