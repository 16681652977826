import CustomInput from "components/CustomInput/CustomInput";
import React, { useState } from "react";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import DeleteIcon from "@material-ui/icons/Delete";
import * as yup from "yup";

const initialState = {
  currentId: 1,
  rows: {1:{
    minimumAmount:0,
    maximumAmount:0,
    fee:0,}
  },
  errors: {},
};
const createDynamicSchema = (data = {}) => {
  let dynamicSchema = {};
  for (let i of Object.keys(data)) {
    dynamicSchema[i] = yup.object({
      minimumAmount: yup.number().required("Minimum amount required !!!"),
      maximumAmount: yup
        .number()
        .moreThan(
          yup.ref("minimumAmount"),
          "Maximum amount should be grater than minimum amount !!!"
        )
        .required("Maximum amount required !!!"),
      fee: yup.number().required("Fee required !!!"),
    });
  }
  const schema = yup.object({
    ...dynamicSchema,
  });
  return schema;
};

const TransactionFeeRows = ({
  passedState,
  currentId,
  action,
  handleSubmit,
}) => {
  const [state, setState] = useState(() => {
    if (passedState) {
      return { rows: passedState, currentId, errors: {} };
    } else return initialState;
  });
  // const [state,setState] = useState(initialState);
 
  const handleChange = (event, id) => {
    const { name, value } = event.target;
    // console.log(value);
    const { rows } = state;
    let newRow = {};
    newRow[id] = {...rows[id],[name]: value?Number(value):value}
    const updatedRows = {...rows,...newRow};
    setState((prevState) => ({
      ...prevState,
      rows:updatedRows,
    }));
  };

  const handleDelete = (id) => {
    const { rows } = state;
    delete rows[id]; //will delete targeted data
    const rowKeyArray = Object.keys(rows);
    const lastIndex = rowKeyArray.length - 1;
    const lastKey = lastIndex < 0 ? 0 : rowKeyArray[lastIndex];
    setState((prevState) => ({
      ...prevState,
       rows:{...rows},
      currentId: Number(lastKey),
    }));
  };

  const addRow = () => {
    const schema = createDynamicSchema(state.rows);
    schema
      .validate(state.rows, {
        abortEarly: false,
      })
      .then(() => {
        const { currentId, rows } = state;
        // console.log(rows,currentId);
        const rowKeyArray = Object.keys(rows);
        const lastIndex = rowKeyArray.length - 1;
        const lastKey = lastIndex < 0 ? 0 : rowKeyArray[lastIndex];
        const lastMaximumPayment = rows[lastKey]
          ? rows[lastKey].maximumAmount
          : 0;
        rows[currentId + 1] = {
          maximumAmount: 0,
          minimumAmount:
            lastMaximumPayment === 0 ? 0 : Number(lastMaximumPayment) + 1,
          fee: 0,
        };
        setState((prevState) => ({
          ...prevState,
          currentId: currentId + 1,
          rows:{...rows},
          errors: {},
        }));
      })
      .catch((errors) => {
        let allErrors = {};
        errors.inner.map((error) => {
          let [a, b] = error.path.split(".");
          allErrors[a] = { [b]: error.message };
          return error;
        });
        setState((prevState) => ({
          ...prevState,
          errors: allErrors,
        }));
      });
  };

  const submit = () => {
    const schema = createDynamicSchema(state.rows);
    schema
      .validate(state.rows, {
        abortEarly: false,
      })
      .then(() => {
        setState((prevState) => ({
          ...prevState,
          errors: {},
        }));
        handleSubmit(state.rows);
      })
      .catch((errors) => {
        let allErrors = {};
        errors.inner.map((error) => {
          let [a, b] = error.path.split(".");
          allErrors[a] = { [b]: error.message };
          return error;
        });
        setState((prevState) => ({
          ...prevState,
          errors: allErrors,
        }));
      });
  };

  return (
    <>
      {getFields({
        handleChange,
        handleDelete,
        rows: state.rows,
        errors: state.errors,
        currentId: state.currentId,
      })}
      <Button color="rose" onClick={addRow}>
        + Add Row
      </Button>
      <Button color="rose" onClick={submit}>
        {action === "update" ? "Update" : "Submit"}
      </Button>
    </>
  );
};

const getFields = ({ handleChange, handleDelete, rows, errors, currentId }) => {
  const elements = [];
  for (let el in rows) {
    elements.push(
      <Fields
        handleChange={handleChange}
        handleDelete={handleDelete}
        {...rows[el]}
        errors={errors[el]}
        id={Number(el)}
        key={el}
        currentId={currentId}
      />
    );
  }
  return elements;
};

const Fields = (props) => {
  const {
    minimumAmount,
    maximumAmount,
    fee,
    errors = {},
    handleChange,
    handleDelete,
    id,
    currentId,
  } = props;
  // console.log(id ,currentId,minimumAmount,maximumAmount);
  return (
    <>
      <GridContainer>
        <GridItem xs={6} sm={4}>
          <CustomInput
            labelText="Minimum Amount *"
            error={errors.minimumAmount !== undefined}
            formControlProps={{
              fullWidth: true,
              error: errors.minimumAmount !== undefined,
            }}
            inputProps={{
              onChange: (event) => handleChange(event, id),
              value: minimumAmount,
              type: "number",
              name: "minimumAmount",
              readOnly: true,
            }}
          />
        </GridItem>
        <GridItem xs={6} sm={4} >
          <CustomInput
            labelText="Maximum Amount *"
            error={errors.maximumAmount !== undefined}
            formControlProps={{
              fullWidth: true,
              error: errors.maximumAmount !== undefined,
            }}
            inputProps={{
              onChange: (event) => handleChange(event, id),
              value: maximumAmount,
              type: "number",
              name: "maximumAmount",
              readOnly: currentId !== id,
            }}
          />
        </GridItem>
        <GridItem xs={6} sm={3}>
          <CustomInput
            labelText="Fee *"
            error={errors.fee !== undefined}
            formControlProps={{
              fullWidth: true,
              error: errors.fee !== undefined,
            }}
            inputProps={{
              onChange: (event) => handleChange(event, id),
              value: fee,
              type: "number",
              name: "fee",
            }}
          />
        </GridItem>
        <GridItem xs={6} sm={1} style={{display:"flex"}}>
          <Button
            justIcon
            round
            simple
            onClick={() => handleDelete(id)}
            disabled={currentId !== id || currentId ===1}
            style={{margin:"auto"}}
          >
            <DeleteIcon color="error" />
          </Button>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default TransactionFeeRows;
