import React, { Component } from "react";
import { isObjectEmpty } from "../utils/commonCheckFunctions";
export const ValidationTypes = {
  email: "email",
  required: "required",
};

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export const ValidateComponent = (schema = {}) => (WrappedComponent) => {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        errors: {},
      };
    }

    validateObject = (dataToBeValidated = {}) => {
      let errors = {};
      for (let key of Object.keys(schema)) {
        const value = dataToBeValidated[key];
        const isValidSchema = Array.isArray(schema[key]);
        if (process.env.NODE_ENV !== "production" && !isValidSchema) {
          console.warn("Schema type is not valid !!!");
        }
        const validationsToRun = isValidSchema ? schema[key] : [];
        let error = {};
        for (let el of validationsToRun) {
          error = Validation[el](value, key);
          const empty = isObjectEmpty(error);
          errors = { ...errors, ...error };
          if (!empty) {
            break;
          }
        }
      }
      this.setState({
        errors,
      });
    };
    render() {
      const { errors } = this.state;
      return (
        <WrappedComponent
          {...this.props}
          errors={errors}
          validateForm={this.validateObject}
        />
      );
    }
  };
};

const Validation = {
  required: (value, name) => {
    const isValid = value !== "" && value !== null && value !== undefined;
    const error = isValid ? {} : { [name]: `${name} is Required !!!` };
    return error;
  },
  email: (value, name) => {
    const isValid = emailRegex.test(value);
    const error = isValid ? {} : { [name]: `Enter valid ${name} !!!` };
    return error;
  },
};
