import React, { useCallback, useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import CustomSweet from "../Components/CustomSweet";
import Progress from "../Components/Progress";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import SweetAlert from "react-bootstrap-sweetalert";
import alertstyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import apiUrl from "../api";
import { makeStyles } from "@material-ui/core";
import {
  isObjectEmpty,
  makeApiCall,
  ValidateObjectAgainstSchema,
  ValidationTypes,
} from "../../utils/commonCheckFunctions";

const baseUrl = apiUrl;

const customStyles = {
  amountFormControl: {
    paddingTop: "17px",
    "&>label": {
      top: 0,
    },
  },
};
const useStyle = makeStyles({ ...cssstyles, ...alertstyles, ...customStyles });
const TransactionType = [{ id: 1, value: "Bank" }, { id: 2, value: "Mobile" }];
const SUPERADMINUSER = "superadmin";
const userType = localStorage.getItem("user_type");

const schema = {
  transactionType: [ValidationTypes.required],
  selectedBA: userType === SUPERADMINUSER ? [ValidationTypes.required] : [],
  client: [ValidationTypes.required],
  recipientCountry: [ValidationTypes.required],
  sourceCurrency: [ValidationTypes.required],
  destinationCurrency: [ValidationTypes.required],
  amount: [ValidationTypes.required],
  bicSwift: [ValidationTypes.required],
  provider: [ValidationTypes.required],
  clientTransactionId: [ValidationTypes.required],
  name: [ValidationTypes.required],
  senderFirstName: [ValidationTypes.required],
  senderLastName: [ValidationTypes.required],
  senderAddress: [ValidationTypes.required],
  senderEmail: [ValidationTypes.required, ValidationTypes.email],
  senderMobileNumber: [ValidationTypes.required],
  senderCountry: [ValidationTypes.required],
  recipientAddress: [ValidationTypes.required],
  recipientEmail: [ValidationTypes.required, ValidationTypes.email],
  recipientPhoneNumber: [ValidationTypes.required],
};

// const sourceCurrencyList = [
//   { name: "USD", value: "usd" },
//   { name: "GBP", value: "gbp" },
//   { name: "CAD", value: "cad" },
//   { name: "EUR", value: "eur" },
// ];

const destinationCurrencyList = [
  // { name: "NGN", value: "ngn" },
  // { name: "GHS", value: "ghs" },
  // { name: "KES", value: "kes" },
  // { name: "INR", value: "inr" },
  // { name: "ZAR", value: "zar" },
  // { name: "XOF", value: "xof" },
  // { name: "PHP", value: "php" },
  { name: "USD", value: "usd" },
];

const AGENT = "agent";


const GetViewForApiVersion = ({
  classes,
  destinationCurrency,
  sourceCurrency,
  handleChange,
  amount,
  errors = {},
  sourceCurrencyList = [],
}) => {
  return (
    <>
      <GridContainer justify="center">
        <GridItem xs={12} md={3} sm={10}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel
              className={classes.selectLabel}
              error={errors.sourceCurrency !== undefined}
            >
              {"Source Currency*"}
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={sourceCurrency}
              onChange={handleChange}
              inputProps={{
                name: "sourceCurrency",
              }}
            >
              {sourceCurrencyList.map((row) => (
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={row.attributes.name}
                  key={row.attributes.id}
                >
                  {row.attributes.name.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} md={3} sm={10}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel
              className={classes.selectLabel}
              error={errors.destinationCurrency !== undefined}
            >
              {"Destination Currency*"}
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={destinationCurrency}
              onChange={handleChange}
              inputProps={{
                name: "destinationCurrency",
              }}
            >
              {destinationCurrencyList.map((row) => (
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={row.value}
                  key={row.value}
                >
                  {row.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
          <FormControl fullWidth>
            <CustomInput
              labelText="Source Amount *"
              error={errors.amount !== undefined}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: handleChange,
                value: amount,
                autoComplete: "off",
                type: "number",

                name: "amount",
              }}
            />
          </FormControl>
        </GridItem>
      </GridContainer>
    </>
  );
};

const getBAUserList = (classes, baUsers, handleChange, errors, selectedBA) => {
  if (userType === SUPERADMINUSER) {
    return (
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel
          className={classes.selectLabel}
          error={errors.selectedBA !== undefined}
        >
          {"BA *"}
        </InputLabel>

        <Select
          MenuProps={{
            className: classes.selectMenu,
          }}
          classes={{
            select: classes.select,
          }}
          value={selectedBA}
          onChange={handleChange}
          inputProps={{
            name: "selectedBA",
          }}
        >
          {baUsers.map((row) => (
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={row.id}
              key={row.id}
            >
              {row.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  } else {
    return null;
  }
};

const CreateTransactionView = ({
  transactionType,
  client,
  sourceCurrency,
  destinationCurrency,
  amount,
  provider,
  clientTransactionId,
  depositNumber,
  allClients = [],
  allProviders = [],
  name,
  errors = {},
  handleChange,
  handleSubmit,
  handleCancel,
  bicSwift,
  senderFirstName,
  senderLastName,
  senderAddress,
  senderEmail,
  senderMobileNumber,
  senderCountry,
  selectedBA,
  baUsers = [],
  recipientEmail,
  recipientAddress,
  recipientCountry,
  recipientPhoneNumber,
  allCountries = [],
  sourceCurrencyList = [],
}) => {
  const classes = useStyle();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" text>
            <CardText color="rose">
              <h4>Create Transaction</h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <form>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      className={classes.selectLabel}
                      error={errors.transactionType !== undefined}
                    >
                      {"Transaction Type*"}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={transactionType}
                      onChange={handleChange}
                      inputProps={{
                        name: "transactionType",
                      }}
                    >
                      {TransactionType.map((row) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={row.value}
                          key={row.id}
                        >
                          {row.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10}>
                  {getBAUserList(
                    classes,
                    baUsers,
                    handleChange,
                    errors,
                    selectedBA
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    disabled={userType === AGENT}
                  >
                    <InputLabel
                      className={classes.selectLabel}
                      error={errors.client !== undefined}
                    >
                      {"Clients *"}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={client}
                      onChange={handleChange}
                      inputProps={{
                        name: "client",
                      }}
                    >
                      {allClients.map((row) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={row.id}
                          key={row.id}
                        >
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    style={{ marginTop: "10px" }}
                  >
                    <InputLabel
                      className={classes.selectLabel}
                      error={errors.recipientCountry !== undefined}
                    >
                      {"Recipient Country *"}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={recipientCountry}
                      onChange={handleChange}
                      inputProps={{
                        name: "recipientCountry",
                      }}
                    >
                      {allCountries.map((row) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={row.attributes.code}
                          key={row.attributes.id}
                        >
                          {row.attributes.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GetViewForApiVersion
                classes={classes}
                handleChange={handleChange}
                sourceCurrency={sourceCurrency}
                destinationCurrency={destinationCurrency}
                amount={amount}
                errors={errors}
                sourceCurrencyList={sourceCurrencyList}
              />
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <FormControl fullWidth>
                    <CustomInput
                      labelText="BIC Swift *"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: handleChange,
                        value: bicSwift,
                        autoComplete: "off",
                        name: "bicSwift",
                        error: errors.bicSwift !== undefined,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center" style={{ marginTop: "10px" }}>
                <GridItem xs={12} md={6} sm={10}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      className={classes.selectLabel}
                      error={errors.provider !== undefined}
                    >
                      {"Bank/Provider *"}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={provider}
                      onChange={handleChange}
                      onOpen={() => {
                        if (transactionType === "") {
                          alert("Please Select Transaction Type  !!! ");
                        } else if (recipientCountry === "") {
                          alert("Please Select Country !!! ");
                        }
                      }}
                      inputProps={{
                        name: "provider",
                      }}
                    >
                      {allProviders.map((row) => {
                        const { attributes } = row;
                        let name = "",
                          code = "",
                          id = "";
                        if (attributes !== undefined) {
                          name = attributes.name;
                          code = attributes.code;
                          id = attributes.id;
                        } else {
                          name = row.name;
                          code = row.code;
                          id = row.id;
                        }
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={code}
                            key={id}
                          >
                            {name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <FormControl fullWidth>
                    <CustomInput
                      labelText="Client Transaction Id *"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: handleChange,
                        value: clientTransactionId,
                        autoComplete: "off",
                        name: "clientTransactionId",
                        error: errors.clientTransactionId !== undefined,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <FormControl fullWidth>
                    <CustomInput
                      labelText="Deposit Number "
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: handleChange,
                        value: depositNumber,
                        autoComplete: "off",
                        name: "depositNumber",
                        error: errors.depositNumber !== undefined,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>

              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <FormControl fullWidth>
                    <CustomInput
                      labelText="Sender First Name *"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: handleChange,
                        value: senderFirstName,
                        autoComplete: "off",
                        name: "senderFirstName",
                        error: errors.senderFirstName !== undefined,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <FormControl fullWidth>
                    <CustomInput
                      labelText="Sender Last Name *"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: handleChange,
                        value: senderLastName,
                        autoComplete: "off",
                        name: "senderLastName",
                        error: errors.senderLastName !== undefined,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <FormControl fullWidth>
                    <CustomInput
                      labelText="Sender Address *"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: handleChange,
                        value: senderAddress,
                        autoComplete: "off",
                        name: "senderAddress",
                        error: errors.senderAddress !== undefined,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <FormControl fullWidth>
                    <CustomInput
                      labelText="Sender Email *"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: handleChange,
                        value: senderEmail,
                        autoComplete: "off",
                        name: "senderEmail",
                        error: errors.senderEmail !== undefined,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <FormControl fullWidth>
                    <CustomInput
                      labelText="Sender Mobile Number *"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: handleChange,
                        value: senderMobileNumber,
                        autoComplete: "off",
                        name: "senderMobileNumber",
                        error: errors.senderMobileNumber !== undefined,
                        type: "number",
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <FormControl fullWidth>
                    <CustomInput
                      labelText="Sender Country *"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: handleChange,
                        value: senderCountry,
                        autoComplete: "off",
                        name: "senderCountry",
                        error: errors.senderCountry !== undefined,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>

              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <FormControl fullWidth>
                    <CustomInput
                      labelText="Name *"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: handleChange,
                        value: name,
                        autoComplete: "off",
                        name: "name",
                        error: errors.name !== undefined,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <FormControl fullWidth>
                    <CustomInput
                      labelText="Recipient Address *"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: handleChange,
                        value: recipientAddress,
                        autoComplete: "off",
                        name: "recipientAddress",
                        error: errors.recipientAddress !== undefined,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <FormControl fullWidth>
                    <CustomInput
                      labelText="Recipient Email *"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: handleChange,
                        value: recipientEmail,
                        autoComplete: "off",
                        name: "recipientEmail",
                        error: errors.recipientEmail !== undefined,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} md={6} sm={10} style={{ height: "50px" }}>
                  <FormControl fullWidth>
                    <CustomInput
                      labelText="Recipient Phone Number *"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: handleChange,
                        value: recipientPhoneNumber,
                        autoComplete: "off",
                        name: "recipientPhoneNumber",
                        error: errors.recipientPhoneNumber !== undefined,
                        type: "number",
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center" style={{ marginTop: "20px" }}>
                <GridItem xs={12} sm={12} md={3} style={{ height: "50px" }}>
                  <FormControl fullWidth>
                    <Button onClick={handleCancel}>Cancel</Button>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} md={3} sm={12}>
                  <FormControl fullWidth>
                    <Button color="rose" onClick={handleSubmit}>
                      Proceed To Pay
                    </Button>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

const initialState = {
  transactionType: "",
  client: "",
  sourceCurrency: "",
  destinationCurrency: "",
  amount: "",
  bicSwift: "",
  provider: "",
  clientTransactionId: "",
  depositNumber: "",
  name: "",
  selectedBA: "",
  senderFirstName: "",
  senderLastName: "",
  senderAddress: "",
  senderEmail: "",
  senderMobileNumber: "",
  senderCountry: "",
  recipientAddress: "",
  recipientCountry: "",
  recipientEmail: "",
  recipientPhoneNumber: "",
  errors: {},
  allClients: [],
  allProviders: [],
  allCountries: [],
  sourceCurrencyList: [],
  baUsers: [],
  loading: false,
  sweetAlert: null,
};

const initialAlertState = {
  alert: false,
  success: false,
  error: false,
  warning: false,
  message: "",
  title: "",
};

const getUpdatedClientlist = (list = []) => {
  const wallet = localStorage.getItem("walletStatus");
  if (userType === AGENT) {
    const { id } = JSON.parse(wallet);
    return { allClients: list, client: id };
  } else {
    return { allClients: list };
  }
};

const getParamList = ({
  transactionType,
  client,
  sourceCurrency,
  destinationCurrency,
  amount,
  bicSwift,
  provider,
  clientTransactionId,
  depositNumber,
  name,
  senderFirstName,
  senderLastName,
  senderAddress,
  senderEmail,
  senderMobileNumber,
  senderCountry,
  recipientAddress,
  recipientCountry,
  recipientEmail,
  recipientPhoneNumber,
}) => {
  return {
    account_code: provider,
    client_id: client,
    account_type: transactionType.trim(),
    bic_swift: bicSwift.trim(),
    client_txn_id: clientTransactionId.trim(),
    deposit_number: depositNumber.trim(),
    destination_currency: destinationCurrency.trim(),
    name: name.trim(),
    sender_first_name: senderFirstName.trim(),
    sender_last_name: senderLastName.trim(),
    sender_address: senderAddress.trim(),
    sender_email: senderEmail.trim(),
    sender_mobile_phone_number: senderMobileNumber,
    sender_country: senderCountry.trim(),
    recipient_address: recipientAddress.trim(),
    recipient_country: recipientCountry.trim(),
    recipient_email: recipientEmail.trim(),
    recipient_phone_number: recipientPhoneNumber,
    source_amount: amount,
    source_currency: sourceCurrency.trim(),
  };
};

const createSignature = async (params = {}) => {
  try {
    const result = await axios({
      method: "post",
      url: baseUrl + "/transactions/create_disburse_signature.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: params,
    });
    return result.data;
  } catch (err) {
    return {
      error: true,
      message: err,
    };
  }
};

const createTransaction = async (params = {}) => {
  try {
    const result = await axios({
      method: "post",
      url: baseUrl + "/api/v1/disburse",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: params,
    });
    return result.data;
  } catch (err) {
    return {
      error: true,
      message: err,
    };
  }
};

const GetSweetAlert = ({ onConfirmation, onHide }) => {
  const classes = useStyle();
  return (
    <SweetAlert
      style={{ display: "block", marginTop: "-100px" }}
      title="Do you want to create Transaction ?"
      showCancel
      confirmBtnText="Yes"
      cancelBtnText="No"
      confirmBtnCssClass={classes.button + " " + classes.success}
      cancelBtnCssClass={classes.button + " " + classes.info}
      onConfirm={onConfirmation}
      onCancel={onHide}
    />
  );
};

const CreateTransactionUsd = () => {
  const [viewState, setViewState] = useState(initialState);
  const [alertState, setAlertState] = useState(initialAlertState);

  useEffect(() => {
    if (userType !== SUPERADMINUSER) {
      getAllClients();
    } else {
      getAllBaUsers();
    }
    getAllCountries();
    getAllCurrencies();
  }, []);

  useEffect(() => {
    if (viewState.selectedBA) {
      getAllClients();
    }
  }, [viewState.selectedBA]);

  const getAllProviders = useCallback(
    (destinationCurrency, transactionType) => {
      // console.log(base_url, provider_type);

      const subUrl =
        transactionType === "Bank"
          ? "/show_banks.json"
          : "/show_providers.json";

      axios({
        method: "get",
        url: baseUrl + subUrl,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        },
        params: {
          currency: destinationCurrency,
        },
      })
        .then((result) => {
          const { banks, providers } = result.data;
          if (banks) {
            setViewState((prevState) => ({
              ...prevState,
              allProviders: banks,
            }));
          } else {
            setViewState((prevState) => ({
              ...prevState,
              allProviders: providers,
            }));
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },
    []
  );

  useEffect(() => {
    if (viewState.recipientCountry !== "" && viewState.transactionType !== "") {
      const [value] = viewState.allCountries.filter((element) => {
        const { code } = element.attributes;
        return code === viewState.recipientCountry;
      });
      const {
        attributes: { currency },
      } = value;
      getAllProviders(currency.toLowerCase(), viewState.transactionType);
      setViewState((prevState) => ({
        ...prevState,
        provider: "",
        allProviders: [],
      }));
    }
  }, [
    viewState.recipientCountry,
    viewState.transactionType,
    viewState.allCountries,
    getAllProviders,
  ]);

  const classes = useStyle();

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "selectedBA") {
      setViewState((prevState) => ({
        ...prevState,
        [name]: value,
        client: "",
      }));
    } else {
      setViewState((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const hideAlert = () => {
    setAlertState(initialAlertState);
  };

  const handleCleanform = () => {
    setViewState((prevState) => ({
      ...initialState,
      allCountries: prevState.allCountries,
      allClients: userType === SUPERADMINUSER ? [] : prevState.allClients,
      sourceCurrencyList: prevState.sourceCurrencyList,
    }));
  };

  const handleSubmit = () => {
    const { isValid, errors } = validateForm();
    setViewState({ ...viewState, errors });

    if (isValid) {
      // const params = getParamList(viewState);
      const view = (
        <GetSweetAlert onConfirmation={onConfirmation} onHide={onHide} />
      );
      setViewState((prevState) => ({ ...prevState, sweetAlert: view }));
      // console.log(params);
    }
    console.log(errors);
  };

  const getAllCurrencies = async () => {
    try {
      const result = await axios({
        method: "get",
        url: baseUrl + "/currencies.json",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        },
      });
      const { currencies = [] } = result.data;
      const sourceCurrencyList = currencies.filter((val) => {
        const { type } = val.attributes;
        return type === "source";
      });
      setViewState((prevState) => ({ ...prevState, sourceCurrencyList }));
    } catch (error) {
      console.log(error);
    }
  };

  const validateForm = () => {
    const errors = ValidateObjectAgainstSchema.validateObject(
      viewState,
      schema
    );
    return { isValid: isObjectEmpty(errors), errors };
  };
  const getAllClients = () => {
    const isSuperAdmin = userType === SUPERADMINUSER;
    const paramsToBeSent = isSuperAdmin ? { ba_id: viewState.selectedBA } : {};
    axios({
      method: "get",
      url: baseUrl + "/clients.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: { ...paramsToBeSent },
    })
      .then((result) => {
        const { clients } = result.data;
        const clientList = Array.isArray(clients) ? clients : [clients];
        // this.getClientData(result.data.clients);
        const clientData = getUpdatedClientlist(clientList);
        setViewState((prevState) => ({ ...prevState, ...clientData }));
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const getAllBaUsers = async () => {
    const data = await makeApiCall({
      url: "/users.json",
      method: "get",
      params: { role: "ba" },
    });
    const { error = false } = data;
    if (!error) {
      setViewState((prevState) => ({
        ...prevState,
        baUsers: data,
      }));
    } else {
      const { message } = data;
      console.log(message);
    }
  };

  const getAllCountries = () => {
    axios({
      method: "get",
      url: baseUrl + "/country_codes.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {},
    })
      .then((result) => {
        const { countries } = result.data;
        setViewState((prevState) => ({
          ...prevState,
          allCountries: countries,
        }));
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const onConfirmation = async () => {
    onHide();
    setViewState({ ...viewState, loading: true });
    let params = getParamList(viewState);
    const data = await createSignature(params);
    const { error, message } = data;
    if (error) {
      setViewState({ ...viewState, loading: false });
      console.log(message);
    } else {
      const { api_token, tonce, signature } = data;
      params = {
        ...params,
        api_token: api_token,
        tonce: tonce,
        signature: signature,
      };
      delete params.client_id; //as client_id is not required for transaction
      doTransaction(params);
    }
  };

  const doTransaction = async (params) => {
    const data = await createTransaction(params);
    const { error, message } = data;
    if (error) {
      // const {error:{message:val}} =message
      let val = "Something Went wrong !!!";
      if (message.response.data.error) {
        const {
          response: { data },
        } = message;
        const {
          error: { message: errorMessage },
        } = data;
        val = errorMessage;
      }
      setViewState({ ...viewState, loading: false });
      setAlertState({
        alert: true,
        success: false,
        error: true,
        warning: false,
        title: "Error",
        message: val,
      });
    } else {
      setViewState((prevState) => ({
        ...initialState,
        allCountries: prevState.allCountries,
        allClients: prevState.allClients,
        sourceCurrencyList: prevState.sourceCurrencyList,
      }));
      setAlertState({
        alert: true,
        success: true,
        error: false,
        warning: false,
        title: "Success",
        message: "Transaction created successfully !!!",
      });
    }
  };

  const onHide = () => {
    setViewState((prevState) => ({ ...prevState, sweetAlert: null }));
  };

  return (
    <>
      <CustomSweet
        alert={alertState.alert}
        message={alertState.message}
        title={alertState.title}
        hide_alert={hideAlert}
        danger={alertState.error}
        success={alertState.success}
      />
      {viewState.sweetAlert}
      <div className={viewState.loading ? classes.main_screen : ""}>
        <div align="center">{viewState.loading && <Progress />}</div>
        <CreateTransactionView
          {...viewState}
          handleCancel={handleCleanform}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      </div>
    </>
  );
};

export default CreateTransactionUsd;
