import React from "react";
import axios from "axios";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Dashboard from "views/Dashboard/Dashboard";

import apiUrl from "../api";

const base_url = apiUrl;

// var variable = "12 Jan 2019";
// var myVariable = "1 Jun 2020";

const today = new Date();
const yesterday = new Date();

yesterday.setDate(today.getDate() - 1);

const created_at = `${yesterday} to ${today}`;
class DashboardSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total_success_count: 0,
      total_valid_count: 0,
      total_process_count: 0,
      total_fail_count: 0,
      total_success_amount: 0.0,
      total_valid_amount: 0.0,
      total_process_amount: 0,
      total_fail_amount: 0.0,
      total_cancelled_count: 0,
      total_cancelled_amount: 0.0,

      status: "",
      clients: "",
      //   client_id: "",
      payment_gateway_all: [],
      payment_gateway_id: "",
      startDate: yesterday.toJSON(),
      endDate: today.toJSON(),
      filters: {
        source_currency: "ngn",
        destination_currency: "ngn",
        client_id: "All",
        payment_gateway: "All",
        aasm_state: "all",
        created_at: created_at,
      },
    };
  }
  componentDidMount() {
    // if (
    //   localStorage.getItem("session_token") === null ||
    //   localStorage.getItem("session_token") === ""
    // ) {
    //   // console.log(this.props);
    //   this.props.history.push("/auth/login-page");
    //   // this.context.history.push("/");
    //   // window.location.href = "/";
    // }
    try {
      this.fetchClients();
      this.fetchPaymentGateway();
    } catch (err) {
      console.log("error in async mounted", err);
    }
  }
  componentWillUnmount() {
    clearInterval(this.intervals);
  }
  getSourceCurrencyFilter = (data) => {
    // console.log(data, "source currency");
    this.setState((prevState) => ({
      filters: { ...prevState.filters, source_currency: data },
    }));
  };
  getDestCurrencyFilter = (data) => {
    // console.log(data, "dest currency");

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, destination_currency: data },
      }),
      () => {
        this.fetchPaymentGateway();
        this.fetchData();
      }
    );
  };
  getStatusFilter = (data) => {
    // console.log(data, "status");
    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, aasm_state: data },
      }),
      () => {
        this.fetchData();
      }
    );
  };
  getClientFilter = (data) => {
    // console.log(data, "client");
    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, client_id: data },
      }),
      () => {
        this.fetchData();
      }
    );
  };
  getGatewayFilter = (data) => {
    // console.log(data, "payment_gateway");
    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, payment_gateway: data },
      }),
      () => {
        this.fetchData();
      }
    );
  };
  getStartDateFilter = (data) => {
    // console.log(data, "start_date");
    let start_date = moment(data).toJSON();
    let end_date = this.state.endDate;
    this.setState(
      (prevState) => ({
        startDate: start_date,
        filters: {
          ...prevState.filters,
          created_at: `${start_date} to ${end_date}`,
        },
      }),
      () => {
        this.fetchData();
      }
    );
  };
  getEndDateFilter = (data) => {
    let end_date = moment(data).toJSON();
    let start_date = this.state.startDate;
    // console.log(data, "end_date", start_date);
    this.setState(
      (prevState) => ({
        endDate: end_date,
        filters: {
          ...prevState.filters,
          created_at: `${start_date} to ${end_date}`,
        },
      }),
      () => {
        this.fetchData();
      }
    );
  };
  fetchData = () => {
    let fq = "";
    let { filters } = this.state;
    // console.log(filters);

    // delete filters.startDate;
    // delete filters.endDate;

    for (const key in filters) {
      // console.log(key, filters[key]);
      //   if (key === "startDate" || key === "endDate") return;
      if (key === "created_at") {
        filters[key] = filters[key].toUpperCase();
        if (filters[key].includes("Z")) {
          fq += "+" + key + ":" + "[" + filters[key] + "]" + " ";
        } else {
          // console.log(filters[key]);
          fq +=
            "+" +
            key +
            ":" +
            "[" +
            filters[key]
              .split("TO")
              .map((date) => new Date(date).toJSON())
              .join(" TO ") +
            "]" +
            " ";
        }
      }

      if (key === "payment_gateway" && filters[key] === "All")
        filters[key] = null;

      if (key === "client_id" && filters[key] === "All") filters[key] = null;

      if (key === "aasm_state" && filters[key] === "all") filters[key] = null;

      if (filters[key] && key !== "created_at")
        fq += "+" + key + ":" + filters[key] + " ";
    }
    // console.log(filters, fq);
    // console.log(fq);
    // fq = "";
    axios({
      url: base_url + "/analytical_search",
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        omitHeader: true,
        q: "*:*",
        rows: 0,
        fq,
        json: {
          facet: {
            failed: {
              type: "query",
              q: "aasm_state:failed",
              facet: {
                amount: "sum(destination_amount)",
              },
            },
            success: {
              type: "query",
              q: "aasm_state:successful",
              facet: {
                amount: "sum(destination_amount)",
              },
            },
            validated: {
              type: "query",
              q: "aasm_state:validated",
              facet: {
                amount: "sum(destination_amount)",
              },
            },
            processing: {
              type: "query",
              q: "aasm_state:processing",
              facet: {
                amount: "sum(destination_amount)",
              },
            },
            cancelled: {
              type: "query",
              q: "aasm_state:cancelled",
              facet: {
                amount: "sum(destination_amount)",
              },
            },
          },
        },
      },
    })
      .then((result) => {
        if (result.data) {
          // console.log("Data", result.data);
          if (result.data.query_response.facets.hasOwnProperty("failed")) {
            if (
              result.data.query_response.facets["failed"].hasOwnProperty(
                "amount"
              )
            ) {
              this.setState({
                total_fail_amount: parseFloat(
                  result.data.query_response.facets["failed"]["amount"]
                )
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              });
              // console.log(
              //   "failed",
              //   result.data.query_response.facets["failed"]["amount"]
              // );
            } else {
              this.setState({
                total_fail_amount: 0.0,
              });
            }
          }

          if (result.data.query_response.facets.hasOwnProperty("failed")) {
            if (
              result.data.query_response.facets["failed"].hasOwnProperty(
                "count"
              )
            ) {
              this.setState({
                total_fail_count:
                  result.data.query_response.facets["failed"]["count"],
              });
              // console.log(
              //   "failed",
              //   result.data.query_response.facets["failed"]["count"]
              // );
            } else {
              this.setState({
                total_fail_count: 0,
              });
            }
          }

          if (result.data.query_response.facets.hasOwnProperty("success")) {
            if (
              result.data.query_response.facets["success"].hasOwnProperty(
                "amount"
              )
            ) {
              this.setState({
                total_success_amount: parseFloat(
                  result.data.query_response.facets["success"]["amount"]
                )
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              });
              // console.log(
              //   "successful",
              //   result.data.query_response.facets["success"]["amount"]
              // );
            } else {
              this.setState({
                total_success_amount: 0,
              });
            }
          }

          if (result.data.query_response.facets.hasOwnProperty("success")) {
            if (
              result.data.query_response.facets["success"].hasOwnProperty(
                "count"
              )
            ) {
              this.setState({
                total_success_count:
                  result.data.query_response.facets["success"]["count"],
              });
              // console.log(
              //   "successful",
              //   result.data.query_response.facets["success"]["count"]
              // );
            } else {
              this.setState({
                total_success_count: 0,
              });
            }
          }

          if (result.data.query_response.facets.hasOwnProperty("processing")) {
            if (
              result.data.query_response.facets["processing"].hasOwnProperty(
                "amount"
              )
            ) {
              this.setState({
                total_process_amount: parseFloat(
                  result.data.query_response.facets["processing"]["amount"]
                )
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              });
              // console.log(
              //   "processing",
              //   result.data.query_response.facets["processing"]["amount"]
              // );
            } else {
              this.setState({
                total_process_amount: 0.0,
              });
            }
          }

          if (result.data.query_response.facets.hasOwnProperty("processing")) {
            if (
              result.data.query_response.facets["processing"].hasOwnProperty(
                "count"
              )
            ) {
              this.setState({
                total_process_count:
                  result.data.query_response.facets["processing"]["count"],
              });
              // console.log(
              //   "processing",
              //   result.data.query_response.facets["processing"]["count"]
              // );
            } else {
              this.setState({
                total_process_count: 0,
              });
            }
          }

          if (result.data.query_response.facets.hasOwnProperty("validated")) {
            if (
              result.data.query_response.facets["validated"].hasOwnProperty(
                "amount"
              )
            ) {
              this.setState({
                total_valid_amount: parseFloat(
                  result.data.query_response.facets["validated"]["amount"]
                )
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              });
              // console.log(
              //   "validated",
              //   result.data.query_response.facets["validated"]["amount"]
              // );
            } else {
              this.setState({
                total_valid_amount: 0.0,
              });
            }
          }

          if (result.data.query_response.facets.hasOwnProperty("validated")) {
            if (
              result.data.query_response.facets["validated"].hasOwnProperty(
                "count"
              )
            ) {
              this.setState({
                total_valid_count:
                  result.data.query_response.facets["validated"]["count"],
              });
              // console.log(
              //   "validated",
              //   result.data.query_response.facets["validated"]["count"]
              // );
            } else {
              this.setState({
                total_valid_count: 0,
              });
            }
          }

          if (result.data.query_response.facets.hasOwnProperty("cancelled")) {
            if (
              result.data.query_response.facets["cancelled"].hasOwnProperty(
                "amount"
              )
            ) {
              this.setState({
                total_cancelled_amount: parseFloat(
                  result.data.query_response.facets["cancelled"]["amount"]
                )
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              });
              // console.log(
              //   "cancelled",
              //   result.data.query_response.facets["cancelled"]["amount"]
              // );
            } else {
              this.setState({
                total_cancelled_amount: 0.0,
              });
            }
          }

          if (result.data.query_response.facets.hasOwnProperty("cancelled")) {
            if (
              result.data.query_response.facets["cancelled"].hasOwnProperty(
                "count"
              )
            ) {
              this.setState({
                total_cancelled_count:
                  result.data.query_response.facets["cancelled"]["count"],
              });
              // console.log(
              //   "cancelled",
              //   result.data.query_response.facets["cancelled"]["count"]
              // );
            } else {
              this.setState({
                total_cancelled_count: 0.0,
              });
            }
          }
        }
      })
      .catch((error) => {});

    // console.log(this.state);
  };
  fetchClients = () => {
    axios({
      method: "get",
      url: base_url + "/clients.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {},
    })
      .then((result) => {
        // console.log(result);
        if (result.data) {
          this.setState({
            clients: result.data.clients,
          });
        }
      })
      .catch((error) => {});
  };
  fetchPaymentGateway = () => {
    // console.log(this.state);
    axios({
      method: "get",
      url: base_url + "/show_payment_gateways.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        currency: this.state.filters.destination_currency,
      },
    })
      .then((result) => {
        //access the results here....
        // console.log(result.data.payment_gateways);
        this.setState({ payment_gateway_all: result.data.payment_gateways });
      })
      .catch((error) => {});
  };
  render() {
    return (
      <div>
        <Dashboard
          total_number_success={this.state.total_success_count}
          total_number_fail={this.state.total_fail_count}
          total_number_process={this.state.total_process_count}
          total_number_valid={this.state.total_valid_count}
          total_number_cancelled={this.state.total_cancelled_count}
          total_success_amount={this.state.total_success_amount}
          total_valid_amount={this.state.total_valid_amount}
          total_process_amount={this.state.total_process_amount}
          total_fail_amount={this.state.total_fail_amount}
          total_cancelled_amount={this.state.total_cancelled_amount}
          getSourceCurrencyFilter={this.getSourceCurrencyFilter}
          getDestCurrencyFilter={this.getDestCurrencyFilter}
          getStatusFilter={this.getStatusFilter}
          clients={this.state.clients}
          getClientFilter={this.getClientFilter}
          payment_gateway_all={this.state.payment_gateway_all}
          getGatewayFilter={this.getGatewayFilter}
          getEndDateFilter={this.getEndDateFilter}
          getStartDateFilter={this.getStartDateFilter}
          //history={this.props.history}
          {...this.props}
        />
      </div>
    );
  }
}
export default withStyles(cssstyles)(DashboardSummary);
