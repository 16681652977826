import React, {useState, useEffect} from "react";
import {render} from "react-dom";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import Table from "components/Table/Table.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.js";

import apiUrl from "../api";
const base_url = apiUrl;

const sourceCurrency = [
  { name: "USD", value: "usd" }
]
const destinationCurrency = [
  { name: "USDT", value: "usdt" },
  { name: "USDC", value: "usdc" }
]

const ConvertUsd = (props) => {
  const [userWallet, setUserWallet] = useState({
    usdBalance: "0.0",
    usdLocked: "0.0",
    usdAmount: "0.0",
    usdtBalance: "0.0",
    usdtLocked: "0.0",
    usdtAmount: "0.0",
    usdcBalance: "0.0",
    usdcLocked: "0.0",
    usdcAmount: "0.0",
    usd_to_usdt: "0.0",
    usd_to_usdc: "0.0"
  });

  const [convertUsdForm, setConvertUsdForm] = useState({
    user_id: "",
    client_id: "",
    source_currency: '',
    destination_currency: '',
    source_amount: '',
    destination_amount: '',
    converted_amount: ''
  });

  useEffect(() => { 
    getBalances();
    getExchangeRate();
  }, []);

  const getBalances = () => {
    if (
      localStorage.getItem("session_token") === "" ||
      localStorage.getItem("session_token") === null
    ) {
      return;
    }
    axios({
      method: "get",
      url: base_url + "/get_wallet_balance.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: { currency: "usd" },
    })
    .then((result) => {
      setUserWallet(previousState => {
        return { ...previousState, 
        usdBalance: result.data.wallet.balance, 
        usdLocked: result.data.wallet.locked, 
        usdAmount: result.data.wallet.amount }
      });
    })
    .catch((error) => {
      console.log("Error", error.response);
    });

    axios({
      method: "get",
      url: base_url + "/get_wallet_balance.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: { currency: "usdt" },
    })
    .then((result) => {
      setUserWallet(previousState => {
        return { ...previousState, 
        usdtBalance: result.data.wallet.balance, 
        usdtLocked: result.data.wallet.locked, 
        usdtAmount: result.data.wallet.amount }
      });
    })
    .catch((error) => {
      console.log("Error", error.response);
    });

    axios({
      method: "get",
      url: base_url + "/get_wallet_balance.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: { currency: "usdc" },
    })
    .then((result) => {
      setUserWallet(previousState => {
        return { ...previousState, 
        usdcBalance: result.data.wallet.balance, 
        usdcLocked: result.data.wallet.locked, 
        usdcAmount: result.data.wallet.amount }
      });
    })
    .catch((error) => {
      console.log("Error", error.response);
    });
  }

  const getExchangeRate = () => {
    const wallet = localStorage.getItem("walletStatus");
    const {id} = JSON.parse(wallet);
    if (
      localStorage.getItem("session_token") === "" ||
      localStorage.getItem("session_token") === null
    ) {
      return;
    }

    axios({
      method: "get",
      url: base_url + "/get_conversion_rate.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: { source_currency: 'usd', destination_currency: 'usdt', client_id: id },
    })
    .then((result) => {
      setUserWallet(previousState => {
        return { ...previousState, usd_to_usdt: result.data.currency_conversion_rate }
      });
    })
    .catch((error) => {
      console.log("Error", error.response);
    });

    axios({
      method: "get",
      url: base_url + "/get_conversion_rate.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: { source_currency: 'usd', destination_currency: 'usdc', client_id: id },
    })
    .then((result) => {
      setUserWallet(previousState => {
        return { ...previousState, usd_to_usdc: result.data.currency_conversion_rate }
      });
    })
    .catch((error) => {
      console.log("Error", error.response);
    });
  };

  const handleChange = (event) => {
    const {name, value} = event.target;
    setConvertUsdForm({...convertUsdForm, [name]: value});
  };

  const handleSourceAmountChange = (event) => {
    const {name, value} = event.target;
    const {destination_currency} = convertUsdForm;
    
    let updated_amt = 0.0;
    let target_currency = {'usdt': userWallet.usd_to_usdt, 'usdc': userWallet.usd_to_usdc}
    let updated_source_amt = value;
    let updated_destination_amt = value;

    if(name === 'source_amount'){
      updated_destination_amt = (value * target_currency[destination_currency])
    }
    else
    {
      updated_source_amt = (value / target_currency[destination_currency])
    }

    setConvertUsdForm(previousState => {
      return { ...previousState, source_amount: updated_source_amt, destination_amount: updated_destination_amt }
    });
  };

  const handleShowInfo = (event) => {
    event.preventDefault();
    if(validateForm()){
      handleSubmit();
    }
    else{
      alert('please fill blank fields');
    }
  };

  const validateForm = () => {
    let validForm = true;
    if(convertUsdForm.source_currency === '')
    {
      validForm = false;
    }
    else if(convertUsdForm.destination_currency === '')
    {
      validForm = false;
    }
    else if(convertUsdForm.source_amount === '')
    {
      validForm = false;
    }
    else if(convertUsdForm.destination_amount === '')
    {
      validForm = false;
    }
    return validForm;
  };

  const handleSubmit = () => {
    const wallet = localStorage.getItem("walletStatus");
    const {id} = JSON.parse(wallet);
    if (
      localStorage.getItem("session_token") === "" ||
      localStorage.getItem("session_token") === null
    ) {
      return;
    }
    axios({
      method: "post",
      url: base_url + "/convert_usd_wallet_amount.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: { 
                source_currency: convertUsdForm.source_currency, 
                destination_currency: convertUsdForm.destination_currency, 
                client_id: id,
                source_amount: convertUsdForm.source_amount,
                destination_amount: convertUsdForm.destination_amount
              }
    })
    .then((result) => {
      let successMessage = "Converted SuccessFully, usd to " + convertUsdForm.destination_currency + ", total destination amount: " + parseFloat(convertUsdForm.destination_amount).toFixed(2)
      if(convertUsdForm.destination_currency === 'usdt'){
        setUserWallet(previousState => {
          return {...previousState, 
            usdBalance: result.data.wallet.balance, 
            usdLocked: result.data.wallet.locked, 
            usdAmount: result.data.wallet.amount,
            usdtBalance: result.data.dest_wallet.balance, 
            usdtLocked: result.data.dest_wallet.locked, 
            usdtAmount: result.data.dest_wallet.amount,
          }
        });
      }
      else{
        setUserWallet(previousState => {
          return {...previousState, 
            usdBalance: result.data.wallet.balance, 
            usdLocked: result.data.wallet.locked, 
            usdAmount: result.data.wallet.amount,
            usdcBalance: result.data.dest_wallet.balance, 
            usdcLocked: result.data.dest_wallet.locked, 
            usdcAmount: result.data.dest_wallet.amount
          }
        });
      }
      alert(successMessage);
    })
    .catch((error) => {
      alert("Error", error.response);
    });
    handleCleanform();
  };

  const handleCleanform = () => {
    setConvertUsdForm(previousState => {
      return {...previousState, 
        source_currency: '',
        destination_currency: '',
        source_amount: '',
        destination_amount: '',
        converted_amount: ''
      }
    });
  };

  const classes = props;
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} md={12} lg={12}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4>Wallet Balance</h4>
              </CardText>
            </CardHeader>
            <br />
            <br />
            <GridContainer justify="space-between">
              <GridItem xs={12} sm={12} md={12}>
                <Table
                  tableData={[
                    ["Wallet", "Balance", "Locked", "Amount"],
                    [
                      "USD Wallet ( $ )",
                      parseFloat(userWallet.usdBalance).toFixed(2),
                      parseFloat(userWallet.usdLocked).toFixed(2),
                      parseFloat(userWallet.usdAmount).toFixed(2),
                    ],
                    [
                      "USDT Wallet ( $ )",
                      parseFloat(userWallet.usdtBalance).toFixed(2),
                      parseFloat(userWallet.usdtLocked).toFixed(2),
                      parseFloat(userWallet.usdtAmount).toFixed(2),
                    ],
                    [
                      "USDC Wallet ( $ )",
                      parseFloat(userWallet.usdcBalance).toFixed(2),
                      parseFloat(userWallet.usdcLocked).toFixed(2),
                      parseFloat(userWallet.usdcAmount).toFixed(2),
                    ]
                  ]}
                />
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
        <GridItem xs={12} md={12} lg={12}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4>Exchange Rate</h4>
              </CardText>
            </CardHeader>
            <br />
            <br />
            <GridContainer justify="space-between">
              <GridItem xs={12} sm={12} md={12}>
                <Table
                  tableData={[
                    ["Currency", "Exchange Rate"],
                    [
                      "USD / USDT",
                      userWallet.usd_to_usdt
                    ],
                    [
                      "USD / USDC",
                      userWallet.usd_to_usdc
                    ]
                  ]}
                />
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} md={12} lg={12}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4>Convert</h4>
              </CardText>
            </CardHeader>
            <br />
            <br />
            <GridContainer justify="space-between" style={{marginLeft: '10px'}}>
              <GridItem xs={6} sm={6} md={6}>
              <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                className={classes.selectLabel}
              >
                {"Select Source Currency *"}
              </InputLabel>
              <Select
                inputProps={{
                  className: classes.selectMenu,
                  name: 'source_currency'
                }}
                classes={{
                  select: classes.select,
                }}
                value={convertUsdForm.source_currency}
                onChange={handleChange}
              >
              {sourceCurrency.map((row, index) => (
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={row.value}
                  key={index}
                >
                  {row.name}
                </MenuItem>
              ))}
              </Select>
            </FormControl>
            </GridItem>
            </GridContainer>

            <GridContainer justify="space-between" style={{marginLeft: '10px'}}>
              <GridItem xs={6} sm={6} md={6}>
              <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                className={classes.selectLabel}
              >
                {"Select Destination Currency *"}
              </InputLabel>
              <Select
                inputProps={{
                  className: classes.selectMenu,
                  name: 'destination_currency'
                }}
                classes={{
                  select: classes.select,
                }}
                onChange={handleChange}
                value={convertUsdForm.destination_currency}
              >
              {destinationCurrency.map((row, index) => (
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={row.value}
                  key={index}
                >
                  {row.name}
                </MenuItem>
              ))}
              </Select>
            </FormControl>
            </GridItem>
            </GridContainer>
            
            <GridContainer style={{marginLeft: '10px'}}>
              <GridItem xs={12} md={3} sm={5} style={{ height: "50px"}}>
                <FormControl fullWidth>
                  <InputLabel>
                    Source Amount *
                  </InputLabel>
                  <Input
                    value={convertUsdForm.source_amount}
                    onChange={handleSourceAmountChange}
                    autoComplete="off"
                    inputProps={{
                      name: 'source_amount',
                      type: "number"
                    }}
                  />
                </FormControl>
              </GridItem>
              
              <GridItem xs={12} md={3} sm={5} style={{ height: "50px" }}>
                <FormControl fullWidth>
                  <InputLabel>Destination Amount *</InputLabel>
                  <Input
                    value={convertUsdForm.destination_amount}
                    onChange={handleSourceAmountChange}
                    autoComplete="off"
                    inputProps={{
                      name: "destination_amount",
                      type: "number"
                    }}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>

            <GridContainer justify="space-between" style={{marginLeft: '10px', marginBottom: '10px'}}>
              <GridItem xs={12} sm={12} md={3} style={{ height: "50px"}}>
                <FormControl fullWidth>
                  <Button color="rose" onClick={handleShowInfo} name='submit'>
                    Convert
                  </Button>
                </FormControl>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
export default withStyles(styles)(ConvertUsd);