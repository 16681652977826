import React from "react";
import { withStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CardHeader from "components/Card/CardHeader.js";
import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import CardText from "components/Card/CardText.js";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Progress from "../Components/Progress";
import CustomSweet from "../Components/CustomSweet";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
const userType = localStorage.getItem("user_type");
const SUPERADMINUSER = "superadmin";


const getBAUserList = (classes,baUsers,selectedBA,handleChange,errors) => {
  if (userType === SUPERADMINUSER) {
    return (
      <GridContainer justify="center">
      <GridItem xs={12} md={6} sm={10} style={{ marginTop: "10px" }}>
            <FormControl
              fullWidth
              className={classes.selectFormControl}
              error={errors["selectedBA"] !== undefined}
            >
              <InputLabel className={classes.selectLabel}>
                {"BA *"}
              </InputLabel>

              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={selectedBA}
                onChange={handleChange}
                inputProps={{
                  name: "selectedBA",
                }}
              >
                {baUsers.map((row) => (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={row.id}
                    key={row.id}
                  >
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
      </GridItem>
      </GridContainer>
    );
  } else {
    return null;
  }
}


const getPaymentGatewayList = (classes, paymentGatewayList) => {
  return paymentGatewayList.map((data) => {
    return (
      <MenuItem
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected,
        }}
        value={data.name}
        key={data.id}
      >
        {data.name}
      </MenuItem>
    );
  });
};

const CreateDebitAccountView = (props) => {
  const {
    classes,
    alert,
    message,
    errors,
    title,
    error,
    success,
    handleChange,
    hideAlert,
    loading,
    handleCreate,
    handleCancel,
    paymentGatewayList,
  } = props;
  const {
    bankName,
    bankCode,
    paymentGateway,
    authorization,
    accountNumber,
    selectedBA,
    baUsers,
  } = props.values;
  return (
    <>
      <CustomSweet
        alert={alert}
        message={message}
        title={title}
        hide_alert={hideAlert}
        danger={error}
        success={success}
      />
      <div className={loading ? classes.main_screen : ""}>
        <div align="center">{loading && <Progress />}</div>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4>Create Debit Account</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem sm={12} md={6} style={{ height: "50px" }}>
                    <FormControl fullWidth>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: bankName,
                          onChange: handleChange,
                          name: "bankName",
                          autoComplete: "off",
                        }}
                        labelText="Bank Name"
                        error={errors["bankName"] !== undefined}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                  <GridItem sm={12} md={6} style={{ height: "50px" }}>
                    <FormControl fullWidth>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: bankCode,
                          onChange: handleChange,
                          name: "bankCode",
                          autoComplete: "off",
                        }}
                        labelText="Bank Code"
                        error={errors["bankCode"] !== undefined}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                  <GridItem sm={12} md={6} style={{ height: "50px" }}>
                    <FormControl fullWidth>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: accountNumber,
                          onChange: handleChange,
                          name: "accountNumber",
                          autoComplete: "off",
                        }}
                        labelText="Account Number"
                        error={errors["accountNumber"] !== undefined}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>
                {getBAUserList(classes,baUsers,selectedBA,handleChange,errors)}
                <GridContainer justify="center">
                  <GridItem
                    sm={12}
                    md={6}
                    style={{ height: "50px", marginTop: "10px" }}
                  >
                    <FormControl
                      fullWidth
                      error={errors["paymentGateway"] !== undefined}
                    >
                      <InputLabel
                        htmlFor="paymentGateway"
                        className={classes.selectLabel}
                      >
                        {"Payment Gateway *"}
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        value={paymentGateway}
                        onChange={handleChange}
                        inputProps={{
                          name: "paymentGateway",
                        }}
                      >
                        {getPaymentGatewayList(classes, paymentGatewayList)}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                  <GridItem sm={12} md={6} style={{ height: "50px" }}>
                    <FormControl fullWidth>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: authorization,
                          onChange: handleChange,
                          name: "authorization",
                          autoComplete: "off",
                        }}
                        labelText="Authorization"
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridContainer justify="center" style={{ marginTop: "40px" }}>
                  <GridItem xs={12} md={3} sm={6}>
                    <FormControl fullWidth>
                      <Button onClick={handleCancel}>Cancel</Button>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} md={3} sm={6}>
                    <FormControl fullWidth>
                      <Button color="rose" onClick={handleCreate}>
                        Create Debit Account
                      </Button>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </>
  );
};

export default withStyles(cssstyles)(CreateDebitAccountView);
