import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import CustomSweet from "../Components/CustomSweet";
import Progress from "../Components/Progress";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import alertstyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { makeStyles } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";

import {
  isObjectEmpty,
  ValidateObjectAgainstSchema,
  ValidationTypes,
  makeApiCall,
} from "../../utils/commonCheckFunctions";
import { countryCodes } from "utils/commonConstant";

const CRYPTOAGENT = "cryptoagent";

const userType = localStorage.getItem("user_type");
const sourceCurrencyList = [
  { name: "USDT", value: "usdt" },
  { name: "USDC", value: "usdc" }
]
const Chains = [
  {name: "CELO", value: "celo"},
  {name: "TRON", value: "tron"},
  {name: "ETH", value: "eth"}
];
const customStyles = {
  expiryFormControl: {
    maxWidth: "110px",
  },
  expiryFormControlLabel: {
    fontSize: "14px",
  },
  marginCustomTop: {
    marginTop: "20px",
  },
};
const useStyle = makeStyles({ ...cssstyles, ...alertstyles, ...customStyles });

let timerId;
let timerIdFee;
const debounce = (func, delay) => {
  if (timerId) {
    clearTimeout(timerId);
  }

  timerId = setTimeout(func, delay);
};
const debounceFee = (func, delay) => {
  if (timerIdFee) {
    clearTimeout(timerIdFee);
  }

  timerIdFee = setTimeout(func, delay);
};

const schema = {
  name: [ValidationTypes.required],
  amount: [ValidationTypes.required],
  depositNumber: [ValidationTypes.required],
  clientTransactionId: [ValidationTypes.required],
  sourceCurrency: [ValidationTypes.required]
};

const AgentCreateUsdtTransactionView = ({
  name,
  amount,
  depositNumber,
  clientTransactionId,
  sourceCurrency,
  handleChange,
  errors = {},
  handleSubmit,
  handleCancel,
}) => {
  const classes = useStyle();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" text>
            <CardText color="rose">
              <h4>Create USDT/USDC Transaction</h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <form>           
              <GridContainer
                justify="center"
                className={classes.marginCustomTop}
              >
                <GridItem xs={6} md={6} sm={6}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    error={errors.sourceCurrency !== undefined}
                  >
                    <InputLabel className={classes.selectLabel}>
                      {"Currency*"}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={sourceCurrency}
                      onChange={handleChange}
                      inputProps={{
                        name: "sourceCurrency",
                      }}
                    >
                      {sourceCurrencyList.map((row) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={row.value}
                          key={row.value}
                        >
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>

              <GridContainer
                justify="center"
                className={classes.marginCustomTop}
              >
                <GridItem xs={6} md={6} sm={6}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    error={errors.name !== undefined}
                  >
                    <InputLabel className={classes.selectLabel}>
                      {"Chain Name*"}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={name}
                      onChange={handleChange}
                      inputProps={{
                        name: "name",
                      }}
                    >
                      {Chains.map((row) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={row.value}
                          key={row.value}
                        >
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>

              <GridContainer justify="center">
                <GridItem xs={6} md={6} sm={6} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Client Transaction ID *"
                    error={errors.clientTransactionId !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.clientTransactionId !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: clientTransactionId,
                      name: "clientTransactionId",
                    }}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer justify="center">
                <GridItem xs={6} md={6} sm={6} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Amount"
                    error={errors.amount !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.amount !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: amount,
                      name: "amount",
                      type: "number"
                    }}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer justify="center">
                <GridItem xs={6} md={6} sm={6} style={{ height: "50px" }}>
                  <CustomInput
                    labelText="Wallet Number"
                    error={errors.depositNumber !== undefined}
                    formControlProps={{
                      fullWidth: true,
                      error: errors.depositNumber !== undefined,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: depositNumber,
                      name: "depositNumber",
                    }}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer
                justify="center"
                className={classes.marginCustomTop}
              >
                <GridItem xs={12} sm={12} md={3} style={{ height: "50px" }}>
                  <FormControl fullWidth>
                    <Button onClick={handleCancel}>Cancel</Button>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} md={3} sm={12}>
                  <FormControl fullWidth>
                    <Button color="rose" onClick={handleSubmit}>
                      Proceed To Pay
                    </Button>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

const initialState = {
  clientId: "",
  clientTransactionId: "",
  sourceCurrency: "",
  amount: "",
  depositNumber: "",
  name: "",
  errors: {},
  loading: false,
  sweetAlert: null,
};

const initialAlertState = {
  alert: false,
  success: false,
  error: false,
  warning: false,
  message: "",
  title: "",
};

const getParamList = ({
  account_type,
  api_version,
  amount,
  clientTransactionId,
  depositNumber,
  destinationCurrency,
  name,
  sourceCurrency
}) => {
  return {
    account_type: 'Bank',
    api_version: "v2",
    client_txn_id: clientTransactionId,
    deposit_number: depositNumber,
    destination_currency: sourceCurrency,
    name: name,
    source_amount: amount,
    source_currency: sourceCurrency
  };
};

const DialogComponent = ({
  isOpen = false,
  createSignature,
  closeFunction,
}) => {
  return (
    <Dialog open={isOpen} onClose={closeFunction}>
      <DialogTitle>Do you want to create Transaction ?</DialogTitle>
      <DialogActions>
        <Button onClick={createSignature} color="success">
          Yes
        </Button>
        <Button onClick={closeFunction} color="info">
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AgentCreateUsdtTransaction = () => {
  const [viewState, setViewState] = useState(initialState);
  const [alertState, setAlertState] = useState(initialAlertState);
  const classes = useStyle();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setViewState({ ...viewState, [name]: value });
  };

  const onHide = () => {
    setViewState((prevState) => ({ ...prevState, dialogOpen: false }));
  };

  const hideAlert = () => {
    setAlertState(initialAlertState);
  };

  const handleCleanform = () => {
    setViewState((prevState) => ({
      ...initialState,
      clientId: prevState.clientId,
    }));
  };


  const handleSubmit = () => {
    const { isValid, errors } = validateForm();
    setViewState({ ...viewState, errors });

    if (isValid) {
      setViewState((prevState) => ({ ...prevState, dialogOpen: true }));
    } else {
      console.log(errors);
    }
  };

  const createSignature = async (paymentMethod) => {
    setViewState({ ...viewState, loading: true, dialogOpen: false });

    const wallet = localStorage.getItem("walletStatus");
    const { id } = JSON.parse(wallet);

    let params = getParamList(viewState);
    const {
      name,
      depositNumber,
      clientTransactionId
    } = viewState;

    const { account_type, api_version, destination_currency, source_amount, source_currency } = params;
    params['account_type'] = account_type
    params['api_version'] = api_version
    params['client_id'] = id;
    params['client_txn_id'] = clientTransactionId;
    params["deposit_number"] = depositNumber;
    params["destination_currency"] = destination_currency
    params["name"] = name;
    params["source_amount"] = source_amount;
    params["source_currency"] = source_currency;
    params["transfer_reason_code"] = 1;

    const data = await makeApiCall({
      url: "/transactions/create_signature.json",
      method: "post",
      params,
    });
    const { error = false, message } = data;
    if (error) {
      console.log(message);
      setViewState({ ...viewState, loading: false });
      setAlertState({
        alert: true,
        success: false,
        error: true,
        warning: false,
        message: "Something went wrong!!!",
        title: "Error",
      });
    } else {
      const { api_token, tonce, signature } = data;
      params = {
        ...params,
        api_token: api_token,
        tonce: tonce,
        signature: signature,
      };
      delete params.api_version; //as api version is not required for transaction
      delete params.client_id; //as client_id is not required for transaction
      createTransaction(params);
    }
  };

  const createTransaction = async (params = {}) => {
    const data = await makeApiCall({
      url: "/api/v1/transactions",
      method: "post",
      params,
    });
    const { error, message } = data;
    if (error) {
      console.log(message);
      let val = "Something Went wrong !!!";
      if (message.response.data.error) {
        const {
          response: { data },
        } = message;
        const {
          error: { message: errorMessage },
        } = data;
        val = errorMessage;
      }
      setViewState({ ...viewState, loading: false });
      setAlertState({
        alert: true,
        success: false,
        error: true,
        warning: false,
        message: val,
        title: "Error",
      });
    } else {
      // console.log(data);
      const paymentMethod = params["pay_by"];
      let successMessage = "Transaction Created Successfully!!!";
      if (paymentMethod === "bank") {
        const { verify_code } = data;
        successMessage = (
          <>
            <b style={{ fontSize: "16px" }}>{verify_code}</b>
            <br />
            This is your transfer reference number, please mention this in the
            notes / description when creating the bank transfer.
          </>
        );
      }
      setAlertState({
        alert: true,
        success: true,
        error: false,
        warning: false,
        message: successMessage,
        title: "Success",
      });
      handleCleanform();
    }
  };

  const validateForm = () => {
    schema["depositNumber"] = [ValidationTypes.required];
    schema["clientTransactionId"] = [ValidationTypes.required];
    schema["name"] = [ValidationTypes.required];
    schema["amount"] = [ValidationTypes.required];
    schema["sourceCurrency"] = [ValidationTypes.required];

    const errors = ValidateObjectAgainstSchema.validateObject(
      viewState,
      schema
    );
    return { isValid: isObjectEmpty(errors), errors };
  };

  return (
    <>
      <CustomSweet
        alert={alertState.alert}
        message={alertState.message}
        title={alertState.title}
        hide_alert={hideAlert}
        danger={alertState.error}
        success={alertState.success}
      />
      <DialogComponent
        isOpen={viewState.dialogOpen}
        createSignature={createSignature}
        closeFunction={onHide}
      />
      {/* {viewState.sweetAlert} */}
      <div className={viewState.loading ? classes.main_screen : ""}>
        <div align="center">{viewState.loading && <Progress />}</div>
        <AgentCreateUsdtTransactionView
          {...viewState}
          handleCancel={handleCleanform}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      </div>
    </>
  );
};

export default AgentCreateUsdtTransaction;