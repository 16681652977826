import React, { useEffect, useState } from "react";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import View from "./AddTransactionFee";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import alertstyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import CustomSweet from "../Components/CustomSweet";
import Progress from "../Components/Progress";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { makeApiCall } from "utils/commonCheckFunctions";
import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";
import EditIcon from "@material-ui/icons/Edit";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";

const useStyles = makeStyles({ ...cssstyles, ...alertstyles });
const userType = localStorage.getItem("user_type");
const SUPERADMINUSER = "superadmin";
const getArrayOfObjects = (rows = {}) => {
  let array = [];
  for (let key of Object.keys(rows)) {
    let obj = {};
    obj["minimum_value"] = rows[key].minimumAmount;
    obj["maximum_value"] = rows[key].maximumAmount;
    obj["fee"] = rows[key].fee;
    array.push(obj);
  }
  return array;
};
const initialState = {
  client: "",
  loading: false,
  allClients: [],
  currencies: [],
  actualSourceCurrenciesList: [],
  sourceCurrenciesList: [], //it will be changed according to add or update
  addedSourceCurrencyList: [],
  selectedBA:"",
  baUsers:[],
  alert: false,
  success: false,
  error: false,
  title: "",
  message: "",
  addDialogOpen: false,
  updateDialogOpen: false,
  trasactionData: [],
  action: "add",
  passedState: undefined,
  currentId: undefined,
  editingFieldId: undefined,
  sourceCurrency: "",
};

const  getBAUserList=(classes,selectedBA,baUsers,handleChange)=>{
  if (userType === SUPERADMINUSER) {
    return (
      <GridItem xs={6} md={3} sm={3}>
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel className={classes.selectLabel}>{"BA *"}</InputLabel>

        <Select
          MenuProps={{
            className: classes.selectMenu,
          }}
          classes={{
            select: classes.select,
          }}
          value={selectedBA}
          onChange={handleChange}
          inputProps={{
            name: "selectedBA",
          }}
        >
          {baUsers.map((row) => (
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={row.id}
              key={row.id}
            >
              {row.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </GridItem>
    );
  } else {
    return null;
  }
}

const TransactionFee = () => {
  const [state, setState] = useState(initialState);
  const classes = useStyles();
  useEffect(() => {
    if(userType === SUPERADMINUSER){
      const getAllBaUsers = async () => {
        const data = await makeApiCall({
          url: "/users.json",
          method: "get",
          params: { role: "ba" },
        });
        const { error = false } = data;
        if (!error) {
          setState((prevState)=>({
            ...prevState,
            baUsers:data,
          }))
        } else {
          const { message } = data;
          console.log(message);
        }
      };
      getAllBaUsers();
    }else{
    async function getClients() {
      const data = await makeApiCall({
        url: "/clients.json",
        method: "get",
      });
      const { error, message } = data;
      if (error) {
        if (message.response.data.error) {
          const {
            response: { data },
          } = message;
          const {
            error: { message: errorMessage },
          } = data;
          console.log(errorMessage);
        } else {
          console.log(message);
        }
      } else {
        // console.log(data);
        const { clients } = data;
        setState((prevState) => ({
          ...prevState,
          allClients: clients,
        }));
      }
    }
    getClients();
  }
    async function fetchCurrencies() {
      const data = await makeApiCall({
        url: "/currencies.json",
        method: "get",
      });
      const { error, message } = data;
      if (error) {
        if (message.response.data.error) {
          const {
            response: { data },
          } = message;
          const {
            error: { message: errorMessage },
          } = data;
          console.log(errorMessage);
        } else {
          console.log(message);
        }
      } else {
        // console.log(data);
        const { currencies } = data;
        const actualSourceCurrenciesList = currencies.filter((data) => {
          return data.attributes.type === "source";
        });
        setState((prevState) => ({
          ...prevState,
          actualSourceCurrenciesList,
          currencies: currencies,
        }));
      }
    }
    fetchCurrencies();
  }, []);

  useEffect(()=>{
    if(state.selectedBA!==""){
    async function getClients() {
      const data = await makeApiCall({
        url: "/clients.json",
        method: "get",
        params:{
          ba_id:state.selectedBA,
        }
      });
      const { error, message } = data;
      if (error) {
        if (message.response.data.error) {
          const {
            response: { data },
          } = message;
          const {
            error: { message: errorMessage },
          } = data;
          console.log(errorMessage);
        } else {
          console.log(message);
        }
      } else {
        // console.log(data);
        const { clients } = data;
        setState((prevState) => ({
          ...prevState,
          allClients: clients,
        }));
      }
    }
    getClients();
  }
  },[state.selectedBA])

  useEffect(() => {
    if (state.client !== "") {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));
      async function fecthTransactionFeeData() {
        const data = await makeApiCall({
          url: "/show_client_source_currency_maps.json",
          method: "get",
          params: {
            client_id: state.client,
          },
        });
        const { error, message } = data;
        if (error) {
          if (message.response.data.error) {
            const {
              response: { data },
            } = message;
            const {
              error: { message: errorMessage },
            } = data;
            console.log(errorMessage);
          } else {
            console.log(message);
          }
          setState((prevState) => ({
            ...prevState,
            loading: false,
          }));
        } else {
          const { actualSourceCurrenciesList } = state;
          const { client_source_currency_maps: sourceMap } = data;
          let updatedCurrencyList = [];
          for (let el of actualSourceCurrenciesList) {
            let matchFound = false;
            for (let val of sourceMap) {
              if (val.source_currency === el.attributes.name) {
                matchFound = true;
                break;
              }
            }
            if (!matchFound) {
              updatedCurrencyList.push(el);
            }
          }
          setState((prevState) => ({
            ...prevState,
            trasactionData: data.client_source_currency_maps,
            loading: false,
            addedSourceCurrencyList: updatedCurrencyList,
          }));
        }
      }
      fecthTransactionFeeData();
    }
  }, [state.client]);

  const handleDialogOpenClose = (event, actionType = "add", id) => {
    const { addDialogOpen } = state;
    if (addDialogOpen) {
      setState((prevState) => ({
        ...prevState,
        addDialogOpen: false,
      }));
    } else {
      if (actionType === "add") {
        setState((prevState) => ({
          ...prevState,
          addDialogOpen: true,
          action: actionType,
          passedState: undefined,
          currentId: undefined,
          editingFieldId: undefined,
          sourceCurrency: "",
          sourceCurrenciesList: state.addedSourceCurrencyList,
        }));
      } else {
        fetchDetailsOfCurrency(id);
      }
    }
  };

  const fetchDetailsOfCurrency = async (id) => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    const data = await makeApiCall({
      url: `/client_source_currency_maps/${id}.json`,
      method: "get",
    });
    const { error, message } = data;
    if (error) {
      if (message.response.data.error) {
        const {
          response: { data },
        } = message;
        const {
          error: { message: errorMessage },
        } = data;
        console.log(errorMessage);
      } else {
        console.log(message);
      }
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    } else {
      const {
        client_source_currency_map: sourceMap,
        source_fees: feeData = [],
      } = data;
      const { source_currency: currency } = sourceMap;
      let rows = {};
      feeData.forEach((data, i) => {
        rows[i + 1] = {
          maximumAmount: Number(data.maximum_value),
          minimumAmount: Number(data.minimum_value),
          fee: Number(data.fee),
        };
      });
      setState((prevState) => ({
        ...prevState,
        loading: false,
        passedState: rows,
        addDialogOpen: true,
        sourceCurrency: currency,
        currentId: feeData.length,
        editingFieldId: id,
        action: "update",
        sourceCurrenciesList: state.actualSourceCurrenciesList,
      }));
    }
  };
  const handleSubmit = async ({ rows, sourceCurrency, id }) => {
    const { client, action } = state;
    setState((prevState) => ({
      ...prevState,
      loading: true,
      addDialogOpen: false,
    }));
    const feeArray = getArrayOfObjects(rows);
    // console.log(feeArray);
    let apiUrl =
      action === "update"
        ? `/client_source_currency_maps/${id}.json`
        : `/client_source_currency_maps.json`;
    let method = action === "update" ? "put" : "post";
    const params = userType === SUPERADMINUSER?{ba_id:state.selectedBA}:{};
    let paramsToPass =
      action === "update"
        ? {
            "client_source_currency_map[source_fee]": JSON.stringify(feeArray),
            ...params
          }
        : {
            "client_source_currency_map[source_currency]": sourceCurrency,
            "client_source_currency_map[client_id]": client,
            "client_source_currency_map[source_fee]": JSON.stringify(feeArray),
            ...params,
          };
    const data = await makeApiCall({
      url: apiUrl,
      method: method,
      params: paramsToPass,
    });
    const { error, message } = data;
    if (error) {
      console.log(message);
      // }
      setState((prevState) => ({
        ...prevState,
        loading: false,
        alert: true,
        success: false,
        error: true,
        message: `Something went Wrong !!!`,
        title: `${action === "update" ? "Update" : "ADD"} Transaction Fee`,
      }));
    } else {
      // console.log(data);
      if (action === "add") {
        const {addedSourceCurrencyList } =state;
        const { client_source_currency_map } = data;
        const updatedCurrencyList = addedSourceCurrencyList.filter((el)=>{
          return el.attributes.name !== client_source_currency_map.source_currency;
        });
        setState((prevState) => ({
          ...prevState,
          loading: false,
          trasactionData: [
            ...prevState.trasactionData,
            client_source_currency_map,
          ],
          addedSourceCurrencyList:updatedCurrencyList,
          alert: true,
          success: true,
          error: false,
          message: "Added Transaction Fee SuccessFully !!!",
          title: "Add Transaction Fee",
        }));
      } else {
        const { client_source_currency_map } = data;
        const {trasactionData } =state;
        let foundElement = trasactionData.filter((row)=>{
          return  row.id === client_source_currency_map.id
        });
        foundElement = client_source_currency_map;
        const updatedData = [...trasactionData];
        setState((prevState) => ({
          ...prevState,
          loading: false,
          trasactionData:updatedData,
          alert: true,
          success: true,
          error: false,
          sourceCurrency:"",
          message: "Updated Transaction Fee SuccessFully !!!",
          title: "Update Transaction Fee",
        }));
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if(name === "selectedBA"){
      setState((prevState) => ({ ...prevState, [name]: value,client:"",trasactionData:[]}));
    }
    else{
      setState((prevState) => ({ ...prevState, [name]: value }));
    }
    
  };

  const hideAlert = () => {
    setState((prevState) => ({
      ...prevState,
      alert: false,
      message: "",
      title: "",
      error: false,
      success: false,
    }));
  };
  return (
    <>
      <CustomSweet
        alert={state.alert}
        message={state.message}
        title={state.title}
        hide_alert={hideAlert}
        danger={state.error}
        success={state.success}
      />
      {/* {viewState.sweetAlert} */}
      <View
        closeFunction={handleDialogOpenClose}
        action={state.action}
        dialogOpen={state.addDialogOpen}
        sourceCurrenciesList={state.sourceCurrenciesList}
        handleSubmitClick={handleSubmit}
        currentId={state.currentId}
        editingFieldId={state.editingFieldId}
        passedState={state.passedState}
        sourceCurrency={state.sourceCurrency}
      />
      <div className={state.loading ? classes.main_screen : ""}>
        <div align="center">{state.loading && <Progress />}</div>
        <GridContainer className={classes.marginCustomTop}>
          {getBAUserList(classes,state.selectedBA,state.baUsers,handleChange)}
          <GridItem xs={6} md={3} sm={3}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel className={classes.selectLabel}>
                {"Clients*"}
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={state.client}
                onChange={handleChange}
                inputProps={{
                  name: "client",
                }}
              >
                {state.allClients.map((row) => (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={row.id}
                    key={row.id}
                  >
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={6} md={3} sm={3}>
            <Button
              color="rose"
              onClick={handleDialogOpenClose}
              disabled={state.client === ""}
            >
              Add Fee
            </Button>
          </GridItem>
        </GridContainer>
        <GridContainer style={{marginTop:"70px"}}>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4>Transaction Fee List</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <FeeTable
                  data={state.trasactionData}
                  handleUpdate={handleDialogOpenClose}
                ></FeeTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </>
  );
};
export default TransactionFee;

const tableStyles = makeStyles((theme) => {
  return { ...styles(theme) };
});
const columns = ["Id", "Source Currency", "Created Date","Created Time","Updated Date","Updated Time","Action"];
const FeeTable = ({ data = [], handleUpdate }) => {
  const classes = tableStyles();
  return (
    <Table>
      <TableHead>
        <TableRow className={classes.tableRow + " " + classes.tableRowHead}>
          {columns.map((row) => {
            return (
              <TableCell
                key={row}
              >
                {row}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.length > 0 ? (
          data.map((row) => {
            return (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.source_currency.toUpperCase()}</TableCell>
                <TableCell>{getDate(row.created_at)}</TableCell>
                <TableCell>{getTime(row.created_at)}</TableCell>
                <TableCell>{getDate(row.updated_at)}</TableCell>
                <TableCell>{getTime(row.updated_at)}</TableCell>

                <TableCell>
                  <Button
                    justIcon
                    round
                    simple
                    onClick={(e) => handleUpdate(e, "update", row.id)}
                    style={{ margin: "auto" }}
                  >
                    <EditIcon color="primary" />
                  </Button>
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell colSpan={7} align="center">
              {" "}
              No Data Found !!!
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};


const getDate = (dateString="")=>{
if(dateString){
  const [date] = dateString.split("T");
  return date;
}
return "";
}

const getTime = (dateString="")=>{
  if(dateString){
    const time = dateString.split("T")[1];
    return time?time.substring(0,8):"";
  }
  return "";
}