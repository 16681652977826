import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import moment from "moment";
import apiUrl from "../api";
import CardText from "components/Card/CardText.js";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const base_url = apiUrl;

let d = new Date();
d.setDate(d.getDate() - 1);
d.setHours(0);
d.setMinutes(0);
d.setSeconds(1);
let prev_date = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
//   " "
let prev_time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();

let tempDate = new Date();
tempDate.setHours(23);
tempDate.setMinutes(59);
tempDate.setSeconds(59);
let date =
  tempDate.getFullYear() +
  "-" +
  (tempDate.getMonth() + 1) +
  "-" +
  tempDate.getDate();
//   " ";
let time =
  tempDate.getHours() +
  ":" +
  tempDate.getMinutes() +
  ":" +
  tempDate.getSeconds();

class PaymentIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: prev_date,
      selectedendDate: date,
      selectedTime: prev_time,
      selectedendTime: time,
      status: "all",
      payments: [],
      user_id: 0,
      admin_user: false,
      agent_user: true,
    };
  }
  componentDidMount() {}
  handleselectChange = (event) => {
    this.setState({ status: event.target.value });
  };
  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };
  handleEndDateChange = (date) => {
    this.setState({ selectedendDate: date });
  };
  handleTimeChange = (date) => {
    this.setState({ selectedTime: date });
  };
  handleEndTimeChange = (date) => {
    this.setState({ selectedendTime: date });
  };
  onSearch = (e) => {
    e.preventDefault();
    // const base_url = window.location.origin;

    const startDate = moment(this.state.selectedDate).format("YYYY-MM-DD");
    const endDate = moment(this.state.selectedendDate).format("YYYY-MM-DD");
    const startTime = moment(this.state.selectedTime, "HH:mm:ss").format(
      "HH:mm:ss"
    );
    const endTime = moment(this.state.selectedendTime, "HH:mm:ss").format(
      "HH:mm:ss"
    );
    this.setState({ loading: true });
    axios({
      method: "get",
      url: base_url + "/payments.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
        statusSearch: this.state.status,
      },
    })
      .then((result) => {
        if (result.data) {
          // console.log(result.data, "Result");
          this.setState({
            payments: result.data,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          show_error: true,
          error_message: error.response.data
            ? error.response.data.message
            : error.message,
        });
        console.log("Error", error);
        this.setState({ loading: false });
      });
  };

  get getData() {
    return this.state.payments.map((row) => ({
      id:
        row.pmt.id +
        " (" +
        row.pmt.transmitted
          .toString()
          .charAt(0)
          .toUpperCase() +
        ") ",
      currency: row.pmt.currency,
      amount: row.pmt.amount,
      aasm_state: row.pmt.aasm_state,
      client_name: row.client_name,
      client_pmt_id: row.pmt.client_pmt_id,
      ref_no: row.pmt.ref_no,
      payment_gateway: row.pmt.payment_gateway,
      customer_email: row.pmt.customer_email,
      customer_id: row.pmt.customer_id,
      created_at_date: row.created_at_date,
      created_time: row.created_time,
      updated_at_date: row.updated_at_date,
      updated_time: row.updated_time,
      gateway_message: row.pmt.gateway_message,
      show: this.showButton(row),
    }));
  }
  handleShow = (event, txn_id) => {
    event.preventDefault();
    console.log(txn_id);
    localStorage.setItem("pmt_id", txn_id);
    console.log(localStorage.getItem("pmt_id"));
    this.setState({ isTimelog: true });
    window.open("/admin/search_biller", "_blank");
  };
  showButton = (row) => {
    return (
      <Button
        color="info"
        size="sm"
        onClick={(e) => this.handleShow(e, row.pmt.id)}
      >
        Show
      </Button>
    );
  };
  handleXLSDownload = (e) => {
    e.preventDefault();
    const startDate = moment(this.state.selectedDate).format("YYYY-MM-DD");
    const endDate = moment(this.state.selectedendDate).format("YYYY-MM-DD");
    const startTime = moment(this.state.selectedTime, "HH:mm:ss").format(
      "HH:mm:ss"
    );
    const endTime = moment(this.state.selectedendTime, "HH:mm:ss").format(
      "HH:mm:ss"
    );
    axios({
      method: "get",
      url: base_url + "/payments.xls",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        "Content-type": "application/vnd.ms-excel",
      },
      responseType: "arraybuffer",
      params: {
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
        statusSearch: this.state.status,
      },
    }).then((result) => {
      if (result.data) {
        // debugger;
        // console.log(result.data);
        const url = window.URL.createObjectURL(
          new Blob([result.data], { type: "application/vnd.ms-excel" })
        );
        const link = document.createElement("a");
        const fileName =
          "payments_startDate=" +
          startDate +
          "&startTime=" +
          startTime +
          "&endDate=" +
          endDate +
          "&endTime=" +
          endTime +
          "&statusSearch=" +
          this.state.status +
          ".xls";
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        console.log(result.data);
      }
    });
  };
  filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
      : true;
  };
  filterFirstMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    var arr = filter.value.split(" ");
    var data_type = "";
    function arraySome() {
      arr.forEach((item, index) => {
        if (parseInt(item)) {
          data_type = "Integer";
        } else {
          data_type = "String";
        }
      });
    }
    arraySome();
    switch (data_type) {
      case "Integer":
        return row[id] !== undefined
          ? String(row[id]).startsWith(filter.value)
          : true;
      default:
        return row[id] !== undefined
          ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
          : true;
    }
  };
  render() {
    const { classes } = this.props;
    const transactionData = this.getData;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={3}>
            <Card>
              <CardBody>
                <InputLabel className={classes.label}>Start Date</InputLabel>
                <br />
                <FormControl fullWidth>
                  <Datetime
                    timeFormat={false}
                    inputProps={{ placeholder: "Start Date" }}
                    id="start-date-picker-dialog"
                    label="Start Date"
                    dateFormat={"YYYY-MM-DD"}
                    value={this.state.selectedDate}
                    onChange={this.handleDateChange}
                  />
                </FormControl>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card>
              <CardBody>
                <InputLabel className={classes.label}>Start Time</InputLabel>
                <br />
                <FormControl fullWidth>
                  <Datetime
                    name
                    dateFormat={false}
                    inputProps={{ placeholder: "Start Time" }}
                    id="start-time-picker"
                    label="Start Time"
                    timeFormat={"HH:mm:ss"}
                    value={this.state.selectedTime}
                    onChange={this.handleTimeChange}
                  />
                </FormControl>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card>
              <CardBody>
                <InputLabel className={classes.label}>End Date</InputLabel>
                <br />
                <FormControl fullWidth>
                  <Datetime
                    timeFormat={false}
                    inputProps={{ placeholder: "End Date" }}
                    id="end-date-picker-dialog"
                    label="End Date"
                    dateFormat={"YYYY-MM-DD"}
                    value={this.state.selectedendDate}
                    onChange={this.handleEndDateChange}
                  />
                </FormControl>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card>
              <CardBody>
                <InputLabel className={classes.label}>End Time</InputLabel>
                <br />
                <FormControl fullWidth>
                  <Datetime
                    dateFormat={false}
                    inputProps={{ placeholder: "End Time" }}
                    id="end-date-picker-dialog"
                    label="End Date"
                    timeFormat="HH:mm:ss"
                    value={this.state.selectedendTime}
                    onChange={this.handleEndTimeChange}
                  />
                </FormControl>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card>
              <CardBody>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Status
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={this.state.status}
                    onChange={this.handleselectChange}
                    inputProps={{
                      name: "simpleSelect",
                      id: "simple-select",
                    }}
                  >
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="all"
                    >
                      All
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="successful"
                    >
                      Successful
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="failed"
                    >
                      failed
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="validated"
                    >
                      validated
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="processing"
                    >
                      processing
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="cancelled"
                    >
                      cancelled
                    </MenuItem>
                  </Select>
                </FormControl>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={3}>
            <Card>
              <CardBody>
                <FormControl fullWidth>
                  <Button color="rose" onClick={this.onSearch}>
                    Search
                  </Button>
                </FormControl>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card>
              <CardBody>
                <FormControl fullWidth>
                  <Button color="rose" onClick={this.handleXLSDownload}>
                    Export
                  </Button>
                </FormControl>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4 className={classes.cardTitle}>Biller Index</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={transactionData}
                  filterable
                  columns={[
                    {
                      Header: "ID",
                      accessor: "id",
                      filterMethod: this.filterFirstMethod,
                    },

                    {
                      Header: "Currency",
                      accessor: "currency",
                      filterMethod: this.filterMethod,
                    },
                    {
                      accessor: "amount",
                      Header: "Amount",
                    },
                    {
                      accessor: "aasm_state",
                      Header: "Status",
                      filterMethod: this.filterMethod,
                    },

                    {
                      accessor: "client_name",
                      Header: "Client Name",
                      filterMethod: this.filterMethod,
                    },
                    {
                      accessor: "client_pmt_id",
                      Header: "Client Biller Id",
                    },
                    {
                      accessor: "ref_no",
                      Header: "Biller Gateway Transaction ID",
                      filterMethod: this.filterMethod,
                    },
                    {
                      accessor: "payment_gateway",
                      Header: "Biller Gateway",
                      filterMethod: this.filterMethod,
                    },
                    {
                      accessor: "customer_email",
                      Header: "Receiver Email",
                      filterMethod: this.filterMethod,
                    },
                    {
                      accessor: "customer_id",
                      Header: "Receiver Customer Id",
                    },
                    {
                      accessor: "created_at_date",
                      Header: "Created At",
                    },
                    {
                      accessor: "created_time",
                      Header: "Created At Time",
                    },
                    {
                      accessor: "updated_at_date",
                      Header: "Updated At",
                    },

                    {
                      accessor: "updated_time",
                      Header: "Updated At Time",
                    },
                    {
                      accessor: "gateway_message",
                      Header: "Gateway Message",
                      filterMethod: this.filterMethod,
                    },
                    {
                      accessor: "show",
                      Header: "Show",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  // defaultFilterMethod={this.filterMethod}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
export default withStyles(cssstyles)(PaymentIndex);
