import React from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardText from "components/Card/CardText.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import apiUrl from "../api";
// import FormLabel from "@material-ui/core/FormLabel";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CustomSweet from "../Components/CustomSweet";
import Progress from "../Components/Progress";

const base_url = apiUrl;

class UpdatePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      new_password: "",
      confirm_password: "",
      valid_confirm_password: "",
      valid_new_password: "",
      loading: false,
    };
  }
  componentDidMount() {
    this.getUserInfo();
  }
  confirmChange = (event) => {
    this.setState({
      confirm_password: event.target.value,
      valid_confirm_password: "",
    });
  };
  newChange = (event) => {
    this.setState({ new_password: event.target.value, valid_new_password: "" });
  };
  getUserInfo = () => {
    axios({
      method: "get",
      url: base_url + "/users/get_user_info.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {},
    })
      .then((result) => {
        console.log(result);
        this.setState({ user_id: result.data.user.id });
      })
      .catch((error) => {
        this.setState({
          show_error: true,
          error_message: error.response.data
            ? error.response.data.message
            : error.message,
        });
      });
  };
  hideAlert = (times) => {
    this.setState({
      alert: false,
      alert_msg: "",
      alert_title: "",
      alert_type: "",
    });
  };
  reset = () => {
    this.setState({
      new_password: "",
      confirm_password: "",
      valid_new_password: "",
      valid_confirm_password: "",
    });
  };
  handlePasswordUpdate = () => {
    // event.preventDefault();
    var new_password = this.state.new_password;
    var confirm_password = this.state.confirm_password;
    if (new_password === "") {
      this.setState({
        valid_new_password: "false",
      });
      alert("Please enter new password");
      return;
    }
    if (confirm_password === "") {
      this.setState({
        valid_confirm_password: "false",
      });
      alert("Please enter confirm password");
      return;
    }
    if (new_password !== confirm_password) {
      this.setState({
        valid_confirm_password: "false",
        valid_new_password: "false",
      });
      alert("Passwords don't match");
      return;
    }
    axios({
      method: "put",
      url: base_url + "/users/update_password/" + this.state.user_id + ".json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        "Content-Type": "application/json",
      },
      params: { new_password: confirm_password },
    })
      .then((result) => {
        //access the results here....
        this.setState({
          loading: false,
          alert: true,
          alert_msg: "Your password updated successfully.!",
          alert_title: "Change Password!",
          danger: false,
          warning: false,
          success: true,
          open: false,
          new_password: "",
          confirm_password: "",
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log("Error", error.response);
        this.setState({
          loading: false,
          alert: true,
          alert_msg: error,
          alert_title: "Error!",
          danger: true,
          warning: false,
          success: false,
        });
      });
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <CustomSweet
          alert={this.state.alert}
          message={this.state.alert_msg}
          title={this.state.alert_title}
          hide_alert={this.hideAlert}
          danger={this.state.danger}
          warning={this.state.warning}
          success={this.state.success}
          times={this.state.times}
        />
        <div className={this.state.loading === true ? classes.main_screen : ""}>
          <div align="center">
            {this.state.loading === true && <Progress />}
          </div>
          <GridContainer>
            <GridItem xs={12} md={12} lg={12}>
              <Card>
                <CardHeader color="rose" text>
                  <CardText color="rose">
                    <h4>Update Password</h4>
                  </CardText>
                </CardHeader>
                <br />
                <br />
                <CardBody>
                  {" "}
                  <form>
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={6} md={6} lg={4}>
                        <CustomInput
                          id="new_password"
                          labelText="New Password *"
                          success={this.state.valid_new_password === ""}
                          error={this.state.valid_new_password === "false"}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "password",
                            value: this.state.new_password,
                            onChange: this.newChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={6} md={6} lg={4}>
                        <CustomInput
                          labelText="Confirm Password *"
                          success={this.state.valid_confirm_password === ""}
                          error={this.state.valid_confirm_password === "false"}
                          id="confirm_password"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "password",
                            value: this.state.confirm_password,
                            onChange: this.confirmChange,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                    <br />
                    <GridContainer justify="center">
                      <GridItem>
                        <Button onClick={this.reset}>Reset</Button>
                      </GridItem>
                      <GridItem>
                        <Button
                          color="rose"
                          onClick={this.handlePasswordUpdate}
                        >
                          Submit
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(UpdatePassword);
