export const mobileProviderList = {
  GH: [
    { id: 1, name: "Vodafone Cash", code: "00VDFM" },
    {
      id: 2,
      name: "MTN",
      code: "00MTNG",
    },
    {
      id: 3,
      name: "Airtel tigo",
      code: "00TIGM",
    },
  ],
  GW: [{ id: 1, name: "MTN", code: "00mtn" }],
  BJ: [
    { id: 1, name: "MTN", code: "00mtn" },
    {
      id: 2,
      name: "Moov",
      code: "00moov",
    },
  ],
  TG: [{ id: 1, name: "Moov", code: "00moov" }],
  NE: [{ id: 1, name: "Airtel Money", code: "00airtel" }],
  ML: [
    { id: 1, name: "Orange", code: "00orange" },
    {
      id: 2,
      name: "Mobi Cash",
      code: "00mobicash",
    },
  ],
  BF: [
    { id: 1, name: "Orange", code: "00orange" },
    {
      id: 2,
      name: "YUP",
      code: "00yup",
    },
    {
      id: 3,
      name: "Mobi Cash",
      code: "00mobicash",
    },
  ],
  SN: [
    {
      id: 1,
      name: "Orange",
      code: "00orange",
    },
    {
      id: 2,
      name: "Wizwall",
      code: "00wizwall",
    },
    {
      id: 3,
      name: "EMONEY",
      code: "00emoney",
    },
    {
      id: 4,
      name: "Tigo",
      code: "00tigo",
    },
  ],
  CI: [
    {
      id: 1,
      name: "Orange",
      code: "00orange",
    },
    {
      id: 2,
      name: "MTN",
      code: "00mtn",
    },
    {
      id: 3,
      name: "Moov",
      code: "00moov",
    },
    {
      id: 4,
      name: "Wizwall",
      code: "00wizwall",
    },
  ],
  SL: [
    {
      id: 1,
      name: "Africell",
      code: "00africell",
    },
    {
      id: 2,
      name: "Orange",
      code: "00orange",
    },
  ],
};
