import React, { Component } from "react";
import axios from "axios";
import apiUrl from "../api";
import { ValidateComponent, ValidationTypes } from "../../utils/validationHoc";
import CreateDebitAccountView from "./creatDebitAccountView";
import { isObjectEmpty } from "utils/commonCheckFunctions";
import { makeApiCall } from "utils/commonCheckFunctions";

const base_url = apiUrl;
const userType = localStorage.getItem("user_type");
const SUPERADMINUSER = "superadmin";

const schema = {
  bankName: [ValidationTypes.required],
  bankCode: [ValidationTypes.required],
  accountNumber: [ValidationTypes.required],
  paymentGateway: [ValidationTypes.required],
  selectedBA:userType===SUPERADMINUSER?[ValidationTypes.required]:[],
};
class CreatDebitAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        bankName: "",
        bankCode: "",
        accountNumber: "",
        paymentGateway: "",
        authorization: "",
        selectedBA:"",
        baUsers:[],
      },
      alert: false,
      message: "",
      title: "",
      error: false,
      success: false,
      loading: false,
      paymentGatewayList: [],
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState((state) => ({
      values: { ...state.values, [name]: value },
    }));
  };

  hideCustomAlert = () => {
    this.setState({
      alert: false,
      error: false,
      success: false,
      title: "",
      message: "",
    });
  };

  componentDidMount(){
    if(userType === SUPERADMINUSER){
       this.getAllBaUsers();
    }
    this.getPaymentGateways();

  }

  getAllBaUsers = async () => {
    const data = await makeApiCall({
      url: "/users.json",
      method: "get",
      params: { role: "ba" },
    });
    const { error = false } = data;
    if (!error) {
      this.setState((state) => ({
        values: { ...state.values, baUsers: data },
      }));
    } else {
      const { message } = data;
      console.log(message);
    }
  };

  showAlert = (message = "") => {
    alert(message);
  };
  handleCreate = () => {
    const { validateForm, errors } = this.props;
    validateForm(this.state.values);
  };

  componentDidUpdate(prevProps) {
    const { errors } = this.props;
    const { errors: prevErrors } = prevProps;
    const isEmpty = isObjectEmpty(errors);
    if (errors !== prevErrors && isEmpty) {
      this.createDebitAccount();
    } else if (errors !== prevErrors && !isEmpty) {
      this.showAlert(errors[Object.keys(errors)[0]]);
    }
  }


  getPaymentGateways = () => {
    axios({
      method: "get",
      url: base_url + "/payment_gateways.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {},
    })
      .then((result) => {
        //access the results here....
        this.setState({
          paymentGatewayList: result.data.payment_gateways,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  createDebitAccount() {
    this.setState({ loading: true });
    const {
      bankCode,
      bankName,
      paymentGateway,
      accountNumber,
      authorization,
      selectedBA
    } = this.state.values;
    const params = {
      bank_name: bankName,
      bank_code: bankCode,
      account_number: accountNumber,
      payment_gateway: paymentGateway,
      authorization: authorization,
      ...(userType === SUPERADMINUSER? {ba_id:selectedBA}:{})
    };
    axios({
      method: "post",
      url: base_url + "/debit_accounts/create.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: params,
    })
      .then(() => {
        //access the results here....
        this.setState({
          loading: false,
          alert: true,
          message: "Debit Account Created Successfully!!",
          title: "Create Debit Account",
          success: true,
          error: false,
        });
        this.clearFields();
      })
      .catch((error) => {
        console.log("Error", error);
        this.setState({
          loading: false,
          alert: true,
          message: "Something went wrong !!",
          title: "Error",
          error: true,
          success: false,
        });
      });
  }

  clearFields = () => {
    this.setState((prevState)=>({
      ...prevState,
      values: {
        ...prevState.values,
        bankName: "",
        bankCode: "",
        paymentGateway: "",
        accountNumber: "",
        authorization: "",
        selectedBA:"",
      }
    }));
  };

  render() {
    const { errors } = this.props;
    // console.log(errors);
    return (
      <>
        <CreateDebitAccountView
          {...this.state}
          errors={errors}
          handleChange={this.handleChange}
          handleCreate={this.handleCreate}
          hideAlert={this.hideCustomAlert}
          handleCancel={this.clearFields}
        ></CreateDebitAccountView>
      </>
    );
  }
}

export default ValidateComponent(schema)(CreatDebitAccount);
