import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardText from "components/Card/CardText.js";
import CardHeader from "components/Card/CardHeader.js";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import moment from "moment";
import Progress from "../Components/Progress";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import apiUrl from "../api";
import actionCableUrl from "../action_cable_api";
// import { makeStyles } from "@material-ui/core";
import TableView from "./transactionTableView";
import { formatTime } from "utils/commonCheckFunctions";
import { formatDate } from "utils/commonCheckFunctions";
import CustomSweet from "../Components/CustomSweet";

const actioncable = require("actioncable");

const base_url = apiUrl;
const today = new Date();
today.setHours(23);
today.setMinutes(59);
today.setSeconds(59);
const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);
yesterday.setHours(0);
yesterday.setMinutes(0);
yesterday.setSeconds(1);

const userType = localStorage.getItem("user_type");
const SUPERADMINUSER = "superadmin";
const allStatus = [
  {
    value: "all",
    name: "All",
  },
  {
    value: "successful",
    name: "Successful",
  },
  {
    value: "failed",
    name: "Failed",
  },
  {
    value: "validated",
    name: "Validated",
  },
  {
    value: "processing",
    name: "Processing",
  },
  {
    value: "cancelled",
    name: "Cancelled",
  },
];

const selectByOptions = [
  {
    value: "created_at",
    name: "Created At",
  },
  {
    value: "updated_at",
    name: "Updated At",
  },
];

class Transaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startTime: formatTime(yesterday),
      startDate: formatDate(yesterday),
      endTime: formatTime(today),
      endDate: formatDate(today),
      loading: false,
      status: "all",
      selectBy: "created_at",
      transactions: [],
      adminUser: localStorage.getItem("user_type") === "admin",
      agentUser: localStorage.getItem("user_type") === "agent",
      superAdminUser: localStorage.getItem("user_type") === "superadmin",
      supportUser: localStorage.getItem("user_type") === "support",
      alert: false,
      success: false,
      alertTitle: "",
      alertMesaage: "",
      error: false,
      warning: false,
    };

    this.cable = actioncable.createConsumer(actionCableUrl);

    this.cableChannel = this.cable.subscriptions.create("TransactionsChannel", {
      received: (data) => {
        // console.log("Data", data["transaction"]);
        this.ReceiveDataFromCable(data);
      },
    });
  }

  componentWillUnmount() {
    this.cableChannel.unsubscribe();
    this.cable.disconnect();
  }

  ReceiveDataFromCable = (data = {}) => {
    const {
      transaction,
      transaction: {
        txn: { ba_id },
      },
    } = data;
    const currentUserBaId = Number(localStorage.getItem("ba_id"));
    if (userType !== SUPERADMINUSER && ba_id === currentUserBaId) {
      this.updateOrAddNewTransaction(transaction);
    } else if(userType === SUPERADMINUSER) {
      this.updateOrAddNewTransaction(transaction);
    }
  };

  updateOrAddNewTransaction = (transaction) => {
    const { transactions: allTransctions } = this.state;
    if (transaction) {
      const listTransaction = allTransctions.filter(
        (element) => element.txn.id === transaction.txn.id
      );
      const [foundTransction] = listTransaction;
      if (foundTransction) {
        // console.log("updating");
        const updatedTransactions = allTransctions.map((el) => {
          el = el.txn.id === transaction.txn.id ? transaction : el;
          return el;
        });
        this.setState({ transactions: updatedTransactions });
      } else {
        const shouldAdd = this.shouldAddNewBroadCastTransaction(transaction);
        // console.log(shouldAdd);
        if (shouldAdd) {
          this.setState({ transactions: [transaction, ...allTransctions] });
        }
      }
    }
  };

  shouldAddNewBroadCastTransaction = (transaction) => {
    const {
      created_at_date,
      updated_at_date,
      txn: { aasm_state, client_id },
    } = transaction;
    //selectedDate and selectedendDate are moment object
    const {
      startDate,
      endDate,
      status,
      selectBy,
      agent_user: agent,
      user_client,
    } = this.state;
    const timeZone = "T00:00:00"; //to convert it into utc time zone
    const creationTime = new Date(created_at_date + timeZone).getTime();
    const updationTime = new Date(updated_at_date + timeZone).getTime();
    const selectedTime = new Date(startDate).getTime();
    const selectedEndTime = new Date(endDate).getTime();
    const TimeRequiredToCompare =
      selectBy === "created_at" ? creationTime : updationTime;

    if (
      (status === aasm_state || status === "all") &&
      (selectedTime <= TimeRequiredToCompare &&
        TimeRequiredToCompare <= selectedEndTime)
    ) {
      if (agent) {
        if (user_client) {
          return user_client.id === client_id;
        } else {
          return false;
        }
      }
      return true;
    }
    return false;
  };

  handleDateChange = (date) => {
    this.setState({ startDate: date });
  };
  handleEndDateChange = (date) => {
    this.setState({ endDate: date });
  };
  handleTimeChange = (date) => {
    this.setState({ startTime: date });
  };
  handleEndTimeChange = (date) => {
    this.setState({ endTime: date });
  };
  handleselectChange = (event) => {
    this.setState({ status: event.target.value });
  };
  handleSelectByChange = (event) => {
    this.setState({
      selectBy: event.target.value,
    });
  };

  onSearch = () => {
    // console.log(this.state, this.ReactTableRef);
    const {
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      startTime: selectedStartTime,
      endTime: selectedEndTime,
      status,
      selectBy,
    } = this.state;
    const startDate = moment(selectedStartDate).format("YYYY-MM-DD");
    const endDate = moment(selectedEndDate).format("YYYY-MM-DD");
    const startTime = moment(selectedStartTime, "HH:mm:ss").format("HH:mm:ss");
    const endTime = moment(selectedEndTime, "HH:mm:ss").format("HH:mm:ss");

    this.setState({ loading: true });
    axios({
      method: "get",
      url: base_url + "/transactions.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
        statusSearch: status,
        create_or_update_filter: selectBy,
      },
    })
      .then((result) => {
        if (result.data) {
          this.setState({
            transactions: result.data.transactions,
            adminUser: result.data.admin_user,
            agentUser: result.data.agent_user,
            supportUser: result.data.support_user,
            userClient: result.data.user_client,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          show_error: true,
          error_message: error.response.data
            ? error.response.data.message
            : error.message,
          loading: false,
        });
        console.log("Error", error, this.props);
      });
  };
  submitForExcel = (event) => {
    event.preventDefault();
    // const base_url = window.location.origin;
    const {
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      startTime: selectedStartTime,
      endTime: selectedEndTime,
      status,
      selectBy,
    } = this.state;
    const startDate = moment(selectedStartDate).format("YYYY-MM-DD");
    const endDate = moment(selectedEndDate).format("YYYY-MM-DD");
    const startTime = moment(selectedStartTime, "HH:mm:ss").format("HH:mm:ss");
    const endTime = moment(selectedEndTime, "HH:mm:ss").format("HH:mm:ss");

    axios({
      method: "get",
      url: base_url + "/transactions.xls",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        "Content-type": "application/vnd.ms-excel",
      },
      responseType: "arraybuffer",
      params: {
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
        statusSearch: status,
        create_or_update_filter: selectBy,
      },
    }).then((result) => {
      if (result.data) {
        // debugger;
        // console.log(result.data);
        const url = window.URL.createObjectURL(
          new Blob([result.data], { type: "application/vnd.ms-excel" })
        );
        const link = document.createElement("a");
        const fileName =
          "transactions_startDate=" +
          startDate +
          "&startTime=" +
          startTime +
          "&endDate=" +
          endDate +
          "&endTime=" +
          endTime +
          "&statusSearch=" +
          this.state.status +
          ".xls";
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        // console.log(result.data);
      }
    });
  };

  setLoading = (value = false) => {
    this.setState({ loading: value });
  };

  setCustomAlertSweet = ({
    alert,
    message = "",
    title = "",
    error = false,
    warning = false,
    success = true,
  }) => {
    this.setState({
      alert,
      alertMesaage: message,
      alertTitle: title,
      danger: error,
      warning,
      success,
    });
  };
  hideAlert = () => {
    this.setState({
      alert: false,
    });
  };

  render() {
    const { classes } = this.props;
    const {
      adminUser,
      agentUser,
      supportUser,
      superAdminUser,
      transactions,
      startDate,
      endDate,
      endTime,
      startTime,
      status,
      selectBy,
      loading,
      alert,
      alertMesaage,
      alertTitle,
      error,
      success,
      warning,
    } = this.state;
    return (
      <>
        <CustomSweet
          alert={alert}
          message={alertMesaage}
          title={alertTitle}
          hide_alert={this.hideAlert}
          danger={error}
          warning={warning}
          success={success}
        />
        <div className={loading ? classes.main_screen : ""}>
          <div align="center">{loading && <Progress />}</div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody>
                  <InputLabel className={classes.label}>Start Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      inputProps={{ placeholder: "Start Date" }}
                      id="start-date-picker-dialog"
                      label="Start Date"
                      dateFormat={"YYYY-MM-DD"}
                      value={startDate}
                      onChange={this.handleDateChange}
                    />
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody>
                  <InputLabel className={classes.label}>Start Time</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      name
                      dateFormat={false}
                      inputProps={{ placeholder: "Start Time" }}
                      id="start-time-picker"
                      label="Start Time"
                      timeFormat={"HH:mm:ss"}
                      value={startTime}
                      onChange={this.handleTimeChange}
                    />
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody>
                  <InputLabel className={classes.label}>End Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      inputProps={{ placeholder: "End Date" }}
                      id="end-date-picker-dialog"
                      label="End Date"
                      dateFormat={"YYYY-MM-DD"}
                      value={endDate}
                      onChange={this.handleEndDateChange}
                    />
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody>
                  <InputLabel className={classes.label}>End Time</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      dateFormat={false}
                      inputProps={{ placeholder: "End Time" }}
                      id="end-date-picker-dialog"
                      label="End Date"
                      timeFormat="HH:mm:ss"
                      value={endTime}
                      onChange={this.handleEndTimeChange}
                    />
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel className={classes.selectLabel}>
                      Status
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={status}
                      onChange={this.handleselectChange}
                      inputProps={{
                        name: "simpleSelect",
                      }}
                    >
                      {allStatus.map((row) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={row.value}
                          key={row.value}
                        >
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel className={classes.selectLabel}>
                      Select By
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={selectBy}
                      onChange={this.handleSelectByChange}
                      inputProps={{
                        name: "simpleSelect",
                      }}
                    >
                      {selectByOptions.map((row) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={row.value}
                          key={row.value}
                        >
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <Button color="rose" onClick={this.onSearch}>
                      Search
                    </Button>
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <Button color="rose" onClick={this.submitForExcel}>
                      Export
                    </Button>
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="rose" text>
                  <CardText color="rose">
                    <h4 className={classes.cardTitle}>Transactions</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <TableView
                    data={transactions}
                    adminUser={adminUser}
                    agentUser={agentUser}
                    supportUser={supportUser}
                    setLoading={this.setLoading}
                    setAlert={this.setCustomAlertSweet}
                    superAdminUser={superAdminUser}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </>
    );
  }
}
export default withStyles(cssstyles)(Transaction);
