import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import CustomSweet from "../Components/CustomSweet";
import Progress from "../Components/Progress";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import apiUrl from "../api";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { makeApiCall } from "utils/commonCheckFunctions";

const base_url = apiUrl;
const userType = localStorage.getItem("user_type");
const SUPERADMINUSER = "superadmin";
class IOCatalogDetailsScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      billerCategoryId: "",
      sku: "",
      client: "",
      allBillerCategoryId: [],
      allSku: [],
      allCountries: [],
      country: "",
      allClients: [],
      ioCatalogList: [],
      selectedBA: "",
      baUsers: [],
      validBillerCategoryId: true,
      validSku: true,
      validClient: true,
      validCountry: true,
      alert: false,
      alertMsg: "",
      alertTitle: "",
      message: "",
      success: true,
      danger: false,
      warning: false,
      loading: false,
    };
  }

  componentDidMount() {
    if (userType !== SUPERADMINUSER) {
      this.getAllClients();
    } else {
      this.getAllBaUsers();
    }
    this.getAllCountries();
  }
  componentDidUpdate(prevProps, prevState) {
    const { selectedBA: prevSelectedBA } = prevState;
    const { selectedBA } = this.state;
    if (prevSelectedBA !== selectedBA && userType === SUPERADMINUSER) {
      this.getAllClients();
    }
  }

  handleBillerCategoryIdChange = (event) => {
    this.setState(
      {
        billerCategoryId: event.target.value,
        validBillerCategoryId: true,
      },
      () => {
        this.getAllSku();
      }
    );
  };

  handleClientChange = (event) => {
    this.setState({
      client: event.target.value,
      validClient: true,
    });
  };

  handleSkuChange = (event) => {
    this.setState({
      sku: event.target.value,
      validSku: true,
    });
  };

  handleCountryChange = (event) => {
    const value = event.target.value;
    this.setState(
      {
        country: value,
        validCountry: true,
        billerCategoryId: "",
        validBillerCategoryId: true,
        sku: "",
        validSku: true,
        allSku: [],
      },
      () => {
        this.getBillerCategoryId();
      }
    );
  };

  getAllClients() {
    // const base_url = window.location.origin;
    const params =
    userType === SUPERADMINUSER ? { ba_id: this.state.selectedBA } : {};
    axios({
      method: "get",
      url: base_url + "/clients.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {...params},
    })
      .then((result) => {
        //access the results here....
        // console.log(result);
        this.setState({
          allClients: result.data.clients,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }

  getAllCountries = () => {
    axios({
      method: "get",
      url: base_url + "/country_codes.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
    })
      .then((result) => {
        //access the results here....
        this.setState({
          allCountries: result.data.countries,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getBillerCategoryId = () => {
    const { country } = this.state;
    if (country) {
      axios({
        method: "get",
        url: base_url + "/payments/getBillerCatalog",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        },
        params: {
          country_name: country,
        },
      })
        .then((result) => {
          //access the results here....
          this.setState({
            allBillerCategoryId: result.data,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  getAllSku = () => {
    axios({
      method: "get",
      url: base_url + "/payments/getBillerSku",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        biller_id: this.state.billerCategoryId,
      },
    })
      .then((result) => {
        //access the results here....
        this.setState({
          allSku: result.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getAllBaUsers = async () => {
    const data = await makeApiCall({
      url: "/users.json",
      method: "get",
      params: { role: "ba" },
    });
    const { error = false } = data;
    if (!error) {
      this.setState({
        baUsers: data,
      });
    } else {
      const { message } = data;
      console.log(message);
    }
  };

  validateForm() {
    const { billerCategoryId, sku, client, selectedBA} = this.state;

    if (billerCategoryId === "") {
      alert("Please select Biller Category");
      this.setState({ validBillerCategoryId: false });
      return false;
    }
    if (sku === "") {
      this.setState({ validSku: false });
      alert("Please enter Sku");
      return false;
    }
    if (selectedBA === "" && userType === SUPERADMINUSER) {
      alert("Please select BA !!!");
      return false;
    }

    if (client === "") {
      this.setState({
        validClient: false,
      });
      alert("Please enter client");
      return false;
    }

    return true;
  }

  hideAlert = () => {
    this.setState({
      alert: false,
      message: "",
      alertTitle: "",
      danger: false,
      success: true,
      warning: false,
    });
  };

  handlepay = (event) => {
    event.preventDefault();
    if (this.validateForm()) {
      this.setState({ loading: true });
      this.createSignature();
    }
  };

  createSignature = () => {
    const { billerCategoryId, sku, client } = this.state;
    let paramList =
      "biller_catalog_id=" +
      billerCategoryId +
      "&sku=" +
      sku +
      "&client_id=" +
      client;
    this.setState({ loading: true });
    axios({
      method: "post",
      url: base_url + "/payments/create_paykii_io_signature.json?" + paramList,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {},
    })
      .then((result) => {
        //access the results here....
        // console.log(result);
        this.setState({ loading: false });
        this.getCatalog(result.data);
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log("Error", error);
        // swal("Error!", "Error. Something went wrong. Please retry.", "error");
      });
  };

  cleanInputs = () => {
    this.setState({
      billerCategoryId: "",
      sku: "",
      country: "",
      allBillerCategoryId: [],
      allSku: [],
      client: "",
      ioCatalogList: [],
      selectedBA:"",
    });
  };

  handleBaChange=(event)=>{
    this.setState({
      selectedBA:event.target.value,
      client:"",
    })
   }
 
   getBAUserList() {
     const { classes } = this.props;
     if (userType === SUPERADMINUSER) {
       return (
         <FormControl fullWidth className={classes.selectFormControl}>
           <InputLabel className={classes.selectLabel}>{"BA *"}</InputLabel>
 
           <Select
             MenuProps={{
               className: classes.selectMenu,
             }}
             classes={{
               select: classes.select,
             }}
             value={this.state.selectedBA}
             onChange={this.handleBaChange}
           >
             {this.state.baUsers.map((row) => (
               <MenuItem
                 classes={{
                   root: classes.selectMenuItem,
                   selected: classes.selectMenuItemSelected,
                 }}
                 value={row.id}
                 key={row.id}
               >
                 {row.name}
               </MenuItem>
             ))}
           </Select>
         </FormControl>
       );
     } else {
       return null;
     }
   }

  getCatalog = (data) => {
    const { billerCategoryId, sku } = this.state;
    let paramList =
      "api_token=" +
      data["api_token"] +
      "&biller_catalog_id=" +
      billerCategoryId +
      "&sku=" +
      sku +
      "&tonce=" +
      data["tonce"] +
      "&signature=" +
      data["signature"];
    this.setState({ loading: true });
    axios({
      method: "get",
      url: base_url + "/api/v1/sku_io_catalog?" + paramList,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {},
    })
      .then((result) => {
        //access the results here....

        this.setState({
          loading: false,
          ioCatalogList: result.data.io_catalog ? result.data.io_catalog : [],
        });
        // window.location.reload();
      })
      .catch((error) => {
        this.setState({
          loading: false,
          alert: true,
          message: error.response.data.error.message,
          alertTitle: "Error!",
          danger: true,
          warning: false,
          success: false,
        });
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <CustomSweet
          alert={this.state.alert}
          message={this.state.message}
          title={this.state.alertTitle}
          hide_alert={this.hideAlert}
          danger={this.state.danger}
          warning={this.state.warning}
          success={this.state.success}
        />
        <div className={this.state.loading === true ? classes.main_screen : ""}>
          <div align="center">
            {this.state.loading === true && <Progress />}
          </div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" text>
                  <CardText color="rose">
                    <h4 className={classes.cardTitle}>IO Catalog</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <form>
                    <GridContainer justify="center">
                      <GridItem xs={12} md={6} sm={10}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                            success={this.state.validCountry}
                            error={!this.state.validCountry}
                          >
                            {"Country *"}
                          </InputLabel>

                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.country}
                            onChange={this.handleCountryChange}
                            inputProps={{
                              name: "simpleSelect",
                            }}
                          >
                            {this.state.allCountries.map((row) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value={`${row.attributes.name}`}
                                key={row.attributes.id}
                              >
                                {row.attributes.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      <GridItem xs={12} md={6} sm={10}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                            success={this.state.validBillerCategoryId}
                            error={!this.state.validBillerCategoryId}
                          >
                            {"Biller Catalog Id *"}
                          </InputLabel>

                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.billerCategoryId}
                            onChange={this.handleBillerCategoryIdChange}
                            inputProps={{
                              name: "simpleSelect",
                            }}
                          >
                            {this.state.allBillerCategoryId.map((row) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value={row.biller_id}
                                key={row.id}
                              >
                                {row.biller_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      <GridItem
                        xs={12}
                        md={6}
                        sm={10}
                        style={{ height: "50px" }}
                      >
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                            success={this.state.validSku}
                            error={!this.state.validSku}
                          >
                            {"Sku *"}
                          </InputLabel>

                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.sku}
                            onChange={this.handleSkuChange}
                            inputProps={{
                              name: "simpleSelect",
                            }}
                            autoWidth
                          >
                            {this.state.allSku.map((row) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value={row.sku}
                                key={row.id}
                              >
                                {`${row.sku} (${row.description})`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      <GridItem xs={12} md={6} sm={10}>
                      {this.getBAUserList()}
                      </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      <GridItem xs={12} md={6} sm={10}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                            success={this.state.validClient}
                            error={!this.state.validClient}
                          >
                            {"Client *"}
                          </InputLabel>

                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.client}
                            onChange={this.handleClientChange}
                            inputProps={{
                              name: "simpleSelect",
                            }}
                            
                          >
                            {this.state.allClients.map((row) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value={row.id}
                                key={row.id}
                              >
                                {row.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    {this.state.ioCatalogList.length > 0 && (
                      <GridContainer justify="center">
                        <GridItem xs={12} md={6} sm={10}>
                          <Typography
                            style={{ marginTop: "20px" }}
                            className={classes.title}
                          >
                            <b> Details Required As Inputs </b>
                          </Typography>
                          <List>
                            {this.state.ioCatalogList.map((data, index) => {
                              return (
                                <ListItem
                                  key={index}
                                  style={{ alignItems: "flex-start" }}
                                >
                                  <ListItemIcon style={{ margin: "4px 0px" }}>
                                    <b>{data.Name}: &nbsp;</b>
                                  </ListItemIcon>
                                  <ListItemText primary={data.Description} />
                                </ListItem>
                              );
                            })}
                          </List>
                        </GridItem>
                      </GridContainer>
                    )}

                    <br />
                    <br />
                    <GridContainer justify="center">
                      <GridItem
                        xs={12}
                        md={3}
                        sm={3}
                        style={{ height: "50px" }}
                      >
                        <FormControl fullWidth>
                          <Button onClick={this.cleanInputs}>Reset</Button>
                        </FormControl>
                      </GridItem>
                      <GridItem
                        xs={12}
                        md={3}
                        sm={3}
                        style={{ height: "50px" }}
                      >
                        <FormControl fullWidth>
                          <Button color="rose" onClick={this.handlepay}>
                            Submit
                          </Button>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </>
    );
  }
}

export default withStyles(cssstyles)(IOCatalogDetailsScreen);
